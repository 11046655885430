import type { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";
import { getValueFromUiSchema } from "@/utils/schema";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";

export const MEMORIA_APIS = [
  {
    title: "minner.no",
    domain: "api.minner.no",
  },
  {
    title: "minnen.se",
    domain: "api.minnen.se",
  },
] as const;

export interface MemoriaObject {
  domain: (typeof MEMORIA_APIS)[number]["domain"];
  topicId: string;
}

// a small section of the topic API response, as it's quite huge.
type MemoriaAPITopic = {
  uuid: string;
  name: string;
  owner: {
    uuid: string;
    name: string;
  };
};

function mapTopic(topic: MemoriaAPITopic): MemoriaAPITopic {
  return {
    uuid: topic.uuid,
    name: topic.name,
    owner: {
      uuid: topic.owner.uuid,
      name: topic.owner.name,
    },
  };
}

type PagedMemoriaListTopicsResponse = {
  items: MemoriaAPITopic[];
  count: number;
  limit: number;
  offset: number;
  total_count: number;
};

export interface MemoriaAPIFieldProps extends Omit<FieldProps, "formData"> {
  formData?: MemoriaObject;
}

const defaultFormData: MemoriaObject = Object.freeze({
  domain: MEMORIA_APIS[0].domain,
  topicId: "",
});

const cachedResponses: Record<string, PagedMemoriaListTopicsResponse> = {};
const inFlightRequests: Record<string, Promise<PagedMemoriaListTopicsResponse> | null> = {};
const isValidDomain = (domain?: string): domain is string =>
  !!domain && MEMORIA_APIS.map((a) => a.domain as string).indexOf(domain) !== -1;

const createRequest = (apiDomain: string) => {
  const request = fetch(`https://${apiDomain}/api/topics/?limit=500`).then(parseResponse);
  inFlightRequests[apiDomain] = request;
  return request;
};

const parseResponse = (response: Response) => {
  if (!response.ok) {
    throw new Error(`Failed to fetch topics: ${response.status} - ${response.statusText}`);
  }
  return response.json() as Promise<PagedMemoriaListTopicsResponse>;
};

const getTopicsForAPI = async (apiDomain?: string): Promise<PagedMemoriaListTopicsResponse> => {
  if (!isValidDomain(apiDomain)) {
    return Promise.resolve({ items: [], count: 0, limit: 0, offset: 0, total_count: 0 });
  }
  if (cachedResponses[apiDomain]) {
    return Promise.resolve(cachedResponses[apiDomain]);
  }
  try {
    const inflightRequest = inFlightRequests[apiDomain];
    if (inflightRequest) {
      return inflightRequest;
    }
    const response = await createRequest(apiDomain);
    inFlightRequests[apiDomain] = null;
    cachedResponses[apiDomain] = response;
    return response;
  } catch (e) {
    throw e;
  } finally {
    inFlightRequests[apiDomain] = null;
  }
};

export const MemoriaAPIField: FC<MemoriaAPIFieldProps> = ({
  formData = defaultFormData,
  onChange,
  uiSchema,
  idSchema,
}) => {
  const { t } = useTranslation("common");
  const [topics, setTopics] = useState<MemoriaAPITopic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    getTopicsForAPI(formData.domain)
      .then((response) => {
        setTopics(response.items.map(mapTopic));
      })
      .catch((e) => {
        setError(e.message);
        setTopics([]);
      })
      .finally(() => setLoading(false));
  }, [formData.domain]);

  const title = getValueFromUiSchema("title", uiSchema);
  const description = getValueFromUiSchema("description", uiSchema);

  const onChangeAPI = (val: string) =>
    // reset topicId when changing API
    onChange({ domain: val, topicId: "" });
  return (
    <Box key={idSchema.$id}>
      <KioTitle title={title} description={description} level={2} />
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <KioSelect
          sx={{
            flexBasis: "30%",
          }}
          disabled={loading}
          label={t("components.MemoriaAPIField.APISelectLabel")}
          onChange={onChangeAPI}
          value={formData.domain}
          options={MEMORIA_APIS.map((api) => ({ value: api.domain, label: api.title }))}
        />
        <Autocomplete
          fullWidth
          disabled={loading}
          options={topics}
          getOptionLabel={(option) => option.name}
          value={topics.find((t) => t.uuid === formData.topicId) || null}
          onChange={(_, newValue) => {
            onChange({ ...formData, topicId: newValue?.uuid || "" });
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter(
              (option) =>
                option.name.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                option.owner.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );
            return filtered;
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.uuid}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Typography>{option.name}</Typography>
                <Typography variant="caption">{option.owner.name}</Typography>
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={loading}
              variant="filled"
              error={!!error}
              InputProps={{
                ...params.InputProps,
                startAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
              }}
              label={t("components.MemoriaAPIField.TopicSelectLabel")}
              helperText={error || getValueFromUiSchema("help", uiSchema)}
              fullWidth
            />
          )}
        />
      </Box>
    </Box>
  );
};
