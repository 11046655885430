import type { FC, ReactNode } from "react";
import { styled } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  background: "#FAFAFA",
  display: "grid",
  gridGap: theme.spacing(2),
  gridTemplateColumns: "repeat(12, 1fr)",
  gridAutoRows: "min-content",
  backgroundColor: theme.palette.background.paper,
}));

export const GridTemplateContainer: FC<{ children: ReactNode }> = ({ children }) => <Container>{children}</Container>;

export default GridTemplateContainer;
