import { styled } from "@mui/material";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type PaginationResult from "@/declarations/PaginationResult";
import Api from "@/services/Api";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";

const VALID_ROUTES = ["instance"];

const InstanceListContainer = styled("div")`
  > div {
    height: fit-content;
  }
`;

type ApplicationInstanceWithSharingType = ApplicationInstance & { sharing_type: "document" | "media" };

export const InstanceContentSharingList: FC = () => {
  const { t, i18n } = useTranslation("common");
  const history = useNavigate();
  const { pathname } = useLocation();
  const pathParams = pathname.split("/");
  const id = Number(pathParams.pop());
  const route = pathParams.pop() || "";
  const getLocalizedString = resolvePotentiallyLocalizedString(i18n.language);

  const [lastFetchedTimestamp] = useState(Date.now());

  if (!VALID_ROUTES.includes(route)) return null;

  const getSharedInstances: DataListProps<ApplicationInstanceWithSharingType>["getItems"] = async (
    page: number,
    pageSize: number
  ) => {
    const defaultValue: PaginationResult<ApplicationInstanceWithSharingType> = {
      page,
      page_size: pageSize,
      count: 0,
      total_count: 0,
      items: [],
    };
    const documentsCtx = Api.getSharedInstances(id, { type: "document" });
    const mediaCtx = Api.getSharedInstances(id, { type: "media" });
    const documentsResponse = documentsCtx.fetchDirect(defaultValue);
    const mediaResponse = mediaCtx.fetchDirect(defaultValue);
    const [documents, media] = await Promise.all([documentsResponse, mediaResponse]);

    return {
      ...defaultValue,
      items: [
        ...documents.items.map((item) => ({
          ...item,
          sharing_type: "document",
        })),
        ...media.items.map((item) => ({
          ...item,
          sharing_type: "media",
        })),
      ].sort((a, b) => getLocalizedString(a.owner_name)!.localeCompare(getLocalizedString(b.owner_name)!)),
      count: documents.count + media.count,
      total_count: documents.total_count + media.total_count,
    } as PaginationResult<ApplicationInstanceWithSharingType>;
  };

  const instanceMapper = (item: ApplicationInstanceWithSharingType): DataListRow => ({
    key: `instance-${item.id}-shared-${item.sharing_type}`,
    title: `${getLocalizedString(item.owner_name)}`,
    subTitle: `${item.application_name}`,
    infoText: `Id: ${item.id}`,
    chipContent: t(`views.admin.instances.sharingType.${item.sharing_type}`),
  });

  const onItemClick = (item: ApplicationInstanceWithSharingType) => {
    history(`/admin/instance/${item.id}`);
  };

  return (
    <InstanceListContainer>
      <DataList
        listTitle={t("views.admin.instances.sharingInstances")}
        getItems={getSharedInstances}
        mapperFn={instanceMapper}
        onItemClick={onItemClick}
        externalDataChanged={lastFetchedTimestamp}
        disableSorting
      />
    </InstanceListContainer>
  );
};

export default InstanceContentSharingList;
