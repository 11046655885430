import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateOrEditRoutes from "./CreateOrEditRoutes";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import type { DataListProps } from "@/components/DataList/DataList";
import type Owner from "@/declarations/models/Owner";
import Api from "@/services/Api";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type { CreateOrEditProps } from "@/components/CreateOrEdit";
import { schema } from "@/declarations/schemas/owner_admin/schema";
import { uiSchema } from "@/declarations/schemas/owner_admin/uiSchema";
import type { RequestContext } from "@/declarations/RequestContext";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import { useDebounce } from "@/hooks/useDebounce";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

const mapperFn = (ownerItem: Owner): DataListRow => ({
  key: String(ownerItem.id),
  title: ownerItem.name,
  subTitle: ownerItem.description,
  chipContent: ownerItem.slug,
  infoText: `ID: ${ownerItem.id}`,
  imageURL: ownerItem?.main_image?.mediaId || ownerItem.image_url,
  updatedAt: ownerItem.updated_at,
  updatedBy: ownerItem.updated_by,
});

const customSortOptions: Array<SortOptions> = [
  { prop: "name", direction: "asc", label: "components.list.sort.byTitleAsc" },
  { prop: "name", direction: "desc", label: "components.list.sort.byTitleDesc" },
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
  { prop: "slug", direction: "asc", label: "components.list.sort.bySlugAsc" },
  { prop: "slug", direction: "desc", label: "components.list.sort.bySlugDesc" },
];

const getOwner: CreateOrEditProps<Owner>["getFormData"] = (id: number): RequestContext<Owner> => Api.getOneOwner(id);
const updateOwner: CreateOrEditProps<Owner>["onSubmit"] = (owner: Owner): RequestContext<Owner> =>
  Api.updateOwner(owner);
const createOwner: CreateOrEditProps<Owner>["onSubmit"] = (owner: Owner): RequestContext<Owner> =>
  Api.createOwner(owner);
const deleteOwner = async (owner: Owner) => {
  const error = (await Api.deleteOwner(owner?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to delete owner");
  }
};
const undeleteOwner = async (item: Owner) => {
  await Api.undeleteOwner(item.id!).fetch();
};

export const OwnerView: FC = () => {
  const { t } = useTranslation("common");

  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    sortOptions: customSortOptions,
    defaultSortBy: "name",
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const getOwners: DataListProps<Owner>["getItems"] = (page, page_size) =>
    Api.getAllOwners({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
    }).fetchDirect({
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    });

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.OWNER}`} />
      <CreateOrEditRoutes
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit
        createFormData={createOwner}
        updateFormData={updateOwner}
        getFormData={getOwner}
        listProps={{
          listTitle: t("schemaTypes.owner_plural"),
          mapperFn,
          getItems: getOwners,
          onDeleteItem: deleteOwner,
          onDeleteItemForever: deleteOwner,
          onUndeleteItem: undeleteOwner,
          handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          initialSortOption,
          sortOptions: customSortOptions,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default OwnerView;
