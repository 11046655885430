export const uiSchema = {
  "ui:title": "schemaTypes.edit_user",
  id: {
    "ui:title": "generic.id",
    "ui:colspan": 3,
  },
  is_admin: {
    "ui:title": "generic.is_admin",
    "ui:widget": "SwitchWidget",
    "ui:colspan": 3,
  },
  activated: {
    "ui:title": "generic.activated",
    "ui:widget": "SwitchWidget",
    "ui:colspan": 3,
  },
  is_banned: {
    "ui:title": "generic.is_banned",
    "ui:widget": "SwitchWidget",
    "ui:colspan": 3,
  },
  created_at: {
    "ui:title": "generic.created_at",
    "ui:widget": "DateTimeWidget",
    "ui:colspan": 6,
  },
  updated_at: {
    "ui:title": "generic.updated_at",
    "ui:widget": "DateTimeWidget",
    "ui:colspan": 6,
  },
  name: {
    "ui:title": "generic.name",
  },
  username: {
    "ui:title": "generic.username",
    "ui:widget": "TextWidget",
  },
  image_url: {
    "ui:title": "generic.image_url",
    "ui:widget": "TextWidget",
  },
};
