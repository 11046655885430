import type { FC } from "react";
import type { AddMediaContentProps } from "@/components/MediaSelector/AddMediaModal";
import AddDMSContent from "@/components/MediaSelector/forms/AddDMSContent";
import MediaType from "@/declarations/models/MediaType";

export const AddDocumentMedia: FC<AddMediaContentProps> = ({ value, onCancel, onMediaSaved, ownerId, instanceId }) => (
  <AddDMSContent
    allowedMediaTypes={[MediaType.DOCUMENT]}
    media={value}
    onMediaSaved={onMediaSaved}
    onCancel={onCancel}
    ownerId={ownerId}
    instanceId={instanceId}
  />
);

export default AddDocumentMedia;
