import { Button, Paper, styled, Typography } from "@mui/material";
import i18next from "i18next";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import placeholderImage from "@/assets/img/placeholder_image_small.svg";
import Styles from "@/assets/styles/Styles";
import CurrentUserAvatar from "@/components/CurrentUserAvatar";
import DataListSelect from "@/components/DataList/common/DataListSelect";
import { Image } from "@/components/Image";
import type Owner from "@/declarations/models/Owner";
import Api from "@/services/Api";
import Settings from "@/Settings";
import { useStore } from "@/Store";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";

export interface ProfileMenuProps {
  owners?: Owner[];
  closeMenu: () => void;
}

const Content = styled(Paper)`
  width: 390px;
  max-height: 100%;
  overflow-y: auto;
`;

const Header = styled("div")`
  padding: 16px 16px;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: flex-start;
  flex: 1;
`;

const HeaderContent = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const OwnerList = styled("div")`
  display: flex;
  flex-direction: column;

  .edit-heading {
    font-weight: 700;
    padding: 0 16px;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    max-height: 456px;
    overflow: hidden;
    overflow-y: scroll;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 16px;
      border-bottom: 1px solid ${Styles.Colors.HEADER_SEPARATOR_COLOR};
      &:hover {
        text-decoration: underline;
      }
      &:first-of-type {
        border-top: 1px solid ${Styles.Colors.HEADER_SEPARATOR_COLOR};
      }
    }
  }
`;

const ImageContainer = styled("div")`
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin: -4px 8px -4px -4px;
  border-radius: 50%;
`;

const HeaderTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
`;

const Footer = styled("div")`
  padding: 20px;
  height: 70px;
  background: #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Details = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  p:first-of-type {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  font-weight: 400;
  letter-spacing: 0.5px;
`;

// KULADM-1177: Owner data should now be edited through the "owner document" instead. Only super admins can edit the real Owner entity.
//  if we could move the document editor for the owner document here, we could allow normal users to edit both the Owner and the
//  owner document in the same place.
const canEditOwners = false;

const ProfileMenu: FC<ProfileMenuProps> = ({ owners, closeMenu }) => {
  const { state } = useStore();
  const name = state.user?.name || "unknown";
  const version: string = state.appVersion || "?.?.?";
  const history = useNavigate();
  const { t } = useTranslation("common");

  const logout = () => history("/logout");

  const selectedLocale: string = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);

  const onOwnerClick = (ownerId: number | undefined) => {
    history(`/owner/${ownerId}`);
    closeMenu();
  };

  async function resolveMediaUrlFromID(id: number | undefined): Promise<string | undefined> {
    if (!id) return placeholderImage;
    const media = await Api.getOneMedia(id).fetchDirect(null);
    if (media) {
      return media.thumbnail_src;
    }
  }

  const handleChangeLocale = (value: string) => {
    i18next
      .reloadResources(value, "db")
      .then(() => i18next.changeLanguage(value))
      .catch((error) => console.error(`Failed to reload resources for language '${value}':`, error));
  };

  return (
    <Content elevation={3}>
      <Header>
        <CurrentUserAvatar />
        <HeaderContent>
          <HeaderTitle>{name}</HeaderTitle>

          <DataListSelect
            items={Settings.SUPPORTED_APP_LOCALES.map((value) => ({
              value,
              label: t(`languagesTranslated.${value}`),
            }))}
            value={selectedLocale}
            onChange={(value) => handleChangeLocale(String(value))}
          />
        </HeaderContent>
      </Header>
      {canEditOwners && owners && owners?.length > 0 && (
        <OwnerList>
          <div className="edit-heading">{t("header.profile.editOwnersHeading")}</div>
          <ul>
            {owners?.map((owner, index) => (
              <li key={index} onClick={() => onOwnerClick(owner?.id)}>
                <ImageContainer>
                  <Image src={resolveMediaUrlFromID(owner?.logo?.mediaId)} />
                </ImageContainer>
                <div>{getLocalizedString(owner.name)}</div>
              </li>
            ))}
          </ul>
        </OwnerList>
      )}
      <Footer>
        <Details>
          <Typography>{t("generic.appTitle")}</Typography>
          <Typography>v{version}</Typography>
        </Details>
        <Button color="primary" variant="outlined" onClick={logout}>
          {t("header.profile.logout")}
        </Button>
      </Footer>
    </Content>
  );
};

export default ProfileMenu;
