import type { TextFieldProps } from "@mui/material";
import { Autocomplete, createFilterOptions, styled, TextField, Typography } from "@mui/material";
import type { FC } from "react";
import { customIconPaths } from "@/components/CustomIcons";
import MuiIcon from "@/components/MuiIcon";
import { HitList } from "@/framework/DocumentSearch";
import { IconPaths } from "@/assets/icons/material-icons";

const customIconDisplayNames = Object.keys(customIconPaths).map((key) => `Custom: ${key}` as const);
const iconDisplayNames = Object.keys(IconPaths).map((key) => key);
const iconNames = [...customIconDisplayNames, ...iconDisplayNames];

export interface MuiIconSelectProps extends Partial<Pick<TextFieldProps, "variant" | "required" | "disabled">> {
  label: string;
  placeholder?: string;
  helpText?: string;
  value?: string | null;
  onChange: (value?: string | null) => void;
}

const CustomListItem = styled("li", { shouldForwardProp: (prop) => prop !== "aria-selected" })(
  ({ "aria-selected": selected, theme }) => `
    display: flex;
    flex-flow: row nowrap;
    padding: ${selected ? "4px !important" : "8px !important"};
    border-left: ${selected ? `${theme.palette.primary.main} solid 4px` : ""};
    gap: 8px;
    overflow: clip;
    .MuiIcon-root {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .MuiTypography-root {
      white-space: nowrap;
    }
  `
);

const filterOptions = createFilterOptions<string>({
  ignoreCase: true,
  limit: 50,
});

export const MuiIconSelect: FC<MuiIconSelectProps> = ({
  value,
  onChange,
  label,
  placeholder,
  helpText,
  variant = "filled",
  required,
  disabled,
}) => (
  <Autocomplete
    id="mui-icon-select"
    // filterOptions={filterOptions}
    value={value || null}
    onChange={(_, value) => onChange(value)}
    renderInput={(inputProps) => (
      <TextField
        {...inputProps}
        helperText={helpText}
        placeholder={placeholder}
        InputProps={{
          ...inputProps.InputProps,
          startAdornment: value ? <MuiIcon>{value}</MuiIcon> : null,
        }}
        label={label}
        variant={variant}
        required={required}
        disabled={disabled}
      />
    )}
    options={iconNames}
    renderOption={(props, opt) => (
      <CustomListItem {...props}>
        <MuiIcon>{opt}</MuiIcon>
        <Typography>{opt}</Typography>
      </CustomListItem>
    )}
    PaperComponent={(props) => <HitList {...props}>{props.children}</HitList>}
    disabled={disabled}
    fullWidth
  />
);

export default MuiIconSelect;
