import type { ChangeEvent, FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";

export interface DataListToggleProps {
  onToggle?: (selected: boolean) => void;
  toggleLabel?: string;
  toggleDefault?: boolean;
}

const DataListToggle: FC<DataListToggleProps> = ({ onToggle, toggleLabel = "", toggleDefault = false }) => {
  const { t } = useTranslation("common");
  const [isChecked, setIsChecked] = useState(toggleDefault);
  const changeHandler = (_: ChangeEvent<HTMLInputElement>, selected: boolean) => {
    onToggle?.(selected);
    setIsChecked(selected);
  };

  return (
    <FormControl>
      <FormControlLabel control={<Checkbox onChange={changeHandler} checked={isChecked} />} label={t(toggleLabel)} />
    </FormControl>
  );
};
export default DataListToggle;
