import * as ekultur from "@ekultur/authentication";
import Settings from "../Settings";

/**
 * Provides a static API for login against eKultur
 */
export abstract class AuthenticationApi {
  private static host: string = Settings.EKULTUR_HOST;
  private static gateway: string = Settings.EKULTUR_GATEWAY;
  private static sameSite: boolean = Settings.EKULTUR_SAMESITE;
  private static redirectURI: string = `${window.location.origin}${Settings.EKULTUR_CALLBACK_URL}`;

  /**
   * Refresh the current token.
   * Resolves if refresh is OK.
   * Rejects if the user needs to log in again
   */
  public static async refreshToken(): Promise<void> {
    return new Promise((onSuccess, onError) => {
      ekultur.refreshToken({
        apiGateway: AuthenticationApi.gateway,
        onSuccess,
        onError,
      });
    });
  }

  /**
   * Start the login process.
   * Redirects the user to the eKultur IDP with the provided callback-url
   */
  public static async login(): Promise<void> {
    ekultur.login({
      loginHost: AuthenticationApi.host,
      redirectUri: AuthenticationApi.redirectURI,
      locationPostLogin: window.location.href,
    });
  }

  /**
   * Log the user out from eKultur
   */
  public static async logout(): Promise<void> {
    ekultur.logout({
      apiGateway: AuthenticationApi.gateway,
    });
  }

  /**
   * When the user is redirected back to the app from eKultur,
   * call this to trade the code and state for a valid token.
   * Resolves with the url the user was at when the login process was started
   * @param code Code from QueryParams
   * @param state State from QueryParams
   */
  public static async codeToToken(code: string, state: string): Promise<string> {
    return new Promise((resolve, onError) => {
      ekultur.codeToToken({
        code,
        state,
        sameSite: AuthenticationApi.sameSite,
        redirectUri: AuthenticationApi.redirectURI,
        apiGateway: AuthenticationApi.gateway,
        onSuccess: ({ previousPath }) => resolve(previousPath || window.location.origin),
        onError,
      });
    });
  }
}

export default AuthenticationApi;
