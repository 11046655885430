import type { ChangeEvent, FC } from "react";
import { useEffect, useState } from "react";
import { styled, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { AddMediaContentProps } from "@/components/MediaSelector/AddMediaModal";
import FormButtons from "@/components/FormButtons";
import VimeoPreview from "@/components/MediaPreview/VimeoPreview";
import { useLookup } from "@/hooks/useLookup";
import { useMessenger } from "@/framework/Messenger/Messenger";
import type Media from "@/declarations/models/Media";
import Api from "@/services/Api";
import MediaType from "@/declarations/models/MediaType";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2, 2),
}));

const getVimeoIdFromVimeoUrlRegex = new RegExp(
  "^(?:https?:\\/\\/(?:www\\.)?(?:player\\.)?vimeo\\.com(?:\\/video)?\\/)?(\\d+)(?:\\?.*)?$"
);

export const AddVimeoMedia: FC<AddMediaContentProps> = ({ value, ownerId, instanceId, onMediaSaved, onCancel }) => {
  const { t } = useTranslation("common");
  const touchedState = useLookup<boolean>({ url: false, name: false });
  const messenger = useMessenger();

  const [vimeoUrl, setVimeoUrl] = useState<string>(value?.src || "");
  const [identifier, setIdentifier] = useState<string>(value?.identifier || "");
  const [videoName, setVideoName] = useState<string>(value?.name || "");
  ownerId = value?.owner_id || ownerId;
  instanceId = value?.application_instance_id || instanceId;

  const urlError = !!touchedState.getItem("url") && !identifier;
  const nameError = !!touchedState.getItem("name") && !videoName;
  const validationError = !identifier || !videoName || (!ownerId && !instanceId);

  const handleVimeoUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value || "";
    const match = getVimeoIdFromVimeoUrlRegex.exec(url) ?? [];
    setVimeoUrl(url);
    setIdentifier(match.length === 2 ? match[1] : "");
  };

  const handleSaveVimeoMedia = async () => {
    const mediaToSave: Media = {
      identifier,
      name: videoName,
      media_type: MediaType.VIMEO,
      owner_id: ownerId!,
      application_instance_id: instanceId!,
    };
    let savedVimeoMedia: Media | null;
    if (value?.id) {
      savedVimeoMedia = await Api.updateMedia({
        ...value,
        ...mediaToSave,
      }).fetchDirect(null);
    } else {
      savedVimeoMedia = await Api.createMedia(mediaToSave).fetchDirect(null);
    }
    if (savedVimeoMedia) {
      onMediaSaved();
    } else {
      messenger.error("components.MediaSelector.AddVimeoMedia.UnableToSaveVimeoVideo");
    }
  };

  useEffect(() => {
    setVimeoUrl(value?.src || "");
    setIdentifier(value?.identifier || "");
    setVideoName(value?.name || "");
  }, [value]);

  return (
    <Container>
      <TextField
        label={t("components.MediaSelector.AddVimeoMedia.VimeoURL")}
        value={vimeoUrl}
        onChange={handleVimeoUrlChange}
        error={urlError}
        onFocus={() => touchedState.setItem("url", true)}
        required
        fullWidth
      />
      <TextField
        label={t("generic.name")}
        value={videoName}
        onChange={(e) => setVideoName(e.target.value || "")}
        error={nameError}
        onFocus={() => touchedState.setItem("name", true)}
        required
        fullWidth
      />
      <VimeoPreview identifier={identifier} />
      <FormButtons disableSubmitButton={validationError} onCancel={onCancel} onSubmit={handleSaveVimeoMedia} />
    </Container>
  );
};

export default AddVimeoMedia;
