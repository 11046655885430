import { Alert, AlertTitle, Collapse, styled } from "@mui/material";
import type { UiSchema, WidgetProps, RJSFSchema } from "@rjsf/utils";
import Ajv from "ajv";
import type { ParseError, SchemaValidationError } from "jsoneditor";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "@/assets/styles/Styles";
import { getValueFromUiSchema } from "@/utils/schema";
import { isJson, jsonEquals, parseJson } from "@/utils/json";
import { useDebounce } from "@/hooks/useDebounce";
import type { KioFormContext } from "@/framework/KioForm/KioForm";
import { UiSchemaValidator } from "@/declarations/schemas/UiSchemaValidator";
import type { UiSchemaError } from "@/declarations/schemas/UiSchemaValidator";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";

type SchemaError = SchemaValidationError | ParseError;

export interface JsonEditorWidgetOptions {
  type: "jsonSchema" | "uiSchema" | "searchSchema";
}

const ajv = new Ajv({ validateSchema: true, allErrors: true, verbose: true });

function getTitle(schema: RJSFSchema, uiSchema: UiSchema): string {
  return schema.title ?? getValueFromUiSchema("title", uiSchema) ?? "";
}

const defaultOptions: JsonEditorWidgetOptions = {
  type: "jsonSchema",
};

const ErrorBox = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const EditorContainer = styled("div")`
  height: ${Styles.Dimensions.JSON_EDITOR_HEIGHT};
  min-height: 215px;
  max-height: 1000px;
  overflow: hidden;
  resize: vertical;
`;

const JsonEditorWidget: FC<WidgetProps> = ({ id, uiSchema, schema, onChange, value, label, formContext }) => {
  const { t } = useTranslation("common");
  // Setup options
  const { debounceTime }: KioFormContext = formContext;
  const type = getValueFromUiSchema("type", uiSchema) || defaultOptions?.type;
  // setup state
  const [invalidJson, setInvalidJson] = useState<boolean>(false);
  const [uiSchemaErrors, setUiSchemaErrors] = useState<UiSchemaError[]>([]);
  // Setup editor
  const jsonEditor = useRef<JSONEditor | null>(null);
  const title = uiSchema ? getTitle(schema, uiSchema) : "";
  const [schemaExpanded, setSchemaExpanded] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onResize = useDebounce(100, () => {
    if (!!jsonEditor.current && isJson(value)) {
      const { start, end } = jsonEditor.current?.getTextSelection();
      jsonEditor.current?.set(parseJson(value));
      jsonEditor.current?.setTextSelection(start, end);
    }
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [onResize]);

  const debouncedOnChange = useDebounce(debounceTime, () => {
    if (!jsonEditor.current || invalidJson) return;
    try {
      const value = parseJson(jsonEditor.current?.get());
      let uiErrors: Array<UiSchemaError> = [];
      if (type === "uiSchema" && !!value) {
        uiErrors = UiSchemaValidator.getInstance(value).validate().errors;
      }
      setUiSchemaErrors(uiErrors);
      if (!uiErrors.length) {
        onChange(JSON.stringify(value || "{}"));
      }
    } catch (_) {
      // Invalid json
      return;
    }
  });

  const initJsonEditor = (editorElement: HTMLDivElement): void => {
    if (!!jsonEditor.current || editorElement == null) return;
    jsonEditor.current = new JSONEditor(
      editorElement,
      {
        mode: "code",
        modes: ["code", "tree", "preview", "text"],
        onChange: debouncedOnChange,
        onValidationError: (errors: readonly SchemaError[]) => setInvalidJson(!!errors?.length),
        ajv,
      },
      parseJson(value)
    );
    // Set the ID to the input-element for correct HTML-semantics and a11y
    const [input] = editorElement.getElementsByTagName("textarea");
    input.setAttribute("id", `${id}-label`);
  };

  useEffect(() => {
    if (!!jsonEditor.current && isJson(value) && !jsonEquals(jsonEditor.current?.getText(), value)) {
      jsonEditor.current?.set(parseJson(value));
    }
  }, [value]);

  useEffect(
    () => () => {
      jsonEditor.current?.destroy();
      jsonEditor.current = null;
    },
    []
  );

  return (
    <div>
      <CollapseBar title={title || (label ?? "")} expanded={schemaExpanded} setExpanded={setSchemaExpanded}>
        <EditorContainer ref={containerRef}>
          <div style={{ height: "100%" }} ref={initJsonEditor} />
        </EditorContainer>
      </CollapseBar>

      <Collapse in={invalidJson} collapsedSize={0} timeout={100}>
        <ErrorBox severity="error">
          <AlertTitle>{t("kioForm.widgets.JsonEditorWidget.parseError")}</AlertTitle>
        </ErrorBox>
      </Collapse>

      <Collapse in={!!uiSchemaErrors?.length} collapsedSize={0} timeout={100}>
        <ErrorBox severity="error">
          <AlertTitle>{t("kioForm.widgets.JsonEditorWidget.uiSchemaValidationError")}</AlertTitle>
          {uiSchemaErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </ErrorBox>
      </Collapse>
    </div>
  );
};

export default JsonEditorWidget;
