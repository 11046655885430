import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  Typography,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import type ApiKey from "@/declarations/models/ApiKey";
import Loader from "@/framework/Loader";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import Api from "@/services/Api";
import { useLookup } from "@/hooks/useLookup";
import { StyledDialog } from "@/components/MediaSelector/FocalPointModal";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";

export interface SingleApiKeyModalProps {
  apiKeyId: number | null;
  onClose: () => void;
}

const ButtonSpan = styled("span")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
}));
const SelectButtonSpan = styled("span")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
}));

export const SingleApiKeyModal: FC<SingleApiKeyModalProps> = ({ apiKeyId, onClose }) => {
  const { t } = useTranslation("common");
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);
  const [availableInstances, setAvailableInstances] = useState<Array<ApplicationInstance>>([]);

  const [loadingInstances, setLoadingInstances] = useState<boolean>(false);
  const [loadingApiKey, setLoadingApiKey] = useState<boolean>(false);

  const selectedInstances = useLookup<boolean>();
  const pendingInstances = useLookup<boolean>();
  const selectedLocale = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);

  const toggleAuthorization = async (instanceId: number) => {
    if (!apiKey || !instanceId) return;
    pendingInstances.setItem(instanceId, true);
    if (selectedInstances.getItem(instanceId)) {
      await Api.revokeApiKeyAccessToApplicationInstance(apiKey.id!, instanceId).fetch();
      selectedInstances.setItem(instanceId, false);
    } else {
      await Api.grantApiKeyAccessToApplicationInstance(apiKey.id!, instanceId).fetch();
      selectedInstances.setItem(instanceId, true);
    }
    pendingInstances.setItem(instanceId, false);
  };

  const toggleAll = async () => {
    if (!apiKey) return;

    await availableInstances.forEach((instance) => {
      if (!selectedInstances.getItem(instance.id)) {
        Api.grantApiKeyAccessToApplicationInstance(apiKey.id!, instance.id!).fetch();
        selectedInstances.setItem(instance.id, true);
      }
    });
  };

  const untoggleAll = async () => {
    if (!apiKey) return;

    await availableInstances.forEach((instance) => {
      if (selectedInstances.getItem(instance.id)) {
        Api.revokeApiKeyAccessToApplicationInstance(apiKey.id!, instance.id!).fetch();
        selectedInstances.setItem(instance.id, false);
      }
    });
  };

  useEffect(() => {
    // Loads the Instances that this API-key can access
    if (!apiKey) {
      setAvailableInstances([]);
      return;
    }
    setLoadingInstances(true);
    const getInstances = Api.getAllInstances({
      application_id: apiKey.application_id,
      sort_by: "name",
    });
    getInstances
      .fetchDirect(null)
      .then((page) => setAvailableInstances(page?.items || []))
      .finally(() => setLoadingInstances(false));
    return getInstances.abort;
  }, [apiKey]);

  useEffect(() => {
    // Loads the API-key, and selects the instances it already has access to
    if (!apiKeyId) {
      setApiKey(null);
      return;
    }
    setLoadingApiKey(true);
    const getApiKey = Api.getOneApiKey(apiKeyId);
    getApiKey
      .fetchDirect(null)
      .then((key) => {
        setApiKey(key);
        selectedInstances.clear();
        key?.application_instance_authorizations?.forEach((i) => {
          selectedInstances.setItem(i.application_instance_id, true);
        });
      })
      .finally(() => setLoadingApiKey(false));
    return getApiKey.abort;
  }, [apiKeyId, selectedInstances]);

  let content: ReactNode;

  if (!apiKey) {
    if (loadingApiKey) {
      content = <Loader loadingText="views.admin.apiKeys.manageApiKeyModal.loadingKey" />;
    } else {
      content = <Typography color="error">{t("views.admin.apiKeys.manageApiKeyModal.keyNotFound")}</Typography>;
    }
  } else {
    content = (
      <>
        <DialogTitle>
          <KioTitle title={t("views.admin.apiKeys.manageApiKeyModal.heading", { name: apiKey?.name || "" })} />
        </DialogTitle>
        <DialogContent dividers>
          <Alert severity="info">
            {t("views.admin.apiKeys.manageApiKeyModal.validForApplicationInfo", { name: apiKey?.application_name })}
          </Alert>
          {apiKey?.description && <Alert severity="info">{apiKey?.description}</Alert>}
          {loadingInstances ? (
            <Loader loadingText="views.admin.apiKeys.manageApiKeyModal.loadingInstances" />
          ) : (
            <List>
              {availableInstances.map((instance) => {
                const id = instance.id!;
                const isSelected = !!selectedInstances.getItem(id);
                const isPending = !!pendingInstances.getItem(id);
                const onClick = () => toggleAuthorization(id);
                return (
                  <ListItemButton key={id} disabled={isPending} onClick={onClick}>
                    <ListItemIcon>
                      <Checkbox edge="start" checked={isSelected} disableRipple disableFocusRipple disableTouchRipple />
                    </ListItemIcon>
                    <ListItemText primary={getLocalizedString(instance.owner_name)} />
                    <ListItemSecondaryAction>
                      {isPending && <CircularProgress variant="indeterminate" color="secondary" size={25} />}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                );
              })}
            </List>
          )}
        </DialogContent>
      </>
    );
  }

  return (
    <StyledDialog onClose={onClose} scroll="paper" open={!!apiKey}>
      {content}
      <DialogActions>
        <ButtonSpan>
          <SelectButtonSpan>
            <Button type="button" color="primary" variant="contained" onClick={toggleAll}>
              {t("generic.selectAll")}
            </Button>
            <Button type="button" color="primary" variant="contained" onClick={untoggleAll}>
              {t("generic.deselectAll")}
            </Button>
          </SelectButtonSpan>
          <Button type="button" color="primary" variant="contained" onClick={onClose}>
            {t("generic.done")}
          </Button>
        </ButtonSpan>
      </DialogActions>
    </StyledDialog>
  );
};

export default SingleApiKeyModal;
