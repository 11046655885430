import { Typography } from "@mui/material";
import type { FieldProps, RJSFSchema } from "@rjsf/utils";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { JSONSchema7Definition } from "json-schema";
import { getSchemaTitle } from "@/utils/schema";

function resolveSchema(schema: RJSFSchema, selectedLocale: string): RJSFSchema | null {
  function reducePatternPropertiesToSelectedLocale(
    accumulator: RJSFSchema | null,
    currentVal: [string, JSONSchema7Definition]
  ): RJSFSchema | null {
    const [key, val] = currentVal;
    if (typeof val == "object" && new RegExp(key).test(selectedLocale)) {
      return val;
    }
    return accumulator;
  }

  if (
    schema.properties &&
    selectedLocale in schema.properties &&
    typeof schema.properties[selectedLocale] === "object"
  ) {
    return schema.properties[selectedLocale] as RJSFSchema;
  } else if (schema.patternProperties) {
    const matchingSchema = Object.entries(schema.patternProperties).reduce(
      reducePatternPropertiesToSelectedLocale,
      null
    );
    if (!matchingSchema) {
      //console.warn(`No matching patternProperties for key ${selectedLocale}!`);
    }
    return matchingSchema;
  } else {
    //console.warn(`Invalid schema. No schema found for ${selectedLocale}, and no patternProperties!`);
    return null;
  }
}

/**
 * Field that can be used to localize *any* other fields.
 * Defined the schema with either patternProperties, or properties.
 * Will render the form fields for the pattern/schema matching formContext.selectedLocale.
 *
 * example:
 * {
 *   properties: {
 *     no: { type: "string" },
 *     en: { type: "number" }
 *   },
 *   patterProperties: {
 *     "^[a-zA-Z]{2}": { type: "boolean" }
 *   }
 * }
 *
 * will render a text field whenever selectedLocale is "no", a number field when it's "en",
 * and a checkbox for all other locales.
 */
const LocalizedField: FC<FieldProps> = ({
  schema,
  uiSchema,
  errorSchema,
  idSchema,
  formData,
  onChange,
  formContext,
  registry,
  ...rest
}) => {
  const { t } = useTranslation("common");
  const selectedLocale: string = formContext?.selectedLocale;
  const label = getSchemaTitle(formContext, schema, uiSchema, formData);

  const update = (data: any): void => {
    onChange({
      ...formData,
      [selectedLocale]: data,
    });
  };

  if (schema.type !== "object") {
    return <Typography color="error">{t("kioForm.fields.LocalizedField.invalidSchemaType")}</Typography>;
  }

  if (!selectedLocale) {
    return <Typography color="error">{t("kioForm.fields.LocalizedField.missingLanguage")}</Typography>;
  }

  const {
    schemaUtils,
    fields: { SchemaField },
  } = registry;
  const selectedLocaleSchema = resolveSchema(schema, selectedLocale);

  if (!selectedLocaleSchema) {
    return (
      <Typography color="error">{t("kioForm.fields.LocalizedField.unresolvedSchema", { selectedLocale })}</Typography>
    );
  }

  const _schema = schemaUtils.retrieveSchema(selectedLocaleSchema, formData?.[selectedLocale]);
  const _idSchema = schemaUtils.toIdSchema(_schema, `${idSchema["$id"]}_${selectedLocale}`, formData?.[selectedLocale]);

  const _uiSchema = uiSchema?.[selectedLocale] || uiSchema?.["items"] || {};
  _uiSchema["ui:options"] = { ..._uiSchema["ui:options"], isLocalized: true };
  const _errorSchema = errorSchema?.[selectedLocale];

  return (
    <>
      <SchemaField
        formData={formData?.[selectedLocale] || undefined}
        schema={selectedLocaleSchema}
        registry={registry}
        formContext={formContext}
        onChange={update}
        label={label}
        idSchema={_idSchema}
        uiSchema={_uiSchema}
        errorSchema={_errorSchema}
        {...rest}
      />
    </>
  );
};

export default LocalizedField;
