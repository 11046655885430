import type { FC } from "react";
import { Select, styled } from "@mui/material";
import type { SelectOption } from "@/framework/KioForm/common/KioSelect";
import { StyledMenuItem } from "@/framework/KioForm/common/KioSelect";

export interface DataListSelectProps {
  items: Array<SelectOption>;
  value: string | number;
  onChange: (value: string | number) => void;
}

const StyledSelect = styled(Select)({
  fontSize: "0.937rem",
  fontWeight: 700,
  "& .MuiSelect-select": {
    alignItems: "center",
    paddingBottom: 0,
  },
});
export const DataListSelect: FC<DataListSelectProps> = ({ items, value, onChange }) => (
  <StyledSelect
    variant={"standard"}
    size={"small"}
    value={value}
    onChange={(e) => onChange(e.target.value as string | number)}
    disableUnderline
  >
    {items.map((option) => (
      <StyledMenuItem key={option.value} value={option.value}>
        {option.label}
      </StyledMenuItem>
    ))}
  </StyledSelect>
);

export default DataListSelect;
