import type { UiSchema } from "@rjsf/utils";

export const uiSchema: UiSchema = {
  "ui:title": "schemaTypes.instance",
  id: {
    "ui:title": "generic.id",
  },
  path: {
    "ui:title": "generic.path",
  },
  name: {
    "ui:title": "generic.name",
    "ui:help":
      "Navnet på applikasjonen benyttes som default. Kun nødvendig å overstyre denne dersom en eier har tilgang til flere apper av samme type",
  },
  slug: {
    "ui:title": "generic.slug",
    "ui:help":
      "Slug fra applikasjonen benyttes som default. Kun nødvendig å overstyre denne dersom en eier har tilgang til flere apper av samme type",
  },
  owner_id: {
    "ui:title": "generic.owner",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Owner",
      valueField: "id",
      displayField: "name",
    },
  },
  license_id: {
    "ui:title": "generic.license",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "License",
      valueField: "id",
      displayField: "name",
    },
  },
  application_id: {
    "ui:title": "generic.application",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Application",
      valueField: "id",
      displayField: "name",
    },
  },
  available_locales: {
    "ui:title": "generic.available_locales",
    items: {
      id: {
        "ui:title": "generic.locale",
        "ui:widget": "EntityAutocompleteWidget",
        "ui:options": {
          type: "Locale",
          valueField: "id",
          displayField: "name",
        },
      },
      "ui:options": {
        compact: true,
      },
    },
  },
  shared_with: {
    "ui:title": "Delinger",
    "ui:help": "Del innhold med en annen instans. Innhold blir tilgjengelig i lesemodus for mottaker.",
    items: {
      to_application_instance_id: {
        "ui:title": "Mottaker",
        "ui:widget": "EntityAutocompleteWidget",
        "ui:options": {
          type: "ApplicationInstance",
          valueField: "id",
          displayField: "name",
          colspan: 8,
        },
      },
      type: {
        "ui:title": "Type",
        "ui:widget": "SelectWidget",
        "ui:options": {
          colspan: 4,
        },
      },
      "ui:options": {
        compact: true,
      },
    },
  },
};
