import { useCallback, useEffect, useRef } from "react";
import { useDepSafeValue } from "@/hooks/useDepSafeCallback";

type DebounceCallback<T> = (data?: T) => void | Promise<void>;

export function useDebounce<T>(ms: number, callback: DebounceCallback<T>): DebounceCallback<T> {
  const depSafeCallback = useDepSafeValue<DebounceCallback<T>>(callback);
  const timerId = useRef<NodeJS.Timer | null>(null);

  const clearTimer = useCallback(() => {
    if (!!timerId.current) {
      clearTimeout(timerId.current);
      timerId.current = null;
    }
  }, []);

  useEffect(() => clearTimer, [clearTimer]);

  return useCallback<DebounceCallback<T>>(
    (data?: T): void => {
      clearTimer();

      timerId.current = setTimeout(() => {
        depSafeCallback.current?.(data);
        clearTimer();
      }, ms);
    },
    [ms, depSafeCallback, clearTimer]
  );
}
