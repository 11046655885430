import { TextField } from "@mui/material";
import type { ChangeEvent, FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormButtons from "@/components/FormButtons";
import SketchfabPreview from "@/components/MediaPreview/SketchfabPreview";
import type { AddMediaContentProps } from "@/components/MediaSelector/AddMediaModal";
import { Container } from "@/components/MediaSelector/forms/AddVimeoMedia";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useLookup } from "@/hooks/useLookup";
import Api from "@/services/Api";

const getModelIdFromSketchfabUrlRegex = new RegExp(
  "^(?:https?:\\/\\/(?:www\\.)?sketchfab\\.com\\/(?:models|3d-models)\\/)?([-\\w]+)(?:\\/embed)?(?:\\?.*)?$"
);

export const AddSketchfabMedia: FC<AddMediaContentProps> = ({ value, ownerId, instanceId, onMediaSaved, onCancel }) => {
  const { t } = useTranslation("common");
  const touchedState = useLookup<boolean>({ url: false, name: false });
  const messenger = useMessenger();

  const [sketchfabUrl, setSketchfabUrl] = useState<string>(value?.src || "");
  const [identifier, setIdentifier] = useState<string>(value?.identifier || "");
  const [sketchfabModelName, setSketchfabModelName] = useState<string>(value?.name || "");
  ownerId = value?.owner_id || ownerId;
  instanceId = value?.application_instance_id || instanceId;

  const urlError = !!touchedState.getItem("url") && !identifier;
  const nameError = !!touchedState.getItem("name") && !sketchfabModelName;
  const validationError = !identifier || !sketchfabModelName || (!ownerId && !instanceId);

  const handleSketchfabUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value || "";
    const match = getModelIdFromSketchfabUrlRegex.exec(url) ?? [];
    const modelIdentifier: string = match?.length === 2 ? String(match[1]) : "";
    if (url.includes("3d-models")) {
      // In this URL-variant, the URL is in the format '<modelName>-<modelId>',
      // where the words in the name is separated by '-'
      const nameAndIdParts = modelIdentifier.split("-");
      setIdentifier(nameAndIdParts.pop() || "");
      setSketchfabModelName((prevName) => {
        if (!touchedState.getItem("name") || !prevName) {
          return nameAndIdParts.join(" ");
        }
        return prevName;
      });
    } else {
      setIdentifier(modelIdentifier);
    }
    setSketchfabUrl(url);
  };

  const handleSaveSketchfabModel = async () => {
    const mediaToSave: Media = {
      identifier,
      name: sketchfabModelName,
      media_type: MediaType.SKETCHFAB,
      application_instance_id: instanceId!,
      owner_id: ownerId!,
    };
    let savedModelMedia: Media | null;
    if (value?.id) {
      savedModelMedia = await Api.updateMedia({
        ...value,
        ...mediaToSave,
      }).fetchDirect(null);
    } else {
      savedModelMedia = await Api.createMedia(mediaToSave).fetchDirect(null);
    }
    if (savedModelMedia) {
      onMediaSaved();
    } else {
      messenger.error("components.MediaSelector.AddSketchfabContent.UnableToSaveSketchfabModel");
    }
  };

  useEffect(() => {
    setSketchfabUrl(value?.src || "");
    setIdentifier(value?.identifier || "");
    setSketchfabModelName(value?.name || "");
  }, [value]);

  return (
    <Container>
      <TextField
        label={t("components.MediaSelector.AddSketchfabContent.SketchfabURL")}
        value={sketchfabUrl}
        onChange={handleSketchfabUrlChange}
        error={urlError}
        onFocus={() => touchedState.setItem("url", true)}
        required
        fullWidth
      />
      <TextField
        label={t("components.MediaSelector.AddSketchfabContent.SketchfabModelName")}
        value={sketchfabModelName}
        onChange={(e) => setSketchfabModelName(e.target.value || "")}
        error={nameError}
        onFocus={() => touchedState.setItem("name", true)}
        required
        fullWidth
      />
      <SketchfabPreview identifier={identifier} />
      <FormButtons disableSubmitButton={validationError} onCancel={onCancel} onSubmit={handleSaveSketchfabModel} />
    </Container>
  );
};

export default AddSketchfabMedia;
