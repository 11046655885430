// eslint-disable-next-line import/no-duplicates
import { format as formatDate, isDate } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { nb } from "date-fns/locale";
export function dateFormater(date: number | Date, format: string, locale: any = { locale: nb }) {
  if (isDate(date)) {
    return formatDate(date, format, locale);
  }
  return "Not a valid date";
}
