import { Close, CollectionsOutlined } from "@mui/icons-material";
import { IconButton, styled, Tooltip } from "@mui/material";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import type { MediaTypeManagerProps } from "./MediaTypeTabPanel/MediaTypeTabPanel";
import MediaTypeTabPanel from "./MediaTypeTabPanel/MediaTypeTabPanel";
import DMTabPanel from "@/components/MediaSelector/DMTabPanel";
import type { TabsLayoutProps } from "@/components/TabsLayout";
import TabsLayout from "@/components/TabsLayout";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import GroupedMedia from "@/declarations/models/GroupedMedia";
import MediaType from "@/declarations/models/MediaType";
import type Owner from "@/declarations/models/Owner";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { useStore } from "@/Store";

export interface MediaSelectorDrawerContentProps
  extends Pick<MediaTypeManagerProps, "onSelect" | "drawerOpen" | "selectMultiple" | "selectedList"> {
  availableMediaTypes?: Array<MediaType>;
  onClose: () => void;
}

const availableTabs: TabsLayoutProps["tabs"] = [
  { value: GroupedMedia.BASIC },
  { value: MediaType.DOCUMENT },
  { value: MediaType.VIMEO },
  { value: MediaType.YOUTUBE },
  { value: MediaType.SKETCHFAB },
  { value: MediaType.DM },
];

const Container = styled("div")`
  height: 100%;
  overflow: auto;
  .MuiTab-wrapper {
    text-transform: none;
  }
`;

const TabsWrapper = styled("div")`
  height: calc(100% - 56px);
`;

const Heading = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& span": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& .column": {
      margin: 0,
    },
  },
  "& .MuiIconButton-root": {
    padding: 0,
  },
}));

export const MediaSelectorDrawerContent: FC<MediaSelectorDrawerContentProps> = ({
  availableMediaTypes,
  onSelect,
  onClose,
  drawerOpen,
  selectMultiple,
  selectedList,
}) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const instance = useStore().state.cmsContextInstance;
  const instanceId: ApplicationInstance["id"] = instance?.id;
  const getOwnerId = () => {
    if (instance?.owner_id) return instance.owner_id;
    const path = location.pathname.split("/");
    return Number(path.find((param, i) => i > 0 && path[i - 1] === "owner")) || undefined;
  };
  const ownerId: Owner["id"] = getOwnerId();

  const availableTabMediaTypes: (MediaType | GroupedMedia)[] = [];
  const filterMediaTypes: MediaType[] = [];
  if (availableMediaTypes) {
    availableMediaTypes.map((tab) => {
      const isMedia = [MediaType.IMAGE, MediaType.VIDEO, MediaType.AUDIO, MediaType.MODEL_3D].includes(tab);

      if (MediaType.IMAGE === tab && !filterMediaTypes.includes(tab)) filterMediaTypes.push(tab);
      if (MediaType.AUDIO === tab && !filterMediaTypes.includes(tab)) filterMediaTypes.push(tab);
      if (MediaType.VIDEO === tab && !filterMediaTypes.includes(tab)) filterMediaTypes.push(tab);
      if (MediaType.MODEL_3D === tab && !filterMediaTypes.includes(tab)) filterMediaTypes.push(tab);

      if (isMedia && !availableTabMediaTypes.includes(GroupedMedia.BASIC)) {
        availableTabMediaTypes.push(GroupedMedia.BASIC);
      } else if (!isMedia && availableMediaTypes.includes(tab as MediaType) && !availableTabMediaTypes.includes(tab)) {
        availableTabMediaTypes.push(tab);
      }
    });
  }

  const tabsToRender = availableMediaTypes?.length
    ? availableTabs.filter(
        (t) =>
          availableTabMediaTypes.includes(t.value as MediaType) ||
          availableTabMediaTypes.includes(t.value as GroupedMedia)
      )
    : availableTabs;

  return (
    <Container>
      <Heading>
        <span>
          <CollectionsOutlined />
          <KioTitle
            title={
              t("components.MediaSelector.Heading") +
              (availableMediaTypes?.length === 1
                ? ` (${t(`mediaType.plural.${availableMediaTypes?.[0]}`).toLowerCase()})`
                : "")
            }
            level={2}
          />
        </span>
        <Tooltip title={t("generic.close") || ""} arrow>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Heading>
      <TabsWrapper>
        <TabsLayout
          tabs={tabsToRender}
          ariaLabel={t("components.MediaSelector.MediaTabsARIALabel")}
          getLabel={(value) => t(`mediaType.${value}`)}
          keepContentMounted={false}
        >
          {(value) =>
            value === MediaType.DM ? (
              <DMTabPanel onSelect={onSelect} instanceId={instanceId} ownerId={ownerId} />
            ) : (
              <MediaTypeTabPanel
                mediaTypes={value === GroupedMedia.BASIC ? filterMediaTypes : (value as MediaType)}
                onSelect={onSelect}
                drawerOpen={drawerOpen}
                instanceId={instanceId}
                ownerId={ownerId}
                selectMultiple={selectMultiple}
                selectedList={selectedList}
                instance={instance}
              />
            )
          }
        </TabsLayout>
      </TabsWrapper>
    </Container>
  );
};

export default MediaSelectorDrawerContent;
