import styled from "@emotion/styled";
import { Add, Check, Close, Refresh, RemoveCircle, List as ListIcon } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import type { FieldProps } from "@rjsf/utils";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import KioTextField from "../common/KioTextField";
import { addTrailingSlashIfMissing } from "@/utils/url";
import { useStore } from "@/Store";
import Api from "@/services/Api";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import type QrCode from "@/declarations/models/QrCode";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";

const Container = styled.div`
  button {
    text-transform: none;
    font-weight: 700;
    width: fit-content;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QRCodeImg = styled.img`
  width: 100px;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

export const QRCodeField: FC<FieldProps> = ({ formContext, formData, onChange }) => {
  const { t } = useTranslation("common");
  const { state } = useStore();
  const qrCodeBaseUrl = addTrailingSlashIfMissing(state.cmsContextInstance?.qr_code_base_url, "https://example.com/");
  const fromDocumentId = Number(useParams<{ documentId: string }>().documentId);
  const toDocumentId = formContext.documentId;

  const hasDocId = (!!fromDocumentId && !!toDocumentId) || !!fromDocumentId;

  const [newQRCode, setNewQRCode] = useState<string>(formData?.code);
  const [qrCodeImageData, setQrCodeImageData] = useState<string | undefined>();
  const [mode, setMode] = useState<string>(formData?.code ? "created" : "start");
  const [isValid, setIsValid] = useState<boolean>(!!formData?.code);
  const [isCustom, setIsCustom] = useState<boolean>(formData?.is_custom);
  const [existingQRCode, setExistingQrCode] = useState<QrCode | null>(null);
  const [availableQrCodes, setAvailableQrCodes] = useState<QrCode[] | null>(null);

  const autoGenerateQrCode = () => {
    const length: number = 10;
    let randomPath: string = "";
    const characters: string = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength: number = characters.length;
    for (let i: number = 0; i < length; i++) {
      randomPath += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setNewQRCode(randomPath);
    if (!isCustom) setIsCustom(false);
    setMode("create");
  };

  useEffect(() => {
    if (mode !== "createFromList") return;
    Api.getAllQrCodes({ application_instance_id: state.cmsContextInstance?.id, only_available: true, sort_by: "code" })
      .fetchDirect([])
      .then((res) => {
        setAvailableQrCodes((res as { [key: string]: any })?.items);
      });
  }, [mode]);

  useEffect(() => {
    Api.getQRCodeImage({
      code: `${addTrailingSlashIfMissing(qrCodeBaseUrl, "https://example.com/")}${newQRCode}`,
    })
      .fetchDirect({})
      .then((imgResponse) => {
        setQrCodeImageData(URL.createObjectURL(imgResponse));
      });

    if (newQRCode && mode !== "created") {
      const checkIfQrCodeExist = Api.getOneQrCodeByCode(newQRCode);

      checkIfQrCodeExist.fetch().then((res) => {
        const [request, error] = res;
        if (error) {
          if (typeof error !== "string" && error?.exception === "KIONotFoundException") {
            // noop. Add logic here if needed
          } else {
            console.error(error);
          }
        }
        const isQrCodeValid: boolean = checkIfQRCodeIsValid(request);
        setExistingQrCode(request);
        setIsValid(isQrCodeValid);
      });
      return checkIfQrCodeExist.abort;
    }
  }, [newQRCode]);

  const checkIfQRCodeIsValid = (potentialExistingQRCode: QrCode | null) => {
    if (newQRCode.length < 3) return false;
    const isAlphaNumeric = /^[a-z0-9#/_\-]+$/i.test(newQRCode);
    if (!isAlphaNumeric) return false;
    if (potentialExistingQRCode === null) return true;
    if (potentialExistingQRCode.code === newQRCode) {
      // qr-code already exist
      return potentialExistingQRCode.document_id === null && potentialExistingQRCode.context_document_id === null;
      // exists, but is not available
    }
    return true;
  };

  const saveQrCode = () => {
    if (newQRCode && isValid) {
      const ownerId: number | undefined = state?.cmsContextInstance?.owner_id;
      const instanceId: number | undefined = state?.cmsContextInstance?.id;

      if (!ownerId || !instanceId) return;

      const data: QrCode = {
        owner_id: ownerId,
        application_instance_id: instanceId,
        code: newQRCode,
        document_id: toDocumentId,
        context_document_id: fromDocumentId,
        is_custom: isCustom,
      };
      if (existingQRCode !== null) {
        data.id = existingQRCode.id;
        Api.updateQrCode(data)
          .fetchDirect(null)
          .then((res) => {
            onChange({
              ...formData,
              id: res?.id,
              ...data,
            });
            setMode("created");
          });
      } else {
        Api.createQrCode(data)
          .fetchDirect(null)
          .then((res) => {
            onChange({
              ...formData,
              id: res?.id,
              ...data,
            });
            setMode("created");
          });
      }
    }
  };

  const releaseQrCode = () => {
    const clearFormData = () => {
      onChange({});
      setMode("start");
      setNewQRCode("");
    };
    Api.getOneQrCode(formData?.id)
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          const data: QrCode = { ...res };
          data.context_document_id = null;
          data.document_id = null;
          Api.updateQrCode(data)
            .fetchDirect(null)
            .then((_) => {
              clearFormData();
            });
        } else {
          clearFormData();
        }
      });
  };

  return (
    <Container>
      <KioTitle title={t("components.QRCodeField.qrCode")} level={2} />
      {mode === "start" && (
        <Row>
          <Tooltip title={hasDocId ? "" : t("components.QRCodeField.documentMustBeSaved")}>
            <Box display={"flex"} gap={2}>
              <Button variant="outlined" startIcon={<Refresh />} disabled={!hasDocId} onClick={autoGenerateQrCode}>
                {t("components.QRCodeField.autoGenerate")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<Add />}
                disabled={!hasDocId}
                onClick={() => {
                  setMode("create");
                  setNewQRCode("");
                  setIsCustom(true);
                }}
              >
                {t("components.QRCodeField.createCustomized")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<ListIcon />}
                disabled={!hasDocId}
                onClick={() => {
                  setMode("createFromList");
                  setNewQRCode("");
                  setIsCustom(true);
                }}
              >
                {t("components.QRCodeField.selectFromList")}
              </Button>
            </Box>
          </Tooltip>
        </Row>
      )}

      {mode === "create" && (
        <Col>
          <KioTitle
            title={
              !isCustom ? t("components.QRCodeField.createAutoGenerated") : t("components.QRCodeField.createCustomized")
            }
            level={3}
          />
          <KioTextField
            name="path"
            value={newQRCode}
            aria-label="path"
            label={t("components.QRCodeField.uniquePath")}
            onChange={(e) => setNewQRCode(e.target.value)}
            disabled={!isCustom}
          />

          {newQRCode && <QRCodeImg src={qrCodeImageData} alt={`QR Code: ${newQRCode}`} />}
          <Row>
            <Tooltip title={isValid ? "" : t("components.QRCodeField.invalidCode")}>
              <div>
                <Button
                  variant="outlined"
                  startIcon={isValid ? <Check /> : <Close />}
                  disabled={!isValid || !hasDocId}
                  onClick={saveQrCode}
                >
                  {t("components.QRCodeField.useCode")}
                </Button>
              </div>
            </Tooltip>
            <Button variant="outlined" startIcon={<Close />} onClick={() => setMode("start")}>
              {t("components.QRCodeField.cancel")}
            </Button>
          </Row>
        </Col>
      )}

      {mode === "createFromList" && (
        <Col>
          <KioTitle title={t("components.QRCodeField.selectFromListDetailed")} level={3} />
          <KioSelect
            name="path"
            options={availableQrCodes?.map((el) => ({
              label: el.code || "",
              value: el.code || "",
            }))}
            value={newQRCode}
            aria-label="path"
            label={t("components.QRCodeField.uniquePath")}
            onChange={setNewQRCode}
            disabled={!isCustom}
          />
          {newQRCode && <QRCodeImg src={qrCodeImageData} alt={`QR Code: ${newQRCode}`} />}
          <Row>
            <Tooltip title={isValid ? "" : t("components.QRCodeField.invalidCode")}>
              <div>
                <Button
                  variant="outlined"
                  startIcon={isValid ? <Check /> : <Close />}
                  disabled={!isValid || !hasDocId}
                  onClick={saveQrCode}
                >
                  {t("components.QRCodeField.useCode")}
                </Button>
              </div>
            </Tooltip>
            <Button variant="outlined" startIcon={<Close />} onClick={() => setMode("start")}>
              {t("components.QRCodeField.cancel")}
            </Button>
          </Row>
        </Col>
      )}

      {mode === "created" && (
        <Row>
          {newQRCode && isValid && <QRCodeImg src={qrCodeImageData} alt={`QR Code: ${newQRCode}`} />}
          <Col>
            <KioTextField
              name="path"
              value={newQRCode}
              aria-label="path"
              label={t("components.QRCodeField.uniquePath")}
              onChange={(e) => setNewQRCode(e.target.value)}
              disabled
            />
            <Button variant="outlined" startIcon={<RemoveCircle />} onClick={releaseQrCode}>
              {t("components.QRCodeField.release")}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default QRCodeField;
