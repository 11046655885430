import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material";
import placeholderImage from "@/assets/img/placeholder_image_small.svg";
import type DocumentSearchHit from "@/declarations/models/DocumentSearchHit";
import Api from "@/services/Api";

interface SearchHitProps {
  hit: DocumentSearchHit;
}

const HitContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  overflow: "clip",
}));

const ImageContainer = styled("div")`
  width: 64px;
  height: 64px;
  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
`;

const TextDiv = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  &.title {
    font-weight: 700;
  }
`;

function mapHitToMarkup(field: string, i: number) {
  const strArr = field.split(/<u>([\w\u0100-\u017F\u0080-\u00FF\u0180-\u024F]+)<\/u>/gi);
  const matches = field.match(/<u>([\w\u0100-\u017F\u0080-\u00FF\u0180-\u024F]+)<\/u>/gi);
  function highlightMatches(str: string) {
    if (matches?.includes(`<u>${str}</u>`)) return <u>{str}</u>;
    return str;
  }
  return strArr.map((field, ii) => <span key={`${i}-${ii}`}>{highlightMatches(field)}</span>);
}

const SearchHit: FC<SearchHitProps> = ({ hit }) => {
  const fields = useMemo(() => hit.fields.map(mapHitToMarkup), [hit]);
  const [imageSrc, setImageSrc] = useState<string | undefined>("");

  useEffect(() => {
    const getDoc = Api.getProcessedFieldsOfDocument(hit.id);
    getDoc.fetchDirect(null).then((doc) => {
      setImageSrc(doc?.thumbnail_src);
    });
    return getDoc.abort;
  }, [hit]);

  return (
    <HitContainer data-cy="search-hit">
      <ImageContainer>
        <img src={imageSrc || placeholderImage} alt={hit.document_name} />
      </ImageContainer>
      <TextContainer>
        <TextDiv className={"title"}>{hit.document_name}</TextDiv>
        {fields.map((field, i) => (
          <TextDiv key={i}>{field}</TextDiv>
        ))}
      </TextContainer>
    </HitContainer>
  );
};

export default SearchHit;
