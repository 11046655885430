import type { RJSFSchema } from "@rjsf/utils";

export const schema: RJSFSchema = {
  type: "object",
  properties: {
    id: {
      type: "integer",
      readOnly: true,
    },
    version: {
      type: "number",
      default: 1,
      readOnly: true,
    },
    created_at: {
      type: "string",
      readOnly: true,
    },
    created_by: {
      type: "string",
      readOnly: true,
    },
    updated_at: {
      type: "string",
      readOnly: true,
    },
    updated_by: {
      type: "string",
      readOnly: true,
    },
    name: {
      type: "string",
      minLength: 1,
    },
    description: {
      type: ["string", "null"],
    },
    application_id: {
      type: "integer",
    },
    single_document_per_instance: {
      type: ["boolean", "null"],
    },
    json_schema: {
      type: "string",
      default: "{}",
    },
    ui_schema: {
      type: "string",
      default: "{}",
    },
    searchable_fields: {
      type: "array",
      items: {
        type: "object",
        properties: {
          include_in_hit: {
            type: ["boolean", "null"],
          },
          jsonpath: {
            type: "string",
            minLength: 1,
          },
        },
        required: ["jsonpath"],
      },
    },
    internal_title_field_json_path: {
      type: ["string", "null"],
    },
    title_field_json_path: {
      type: ["string", "null"],
    },
    description_field_json_path: {
      type: ["string", "null"],
    },
    image_field_json_path: {
      type: ["string", "null"],
    },
    preview_url: {
      type: ["string", "null"],
    },
    description_field_override: {
      type: ["string", "null"],
    },
  },
  required: ["name", "json_schema", "ui_schema", "application_id"],
};
