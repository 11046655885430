import type { FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, styled, Typography } from "@mui/material";
import AuthenticationApi from "../../services/AuthenticationApi";
import Layout from "../Layout";
import Header from "../Header";
import { useStore } from "@/Store";
import { cancellablePromise } from "@/utils/async";

const COUNT_DOWN_SECONDS = 5;

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(8),
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));

export const EkulturLogoutHandler: FC = () => {
  const { state } = useStore();
  const name = state?.user?.name || "header.profile.anonymous";

  const intervalId = useRef<NodeJS.Timer | null>(null);
  const [counter, setCounter] = useState<number>(COUNT_DOWN_SECONDS);
  const history = useNavigate();
  const { t } = useTranslation("common");

  const cleanupTimer = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, []);

  const handleAbort = () => {
    history("/");
  };

  const handleLogoutNow = () => {
    setCounter(0);
  };

  useEffect(() => {
    intervalId.current = setInterval(() => setCounter((c) => c - 1), 1000);
    return cleanupTimer;
  }, [cleanupTimer]);

  useEffect(() => {
    if (counter > 0) {
      return;
    }
    cleanupTimer();
    const [logout, cancel] = cancellablePromise(AuthenticationApi.logout());
    logout.then(() => history("/"));
    return cancel;
  }, [counter, history, cleanupTimer]);

  return (
    <Layout header={<Header />}>
      <Container>
        {counter > 0 ? (
          <>
            <Typography variant="h4" component="h1">
              {t("views.logout.loggingOutInXSec", { name: t(name), count: counter })}
            </Typography>
            <ButtonContainer>
              <Button type="button" variant="outlined" onClick={handleAbort} role="navigation">
                {t("generic.cancel")}
              </Button>
              <Button type="button" variant="contained" color="primary" onClick={handleLogoutNow}>
                {t("views.logout.logOutNow")}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <Typography variant="h4" component="h1">
              {t("views.logout.youAreNowBeingLoggedOut")}
            </Typography>
            <CircularProgress variant="indeterminate" color="primary" />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default EkulturLogoutHandler;
