import { Delete, Movie, MusicNote } from "@mui/icons-material";
import { Avatar, ButtonBase, IconButton, styled, Tooltip, Typography } from "@mui/material";
import type { ChangeEvent, DragEvent, FC, ReactNode } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ModelViewerElement } from "@google/model-viewer";
import FormButtons from "@/components/FormButtons";
import HoverToggle from "@/components/HoverToggle";
import type Media from "@/declarations/models/Media";
import Loader from "@/framework/Loader";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { clsx } from "@/utils/styles";
import "@google/model-viewer/dist/model-viewer";
import MediaType from "@/declarations/models/MediaType";

const IMAGE_ICON_STYLING = { height: 56, width: 56 };

export interface FileUploadProps {
  name?: string;
  required?: boolean;
  allowedMimeTypes: Array<string>;
  multiple?: boolean;
  validateFile?: (file: File) => boolean;
  onCancel: () => void;
  onSaved: () => void;
  ownerId?: number;
  instanceId?: number;
  isModal?: boolean;
}

const DropZone = styled(ButtonBase)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  maxHeight: "550px",
  minHeight: "150px",
  width: "100%",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.getContrastText(theme.palette.background.paper),
  border: `2px dashed ${theme.palette.secondary.main}`,
  borderRadius: theme.spacing(1),
  gap: theme.spacing(1),
  margin: theme.spacing(2),
  "&:focus": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  "&:hover, &.dragging": {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  "&.error": {
    borderColor: theme.palette.error.main,
  },
  "&[disabled]": {
    borderColor: theme.palette.text.disabled,
    color: theme.palette.text.disabled,
  },
}));

const SelectedFilesContainer = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled("div")`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-width: 1000px;
  min-height: 96px;
  gap: 16px;
  padding: 16px;
`;

function isDragEvent(event: ChangeEvent | DragEvent): event is DragEvent {
  return !!event && Object.keys(event).includes("dataTransfer");
}

function isGlb(file: File): boolean {
  const fileExtension = file.name.split(".").pop();
  return fileExtension === "glb" || file.type === "model/gltf-binary";
}

export const FileUpload: FC<FileUploadProps> = ({
  name = "file",
  allowedMimeTypes = [],
  required = false,
  multiple = true,
  validateFile,
  onCancel,
  onSaved,
  ownerId,
  instanceId,
  isModal = true,
}) => {
  const { t } = useTranslation("common");
  const { error } = useMessenger();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [userIsCurrentlyDraggingAFile, setUserIsCurrentlyDraggingAFile] = useState<boolean>(false);

  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [validFiles, setValidFiles] = useState<Array<File>>([]);
  const validationError: boolean = (!ownerId && !instanceId) || validFiles.length <= 0;
  const isFileValid = (file: File): boolean => {
    const fileExtension = file.name.split(".").pop();
    const isValidType = !allowedMimeTypes.length || allowedMimeTypes.includes(file.type);
    const isValidExtension = !allowedMimeTypes.length || allowedMimeTypes.includes(`.${fileExtension}` || "");

    return (isValidType || isValidExtension) && (!validateFile || validateFile(file));
  };

  const handleFilesSelected = (event: ChangeEvent<HTMLInputElement> | DragEvent): void => {
    const files = isDragEvent(event) ? event.dataTransfer.files : event.target.files;
    if (files?.length) {
      const validNew = Array.from(files).filter(isFileValid);
      const invalidCount = files.length - validNew.length;
      if (invalidCount > 0) console.info(`${invalidCount} invalid files were removed from selection.`);
      setValidFiles([...validFiles, ...validNew]);
    }
  };

  const handleDragEnter = (event: DragEvent) => {
    // Prevent the browser from opening the file, and signifies that this element is a DropZone
    event.preventDefault();
    setUserIsCurrentlyDraggingAFile(true);
  };

  const handleDragLeave = () => {
    setUserIsCurrentlyDraggingAFile(false);
  };

  const handleFileDropped = (event: DragEvent) => {
    // Prevent the browser from opening the file
    event.preventDefault();
    handleFilesSelected(event);
    handleDragLeave();
  };

  const handleRemoveFileFromUpload = (file: File) => () => {
    if (!!inputRef.current?.files && Array.from(inputRef.current?.files).includes(file)) {
      inputRef.current.value = "";
    }
    setValidFiles(validFiles.filter((f) => f !== file));
  };

  const captureAndUpdateModel3DThumbnail = async (media: Media, index: number) => {
    const modelViewer = document.getElementById(`secret-thumbnail-generator-model-viewer-${index}`) as
      | ModelViewerElement
      | undefined;
    if (modelViewer) {
      try {
        const b = await modelViewer.toBlob();
        const fileName: string = t("editMedia.generatedModel3DThumbnail", { modelName: media.name });
        const thumbnailMedia = await Api.uploadToDMS(b, ownerId, instanceId, fileName).fetchDirect(null);
        if (thumbnailMedia) {
          await Api.updateMedia({
            ...media,
            thumbnail_override: {
              mediaId: thumbnailMedia.id!,
            },
          }).fetchDirect(null);
        }
      } catch (e) {
        console.error("Failed to capture and update model 3D thumbnail", e);
      }
    }
  };

  const saveMedia = async () => {
    if (!validationError) {
      startLoading();
      for (let i = 0; i < validFiles.length; i++) {
        const file = validFiles[i];
        let savedMedia: Media | null = null;
        const fileName: string = file.name.replace(/\.[^/.]+$/, "") || "NoFileNameFound";
        savedMedia = await Api.uploadToDMS(file, ownerId, instanceId, fileName).fetchDirect(null);
        if (savedMedia) {
          if (savedMedia.media_type === MediaType.MODEL_3D) {
            await captureAndUpdateModel3DThumbnail(savedMedia, i);
          }
        } else {
          error("components.MediaSelector.AddDMSContent.uploadError");
        }
      }
      setValidFiles([]);
      onSaved();
      stopLoading();
    }
  };

  const mapSelectedFiles = (files: Array<File>) => {
    const toItem = (file: File, index: number): ReactNode => (
      <HoverToggle
        key={index}
        toggle={
          <Tooltip title={t("components.FileUpload.removeFile", { fileName: file.name }) ?? ""} placement="top" arrow>
            <IconButton onClick={handleRemoveFileFromUpload(file)} sx={IMAGE_ICON_STYLING}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        }
      >
        <Avatar src={URL.createObjectURL(file)} alt={file.name} sx={IMAGE_ICON_STYLING}>
          {isGlb(file) ? (
            <>
              {/* hidden model-viewer for generating higher-quality thumbnail */}
              <model-viewer
                id={`secret-thumbnail-generator-model-viewer-${index}`}
                src={URL.createObjectURL(file)}
                style={{
                  position: "absolute",
                  visibility: "hidden",
                  height: "512px",
                  width: "512px",
                }}
              ></model-viewer>
              {/* model-viewer for displaying a tiny preview before upload
              not suited for generating a blob to upload, it gets too small */}
              <model-viewer
                id={`preview-3d-model-${index}`}
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={IMAGE_ICON_STYLING}
              ></model-viewer>
            </>
          ) : file.type.startsWith("audio") ? (
            <MusicNote />
          ) : (
            <Movie />
          )}
        </Avatar>
      </HoverToggle>
    );
    return files.map(toItem);
  };
  return (
    <>
      <input
        ref={inputRef}
        type="file"
        name={name}
        required={required}
        aria-labelledby={`${name}-upload-drop-zone`}
        aria-disabled={isLoading}
        disabled={isLoading}
        onChange={handleFilesSelected}
        multiple={multiple}
        hidden
        accept={allowedMimeTypes.toString()}
      />
      <DropZone
        id={`${name}-upload-drop-zone`}
        type="button"
        className={clsx({ dragging: userIsCurrentlyDraggingAFile, error: false })}
        disabled={isLoading}
        onClick={() => inputRef.current?.click()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleFileDropped}
      >
        {t("components.FileUpload.dropOrClickToSelectFile")}
        <Typography hidden={true} color="error">
          {t("components.FileUpload.invalidFile", { count: 14 })}
        </Typography>
      </DropZone>
      {isLoading && <Loader loadingText="components.MediaSelector.AddDMSContent.uploadingContent" />}
      <FormContainer>
        <SelectedFilesContainer>{mapSelectedFiles(validFiles)}</SelectedFilesContainer>
        <FormButtons
          disableCancelButton={isLoading}
          disableSubmitButton={isLoading || validationError}
          onCancel={isModal ? onCancel : undefined}
          onSubmit={saveMedia}
        />
      </FormContainer>
    </>
  );
};

export default FileUpload;
