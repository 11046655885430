import { useCallback, useDebugValue, useState } from "react";
import { useIsMounted } from "./useIsMounted";

type Dispatch<T> = ((prev: T) => T) | T;
type AsyncSafeState<T> = [value: T, setter: (value: Dispatch<T>) => void, isMounted: () => boolean];

/**
 * Works the same way as React's `useState`, but with async safety.
 * Meaning you can set state in an async callback, without getting a memory leak.
 * @param initialValue
 */
export const useAsyncSafeState = <T>(initialValue?: T): AsyncSafeState<T> => {
  const isMounted = useIsMounted();

  const [value, setValue] = useState<T>(initialValue as T);

  useDebugValue(value);

  const setValueSafely = useCallback(
    (newValue: Dispatch<T>) => {
      if (isMounted()) {
        setValue(newValue);
      }
    },
    [isMounted]
  );

  return [value, setValueSafely, isMounted];
};
