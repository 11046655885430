/**
 * Root path for admin resources
 */
export enum AdminResourcePath {
  USER = "user",
  TRANSLATION = "translation",
  TRANSLATION_KEY = "translation-key",
  APPLICATION = "application",
  OWNER = "owner",
  LICENSE = "license",
  API_KEY = "api-key",
  SCHEMA = "schema",
  LOCALE = "locale",
  INSTANCE = "instance",
}
