import type { RJSFSchema, UiSchema } from "@rjsf/utils";
import { baseJsonSchema, baseUiSchema } from "@/views/cms/EditorView/mediaSchemas/baseMediaSchema";

const tempJson = JSON.parse(JSON.stringify(baseJsonSchema)) as RJSFSchema;
export const imageJsonSchema: RJSFSchema = tempJson;

const tempUi = JSON.parse(JSON.stringify(baseUiSchema)) as UiSchema;
tempUi.metaData.category["ui:options"] = {
  choices: [
    { value: "", "ui:label": "mediaCategory.none" },
    { value: "photograph", "ui:label": "mediaCategory.photograph" },
    { value: "illustration", "ui:label": "mediaCategory.illustration" },
    { value: "painting", "ui:label": "mediaCategory.painting" },
    { value: "vector", "ui:label": "mediaCategory.vector" },
    { value: "other", "ui:label": "mediaCategory.other" },
  ],
};
export const imageUiSchema: UiSchema = tempUi;
