import type { ReactNode } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import i18next from "i18next";
import type { RJSFSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { generatePath, useMatch, useNavigate, useParams } from "react-router-dom";
import type BaseModel from "../declarations/models/BaseModel";
import KioForm from "../framework/KioForm/KioForm";
import BreadcrumbNode from "./BreadcrumbNode";
import Styles from "@/assets/styles/Styles";
import PersistentDrawer from "@/components/PersistentDrawer";
import type { RequestContext } from "@/declarations/RequestContext";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { usePrompt } from "@/hooks/useUNSAFE_Prompt";
import { isPromise } from "@/utils/async";
import { checkIsDirty, getLanguageCode, getValue } from "@/utils/obj";

export interface CreateOrEditProps<T> {
  getFormData?: (id: number) => RequestContext<T>;
  onSubmit?: (payload: T) => RequestContext<T> | Promise<RequestContext<T>>;
  onChange?: (payload: T) => void;
  schema?: RJSFSchema;
  uiSchema?: any;
  goBackOnSubmit?: boolean;
  /**
   * The path to the value of the object (in schema) to use for the breadcrumb
   */
  breadcrumbPath?: string;
  previewContent?: ReactNode;
  previewWidth?: string;
  beforeContent?: ReactNode;
  afterContent?: ReactNode;
  ifDirtyPrompt?: boolean;
}

const CreateOrEdit = <T extends BaseModel>({
  schema,
  uiSchema,
  getFormData,
  onSubmit,
  onChange,
  goBackOnSubmit = false,
  breadcrumbPath = "name",
  previewContent,
  previewWidth,
  beforeContent,
  afterContent,
  ifDirtyPrompt = true,
}: CreateOrEditProps<T>) => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  const match = useMatch({
    path: "/admin/:item/:action",
  });
  const [originalFormData, setOriginalFormData] = useState<T>({} as T);
  const getFormDataRef = useRef(getFormData);
  getFormDataRef.current = getFormData;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const { id } = useParams<{ id?: string }>();
  const [formData, setFormData] = useAsyncSafeState<T>({} as T);
  const msg = useMessenger();
  const breadcrumbLabel = getValue(formData, breadcrumbPath) || "";
  const title = t(uiSchema["ui:title"]);
  if (title) document.title = title;

  const isDirty = useMemo<boolean>(() => {
    if (!formData) return false;
    return checkIsDirty(originalFormData, formData);
  }, [formData, originalFormData]);

  usePrompt(t("generic.unsavedChangesMessage"), ifDirtyPrompt && isDirty);

  const onSubmitHandler = async () => {
    const result = onSubmit?.(formData);
    if (!result) {
      return;
    }
    let ctx;
    if (isPromise(result)) {
      ctx = await result;
    } else {
      ctx = result;
    }
    const [res, err, source] = await ctx.fetch();
    if (err) {
      console.error("Unable to save: ", err);
      msg.error("components.createOrEdit.errorOccurred");
    } else if (res) {
      setOriginalFormData(formData);
      msg.success("generic.saved");
      if (goBackOnSubmit) {
        history(-1);
      } else {
        if (match?.params.action == "create") {
          const targetPath = generatePath("/admin/:item/:id", {
            id: res.id ? res.id.toString() : "",
            item: match.params.item,
          });
          history(targetPath);
        }
      }
    } else {
      console.warn("Neither result, nor error present: ", source);
      // KULADM-145: body from response is read further up, we can assume it was successful because no error, so go back anyway.
      setOriginalFormData(formData);
      goBackOnSubmit && history(-1);
    }
  };

  const handleChange = (data: T) => {
    onChange?.(data);
    setFormData(data);
  };

  useEffect(() => {
    if (!id || !getFormDataRef.current) {
      setFormData({} as T);
      onChangeRef.current?.({} as T);
      setOriginalFormData({} as T);
      return;
    }
    const getFormDataRequest = getFormDataRef.current(parseInt(id));
    getFormDataRequest.fetchDirect({} as T).then((data) => {
      setOriginalFormData(data);
      setFormData(data);
      onChangeRef.current?.(data);
    });
    return getFormDataRequest.abort;
  }, [id, setFormData]);

  return (
    <>
      <PersistentDrawer
        position="right"
        width={previewWidth || Styles.Dimensions.DEFAULT_ADMIN_PREVIEW_WIDTH}
        open={!!previewContent}
        drawerContent={previewContent}
      >
        <Box p={2} display={"flex"} flexDirection={"column"} gap={2}>
          <BreadcrumbNode
            label={t(`components.createOrEdit.breadcrumbLabel.${id ? "edit" : "create"}`, {
              name: breadcrumbLabel || schema?.title || "?",
            })}
          />
          {beforeContent}
          {schema && formData && (
            <KioForm
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              onSubmit={onSubmitHandler}
              onChange={handleChange}
              onCancel={() => history(-1)}
              selectedLocale={getLanguageCode(i18next.language)}
            />
          )}
          {afterContent}
        </Box>
      </PersistentDrawer>
    </>
  );
};

export default CreateOrEdit;
