import i18next from "i18next";
import { Build, Menu, MenuOpen } from "@mui/icons-material";
import { AppBar, IconButton, Popover, styled, Toolbar, Tooltip, Typography } from "@mui/material";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Styles from "@/assets/styles/Styles";
import CurrentUserAvatar from "@/components/CurrentUserAvatar";
import ProfileMenu from "@/components/ProfileMenu";
import TextDivider from "@/components/TextDivider";
import type Owner from "@/declarations/models/Owner";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import Api from "@/services/Api";
import { useStore } from "@/Store";

export interface HeaderProps {
  onToggleMenu?: (open: boolean) => void;
  showMenuButton?: boolean;
}

const TopAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Container = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  height: theme.spacing(Styles.Dimensions.HEADER_HEIGHT),
}));

const IconButtonOnPrimary = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const ProfileAvatarButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(1),
  "& .MuiAvatar-colorDefault": {
    background: Styles.Colors.AVATAR_BACKGROUND_COLOR,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const PrimaryText = styled(Text)({
  fontSize: "20px",
  fontWeight: 700,
});

const SecondaryText = styled(Text)({
  fontSize: "18px",
  fontWeight: 400,
});

const TextContainer = styled(Link)({
  display: "flex",
  textDecoration: "none",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
});

const Header: FC<HeaderProps> = ({ onToggleMenu, showMenuButton = false }) => {
  const { t } = useTranslation("common");
  const { state } = useStore();
  const history = useNavigate();
  const appsButtonRef = useRef<HTMLButtonElement | null>(null);
  const profileButtonRef = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const isAdminRoute: boolean = pathname.includes("admin");
  const selectedLocale = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);
  const [owners, setOwners] = useState<Owner[] | undefined>([]);

  const textElements = [
    t("generic.appTitle"),
    getLocalizedString(state.cmsContextInstance?.owner_name) || "",
    state.cmsContextInstance?.name || "",
  ]
    .filter((n) => !!n)
    .slice(-2)
    .reverse();
  const primaryTitle = textElements.pop();
  const secondaryTitle = textElements.pop();

  const toggleMenu = () => {
    const isOpen = !menuOpen;
    setMenuOpen(isOpen);
    onToggleMenu && onToggleMenu(isOpen);
  };

  useEffect(() => {
    const getInstances = Api.getAllOwners({ sort_by: "name", only_edit_privileges: true });

    getInstances.fetchDirect(null).then((res) => setOwners(res?.items));
    return getInstances.abort;
  }, [setOwners]);

  return (
    <TopAppBar position="relative">
      <Container>
        {showMenuButton ? (
          <Tooltip title={t(`headerButtons.${menuOpen ? "hideMenu" : "showMenu"}`) || "Menu"}>
            <IconButtonOnPrimary onClick={toggleMenu} edge="start">
              {menuOpen ? <MenuOpen /> : <Menu />}
            </IconButtonOnPrimary>
          </Tooltip>
        ) : (
          <span />
        )}

        <TextContainer to="/dashboard">
          {!!primaryTitle && <PrimaryText>{primaryTitle}</PrimaryText>}
          {!!secondaryTitle && <TextDivider />}
          {!!secondaryTitle && <SecondaryText>{secondaryTitle}</SecondaryText>}
        </TextContainer>

        <span>
          {!!state.user?.is_admin && (
            <Tooltip title={t(`headerButtons.${isAdminRoute ? "goToDashboard" : "goToAdmin"}`) || "Admin"}>
              <IconButtonOnPrimary
                ref={appsButtonRef}
                onClick={() => (isAdminRoute ? history("/dashboard") : history("/admin"))}
              >
                {isAdminRoute ? <span className="material-icons">wysiwyg</span> : <Build />}
              </IconButtonOnPrimary>
            </Tooltip>
          )}

          <Tooltip title={t(`headerButtons.${profileMenuOpen ? "hideProfileMenu" : "showProfileMenu"}`) || "Profile"}>
            <ProfileAvatarButton ref={profileButtonRef} edge="end" onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
              <CurrentUserAvatar />
            </ProfileAvatarButton>
          </Tooltip>
          <Popover
            anchorEl={profileButtonRef.current}
            open={profileMenuOpen}
            onClose={() => setProfileMenuOpen(false)}
            anchorOrigin={{ vertical: 56, horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <ProfileMenu owners={owners} closeMenu={() => setProfileMenuOpen(false)} />
          </Popover>
        </span>
      </Container>
    </TopAppBar>
  );
};

export default Header;
