import type { UiSchema } from "@rjsf/utils";

export const uiSchema: UiSchema = {
  "ui:title": "schemaTypes.application",
  id: {
    "ui:title": "generic.id",
  },
  name: {
    "ui:title": "generic.name",
  },
  slug: {
    "ui:title": "generic.slug",
  },
  description: {
    "ui:title": "generic.description",
  },
  image_url: {
    "ui:title": "generic.image_url",
  },
  url: {
    "ui:title": "generic.url",
  },
  qr_code_base_url: {
    "ui:title": "views.admin.applications.qr_code_base_url",
  },
  owner_schema_id: {
    "ui:title": "views.admin.applications.owner_schema_id",
    "ui:help": "views.admin.applications.owner_schema_id_help",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Schema",
      valueField: "id",
      displayField: "name",
    },
  },
  menu_heading: {
    "ui:widget": "HelperTextWidget",
    "ui:title": "generic.menu",
  },
  menu: {
    "ui:title": "generic.menu_item",
    "ui:layout": {
      template: "Flat",
    },
    items: {
      title: {
        "ui:field": "LocalizedField",
        items: {
          "ui:title": "generic.title",
        },
      },
      menu_items: {
        "ui:title": "generic.menu_item",
        "ui:layout": {
          template: "Flat",
        },
        items: {
          mui_icon: {
            "ui:title": "generic.icon",
            "ui:widget": "MuiIconWidget",
            "ui:colspan": 5,
          },
          title: {
            "ui:field": "LocalizedField",
            items: {
              "ui:title": "generic.title",
            },
            "ui:colspan": 7,
          },
          action: {
            "ui:title": "generic.menu_action",
            doc_type: {
              "ui:title": "Dokumenttype",
            },
            media_type: {
              "ui:title": "Media type",
            },
            schema_id: {
              "ui:title": "Skjema",
              "ui:widget": "EntityAutocompleteWidget",
              "ui:options": {
                type: "Schema",
                valueField: "id",
                displayField: "name",
              },
              "ui:colspan": 9,
            },
            single_document: {
              "ui:title": "Enkeltdokument",
              "ui:widget": "SwitchWidget",
              "ui:colspan": 3,
            },
          },
          sub_menu_items: {
            "ui:title": "generic.menu_sub_item",
            "ui:layout": {
              template: "Flat",
            },
            items: {
              mui_icon: {
                "ui:title": "generic.icon",
                "ui:widget": "MuiIconWidget",
                "ui:colspan": 5,
              },
              title: {
                "ui:field": "LocalizedField",
                items: {
                  "ui:title": "generic.title",
                },
                "ui:colspan": 7,
              },
              action: {
                "ui:title": "generic.menu_action",
                doc_type: {
                  "ui:title": "Dokumenttype",
                },
                media_type: {
                  "ui:title": "Media type",
                },
                schema_id: {
                  "ui:title": "Skjema",
                  "ui:widget": "EntityAutocompleteWidget",
                  "ui:options": {
                    type: "Schema",
                    valueField: "id",
                    displayField: "name",
                  },
                  "ui:colspan": 9,
                },
                single_document: {
                  "ui:title": "Enkeltdokument",
                  "ui:widget": "SwitchWidget",
                  "ui:colspan": 3,
                },
              },
            },
          },
        },
      },
    },
  },
};
