import type { FC } from "react";
import { Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Layout from "../framework/Layout";
import Header from "../framework/Header";

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(8),
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));

export const AccessDeniedView: FC = () => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  return (
    <Layout header={<Header />}>
      <ContentContainer>
        <Typography variant="h4" component="h1">
          {t("views.accessDenied.youDoNotHaveAccessToThisPage")}
        </Typography>
        <ButtonContainer>
          <Button type="button" variant="outlined" role="navigation" onClick={() => history(-1)}>
            {t("generic.back")}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            role="navigation"
            onClick={() => history("/dashboard")}
          >
            {t("generic.toDashboard")}
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Layout>
  );
};

export default AccessDeniedView;
