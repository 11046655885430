import { List as MuiList, ListItemButton } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef, useState } from "react";
import type { SimpleDialogListRow } from "./SimpleDialogListRow";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { StyledDialog } from "@/components/MediaSelector/FocalPointModal";

export interface SimpleDialogProps<T> {
  data: Array<T>;
  title: string;
  open: boolean;
  selectedValue: any;

  onClose?: (selectedValue: any) => void;
  mapperFn: (item: T) => SimpleDialogListRow;
}

const SimpleDialog = <T extends object | string>({
  data,
  title,
  open,
  selectedValue,
  onClose,
  mapperFn,
}: SimpleDialogProps<T>) => {
  const [items, setItems] = useState<Array<SimpleDialogListRow> | undefined>();
  const mapperRef = useRef<(original: T) => SimpleDialogListRow>(mapperFn);
  mapperRef.current = mapperFn;

  useEffect(() => {
    setItems(data.map((_item) => mapperRef.current(_item)));
  }, [data]);

  return (
    <StyledDialog onClose={() => onClose?.(selectedValue)} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <KioTitle title={title} />
      </DialogTitle>
      <MuiList>
        {items &&
          items.map((_item) => (
            <ListItemButton key={_item.key} onClick={() => onClose?.(_item.key)}>
              {_item.title}
            </ListItemButton>
          ))}
      </MuiList>
    </StyledDialog>
  );
};
export default SimpleDialog;
