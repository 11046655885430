import type { RJSFSchema, UiSchema } from "@rjsf/utils";
import { baseJsonSchema, baseUiSchema } from "@/views/cms/EditorView/mediaSchemas/baseMediaSchema";

const tempJson = JSON.parse(JSON.stringify(baseJsonSchema)) as RJSFSchema;
if (tempJson.properties) {
  tempJson.properties.captions = {
    type: "object",
    properties: {
      closed_captions: {
        type: "object",
        patternProperties: {
          "^[a-zA-Z]{2}$": {
            type: "string",
          },
        },
      },
    },
  };
  tempJson.properties.settings = {
    type: "object",
    properties: {
      volume: {
        type: "integer",
        minimum: 0,
        maximum: 100,
        default: 50,
      },

      thumbnail_override: {
        title: "Thumbnail",
        type: "object",
        properties: {
          mediaId: {
            type: ["integer", "null"],
            default: null,
          },
        },
      },
    },
  };
}
export const videoJsonSchema: RJSFSchema = tempJson;

const tempUi = JSON.parse(JSON.stringify(baseUiSchema)) as UiSchema;
tempUi["ui:order"] = ["general", "metaData", "credits", "captions", "settings"];
tempUi.metaData.category["ui:options"] = {
  choices: [
    { value: "", "ui:label": "mediaCategory.none" },
    { value: "movie", "ui:label": "mediaCategory.movie" },
    { value: "tv", "ui:label": "mediaCategory.tv" },
    { value: "musicVideo", "ui:label": "mediaCategory.musicVideo" },
    { value: "other", "ui:label": "mediaCategory.other" },
  ],
};
tempUi.captions = {
  "ui:title": "editMedia.closedCaptionsHeader",
  closed_captions: {
    items: {
      "ui:title": "editMedia.closedCaptions",
      "ui:widget": "textarea",
    },
    "ui:field": "LocalizedField",
  },
  "ui:layout": {
    fields: ["closed_captions"],
    template: "Section",
  },
};
tempUi.settings = {
  "ui:title": "generic.settings",
  volume: {
    "ui:title": "generic.volume",
    "ui:widget": "RangeWidget",
  },

  thumbnail_override: {
    "ui:field": "MediaSelectorField",
    "ui:title": "editMedia.thumbnail",
    "ui:options": {
      mediaTypes: ["image"],
    },
  },
  "ui:layout": {
    fields: ["volume", "thumbnail_override"],
    template: "Section",
  },
};
export const videoUiSchema: UiSchema = tempUi;
