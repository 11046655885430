import { styled } from "@mui/material";
import type { FC, ImgHTMLAttributes, SyntheticEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import placeholderImage from "@/assets/img/placeholder_image.svg";
import type { FilterProps } from "@/components/MediaSelector/ColorFiltersModal";
import type {
  MediaSelectorFieldImageDimensions,
  MediaSelectorFieldsetImageDimensions,
} from "@/framework/KioForm/fields/MediaSelectorField/MediaSelectorField";
import { isPromise } from "@/utils/async";
import { replaceQueryParam } from "@/utils/url";

export interface Dimension {
  /**
   * The value of the dimension-parameter. E.g. "600x800"
   */
  dimensionParamValue?: string;
  /**
   * The actual width of the image, E.g. 600
   */
  actualImageWidth: number;
  /**
   * Use this url instead of the replaced.
   */
  urlOverride?: string;
}

export interface ImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
  /**
   * The URL to the image
   */
  src?: string | Promise<string | undefined>;
  /**
   * The name of the query-param that is used to denote the dimension of the image to load
   */
  dimensionParamName?: string;
  /**
   * The dimensions available, if any
   */
  dimensions?: Array<Dimension>;
  /**
   * Image to show if `src` is not available.
   */
  fallbackImage?: string;
  setImageDimensions?: MediaSelectorFieldsetImageDimensions;
  isLandscape?: boolean;
  isWebPFormat?: boolean;
  filterProps?: FilterProps;
}

const dmsDimensions: Array<Dimension> = [
  { dimensionParamValue: "167x167", actualImageWidth: 167 },
  { dimensionParamValue: "250x250", actualImageWidth: 250 },
  { dimensionParamValue: "400x400", actualImageWidth: 400 },
  { dimensionParamValue: "600x600", actualImageWidth: 600 },
  { dimensionParamValue: "800x800", actualImageWidth: 800 },
  { dimensionParamValue: "1200x1200", actualImageWidth: 1200 },
];

interface ImgContainerProps {
  height?: string | number;
  width?: string | number;
}

const ImgContainer = styled("div")<ImgContainerProps>`
  display: flex;
  height: ${(props) => props.height ?? "100%"};
  width: ${(props) => props.width ?? "100%"};
  align-items: center;
  justify-content: center;
`;

export const getCSSFilter = (filterProps?: FilterProps): string => {
  if (!filterProps) return "";
  let filters = "";
  Object.entries(filterProps).forEach(([key, value]) => {
    filters = filters.concat(`${key}(${value}) `);
  });
  return filters;
};

const Img = styled("img")<Pick<ImageProps, "filterProps">>`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  filter: ${(props) => getCSSFilter(props.filterProps)};
`;

const webpQuery = "&mediaType=image/webp";

export const Image: FC<ImageProps> = ({
  src,
  dimensionParamName = "dimension",
  dimensions = dmsDimensions,
  fallbackImage,
  setImageDimensions,
  isWebPFormat,
  alt,
  filterProps,
  ...imageProps
}) => {
  const [loadError, setLoadError] = useState<boolean>(false);
  const [resolvedSrc, setResolvedSrc] = useState<string | undefined>(isPromise(src) ? undefined : src);
  const imgElRefLocal = useRef<HTMLImageElement | null>(null);
  const processedImage = useMemo(
    () => replaceQueryParam(resolvedSrc, dimensionParamName, dimensions[0]?.dimensionParamValue),
    [dimensionParamName, dimensions, resolvedSrc]
  );

  function handleImageLoad(event: SyntheticEvent<HTMLImageElement>) {
    if (!!setImageDimensions && !!imgElRefLocal?.current) {
      if (!!imgElRefLocal.current?.clientWidth && !!imgElRefLocal.current?.clientHeight) {
        const _imageDimensions: MediaSelectorFieldImageDimensions = {
          width: imgElRefLocal.current.clientWidth,
          height: imgElRefLocal.current.clientHeight,
        };
        setImageDimensions(_imageDimensions);
      }
    }
  }

  useEffect(() => {
    if (src === resolvedSrc) return;
    else if (isPromise(src)) src.then(setResolvedSrc);
    else setResolvedSrc(src);
  }, [resolvedSrc, src]);

  if (!resolvedSrc || loadError) {
    return (
      <ImgContainer {...imageProps}>
        <Img src={fallbackImage || placeholderImage} />
      </ImgContainer>
    );
  }

  const sortedDimensions = dimensions?.sort((a, b) => a.actualImageWidth - b.actualImageWidth) ?? [];
  const sizes = sortedDimensions
    .reverse()
    .map((dimension) => `(min-width:${dimension.actualImageWidth}px) ${dimension.actualImageWidth}px`)
    .join(",");

  const sourceSet = sortedDimensions
    .map(
      (dimension) =>
        `${dimension.urlOverride || replaceQueryParam(resolvedSrc, dimensionParamName, dimension.dimensionParamValue)}${
          isWebPFormat ? webpQuery : ""
        } ${dimension.actualImageWidth}w`
    )
    .join(",");

  return (
    <ImgContainer {...imageProps}>
      {resolvedSrc.startsWith("blob") ? (
        <Img
          ref={imgElRefLocal}
          src={resolvedSrc}
          onError={() => setLoadError(true)}
          alt={alt}
          onLoad={handleImageLoad}
        />
      ) : (
        <Img
          ref={imgElRefLocal}
          src={`${processedImage}${isWebPFormat ? webpQuery : ""}`}
          onError={() => setLoadError(true)}
          alt={alt}
          srcSet={sourceSet}
          sizes={sizes}
          onLoad={handleImageLoad}
          filterProps={filterProps}
        />
      )}
    </ImgContainer>
  );
};

export default Image;
