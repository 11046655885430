import type { DataListRow } from "@/components/DataList/DataListRow";
import type MediaType from "@/declarations/models/MediaType";
import type { QueryParams } from "@/declarations/QueryParams";

/**
 * QueryParameters that define the mapping between the content and the ListItem.
 * May be object-paths to refer to content within the document.
 */
type MappingQueryParams = { [mappingKey in keyof DataListRow]: string };

/**
 * All parameters that are used to configure what data is displayed.
 * These are the params that should be available when creating an Action
 */
export interface FinderConfigurationQueryParams extends QueryParams, MappingQueryParams {
  /**
   * The ID of the schema whose documents to load
   */
  schema: number | string;
  mediaType?: MediaType;
}

/**
 * Default values for all params
 */
export const defaultFinderQueryParamsValues: FinderConfigurationQueryParams = {
  key: "id",
  title: "content.title",
  subTitle: undefined,
  imageURL: "",
  schema: 0,
  updatedAt: "updated_at",
  updatedBy: "updated_by",
  chipContent: undefined,
  mediaType: undefined,
};
