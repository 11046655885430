import { useCallback, useDebugValue, useMemo } from "react";
import { useAsyncSafeState } from "./useAsyncSafeState";

export const useLoadingState = () => {
  const [loadingCount, setLoadingCount] = useAsyncSafeState<number>(0);

  const startLoading = useCallback(() => {
    setLoadingCount((prev) => prev + 1);
  }, [setLoadingCount]);

  const stopLoading = useCallback(() => {
    setLoadingCount((prev) => prev - 1);
  }, [setLoadingCount]);

  useDebugValue((loadingCount ?? 0) > 0 ? "Loading" : "Not loading");

  return useMemo(
    () => ({
      isLoading: (loadingCount ?? 0) > 0,
      startLoading,
      stopLoading,
    }),
    [loadingCount, startLoading, stopLoading]
  );
};
