import type { ResponseType } from "./ResponseType";

/**
 * This is an object that is uniquely created for each request, and is the returned value for each API-call.
 * The main purpose of this entity is to be able to abort an ongoing request, witch is an important feature in react.
 *
 */
export class RequestContext<T> {
  private readonly request: Promise<Response>;
  private readonly controller: AbortController;

  constructor(request: Promise<Response>, controller: AbortController) {
    this.request = request;
    this.controller = controller;
    this.fetch = this.fetch.bind(this);
    this.abort = this.abort.bind(this);
  }

  public async fetch(): Promise<ResponseType<T>> {
    try {
      const response: Response = await this.request;
      let content = null;
      if (!response.bodyUsed && response.status !== 204) {
        if (response.headers.get("Content-Type") === "application/json") {
          content = await response.json();
        } else if (response.headers.get("Content-Type") === "image/png") {
          content = await response.blob();
        } else if (response.headers.get("Content-Type") === "text/csv") {
          content = await response.blob();
        } else {
          content = await response.text();
        }
      }
      return response.ok ? [content, null, response] : [null, content, response];
    } catch (e: unknown) {
      if (e instanceof DOMException && e.name === "AbortError") {
        // Fetch was aborted. No error and no content
        return [null, null, e];
      } else if (e instanceof Error) {
        console.error("Request failed", e);
        return [null, e.message, e];
      }
      return [null, "No valid error caught", null];
    }
  }

  public async fetchDirect<R>(defaultValue: T | R): Promise<T | R> {
    const [request, error, source] = await this.fetch();
    if (error) {
      console.error(error, source);
      return defaultValue;
    }
    return request || defaultValue;
  }

  public abort(): void {
    this.controller.abort();
  }
}
