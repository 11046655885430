import type { FC } from "react";
import { Breadcrumbs, Button, styled } from "@mui/material";
import { Home, NavigateNext } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/Store";
import type { Breadcrumb } from "@/declarations/Breadcrumb";
import { MuiIcon } from "@/components/MuiIcon";

interface ApplicationBreadcrumbsProps {
  showHomeIconLink?: boolean;
}

const StyledBreadcrumbs = styled(Breadcrumbs)({
  maxWidth: "100%",
  width: "fit-content",
  "& .MuiBreadcrumbs-ol": {
    flexWrap: "nowrap",
    "& .MuiBreadcrumbs-li": {
      display: "flex",
      minWidth: 0,
      " & .MuiTypography-root": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
});

const StyledButtonLink = styled(Button)({
  display: "flex",
  flexWrap: "nowrap",
  fontSize: "20px",
  lineHeight: "30px",
  fontWeight: 700,
  textDecoration: "none",
  textTransform: "none",
  color: "black",
});

const StyledButtonLinkText = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * This component renders the currently available Breadcrumbs.
 *
 * Breadcrumbs are added/removed by the "BreadcrumbNode"-component.
 * See it's description for more information
 *
 * @constructor
 * @see BreadcrumbNode
 */
export const ApplicationBreadcrumbs: FC<ApplicationBreadcrumbsProps> = ({ showHomeIconLink = false }) => {
  const { state } = useStore();
  const breadcrumbs: Array<Breadcrumb> = state.breadcrumbs || [];
  const breadcrumbList = [...breadcrumbs].slice(0, -1);
  const lastBreadcrumb = [...breadcrumbs].pop();
  const history = useNavigate();

  return (
    <StyledBreadcrumbs aria-label="breadcrumb" separator={<NavigateNext color="primary" />}>
      {showHomeIconLink && (
        <StyledButtonLink variant="text" color="secondary" href="/">
          <MuiIcon>
            <Home />
          </MuiIcon>
        </StyledButtonLink>
      )}
      {breadcrumbList.map((breadcrumb) => (
        <StyledButtonLink
          key={breadcrumb.path}
          variant="text"
          color="primary"
          onClick={() => {
            history(breadcrumb.path);
          }}
        >
          <StyledButtonLinkText>{breadcrumb.label}</StyledButtonLinkText>
        </StyledButtonLink>
      ))}
      {!!lastBreadcrumb && (
        <StyledButtonLink
          variant="text"
          color="primary"
          onClick={() => {
            history(lastBreadcrumb.path);
          }}
        >
          <StyledButtonLinkText>{lastBreadcrumb.label}</StyledButtonLinkText>
        </StyledButtonLink>
      )}
    </StyledBreadcrumbs>
  );
};

export default ApplicationBreadcrumbs;
