import {
  Code,
  DynamicFeed,
  ImportantDevices,
  Language,
  Lock,
  Museum,
  People,
  Translate,
  VpnKey,
} from "@mui/icons-material";
import type { FC } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import SideMenu from "@/components/SideMenu/SideMenu";
import type { SideMenuDef, SideMenuItemDef } from "@/components/SideMenu/SideMenu";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";

const getMenus = (t: TFunction<"common">): Array<SideMenuDef> => [
  {
    label: t("generic.content"),
    items: [
      [AdminResourcePath.OWNER, <Museum key="museum" />],
      [AdminResourcePath.APPLICATION, <ImportantDevices key="important-devices" />],
      [AdminResourcePath.INSTANCE, <DynamicFeed key="dynamic-feed" />],
      [AdminResourcePath.SCHEMA, <Code key="code" />],
      [AdminResourcePath.LICENSE, <Lock key="lock" />],
      [AdminResourcePath.API_KEY, <VpnKey key="vpn-key" />],
      [AdminResourcePath.USER, <People key="people" />],
      [AdminResourcePath.TRANSLATION, <Translate key="translate" />],
      [AdminResourcePath.LOCALE, <Language key="language" />],
    ].map(
      ([path, icon]) =>
        ({
          path: `/admin/${path.toString()}`,
          icon,
          label: t(`schemaTypes.${path.toString()}_plural`),
        } as SideMenuItemDef)
    ),
  },
];

export const AdminMenu: FC = () => {
  const { t } = useTranslation("common");
  return <SideMenu menus={getMenus(t)} />;
};

export default AdminMenu;
