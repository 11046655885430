import type { FC } from "react";
import { DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import AddYouTubeMedia from "@/components/MediaSelector/forms/AddYouTubeMedia";
import AddImageMedia from "@/components/MediaSelector/forms/AddImageMedia";
import AddVideoMedia from "@/components/MediaSelector/forms/AddVideoMedia";
import AddAudioMedia from "@/components/MediaSelector/forms/AddAudioMedia";
import AddVimeoMedia from "@/components/MediaSelector/forms/AddVimeoMedia";
import AddSketchfabMedia from "@/components/MediaSelector/forms/AddSketchfabMedia";
import AddDocumentMedia from "@/components/MediaSelector/forms/AddDocumentMedia";
import AddDMMedia from "@/components/MediaSelector/forms/AddDMMedia/AddDMMedia";
import AddDMSContent from "@/components/MediaSelector/forms/AddDMSContent";
import { StyledDialog } from "@/components/MediaSelector/FocalPointModal";
import AddModel3DMedia from "@/components/MediaSelector/forms/AddModel3DMedia";

export interface AddMediaContentProps {
  value?: Media | null;
  onCancel: () => void;
  onMediaSaved: () => void;
  ownerId?: number;
  instanceId?: number;
  mediaTypes: MediaType[];
}

export interface AddMediaModalProps extends AddMediaContentProps {
  open: boolean;
}

export const AddMediaModal: FC<AddMediaModalProps> = ({
  value = null,
  mediaTypes,
  open,
  onCancel,
  onMediaSaved,
  ownerId,
  instanceId,
}) => {
  const { t } = useTranslation("common");
  const contentComponentProps = { value, onCancel, onMediaSaved, ownerId, instanceId } as AddMediaContentProps;
  return (
    <StyledDialog
      open={open}
      aria-labelledby="add-content-modal-title"
      onClose={onCancel}
      fullWidth
      fullScreen={!value && mediaTypes[0] === MediaType.DM}
      sx={{
        ".MuiDialog-paper": {
          height: "100%!important",
          minWidth: "150px!important",
          width: "100%",
          p: 2,
        },
      }}
    >
      <DialogTitle id="add-content-modal-title">
        <Typography component={"h1"}>
          {mediaTypes.length !== 1
            ? t("components.MediaSelector.createContent.media")
            : t(`components.MediaSelector.createContent.${mediaTypes[0]}`)}
        </Typography>
        <IconButton onClick={onCancel} className={"noPadding"}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {mediaTypes.length !== 1 ? (
          <AddDMSContent
            onMediaSaved={onMediaSaved}
            onCancel={onCancel}
            ownerId={ownerId}
            instanceId={instanceId}
            allowedMediaTypes={mediaTypes}
          />
        ) : (
          (mediaTypes[0] === MediaType.IMAGE && <AddImageMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.VIDEO && <AddVideoMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.AUDIO && <AddAudioMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.DOCUMENT && <AddDocumentMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.YOUTUBE && <AddYouTubeMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.VIMEO && <AddVimeoMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.SKETCHFAB && <AddSketchfabMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.DM && <AddDMMedia {...contentComponentProps} />) ||
          (mediaTypes[0] === MediaType.MODEL_3D && <AddModel3DMedia {...contentComponentProps} />)
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default AddMediaModal;
