export const uiSchema = {
  "ui:title": "schemaTypes.owner",
  name: {
    "ui:field": "LocalizedField",
    items: {
      "ui:title": "generic.name",
    },
    "ui:colspan": 10,
  },
  id: {
    "ui:title": "generic.id",
    "ui:colspan": 2,
  },
  description: {
    "ui:field": "LocalizedField",
    items: {
      "ui:title": "generic.description",
      "ui:widget": "TextareaWidget",
    },
  },
  slug: {
    "ui:title": "generic.slug",
    "ui:colspan": 6,
  },
  identifier: {
    "ui:title": "generic.identifier",
    "ui:colspan": 6,
  },
  google_analytics_code: {
    "ui:title": "generic.google_analytics_code",
  },
  owner_id: {
    "ui:title": "generic.owner",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Owner",
      valueField: "id",
      displayField: "name",
    },
  },
  availability_url: {
    "ui:title": "generic.availability_url",
  },
  logo: {
    "ui:colspan": 6,
    "ui:title": "generic.logo",
    "ui:description": "generic.logo",
    "ui:field": "MediaSelectorField",
    "ui:options": {
      mediaTypes: ["image"],
      showFocalPoint: true,
      aspectRatio: "2:1",
    },
  },
  main_image: {
    "ui:colspan": 6,
    "ui:title": "generic.main_image",
    "ui:description": "generic.image",
    "ui:field": "MediaSelectorField",
    "ui:options": {
      mediaTypes: ["image"],
      showFocalPoint: true,
      aspectRatio: "2:1",
    },
  },
  image_url: {
    "ui:title": "generic.image_url",
  },
  location: {
    "ui:title": "generic.location",
    "ui:field": "LocationPickerField",
  },
};
