import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

export interface LoaderProps {
  /**
   * Optional text to be displayed beneath the loader
   */
  loadingText?: string;
  darkBackground?: boolean;
  size?: number;
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

export const Loader: FC<LoaderProps> = ({ loadingText, darkBackground = false, size = 56 }) => {
  const { t } = useTranslation("common");
  return (
    <Container>
      <CircularProgress variant="indeterminate" color="primary" size={size} />
      {loadingText && (
        <Typography fontSize={"18px"} color={darkBackground ? "white" : "black"}>
          {t(loadingText)}
        </Typography>
      )}
    </Container>
  );
};

export default Loader;
