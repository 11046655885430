export const WidgetNames = [
  "JsonEditorWidget",
  "EntityAutocompleteWidget",
  "ActionQueryEditorWidget",
  "TextAreaWysiwygWidget",
  "SwitchWidget",
  "SelectWidget",
  "TextWidget",
  "TextareaWidget",
  "DateTimeWidget",
  "MuiIconWidget",
  "HelperTextWidget",
  "RangeWidget",
  "ColorWidget",
  "GridSelectWidget",
] as const;

export type WidgetNamesList = (typeof WidgetNames)[number];

export const WhiteListedWidgets: string[] = [
  "alt-date-time",
  "text",
  "radio",
  "select",
  "textarea",
  "password",
  "color",
  "up-down",
  "range",
  "url",
  "hidden",
  "file",
  "email",
  "date",
  "date-time",
  "checkbox",
  "checkboxes",
  "alt-date",
];
