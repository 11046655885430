import type { FC } from "react";
import type { SelectProps } from "@mui/material";
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, styled } from "@mui/material";

export interface SelectOption {
  label: string;
  value: any;
}

export interface KioSelectFieldProps extends Omit<SelectProps, "onChange" | "value"> {
  onChange: (value: any) => void;
  value?: any[] | any;
  options?: SelectOption[];
  isHeaderVariant?: boolean;
  helperText?: string;
}

const ChipList = styled("div")(() => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  gap: "8px",
}));

export const StyledMenuItem = styled(MenuItem, { shouldForwardProp: (prop) => prop !== "selected" })(
  ({ selected, theme }) => `
  padding-left: ${selected ? "12px" : "16px"};
  border-left: ${selected ? `${theme.palette.primary.main} solid 4px` : ""};
`
);

export const KioSelect: FC<KioSelectFieldProps> = ({
  options,
  required,
  label,
  value,
  onChange,
  isHeaderVariant = false,
  renderValue,
  displayEmpty,
  helperText,
  sx,
  ...rest
}) => {
  const onChangeHandler: SelectProps["onChange"] = (event) => {
    onChange?.(event?.target?.value);
  };
  const defaultRenderValue = (selected: any) => {
    const selectedLabel = (value: any) => options?.find((o) => o.value === value)?.label;
    if (Array.isArray(selected)) {
      return (
        <ChipList>
          {selected.map((value: any) => (
            <Chip key={value} label={selectedLabel(value) || value} size={"small"} aria-label={value} />
          ))}
        </ChipList>
      );
    }
    return <Box>{selectedLabel(value) || selected.toString()}</Box>;
  };

  return (
    <FormControl
      variant={isHeaderVariant ? "outlined" : "filled"}
      size={isHeaderVariant ? "small" : "medium"}
      required={required}
      fullWidth
      sx={sx}
    >
      {label && (
        <InputLabel id={"label-id"} shrink={displayEmpty}>
          {label}
        </InputLabel>
      )}
      <Select
        onChange={onChangeHandler}
        labelId={"label-id"}
        label={label}
        value={value ?? ""}
        renderValue={renderValue || defaultRenderValue}
        displayEmpty={displayEmpty}
        {...rest}
      >
        {(options ?? []).map((option, index) => (
          <StyledMenuItem key={`${index}-${option.label || option.value}`} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
