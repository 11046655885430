import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import EkulturLoginHandler from "./framework/EkulturLoginHandler/EkulturLoginHandler";
import RootView from "./views/RootView";
import Messenger from "./framework/Messenger/Messenger";
import Settings from "./Settings";
import ErrorView from "@/views/ErrorView";
import "external-svg-loader";

const router = createBrowserRouter([
  {
    path: "*",
    Component: Root,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  Settings.validateSettings();

  return (
    <Messenger>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <EkulturLoginHandler>
          <RootView />
        </EkulturLoginHandler>
      </ErrorBoundary>
    </Messenger>
  );
}
