import type { DragEvent, MouseEvent } from "react";

export function calculateCoordinates(e: DragEvent<HTMLElement> | MouseEvent<HTMLElement>, decimals = 0) {
  const rect = e.currentTarget.getBoundingClientRect();
  const { left, top } = rect;

  const x = e.pageX - left;
  const y = e.pageY - top;

  const cw = e.currentTarget.clientWidth;
  const ch = e.currentTarget.clientHeight;

  const px = Number(((x / cw) * 100).toFixed(decimals));
  const py = Number(((y / ch) * 100).toFixed(decimals));

  return {
    x: px,
    y: py,
  };
}
