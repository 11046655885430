import { styled } from "@mui/material";
import Styles from "@/assets/styles/Styles";

export const PreviewContainer = styled("div")(({ theme }) => ({
  backgroundColor: Styles.Colors.PREVIEW_BACKGROUND_COLOR,
  color: theme.palette.getContrastText(Styles.Colors.PREVIEW_BACKGROUND_COLOR),
  position: "relative",
  width: "100%",
  minHeight: Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_HEIGHT,
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",

  "&.small": {
    minHeight: "124px",
  },
}));
