import "@/components/Lexical/index.css";

import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { $convertFromMarkdownString, $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { styled } from "@mui/material";
import type { WidgetProps } from "@rjsf/utils";
import { $getRoot, $insertNodes } from "lexical";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { getValueFromUiSchema } from "@/utils/schema";
import { useDebounce } from "@/hooks/useDebounce";
import ContentEditable from "@/components/Lexical/ui/ContentEditable";
import PlaygroundEditorTheme from "@/components/Lexical/themes/EditorTheme";
import ToolbarPlugin from "@/components/Lexical/plugins/ToolbarPlugin";
import FloatingLinkEditorPlugin from "@/components/Lexical/plugins/FloatingLinkEditorPlugin";
import ClickableLinkPlugin from "@/components/Lexical/plugins/ClickableLinkPlugin";
import KioNodes from "@/components/Lexical/nodes/KioNodes";

/**
 * Lexical seems to crash if there is a block-element which has a non-block element as sibling.
 * Moves all siblings which are before or after a block-element to a selected type of parent element.
 * Those elements are grouped together between block-elements.
 * @param dom
 * @constructor
 */
function HTMLSanitation(dom: Document) {
  const BLOCKSELECTORS =
    "address, article, aside, blockquote, canvas, dd, div, dl, dt, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hr, li, main, nav, noscript, ol, p, pre, section, table, tfoot, ul, video";
  const blockselectors_list = BLOCKSELECTORS.replaceAll(" ", "").split(",");
  const BLOCK_TAG = "p";
  dom.querySelectorAll("div").forEach((domElem) => {
    if (domElem) {
      const newTag = dom.createElement("p");
      newTag.innerHTML = domElem.innerHTML;
      const parent = domElem.parentNode;

      parent?.replaceChild(newTag, domElem);
    }
  });
  dom.querySelectorAll(BLOCKSELECTORS).forEach((domElem) => {
    if (domElem) {
      const parentElem = domElem?.parentNode;
      let newParent: HTMLElement | null = null;
      if (parentElem) {
        for (const childIdxAsString in parentElem.children) {
          const childIdx = Number(childIdxAsString);
          const child = parentElem?.children[childIdx];

          if (
            (!!child && child?.tagName && !blockselectors_list.includes(child.tagName.toLowerCase())) ||
            (!!child && child?.tagName === undefined)
          ) {
            if (!newParent) {
              newParent = dom.createElement(BLOCK_TAG);
              newParent.appendChild(child);
            }
            // in-line elements before block-element
          } else if (newParent) {
            parentElem.insertBefore(newParent, child);
            newParent = null;
          }
        }
        // if the in-line elements was after block-element
        if (newParent) {
          parentElem.appendChild(newParent);
          newParent = null;
        }
      }
    }
  });
}

const EditorWrapper = styled("div")`
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #cdcdcd;
`;

const InitialHTMLPlugin = ({ value, selectedLocale }: any) => {
  const [editor] = useLexicalComposerContext();
  const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  const parser = new DOMParser();
  const dom = parser.parseFromString(value, "text/html");

  HTMLSanitation(dom);

  useEffect(() => {
    if (!value || !editor) return;
    editor.update(() => {
      if (isHTML(value)) {
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();

        $insertNodes(nodes);
      } else {
        $convertFromMarkdownString(value, TRANSFORMERS);
      }
    });
  }, []);

  useEffect(() => {
    if (!value || !editor) return;
    editor.update(() => {
      if (isHTML(value)) {
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();

        root.splice(0, root.__size, nodes);
      } else {
        // FIXME: har ikke testet med noe annet enn html-values, vet ikke om dette blir rett
        $convertFromMarkdownString(value, TRANSFORMERS);
      }
    });
  }, [selectedLocale]);

  return null;
};

const TextAreaWysiwygWidget: FC<WidgetProps> = ({ onChange, value, label, uiSchema, formContext }) => {
  const format = getValueFromUiSchema("format", uiSchema);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | undefined>();

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const initialConfig = {
    namespace: "Kio",
    nodes: [...KioNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  const debounceChange = useDebounce(500, (htmlString) => onChange(htmlString));

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <InitialHTMLPlugin value={value} selectedLocale={formContext.selectedLocale} />
      <ToolbarPlugin />
      <RichTextPlugin
        contentEditable={
          <EditorWrapper className="editor" ref={onRef}>
            <ContentEditable />
          </EditorWrapper>
        }
        placeholder={<div></div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin
        onChange={(editorState, editor) => {
          editorState.read(() => {
            if (format === "markdown") {
              const markdownString = $convertToMarkdownString(TRANSFORMERS);
              debounceChange(markdownString);
            } else {
              const htmlString = $generateHtmlFromNodes(editor, null);
              debounceChange(htmlString);
            }
          });
        }}
      />
      <HistoryPlugin />
      <ListPlugin />
      <LinkPlugin />
      <ClickableLinkPlugin />
      <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
    </LexicalComposer>
  );
};

export default TextAreaWysiwygWidget;
