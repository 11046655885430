import { Button, Input, Popover, Slider } from "@mui/material";
import type { FC, RefObject } from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { useDebounce } from "@/hooks/useDebounce";

const Container = styled("div")`
  height: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 16px;
`;
const SliderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 2em;
  align-items: center;
`;

export interface ScalingPopoverProps {
  open: boolean;
  value?: number;
  handleClose: any;
  onChange: (val?: number) => void;
  anchorRef?: RefObject<HTMLButtonElement>;
}

export const ScalingPopover: FC<ScalingPopoverProps> = ({ open, value, handleClose, onChange, anchorRef }) => {
  const { t } = useTranslation("common");
  const [scale, setScale] = useState<number>(value ?? 1);

  const debouncedOnChange = useDebounce(400, (value?: number) => {
    onChange(value);
  });
  const _onChange = (value: number) => {
    setScale(value);
    debouncedOnChange(value);
  };

  return (
    <Popover anchorEl={anchorRef?.current} open={open} onClose={handleClose}>
      <Container>
        <SliderContainer>
          <KioTitle title={t("kioForm.widgets.MediaSelectorField.scaleImage")} />
          <Slider
            orientation={"vertical"}
            value={scale}
            onChange={(_, val) => _onChange(val as number)}
            min={0.1}
            max={5}
            step={0.01}
            valueLabelDisplay="off"
            sx={{
              maxHeight: 300,
              height: "40vh",
            }}
          />
          <Input
            value={scale}
            onChange={(e) => _onChange(Number(e.target.value))}
            inputProps={{
              step: 0.01,
              min: 0.1,
              max: 5,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
          <Button onClick={() => _onChange(1)} variant={"outlined"}>
            {t("generic.reset")}
          </Button>
        </SliderContainer>
      </Container>
    </Popover>
  );
};
