import type { ThemeOptions } from "@mui/material";
import Styles from "./assets/styles/Styles";

function createCustomTheme(primaryColor: string, secondaryColor: string): ThemeOptions {
  return {
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    typography: {
      fontFamily: '"Akkurat Pro", sans-serif',
    },
    shape: {
      borderRadius: 2,
    },
  };
}

abstract class Settings {
  public static readonly DEFAULT_LOCALE: string = "no";
  public static readonly SUPPORTED_APP_LOCALES: string[] = ["no", "sv", "en"];
  public static readonly SUPPORTED_CONTENT_LOCALES: string[] = [
    "no",
    "nn",
    "sv",
    "en",
    "de",
    "es",
    "fr",
    "sma",
    "smj",
    "sme",
  ];
  public static readonly FALLS_BACK_TO_NO: string[] = ["no", "nb", "nn", "se", "sma", "smj", "sme"];
  public static readonly API_BASE_HOST: string = import.meta.env.VITE_API_BASE_HOST || "";
  public static readonly EKULTUR_HOST: string = import.meta.env.VITE_EKULTUR_HOST || "";
  public static readonly EKULTUR_GATEWAY: string = import.meta.env.VITE_EKULTUR_GATEWAY || "";
  public static readonly EKULTUR_SAMESITE: boolean =
    (import.meta.env.VITE_EKULTUR_SAMESITE ?? "true").toLowerCase() === "true";
  public static readonly DM_SOLR: string = import.meta.env.VITE_DM_SOLR || "https://api.dimu.org/api/solr/select";
  public static readonly GOOGLE_MAPS_API_KEY: string = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";
  public static readonly LOCATION_PICKER_DEFAULT_COORDS = { lat: 59.9138688, lng: 10.7522454 };
  public static readonly EKULTUR_CALLBACK_URL: string = import.meta.env.VITE_EKULTUR_CALLBACK_URL || "/oauth2/callback";
  public static readonly DEBUG_I18NEXT: boolean = import.meta.env.VITE_DEBUG_I18NEXT === "true";
  public static readonly NEWSFEED_API_KEY: string = import.meta.env.VITE_KULTURIO_NEWSFEED_API_KEY || "";
  public static readonly KULTURIO_NEWSFEED_API_KEY: string = import.meta.env.VITE_KULTURIO_NEWSFEED_API_KEY || "";
  public static readonly THEME_OPTIONS: ThemeOptions = createCustomTheme(
    Styles.Colors.THEME_PRIMARY,
    Styles.Colors.THEME_SECONDARY
  );

  public static readonly SHARED_ELEMENT_SYMBOL = "🔗";

  public static readonly ADMIN_THEME_OPTIONS: ThemeOptions = createCustomTheme(
    Styles.Colors.THEME_ADMIN_PRIMARY,
    Styles.Colors.THEME_ADMIN_SECONDARY
  );

  public static validateSettings(): void {
    const errors: Array<string> = [];

    const requiredEnv = (value: any, name: string) => {
      if (!value) {
        errors.push(`[REQUIRED ENV] Required environment variable 'VITE_${name}' was not set`);
      }
    };

    requiredEnv(Settings.EKULTUR_HOST, "EKULTUR_HOST");
    requiredEnv(Settings.EKULTUR_GATEWAY, "EKULTUR_GATEWAY");
    requiredEnv(Settings.EKULTUR_CALLBACK_URL, "EKULTUR_CALLBACK_URL");

    if (errors.length > 0) {
      console.error("Invalid settings: ", errors);
      throw Error(`Invalid settings: \n-${errors.join("\n-")}`);
    }
  }
}

export default Settings;
