import { useTranslation } from "react-i18next";
import type { FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { List, ListItem, Typography } from "@mui/material";
import baseStyled from "@emotion/styled";
import i18n from "i18next";
import type Document from "@/declarations/models/Document";
import DocumentStatus from "@/declarations/models/DocumentStatus";
import Api from "@/services/Api";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import Loader from "@/framework/Loader";

interface NewsFeedProps {}

const StyledList = baseStyled(List)`
    
`;

const ListItemInnerContainer = baseStyled.div`
    display: grid;
    width: 100%;    
    outline: 1px solid #CCC;    
`;

const PAGESIZE = 10;

const getDocuments =
  async (status?: DocumentStatus, path_value?: string, sort = "$created_at", order_asc = true) =>
  async (page: number, page_size: number) =>
    Api.getAllPortalDocuments({
      page,
      page_size,
      status,
      path_value,
      sort,
      order_asc,
    }).fetchDirect({ page, page_size, count: 0, items: [], total_count: 0 });

const NewsFeed: FC<NewsFeedProps> = () => {
  const { t } = useTranslation("common");
  const getLocalizedString = resolvePotentiallyLocalizedString(i18n.language);
  const [newsFeed, setNewsFeed] = useState<Document[]>([]);
  const [expandedStateList, setExpandedStateList] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const loaderRef = useRef(null);
  const [noMoreDocuments, setNoMoreDocuments] = useState(false);

  const fetchData = useCallback(async () => {
    if (noMoreDocuments || loading) return;
    const unPinnedDocumentsRequest = getDocuments(DocumentStatus.PUBLISHED, "settings.pinned:false");
    setLoading(true);
    unPinnedDocumentsRequest.then((req) => {
      req(currentPage, PAGESIZE).then((docs) => {
        if (docs?.total_count <= PAGESIZE * (docs?.page + 1)) {
          setNoMoreDocuments(true);
        }
        setNewsFeed((prev) => [...prev, ...docs.items]);
        setExpandedStateList((prev) => [...prev, ...docs.items.map(() => false)]);
        setLoading(false);
        setCurrentPage((prev) => prev + 1);
      });
    });
  }, [currentPage, loading]);

  useEffect(() => {
    const pinnedDocumentsRequest = getDocuments(DocumentStatus.PUBLISHED, "settings.pinned:true");
    const unPinnedDocumentsRequest = getDocuments(DocumentStatus.PUBLISHED, "settings.pinned:false");
    setLoading(true);
    Promise.all([pinnedDocumentsRequest, unPinnedDocumentsRequest]).then((values) => {
      Promise.all(values.map((val) => val(0, PAGESIZE))).then((docs) => {
        if (docs[1]?.total_count <= PAGESIZE * (docs[1]?.page + 1)) {
          setNoMoreDocuments(true);
        }
        setNewsFeed([...docs[0].items, ...docs[1].items]);
        setExpandedStateList([...docs[0].items.map(() => true), ...docs[1].items.map(() => false)]);
        setLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    if (noMoreDocuments) return;

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchData();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [fetchData]);

  return (
    <StyledList>
      {newsFeed &&
        newsFeed.map((doc, idx) => {
          const dateRaw = doc?.content?.general?.date;
          const date = new Date(dateRaw);
          const isPinned = !!doc?.content?.settings?.pinned;
          return (
            <ListItem key={doc?.id} sx={{ paddingBottom: "0px" }}>
              <ListItemInnerContainer>
                <CollapseBar
                  sx={{
                    height: "55px",
                    "&.is-active": {
                      height: "auto",
                    },
                  }}
                  title={`${getLocalizedString(doc?.title)}${isPinned ? " 📌" : ""}`}
                  overrideKioTitleLevel={2}
                  expanded={expandedStateList[idx]}
                  setExpanded={(expanded) =>
                    setExpandedStateList([
                      ...expandedStateList.slice(0, idx),
                      expanded,
                      ...expandedStateList.slice(idx + 1),
                    ])
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: getLocalizedString(doc?.content?.general?.body) }} />
                  {!!dateRaw && (
                    <Typography>
                      {t("views.dashboard.published")} {date.toLocaleDateString()}
                    </Typography>
                  )}
                </CollapseBar>
              </ListItemInnerContainer>
            </ListItem>
          );
        })}
      <div ref={loaderRef}>{loading && <Loader />}</div>
    </StyledList>
  );
};

export default NewsFeed;
