import type { FC } from "react";
import { useMemo } from "react";
import type { ObjectFieldTemplateProps } from "@rjsf/utils";
import styled from "@emotion/styled";
import SectionObjectTemplate from "./SectionObjectTemplate";
import DefaultObjectFieldTemplate from "./DefaultObjectFieldTemplate";
import ModuleObjectTemplate from "./ModuleObjectTemplate";
import TabsObjectTemplate from "./TabsObjectTemplate";
import CategoryObjectTemplate from "./CategoryObjectTemplate";
import type { TemplateLayout } from "@/declarations/TemplateLayout";
import { ObjectFieldTemplateLayout } from "@/declarations/TemplateLayout";
import { getSchemaTitle, getValueFromUiSchema } from "@/utils/schema";
import type { KioFormContext } from "@/framework/KioForm/KioForm";
import { isObject } from "@/utils/obj";

type UiGlobalProperties = Record<string, string>;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

/** If filter keys are present, it will only keep these in properties, else return original property array **/
const filterPropertiesByFields = <T extends { [K: string]: unknown }, K extends keyof T>(
  items: T[],
  itemAttr: K,
  keys: string[],
  propertiesMapper?: KioFormContext["globalProperties"]
): T[] => {
  if (keys.length === 0) return items;
  const arr = [];
  for (const key of keys) {
    if (key.includes("#")) {
      const property = propertiesMapper?.[key.split("#")[1]];
      property && arr.push(property);
    } else {
      const prop = items.find((prop) => prop?.[itemAttr] === key);
      prop && arr.push(prop);
    }
  }
  return arr;
};

/** Removes property from property if an extractable is marked as hidden **/
const removeProperty = (properties: ObjectFieldTemplateProps["properties"], property: any) => {
  const idx = properties.indexOf(property);
  if (idx > -1) properties.splice(idx, 1);
};

/** This method extracts potential global properties if defined in
 * extractableMapper. And removed the property if defined as hidden **/
const processProperties = (
  properties: ObjectFieldTemplateProps["properties"],
  extractableMapper: UiGlobalProperties,
  formContext: KioFormContext
) => {
  if (!isObject(extractableMapper)) return;
  for (const prop of [...properties]) {
    for (const [key, value] of Object.entries(extractableMapper)) {
      if (key !== prop.name) continue;
      const alias = value || "unmapped";
      if (!formContext.globalProperties?.[alias]) {
        formContext.globalProperties[alias] = prop;
      }
      removeProperty(properties, prop);
    }
  }
};

const ObjectFieldTemplate: FC<ObjectFieldTemplateProps> = (props) => {
  const { properties, uiSchema, formContext } = props;

  const layout = getValueFromUiSchema<TemplateLayout>("layout", uiSchema);
  const template = layout?.template || null;

  const _properties: ObjectFieldTemplateProps["properties"] = useMemo(() => {
    const fields = layout?.fields ?? [];
    const globalFields: UiGlobalProperties = uiSchema?.["ui:moveToGlobalProperties"];
    processProperties(properties, globalFields, formContext);
    return filterPropertiesByFields(properties, "name", fields, formContext.globalProperties);
  }, [properties, layout, formContext, uiSchema]);

  const title = getSchemaTitle(props.formContext, props.schema, props.uiSchema, props.formData) || props.title;

  const renderTemplate = () => {
    if (template) {
      if (Object.values(ObjectFieldTemplateLayout).includes(template as ObjectFieldTemplateLayout)) {
        switch (template) {
          case ObjectFieldTemplateLayout.SECTION:
            return <SectionObjectTemplate {...props} title={title} properties={_properties} />;
          case ObjectFieldTemplateLayout.MODULE:
            return <ModuleObjectTemplate {...props} title={title} properties={_properties} />;
          case ObjectFieldTemplateLayout.CATEGORY:
            return <CategoryObjectTemplate {...props} title={title} properties={_properties} />;
          case ObjectFieldTemplateLayout.TABS:
            return <TabsObjectTemplate {...props} title={title} properties={_properties} />;
        }
      } else {
        console.warn(
          `(${title}) Trying to access a uiSchema template (ObjectFieldTemplate) that doesnt exist: ${template}`
        );
      }
    }
    return <DefaultObjectFieldTemplate {...props} title={title} properties={_properties} />;
  };

  return <Container>{renderTemplate()}</Container>;
};

export default ObjectFieldTemplate;
