import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CreateOrEditRoutes from "./CreateOrEditRoutes";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import type { CreateOrEditProps } from "@/components/CreateOrEdit";
import type { DataListProps } from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import type { RequestContext } from "@/declarations/RequestContext";
import { schema } from "@/declarations/schemas/instance/schema";
import { uiSchema } from "@/declarations/schemas/instance/uiSchema";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import LicenseUserList from "@/views/admin/LicenseUserList";
import InstanceContentSharingList from "@/views/admin/InstanceContentSharingList";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

const mapperFn = (instanceItem: ApplicationInstance): DataListRow => ({
  key: String(instanceItem.id),
  title: instanceItem.name!,
  subTitle: instanceItem.owner_name,
  chipContent: `${instanceItem.slug}`,
  infoText: `ID: ${instanceItem.id}`,
  updatedAt: instanceItem.updated_at,
  updatedBy: instanceItem.updated_by,
});

const getInstanceFormData: CreateOrEditProps<ApplicationInstance>["getFormData"] = (
  id: number
): RequestContext<ApplicationInstance> => Api.getOneInstance(id);

const updateInstanceFormData: CreateOrEditProps<ApplicationInstance>["onSubmit"] = (
  instance: ApplicationInstance
): RequestContext<ApplicationInstance> => Api.updateInstance(instance);

const createInstanceFormData: CreateOrEditProps<ApplicationInstance>["onSubmit"] = (
  instance: ApplicationInstance
): RequestContext<ApplicationInstance> => Api.createInstance(instance);

const deleteInstance = async (instance: ApplicationInstance) => {
  const error = (await Api.deleteInstance(instance?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to delete instance");
  }
};

const undeleteInstance = async (item: ApplicationInstance) => {
  await Api.undeleteInstance(item.id!).fetch();
};

export const InstanceView = () => {
  const { t } = useTranslation("common");

  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    defaultSortOption: 2,
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const { pathname } = useLocation();
  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const getInstances: DataListProps<ApplicationInstance>["getItems"] = (page, page_size) => {
    const defaultResponse = { page, page_size, count: 0, items: [], total_count: 0 };

    return Api.getAllInstances({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
    }).fetchDirect(defaultResponse);
  };

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.INSTANCE}`} />
      <CreateOrEditRoutes
        key={pathname}
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit
        createFormData={createInstanceFormData}
        updateFormData={updateInstanceFormData}
        getFormData={getInstanceFormData}
        afterContent={
          <>
            <LicenseUserList />
            <InstanceContentSharingList />
          </>
        }
        listProps={{
          getItems: getInstances,
          mapperFn,
          onDeleteItem: deleteInstance,
          onDeleteItemForever: deleteInstance,
          onUndeleteItem: undeleteInstance,
          listTitle: t("schemaTypes.instance_plural"),
          hideImage: true,
          defaultViewType: ViewType.LIST,
          disableViewTypeSelection: true,
          handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          initialSortOption,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default InstanceView;
