import { Alert, LinearProgress, Snackbar, styled } from "@mui/material";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { limit } from "@/utils/numbers";
import type { Severity } from "@/framework/Messenger/Messenger";

export interface ToastProps {
  message?: string;
  severity?: Severity;
  autoHideDuration: number;
  onClose: () => void;
}

const Container = styled("span")(({ theme }) => ({
  boxShadow: theme.shadows[5],
}));

/**
 * Number of ms between the progressbar updates
 */
const UPDATED_INTERVAL_MS = 200;
/**
 * Factor by which to adjust the reduction of each update.
 * Used to adjust the current percentage, so that the progress in the toaster is visually correct.
 */
const PROGRESS_OFFSET_FACTOR = 1.04;

export const Toast: FC<ToastProps> = ({ message, severity, autoHideDuration, onClose }) => {
  const { t } = useTranslation("common");
  const intervalId = useRef<NodeJS.Timer | null>(null);
  const [timeRemainingPercent, setTimeRemainingPercent] = useState<number>(100);

  useEffect(() => {
    if (message) {
      const numberOfUpdates = autoHideDuration / UPDATED_INTERVAL_MS;
      const percentPrStep = (100 / numberOfUpdates) * PROGRESS_OFFSET_FACTOR;

      intervalId.current = setInterval(() => {
        setTimeRemainingPercent((prev) => limit(prev - percentPrStep, 0, 100) || 0);
      }, UPDATED_INTERVAL_MS);
    }
    return () => {
      !!intervalId.current && clearInterval(intervalId.current);
    };
  }, [message, autoHideDuration]);

  return (
    <Snackbar
      open={!!message && !!severity}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      ClickAwayListenerProps={{ onClickAway: () => {} }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Container>
        <Alert variant="filled" severity={severity} onClose={onClose}>
          {t(message || "", { nsSeparator: false })}
        </Alert>
        <LinearProgress value={timeRemainingPercent} variant="determinate" color="secondary" />
      </Container>
    </Snackbar>
  );
};

export default Toast;
