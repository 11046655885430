import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import DataListSelect from "@/components/DataList/common/DataListSelect";

export interface HeaderPaginatorProps {
  page: number;
  pageSize: number;
  availablePageSizes: Array<number>;
  totalPageCount: number;
  onChange: (page: number, pageSize: number) => void;
}

export const HeaderPaginator: FC<HeaderPaginatorProps> = ({
  page,
  pageSize,
  availablePageSizes,
  totalPageCount,
  onChange,
}) => {
  const { t } = useTranslation("common");

  const handlePageSizeChanged = (newSize: number | string) => {
    onChange(page, Number(newSize));
  };

  const previousPageDisabled = page < 1;
  const nextPageDisabled = page >= totalPageCount - 1;

  const handlePreviousPageClicked = () => {
    if (!previousPageDisabled) {
      onChange(page - 1, pageSize);
    }
  };
  const handleNextPageClicked = () => {
    if (!nextPageDisabled) {
      onChange(page + 1, pageSize);
    }
  };

  return (
    <div>
      <DataListSelect
        items={availablePageSizes.map((option) => ({
          label: `${option} ${t("components.list.paginator.perPage")}`,
          value: option,
        }))}
        value={pageSize}
        onChange={handlePageSizeChanged}
      />
      <Tooltip title={t("headerButtons.previousPage") || "Previous"}>
        <span>
          <IconButton onClick={handlePreviousPageClicked} disabled={previousPageDisabled}>
            <ChevronLeft />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("headerButtons.nextPage") || "Next"}>
        <span>
          <IconButton onClick={handleNextPageClicked} disabled={nextPageDisabled}>
            <ChevronRight />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default HeaderPaginator;
