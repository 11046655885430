import type { FC, ReactNode } from "react";
import Styles from "../assets/styles/Styles";
import Layout from "./Layout";
import Header from "./Header";
import ApplicationBreadcrumbs from "./ApplicationBreadcrumbs";
import PersistentDrawer from "@/components/PersistentDrawer";

export interface MainLayoutProps {
  children?: ReactNode;
  menuContent?: ReactNode;
  hideBreadcrumbs?: boolean;
  menuOpen: boolean;
  setMenuOpen: (open: boolean | ((prev: boolean) => boolean)) => void;
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
  menuOpen,
  setMenuOpen,
  menuContent,
  hideBreadcrumbs = false,
}) => (
  <Layout header={<Header onToggleMenu={setMenuOpen} showMenuButton={!!menuContent} />}>
    <PersistentDrawer open={menuOpen && !!menuContent} width={Styles.Dimensions.MENU_WIDTH} drawerContent={menuContent}>
      {!hideBreadcrumbs && <ApplicationBreadcrumbs />}
      {children}
    </PersistentDrawer>
  </Layout>
);

export default MainLayout;
