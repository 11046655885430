import type { FC } from "react";
import type { DataListRow } from "@/components/DataList/DataListRow";
import TimeDiffLabel from "@/components/TimeDiffLabel";

export interface DataListUpdatedByAtProps {
  item: DataListRow;
}

export const DataListUpdatedByAt: FC<DataListUpdatedByAtProps> = ({ item }) => {
  if (!item.updatedAt) {
    return null;
  }
  return <TimeDiffLabel datetime={item.updatedAt} name={item.updatedBy} />;
};

export default DataListUpdatedByAt;
