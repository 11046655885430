export const IconPaths = {
  OneKk: "/md-icons/10k.svg",
  TenMp: "/md-icons/10mp.svg",
  ElevenMp: "/md-icons/11mp.svg",
  OneTwoThree: "/md-icons/123.svg",
  TwelveMp: "/md-icons/12mp.svg",
  ThirteenMp: "/md-icons/13mp.svg",
  FourteenMp: "/md-icons/14mp.svg",
  FifteenMp: "/md-icons/15mp.svg",
  SixteenMp: "/md-icons/16mp.svg",
  SeventeenMp: "/md-icons/17mp.svg",
  EighteenUpRating: "/md-icons/18_up_rating.svg",
  EightteenMp: "/md-icons/18mp.svg",
  NineteenMp: "/md-icons/19mp.svg",
  OneK: "/md-icons/1k.svg",
  OneKPlus: "/md-icons/1k_plus.svg",
  TimesOneMobiledata: "/md-icons/1x_mobiledata.svg",
  TwentyMp: "/md-icons/20mp.svg",
  TwentyOneMp: "/md-icons/21mp.svg",
  TwentyTwoMp: "/md-icons/22mp.svg",
  TwentyThreeMp: "/md-icons/23mp.svg",
  TwentyFourMp: "/md-icons/24mp.svg",
  TwoK: "/md-icons/2k.svg",
  TwoKPlus: "/md-icons/2k_plus.svg",
  TwoMp: "/md-icons/2mp.svg",
  ThirtyFps: "/md-icons/30fps.svg",
  ThirtyFpsSelect: "/md-icons/30fps_select.svg",
  ThreeSixty: "/md-icons/360.svg",
  ThreeDRotation: "/md-icons/3d_rotation.svg",
  ThreeGMobiledata: "/md-icons/3g_mobiledata.svg",
  ThreeK: "/md-icons/3k.svg",
  ThreeKPlus: "/md-icons/3k_plus.svg",
  ThreeMp: "/md-icons/3mp.svg",
  ThreeP: "/md-icons/3p.svg",
  FourGMobiledata: "/md-icons/4g_mobiledata.svg",
  FourGPlusMobiledata: "/md-icons/4g_plus_mobiledata.svg",
  FourK: "/md-icons/4k.svg",
  FourKPlus: "/md-icons/4k_plus.svg",
  FourMp: "/md-icons/4mp.svg",
  FiveG: "/md-icons/5g.svg",
  FiveK: "/md-icons/5k.svg",
  FiveKPlus: "/md-icons/5k_plus.svg",
  FiveMp: "/md-icons/5mp.svg",
  SixtyFps: "/md-icons/60fps.svg",
  SixtyFpsSelect: "/md-icons/60fps_select.svg",
  SixFeetApart: "/md-icons/6_ft_apart.svg",
  SixK: "/md-icons/6k.svg",
  SixKPlus: "/md-icons/6k_plus.svg",
  SixMp: "/md-icons/6mp.svg",
  SevenK: "/md-icons/7k.svg",
  SevenKPlus: "/md-icons/7k_plus.svg",
  SevenMp: "/md-icons/7mp.svg",
  EightK: "/md-icons/8k.svg",
  EightKPlus: "/md-icons/8k_plus.svg",
  EightMp: "/md-icons/8mp.svg",
  NineK: "/md-icons/9k.svg",
  NineKPlus: "/md-icons/9k_plus.svg",
  NineMp: "/md-icons/9mp.svg",
  Abc: "/md-icons/abc.svg",
  AcUnit: "/md-icons/ac_unit.svg",
  AccessAlarm: "/md-icons/access_alarm.svg",
  AccessAlarms: "/md-icons/access_alarms.svg",
  AccessTime: "/md-icons/access_time.svg",
  AccessTimeFilled: "/md-icons/access_time_filled.svg",
  Accessibility: "/md-icons/accessibility.svg",
  AccessibilityNew: "/md-icons/accessibility_new.svg",
  Accessible: "/md-icons/accessible.svg",
  AccessibleForward: "/md-icons/accessible_forward.svg",
  AccountBalance: "/md-icons/account_balance.svg",
  AccountBalanceWallet: "/md-icons/account_balance_wallet.svg",
  AccountBox: "/md-icons/account_box.svg",
  AccountCircle: "/md-icons/account_circle.svg",
  AccountTree: "/md-icons/account_tree.svg",
  AdUnits: "/md-icons/ad_units.svg",
  Adb: "/md-icons/adb.svg",
  Add: "/md-icons/add.svg",
  AddAPhoto: "/md-icons/add_a_photo.svg",
  AddAlarm: "/md-icons/add_alarm.svg",
  AddAlert: "/md-icons/add_alert.svg",
  AddBox: "/md-icons/add_box.svg",
  AddBusiness: "/md-icons/add_business.svg",
  AddCard: "/md-icons/add_card.svg",
  AddChart: "/md-icons/add_chart.svg",
  AddCircle: "/md-icons/add_circle.svg",
  AddCircleOutline: "/md-icons/add_circle_outline.svg",
  AddComment: "/md-icons/add_comment.svg",
  AddHome: "/md-icons/add_home.svg",
  AddHomeWork: "/md-icons/add_home_work.svg",
  AddIcCall: "/md-icons/add_ic_call.svg",
  AddLink: "/md-icons/add_link.svg",
  AddLocation: "/md-icons/add_location.svg",
  AddLocationAlt: "/md-icons/add_location_alt.svg",
  AddModerator: "/md-icons/add_moderator.svg",
  AddPhotoAlternate: "/md-icons/add_photo_alternate.svg",
  AddReaction: "/md-icons/add_reaction.svg",
  AddRoad: "/md-icons/add_road.svg",
  AddShoppingCart: "/md-icons/add_shopping_cart.svg",
  AddTask: "/md-icons/add_task.svg",
  AddToDrive: "/md-icons/add_to_drive.svg",
  AddToHomeScreen: "/md-icons/add_to_home_screen.svg",
  AddToPhotos: "/md-icons/add_to_photos.svg",
  AddToQueue: "/md-icons/add_to_queue.svg",
  Addchart: "/md-icons/addchart.svg",
  AdfScanner: "/md-icons/adf_scanner.svg",
  Adjust: "/md-icons/adjust.svg",
  AdminPanelSettings: "/md-icons/admin_panel_settings.svg",
  AdsClick: "/md-icons/ads_click.svg",
  Agriculture: "/md-icons/agriculture.svg",
  Air: "/md-icons/air.svg",
  AirlineSeatFlat: "/md-icons/airline_seat_flat.svg",
  AirlineSeatFlatAngled: "/md-icons/airline_seat_flat_angled.svg",
  AirlineSeatIndividualSuite: "/md-icons/airline_seat_individual_suite.svg",
  AirlineSeatLegroomExtra: "/md-icons/airline_seat_legroom_extra.svg",
  AirlineSeatLegroomNormal: "/md-icons/airline_seat_legroom_normal.svg",
  AirlineSeatLegroomReduced: "/md-icons/airline_seat_legroom_reduced.svg",
  AirlineSeatReclineExtra: "/md-icons/airline_seat_recline_extra.svg",
  AirlineSeatReclineNormal: "/md-icons/airline_seat_recline_normal.svg",
  AirlineStops: "/md-icons/airline_stops.svg",
  Airlines: "/md-icons/airlines.svg",
  AirplaneTicket: "/md-icons/airplane_ticket.svg",
  AirplanemodeActive: "/md-icons/airplanemode_active.svg",
  AirplanemodeInactive: "/md-icons/airplanemode_inactive.svg",
  Airplay: "/md-icons/airplay.svg",
  AirportShuttle: "/md-icons/airport_shuttle.svg",
  Alarm: "/md-icons/alarm.svg",
  AlarmAdd: "/md-icons/alarm_add.svg",
  AlarmOff: "/md-icons/alarm_off.svg",
  AlarmOn: "/md-icons/alarm_on.svg",
  Album: "/md-icons/album.svg",
  AlignHorizontalCenter: "/md-icons/align_horizontal_center.svg",
  AlignHorizontalLeft: "/md-icons/align_horizontal_left.svg",
  AlignHorizontalRight: "/md-icons/align_horizontal_right.svg",
  AlignVerticalBottom: "/md-icons/align_vertical_bottom.svg",
  AlignVerticalCenter: "/md-icons/align_vertical_center.svg",
  AlignVerticalTop: "/md-icons/align_vertical_top.svg",
  AllInbox: "/md-icons/all_inbox.svg",
  AllInclusive: "/md-icons/all_inclusive.svg",
  AllOut: "/md-icons/all_out.svg",
  AltRoute: "/md-icons/alt_route.svg",
  AlternateEmail: "/md-icons/alternate_email.svg",
  Analytics: "/md-icons/analytics.svg",
  Anchor: "/md-icons/anchor.svg",
  Android: "/md-icons/android.svg",
  Animation: "/md-icons/animation.svg",
  Announcement: "/md-icons/announcement.svg",
  Aod: "/md-icons/aod.svg",
  Apartment: "/md-icons/apartment.svg",
  Api: "/md-icons/api.svg",
  AppBlocking: "/md-icons/app_blocking.svg",
  AppRegistration: "/md-icons/app_registration.svg",
  AppSettingsAlt: "/md-icons/app_settings_alt.svg",
  AppShortcut: "/md-icons/app_shortcut.svg",
  Approval: "/md-icons/approval.svg",
  Apps: "/md-icons/apps.svg",
  AppsOutage: "/md-icons/apps_outage.svg",
  Architecture: "/md-icons/architecture.svg",
  Archive: "/md-icons/archive.svg",
  AreaChart: "/md-icons/area_chart.svg",
  ArrowBack: "/md-icons/arrow_back.svg",
  ArrowBackIos: "/md-icons/arrow_back_ios.svg",
  ArrowBackIosNew: "/md-icons/arrow_back_ios_new.svg",
  ArrowCircleDown: "/md-icons/arrow_circle_down.svg",
  ArrowCircleLeft: "/md-icons/arrow_circle_left.svg",
  ArrowCircleRight: "/md-icons/arrow_circle_right.svg",
  ArrowCircleUp: "/md-icons/arrow_circle_up.svg",
  ArrowDownward: "/md-icons/arrow_downward.svg",
  ArrowDropDown: "/md-icons/arrow_drop_down.svg",
  ArrowDropDownCircle: "/md-icons/arrow_drop_down_circle.svg",
  ArrowDropUp: "/md-icons/arrow_drop_up.svg",
  ArrowForward: "/md-icons/arrow_forward.svg",
  ArrowForwardIos: "/md-icons/arrow_forward_ios.svg",
  ArrowLeft: "/md-icons/arrow_left.svg",
  ArrowOutward: "/md-icons/arrow_outward.svg",
  ArrowRight: "/md-icons/arrow_right.svg",
  ArrowRightAlt: "/md-icons/arrow_right_alt.svg",
  ArrowUpward: "/md-icons/arrow_upward.svg",
  ArtTrack: "/md-icons/art_track.svg",
  Article: "/md-icons/article.svg",
  AspectRatio: "/md-icons/aspect_ratio.svg",
  Assessment: "/md-icons/assessment.svg",
  Assignment: "/md-icons/assignment.svg",
  AssignmentInd: "/md-icons/assignment_ind.svg",
  AssignmentLate: "/md-icons/assignment_late.svg",
  AssignmentReturn: "/md-icons/assignment_return.svg",
  AssignmentReturned: "/md-icons/assignment_returned.svg",
  AssignmentTurnedIn: "/md-icons/assignment_turned_in.svg",
  AssistWalker: "/md-icons/assist_walker.svg",
  Assistant: "/md-icons/assistant.svg",
  AssistantDirection: "/md-icons/assistant_direction.svg",
  AssistantPhoto: "/md-icons/assistant_photo.svg",
  AssuredWorkload: "/md-icons/assured_workload.svg",
  Atm: "/md-icons/atm.svg",
  AttachEmail: "/md-icons/attach_email.svg",
  AttachFile: "/md-icons/attach_file.svg",
  AttachMoney: "/md-icons/attach_money.svg",
  Attachment: "/md-icons/attachment.svg",
  Attractions: "/md-icons/attractions.svg",
  Attribution: "/md-icons/attribution.svg",
  AudioFile: "/md-icons/audio_file.svg",
  Audiotrack: "/md-icons/audiotrack.svg",
  AutoAwesome: "/md-icons/auto_awesome.svg",
  AutoAwesomeMosaic: "/md-icons/auto_awesome_mosaic.svg",
  AutoAwesomeMotion: "/md-icons/auto_awesome_motion.svg",
  AutoDelete: "/md-icons/auto_delete.svg",
  AutoFixHigh: "/md-icons/auto_fix_high.svg",
  AutoFixNormal: "/md-icons/auto_fix_normal.svg",
  AutoFixOff: "/md-icons/auto_fix_off.svg",
  AutoGraph: "/md-icons/auto_graph.svg",
  AutoMode: "/md-icons/auto_mode.svg",
  AutoStories: "/md-icons/auto_stories.svg",
  AutofpsSelect: "/md-icons/autofps_select.svg",
  Autorenew: "/md-icons/autorenew.svg",
  AvTimer: "/md-icons/av_timer.svg",
  BabyChangingStation: "/md-icons/baby_changing_station.svg",
  BackHand: "/md-icons/back_hand.svg",
  Backpack: "/md-icons/backpack.svg",
  Backspace: "/md-icons/backspace.svg",
  Backup: "/md-icons/backup.svg",
  BackupTable: "/md-icons/backup_table.svg",
  Badge: "/md-icons/badge.svg",
  BakeryDining: "/md-icons/bakery_dining.svg",
  Balance: "/md-icons/balance.svg",
  Balcony: "/md-icons/balcony.svg",
  Ballot: "/md-icons/ballot.svg",
  BarChart: "/md-icons/bar_chart.svg",
  BatchPrediction: "/md-icons/batch_prediction.svg",
  Bathroom: "/md-icons/bathroom.svg",
  Bathtub: "/md-icons/bathtub.svg",
  Battery0Bar: "/md-icons/battery_0_bar.svg",
  Battery1Bar: "/md-icons/battery_1_bar.svg",
  Battery2Bar: "/md-icons/battery_2_bar.svg",
  Battery3Bar: "/md-icons/battery_3_bar.svg",
  Battery4Bar: "/md-icons/battery_4_bar.svg",
  Battery5Bar: "/md-icons/battery_5_bar.svg",
  Battery6Bar: "/md-icons/battery_6_bar.svg",
  BatteryAlert: "/md-icons/battery_alert.svg",
  BatteryChargingFull: "/md-icons/battery_charging_full.svg",
  BatteryFull: "/md-icons/battery_full.svg",
  BatterySaver: "/md-icons/battery_saver.svg",
  BatteryStd: "/md-icons/battery_std.svg",
  BatteryUnknown: "/md-icons/battery_unknown.svg",
  BeachAccess: "/md-icons/beach_access.svg",
  Bed: "/md-icons/bed.svg",
  BedroomBaby: "/md-icons/bedroom_baby.svg",
  BedroomChild: "/md-icons/bedroom_child.svg",
  BedroomParent: "/md-icons/bedroom_parent.svg",
  Bedtime: "/md-icons/bedtime.svg",
  BedtimeOff: "/md-icons/bedtime_off.svg",
  Beenhere: "/md-icons/beenhere.svg",
  Bento: "/md-icons/bento.svg",
  BikeScooter: "/md-icons/bike_scooter.svg",
  Biotech: "/md-icons/biotech.svg",
  Blender: "/md-icons/blender.svg",
  Blind: "/md-icons/blind.svg",
  Blinds: "/md-icons/blinds.svg",
  BlindsClosed: "/md-icons/blinds_closed.svg",
  Block: "/md-icons/block.svg",
  Bloodtype: "/md-icons/bloodtype.svg",
  Bluetooth: "/md-icons/bluetooth.svg",
  BluetoothAudio: "/md-icons/bluetooth_audio.svg",
  BluetoothConnected: "/md-icons/bluetooth_connected.svg",
  BluetoothDisabled: "/md-icons/bluetooth_disabled.svg",
  BluetoothDrive: "/md-icons/bluetooth_drive.svg",
  BluetoothSearching: "/md-icons/bluetooth_searching.svg",
  BlurCircular: "/md-icons/blur_circular.svg",
  BlurLinear: "/md-icons/blur_linear.svg",
  BlurOff: "/md-icons/blur_off.svg",
  BlurOn: "/md-icons/blur_on.svg",
  Bolt: "/md-icons/bolt.svg",
  Book: "/md-icons/book.svg",
  BookOnline: "/md-icons/book_online.svg",
  Bookmark: "/md-icons/bookmark.svg",
  BookmarkAdd: "/md-icons/bookmark_add.svg",
  BookmarkAdded: "/md-icons/bookmark_added.svg",
  BookmarkBorder: "/md-icons/bookmark_border.svg",
  BookmarkRemove: "/md-icons/bookmark_remove.svg",
  Bookmarks: "/md-icons/bookmarks.svg",
  BorderAll: "/md-icons/border_all.svg",
  BorderBottom: "/md-icons/border_bottom.svg",
  BorderClear: "/md-icons/border_clear.svg",
  BorderColor: "/md-icons/border_color.svg",
  BorderHorizontal: "/md-icons/border_horizontal.svg",
  BorderInner: "/md-icons/border_inner.svg",
  BorderLeft: "/md-icons/border_left.svg",
  BorderOuter: "/md-icons/border_outer.svg",
  BorderRight: "/md-icons/border_right.svg",
  BorderStyle: "/md-icons/border_style.svg",
  BorderTop: "/md-icons/border_top.svg",
  BorderVertical: "/md-icons/border_vertical.svg",
  Boy: "/md-icons/boy.svg",
  BrandingWatermark: "/md-icons/branding_watermark.svg",
  BreakfastDining: "/md-icons/breakfast_dining.svg",
  Brightness1: "/md-icons/brightness_1.svg",
  Brightness2: "/md-icons/brightness_2.svg",
  Brightness3: "/md-icons/brightness_3.svg",
  Brightness4: "/md-icons/brightness_4.svg",
  Brightness5: "/md-icons/brightness_5.svg",
  Brightness6: "/md-icons/brightness_6.svg",
  Brightness7: "/md-icons/brightness_7.svg",
  BrightnessAuto: "/md-icons/brightness_auto.svg",
  BrightnessHigh: "/md-icons/brightness_high.svg",
  BrightnessLow: "/md-icons/brightness_low.svg",
  BrightnessMedium: "/md-icons/brightness_medium.svg",
  BroadcastOnHome: "/md-icons/broadcast_on_home.svg",
  BroadcastOnPersonal: "/md-icons/broadcast_on_personal.svg",
  BrokenImage: "/md-icons/broken_image.svg",
  BrowseGallery: "/md-icons/browse_gallery.svg",
  BrowserNotSupported: "/md-icons/browser_not_supported.svg",
  BrowserUpdated: "/md-icons/browser_updated.svg",
  BrunchDining: "/md-icons/brunch_dining.svg",
  Brush: "/md-icons/brush.svg",
  BubbleChart: "/md-icons/bubble_chart.svg",
  BugReport: "/md-icons/bug_report.svg",
  Build: "/md-icons/build.svg",
  BuildCircle: "/md-icons/build_circle.svg",
  Bungalow: "/md-icons/bungalow.svg",
  BurstMode: "/md-icons/burst_mode.svg",
  BusAlert: "/md-icons/bus_alert.svg",
  Business: "/md-icons/business.svg",
  BusinessCenter: "/md-icons/business_center.svg",
  Cabin: "/md-icons/cabin.svg",
  Cable: "/md-icons/cable.svg",
  Cached: "/md-icons/cached.svg",
  Cake: "/md-icons/cake.svg",
  Calculate: "/md-icons/calculate.svg",
  CalendarMonth: "/md-icons/calendar_month.svg",
  CalendarToday: "/md-icons/calendar_today.svg",
  CalendarViewDay: "/md-icons/calendar_view_day.svg",
  CalendarViewMonth: "/md-icons/calendar_view_month.svg",
  CalendarViewWeek: "/md-icons/calendar_view_week.svg",
  Call: "/md-icons/call.svg",
  CallEnd: "/md-icons/call_end.svg",
  CallMade: "/md-icons/call_made.svg",
  CallMerge: "/md-icons/call_merge.svg",
  CallMissed: "/md-icons/call_missed.svg",
  CallMissedOutgoing: "/md-icons/call_missed_outgoing.svg",
  CallReceived: "/md-icons/call_received.svg",
  CallSplit: "/md-icons/call_split.svg",
  CallToAction: "/md-icons/call_to_action.svg",
  Camera: "/md-icons/camera.svg",
  CameraAlt: "/md-icons/camera_alt.svg",
  CameraEnhance: "/md-icons/camera_enhance.svg",
  CameraFront: "/md-icons/camera_front.svg",
  CameraIndoor: "/md-icons/camera_indoor.svg",
  CameraOutdoor: "/md-icons/camera_outdoor.svg",
  CameraRear: "/md-icons/camera_rear.svg",
  CameraRoll: "/md-icons/camera_roll.svg",
  Cameraswitch: "/md-icons/cameraswitch.svg",
  Campaign: "/md-icons/campaign.svg",
  Cancel: "/md-icons/cancel.svg",
  CancelPresentation: "/md-icons/cancel_presentation.svg",
  CancelScheduleSend: "/md-icons/cancel_schedule_send.svg",
  CandlestickChart: "/md-icons/candlestick_chart.svg",
  CarCrash: "/md-icons/car_crash.svg",
  CarRental: "/md-icons/car_rental.svg",
  CarRepair: "/md-icons/car_repair.svg",
  CardGiftcard: "/md-icons/card_giftcard.svg",
  CardMembership: "/md-icons/card_membership.svg",
  CardTravel: "/md-icons/card_travel.svg",
  Carpenter: "/md-icons/carpenter.svg",
  Cases: "/md-icons/cases.svg",
  Casino: "/md-icons/casino.svg",
  Cast: "/md-icons/cast.svg",
  CastConnected: "/md-icons/cast_connected.svg",
  CastForEducation: "/md-icons/cast_for_education.svg",
  Castle: "/md-icons/castle.svg",
  CatchingPokemon: "/md-icons/catching_pokemon.svg",
  Category: "/md-icons/category.svg",
  Celebration: "/md-icons/celebration.svg",
  CellTower: "/md-icons/cell_tower.svg",
  CellWifi: "/md-icons/cell_wifi.svg",
  CenterFocusStrong: "/md-icons/center_focus_strong.svg",
  CenterFocusWeak: "/md-icons/center_focus_weak.svg",
  Chair: "/md-icons/chair.svg",
  ChairAlt: "/md-icons/chair_alt.svg",
  Chalet: "/md-icons/chalet.svg",
  ChangeCircle: "/md-icons/change_circle.svg",
  ChangeHistory: "/md-icons/change_history.svg",
  ChargingStation: "/md-icons/charging_station.svg",
  Chat: "/md-icons/chat.svg",
  ChatBubble: "/md-icons/chat_bubble.svg",
  ChatBubbleOutline: "/md-icons/chat_bubble_outline.svg",
  Check: "/md-icons/check.svg",
  CheckBox: "/md-icons/check_box.svg",
  CheckBoxOutlineBlank: "/md-icons/check_box_outline_blank.svg",
  CheckCircle: "/md-icons/check_circle.svg",
  CheckCircleOutline: "/md-icons/check_circle_outline.svg",
  Checklist: "/md-icons/checklist.svg",
  ChecklistRtl: "/md-icons/checklist_rtl.svg",
  Checkroom: "/md-icons/checkroom.svg",
  ChevronLeft: "/md-icons/chevron_left.svg",
  ChevronRight: "/md-icons/chevron_right.svg",
  ChildCare: "/md-icons/child_care.svg",
  ChildFriendly: "/md-icons/child_friendly.svg",
  ChromeReaderMode: "/md-icons/chrome_reader_mode.svg",
  Church: "/md-icons/church.svg",
  Circle: "/md-icons/circle.svg",
  CircleNotifications: "/md-icons/circle_notifications.svg",
  Class: "/md-icons/class.svg",
  CleanHands: "/md-icons/clean_hands.svg",
  CleaningServices: "/md-icons/cleaning_services.svg",
  Clear: "/md-icons/clear.svg",
  ClearAll: "/md-icons/clear_all.svg",
  Close: "/md-icons/close.svg",
  CloseFullscreen: "/md-icons/close_fullscreen.svg",
  ClosedCaption: "/md-icons/closed_caption.svg",
  ClosedCaptionDisabled: "/md-icons/closed_caption_disabled.svg",
  ClosedCaptionOff: "/md-icons/closed_caption_off.svg",
  Cloud: "/md-icons/cloud.svg",
  CloudCircle: "/md-icons/cloud_circle.svg",
  CloudDone: "/md-icons/cloud_done.svg",
  CloudDownload: "/md-icons/cloud_download.svg",
  CloudOff: "/md-icons/cloud_off.svg",
  CloudQueue: "/md-icons/cloud_queue.svg",
  CloudSync: "/md-icons/cloud_sync.svg",
  CloudUpload: "/md-icons/cloud_upload.svg",
  Co2: "/md-icons/co2.svg",
  CoPresent: "/md-icons/co_present.svg",
  Code: "/md-icons/code.svg",
  CodeOff: "/md-icons/code_off.svg",
  Coffee: "/md-icons/coffee.svg",
  CoffeeMaker: "/md-icons/coffee_maker.svg",
  Collections: "/md-icons/collections.svg",
  CollectionsBookmark: "/md-icons/collections_bookmark.svg",
  ColorLens: "/md-icons/color_lens.svg",
  Colorize: "/md-icons/colorize.svg",
  Comment: "/md-icons/comment.svg",
  CommentBank: "/md-icons/comment_bank.svg",
  CommentsDisabled: "/md-icons/comments_disabled.svg",
  Commit: "/md-icons/commit.svg",
  Commute: "/md-icons/commute.svg",
  Compare: "/md-icons/compare.svg",
  CompareArrows: "/md-icons/compare_arrows.svg",
  CompassCalibration: "/md-icons/compass_calibration.svg",
  Compost: "/md-icons/compost.svg",
  Compress: "/md-icons/compress.svg",
  Computer: "/md-icons/computer.svg",
  ConfirmationNumber: "/md-icons/confirmation_number.svg",
  ConnectWithoutContact: "/md-icons/connect_without_contact.svg",
  ConnectedTv: "/md-icons/connected_tv.svg",
  ConnectingAirports: "/md-icons/connecting_airports.svg",
  Construction: "/md-icons/construction.svg",
  ContactEmergency: "/md-icons/contact_emergency.svg",
  ContactMail: "/md-icons/contact_mail.svg",
  ContactPage: "/md-icons/contact_page.svg",
  ContactPhone: "/md-icons/contact_phone.svg",
  ContactSupport: "/md-icons/contact_support.svg",
  Contactless: "/md-icons/contactless.svg",
  Contacts: "/md-icons/contacts.svg",
  ContentCopy: "/md-icons/content_copy.svg",
  ContentCut: "/md-icons/content_cut.svg",
  ContentPaste: "/md-icons/content_paste.svg",
  ContentPasteGo: "/md-icons/content_paste_go.svg",
  ContentPasteOff: "/md-icons/content_paste_off.svg",
  ContentPasteSearch: "/md-icons/content_paste_search.svg",
  Contrast: "/md-icons/contrast.svg",
  ControlCamera: "/md-icons/control_camera.svg",
  ControlPoint: "/md-icons/control_point.svg",
  ControlPointDuplicate: "/md-icons/control_point_duplicate.svg",
  Cookie: "/md-icons/cookie.svg",
  CopyAll: "/md-icons/copy_all.svg",
  Copyright: "/md-icons/copyright.svg",
  Coronavirus: "/md-icons/coronavirus.svg",
  CorporateFare: "/md-icons/corporate_fare.svg",
  Cottage: "/md-icons/cottage.svg",
  Countertops: "/md-icons/countertops.svg",
  Create: "/md-icons/create.svg",
  CreateNewFolder: "/md-icons/create_new_folder.svg",
  CreditCard: "/md-icons/credit_card.svg",
  CreditCardOff: "/md-icons/credit_card_off.svg",
  CreditScore: "/md-icons/credit_score.svg",
  Crib: "/md-icons/crib.svg",
  CrisisAlert: "/md-icons/crisis_alert.svg",
  Crop: "/md-icons/crop.svg",
  Crop169: "/md-icons/crop_16_9.svg",
  Crop32: "/md-icons/crop_3_2.svg",
  Crop54: "/md-icons/crop_5_4.svg",
  Crop75: "/md-icons/crop_7_5.svg",
  CropDin: "/md-icons/crop_din.svg",
  CropFree: "/md-icons/crop_free.svg",
  CropLandscape: "/md-icons/crop_landscape.svg",
  CropOriginal: "/md-icons/crop_original.svg",
  CropPortrait: "/md-icons/crop_portrait.svg",
  CropRotate: "/md-icons/crop_rotate.svg",
  CropSquare: "/md-icons/crop_square.svg",
  CrueltyFree: "/md-icons/cruelty_free.svg",
  Css: "/md-icons/css.svg",
  CurrencyBitcoin: "/md-icons/currency_bitcoin.svg",
  CurrencyExchange: "/md-icons/currency_exchange.svg",
  CurrencyFranc: "/md-icons/currency_franc.svg",
  CurrencyLira: "/md-icons/currency_lira.svg",
  CurrencyPound: "/md-icons/currency_pound.svg",
  CurrencyRuble: "/md-icons/currency_ruble.svg",
  CurrencyRupee: "/md-icons/currency_rupee.svg",
  CurrencyYen: "/md-icons/currency_yen.svg",
  CurrencyYuan: "/md-icons/currency_yuan.svg",
  Curtains: "/md-icons/curtains.svg",
  CurtainsClosed: "/md-icons/curtains_closed.svg",
  Cyclone: "/md-icons/cyclone.svg",
  Dangerous: "/md-icons/dangerous.svg",
  DarkMode: "/md-icons/dark_mode.svg",
  Dashboard: "/md-icons/dashboard.svg",
  DashboardCustomize: "/md-icons/dashboard_customize.svg",
  DataArray: "/md-icons/data_array.svg",
  DataExploration: "/md-icons/data_exploration.svg",
  DataObject: "/md-icons/data_object.svg",
  DataSaverOff: "/md-icons/data_saver_off.svg",
  DataSaverOn: "/md-icons/data_saver_on.svg",
  DataThresholding: "/md-icons/data_thresholding.svg",
  DataUsage: "/md-icons/data_usage.svg",
  Dataset: "/md-icons/dataset.svg",
  DatasetLinked: "/md-icons/dataset_linked.svg",
  DateRange: "/md-icons/date_range.svg",
  Deblur: "/md-icons/deblur.svg",
  Deck: "/md-icons/deck.svg",
  Dehaze: "/md-icons/dehaze.svg",
  Delete: "/md-icons/delete.svg",
  DeleteForever: "/md-icons/delete_forever.svg",
  DeleteOutline: "/md-icons/delete_outline.svg",
  DeleteSweep: "/md-icons/delete_sweep.svg",
  DeliveryDining: "/md-icons/delivery_dining.svg",
  DensityLarge: "/md-icons/density_large.svg",
  DensityMedium: "/md-icons/density_medium.svg",
  DensitySmall: "/md-icons/density_small.svg",
  DepartureBoard: "/md-icons/departure_board.svg",
  Description: "/md-icons/description.svg",
  Deselect: "/md-icons/deselect.svg",
  DesignServices: "/md-icons/design_services.svg",
  Desk: "/md-icons/desk.svg",
  DesktopAccessDisabled: "/md-icons/desktop_access_disabled.svg",
  DesktopMac: "/md-icons/desktop_mac.svg",
  DesktopWindows: "/md-icons/desktop_windows.svg",
  Details: "/md-icons/details.svg",
  DeveloperBoard: "/md-icons/developer_board.svg",
  DeveloperBoardOff: "/md-icons/developer_board_off.svg",
  DeveloperMode: "/md-icons/developer_mode.svg",
  DeviceHub: "/md-icons/device_hub.svg",
  DeviceThermostat: "/md-icons/device_thermostat.svg",
  DeviceUnknown: "/md-icons/device_unknown.svg",
  Devices: "/md-icons/devices.svg",
  DevicesFold: "/md-icons/devices_fold.svg",
  DevicesOther: "/md-icons/devices_other.svg",
  DialerSip: "/md-icons/dialer_sip.svg",
  Dialpad: "/md-icons/dialpad.svg",
  Diamond: "/md-icons/diamond.svg",
  Difference: "/md-icons/difference.svg",
  Dining: "/md-icons/dining.svg",
  DinnerDining: "/md-icons/dinner_dining.svg",
  Directions: "/md-icons/directions.svg",
  DirectionsBike: "/md-icons/directions_bike.svg",
  DirectionsBoat: "/md-icons/directions_boat.svg",
  DirectionsBoatFilled: "/md-icons/directions_boat_filled.svg",
  DirectionsBus: "/md-icons/directions_bus.svg",
  DirectionsBusFilled: "/md-icons/directions_bus_filled.svg",
  DirectionsCar: "/md-icons/directions_car.svg",
  DirectionsCarFilled: "/md-icons/directions_car_filled.svg",
  DirectionsOff: "/md-icons/directions_off.svg",
  DirectionsRailway: "/md-icons/directions_railway.svg",
  DirectionsRailwayFilled: "/md-icons/directions_railway_filled.svg",
  DirectionsRun: "/md-icons/directions_run.svg",
  DirectionsSubway: "/md-icons/directions_subway.svg",
  DirectionsSubwayFilled: "/md-icons/directions_subway_filled.svg",
  DirectionsTransit: "/md-icons/directions_transit.svg",
  DirectionsTransitFilled: "/md-icons/directions_transit_filled.svg",
  DirectionsWalk: "/md-icons/directions_walk.svg",
  DirtyLens: "/md-icons/dirty_lens.svg",
  DisabledByDefault: "/md-icons/disabled_by_default.svg",
  DisabledVisible: "/md-icons/disabled_visible.svg",
  DiscFull: "/md-icons/disc_full.svg",
  Discount: "/md-icons/discount.svg",
  DisplaySettings: "/md-icons/display_settings.svg",
  Diversity1: "/md-icons/diversity_1.svg",
  Diversity2: "/md-icons/diversity_2.svg",
  Diversity3: "/md-icons/diversity_3.svg",
  Dns: "/md-icons/dns.svg",
  DoDisturb: "/md-icons/do_disturb.svg",
  DoDisturbAlt: "/md-icons/do_disturb_alt.svg",
  DoDisturbOff: "/md-icons/do_disturb_off.svg",
  DoDisturbOn: "/md-icons/do_disturb_on.svg",
  DoNotDisturb: "/md-icons/do_not_disturb.svg",
  DoNotDisturbAlt: "/md-icons/do_not_disturb_alt.svg",
  DoNotDisturbOff: "/md-icons/do_not_disturb_off.svg",
  DoNotDisturbOn: "/md-icons/do_not_disturb_on.svg",
  DoNotDisturbOnTotalSilence: "/md-icons/do_not_disturb_on_total_silence.svg",
  DoNotStep: "/md-icons/do_not_step.svg",
  DoNotTouch: "/md-icons/do_not_touch.svg",
  Dock: "/md-icons/dock.svg",
  DocumentScanner: "/md-icons/document_scanner.svg",
  Domain: "/md-icons/domain.svg",
  DomainAdd: "/md-icons/domain_add.svg",
  DomainDisabled: "/md-icons/domain_disabled.svg",
  DomainVerification: "/md-icons/domain_verification.svg",
  Done: "/md-icons/done.svg",
  DoneAll: "/md-icons/done_all.svg",
  DoneOutline: "/md-icons/done_outline.svg",
  DonutLarge: "/md-icons/donut_large.svg",
  DonutSmall: "/md-icons/donut_small.svg",
  DoorBack: "/md-icons/door_back.svg",
  DoorFront: "/md-icons/door_front.svg",
  DoorSliding: "/md-icons/door_sliding.svg",
  Doorbell: "/md-icons/doorbell.svg",
  DoubleArrow: "/md-icons/double_arrow.svg",
  DownhillSkiing: "/md-icons/downhill_skiing.svg",
  Download: "/md-icons/download.svg",
  DownloadDone: "/md-icons/download_done.svg",
  DownloadForOffline: "/md-icons/download_for_offline.svg",
  Downloading: "/md-icons/downloading.svg",
  Drafts: "/md-icons/drafts.svg",
  DragHandle: "/md-icons/drag_handle.svg",
  DragIndicator: "/md-icons/drag_indicator.svg",
  Draw: "/md-icons/draw.svg",
  DriveEta: "/md-icons/drive_eta.svg",
  DriveFileMove: "/md-icons/drive_file_move.svg",
  DriveFileMoveRtl: "/md-icons/drive_file_move_rtl.svg",
  DriveFileRenameOutline: "/md-icons/drive_file_rename_outline.svg",
  DriveFolderUpload: "/md-icons/drive_folder_upload.svg",
  Dry: "/md-icons/dry.svg",
  DryCleaning: "/md-icons/dry_cleaning.svg",
  Duo: "/md-icons/duo.svg",
  Dvr: "/md-icons/dvr.svg",
  DynamicFeed: "/md-icons/dynamic_feed.svg",
  DynamicForm: "/md-icons/dynamic_form.svg",
  EMobiledata: "/md-icons/e_mobiledata.svg",
  Earbuds: "/md-icons/earbuds.svg",
  EarbudsBattery: "/md-icons/earbuds_battery.svg",
  East: "/md-icons/east.svg",
  EdgesensorHigh: "/md-icons/edgesensor_high.svg",
  EdgesensorLow: "/md-icons/edgesensor_low.svg",
  Edit: "/md-icons/edit.svg",
  EditAttributes: "/md-icons/edit_attributes.svg",
  EditCalendar: "/md-icons/edit_calendar.svg",
  EditLocation: "/md-icons/edit_location.svg",
  EditLocationAlt: "/md-icons/edit_location_alt.svg",
  EditNote: "/md-icons/edit_note.svg",
  EditNotifications: "/md-icons/edit_notifications.svg",
  EditOff: "/md-icons/edit_off.svg",
  EditRoad: "/md-icons/edit_road.svg",
  Egg: "/md-icons/egg.svg",
  EggAlt: "/md-icons/egg_alt.svg",
  Eject: "/md-icons/eject.svg",
  Elderly: "/md-icons/elderly.svg",
  ElderlyWoman: "/md-icons/elderly_woman.svg",
  ElectricBike: "/md-icons/electric_bike.svg",
  ElectricBolt: "/md-icons/electric_bolt.svg",
  ElectricCar: "/md-icons/electric_car.svg",
  ElectricMeter: "/md-icons/electric_meter.svg",
  ElectricMoped: "/md-icons/electric_moped.svg",
  ElectricRickshaw: "/md-icons/electric_rickshaw.svg",
  ElectricScooter: "/md-icons/electric_scooter.svg",
  ElectricalServices: "/md-icons/electrical_services.svg",
  Elevator: "/md-icons/elevator.svg",
  Email: "/md-icons/email.svg",
  Emergency: "/md-icons/emergency.svg",
  EmergencyRecording: "/md-icons/emergency_recording.svg",
  EmergencyShare: "/md-icons/emergency_share.svg",
  EmojiEmotions: "/md-icons/emoji_emotions.svg",
  EmojiEvents: "/md-icons/emoji_events.svg",
  EmojiFoodBeverage: "/md-icons/emoji_food_beverage.svg",
  EmojiNature: "/md-icons/emoji_nature.svg",
  EmojiObjects: "/md-icons/emoji_objects.svg",
  EmojiPeople: "/md-icons/emoji_people.svg",
  EmojiSymbols: "/md-icons/emoji_symbols.svg",
  EmojiTransportation: "/md-icons/emoji_transportation.svg",
  EnergySavingsLeaf: "/md-icons/energy_savings_leaf.svg",
  Engineering: "/md-icons/engineering.svg",
  EnhancedEncryption: "/md-icons/enhanced_encryption.svg",
  Equalizer: "/md-icons/equalizer.svg",
  Error: "/md-icons/error.svg",
  ErrorOutline: "/md-icons/error_outline.svg",
  Escalator: "/md-icons/escalator.svg",
  EscalatorWarning: "/md-icons/escalator_warning.svg",
  Euro: "/md-icons/euro.svg",
  EuroSymbol: "/md-icons/euro_symbol.svg",
  EvStation: "/md-icons/ev_station.svg",
  Event: "/md-icons/event.svg",
  EventAvailable: "/md-icons/event_available.svg",
  EventBusy: "/md-icons/event_busy.svg",
  EventNote: "/md-icons/event_note.svg",
  EventRepeat: "/md-icons/event_repeat.svg",
  EventSeat: "/md-icons/event_seat.svg",
  ExitToApp: "/md-icons/exit_to_app.svg",
  Expand: "/md-icons/expand.svg",
  ExpandCircleDown: "/md-icons/expand_circle_down.svg",
  ExpandLess: "/md-icons/expand_less.svg",
  ExpandMore: "/md-icons/expand_more.svg",
  Explicit: "/md-icons/explicit.svg",
  Explore: "/md-icons/explore.svg",
  ExploreOff: "/md-icons/explore_off.svg",
  Exposure: "/md-icons/exposure.svg",
  ExposureNeg1: "/md-icons/exposure_neg_1.svg",
  ExposureNeg2: "/md-icons/exposure_neg_2.svg",
  ExposurePlus1: "/md-icons/exposure_plus_1.svg",
  ExposurePlus2: "/md-icons/exposure_plus_2.svg",
  ExposureZero: "/md-icons/exposure_zero.svg",
  Extension: "/md-icons/extension.svg",
  ExtensionOff: "/md-icons/extension_off.svg",
  Face: "/md-icons/face.svg",
  Face2: "/md-icons/face_2.svg",
  Face3: "/md-icons/face_3.svg",
  Face4: "/md-icons/face_4.svg",
  Face5: "/md-icons/face_5.svg",
  Face6: "/md-icons/face_6.svg",
  FaceRetouchingNatural: "/md-icons/face_retouching_natural.svg",
  FaceRetouchingOff: "/md-icons/face_retouching_off.svg",
  FactCheck: "/md-icons/fact_check.svg",
  Factory: "/md-icons/factory.svg",
  FamilyRestroom: "/md-icons/family_restroom.svg",
  FastForward: "/md-icons/fast_forward.svg",
  FastRewind: "/md-icons/fast_rewind.svg",
  Fastfood: "/md-icons/fastfood.svg",
  Favorite: "/md-icons/favorite.svg",
  FavoriteBorder: "/md-icons/favorite_border.svg",
  Fax: "/md-icons/fax.svg",
  FeaturedPlayList: "/md-icons/featured_play_list.svg",
  FeaturedVideo: "/md-icons/featured_video.svg",
  Feed: "/md-icons/feed.svg",
  Feedback: "/md-icons/feedback.svg",
  Female: "/md-icons/female.svg",
  Fence: "/md-icons/fence.svg",
  Festival: "/md-icons/festival.svg",
  FiberDvr: "/md-icons/fiber_dvr.svg",
  FiberManualRecord: "/md-icons/fiber_manual_record.svg",
  FiberNew: "/md-icons/fiber_new.svg",
  FiberPin: "/md-icons/fiber_pin.svg",
  FiberSmartRecord: "/md-icons/fiber_smart_record.svg",
  FileCopy: "/md-icons/file_copy.svg",
  FileDownload: "/md-icons/file_download.svg",
  FileDownloadDone: "/md-icons/file_download_done.svg",
  FileDownloadOff: "/md-icons/file_download_off.svg",
  FileOpen: "/md-icons/file_open.svg",
  FilePresent: "/md-icons/file_present.svg",
  FileUpload: "/md-icons/file_upload.svg",
  Filter: "/md-icons/filter.svg",
  Filter1: "/md-icons/filter_1.svg",
  Filter2: "/md-icons/filter_2.svg",
  Filter3: "/md-icons/filter_3.svg",
  Filter4: "/md-icons/filter_4.svg",
  Filter5: "/md-icons/filter_5.svg",
  Filter6: "/md-icons/filter_6.svg",
  Filter7: "/md-icons/filter_7.svg",
  Filter8: "/md-icons/filter_8.svg",
  Filter9: "/md-icons/filter_9.svg",
  Filter9Plus: "/md-icons/filter_9_plus.svg",
  FilterAlt: "/md-icons/filter_alt.svg",
  FilterAltOff: "/md-icons/filter_alt_off.svg",
  FilterBAndW: "/md-icons/filter_b_and_w.svg",
  FilterCenterFocus: "/md-icons/filter_center_focus.svg",
  FilterDrama: "/md-icons/filter_drama.svg",
  FilterFrames: "/md-icons/filter_frames.svg",
  FilterHdr: "/md-icons/filter_hdr.svg",
  FilterList: "/md-icons/filter_list.svg",
  FilterListOff: "/md-icons/filter_list_off.svg",
  FilterNone: "/md-icons/filter_none.svg",
  FilterTiltShift: "/md-icons/filter_tilt_shift.svg",
  FilterVintage: "/md-icons/filter_vintage.svg",
  FindInPage: "/md-icons/find_in_page.svg",
  FindReplace: "/md-icons/find_replace.svg",
  Fingerprint: "/md-icons/fingerprint.svg",
  FireExtinguisher: "/md-icons/fire_extinguisher.svg",
  FireHydrantAlt: "/md-icons/fire_hydrant_alt.svg",
  FireTruck: "/md-icons/fire_truck.svg",
  Fireplace: "/md-icons/fireplace.svg",
  FirstPage: "/md-icons/first_page.svg",
  FitScreen: "/md-icons/fit_screen.svg",
  Fitbit: "/md-icons/fitbit.svg",
  FitnessCenter: "/md-icons/fitness_center.svg",
  Flag: "/md-icons/flag.svg",
  FlagCircle: "/md-icons/flag_circle.svg",
  Flaky: "/md-icons/flaky.svg",
  Flare: "/md-icons/flare.svg",
  FlashAuto: "/md-icons/flash_auto.svg",
  FlashOff: "/md-icons/flash_off.svg",
  FlashOn: "/md-icons/flash_on.svg",
  FlashlightOff: "/md-icons/flashlight_off.svg",
  FlashlightOn: "/md-icons/flashlight_on.svg",
  Flatware: "/md-icons/flatware.svg",
  Flight: "/md-icons/flight.svg",
  FlightClass: "/md-icons/flight_class.svg",
  FlightLand: "/md-icons/flight_land.svg",
  FlightTakeoff: "/md-icons/flight_takeoff.svg",
  Flip: "/md-icons/flip.svg",
  FlipCameraAndroid: "/md-icons/flip_camera_android.svg",
  FlipCameraIos: "/md-icons/flip_camera_ios.svg",
  FlipToBack: "/md-icons/flip_to_back.svg",
  FlipToFront: "/md-icons/flip_to_front.svg",
  Flood: "/md-icons/flood.svg",
  Fluorescent: "/md-icons/fluorescent.svg",
  FlutterDash: "/md-icons/flutter_dash.svg",
  FmdBad: "/md-icons/fmd_bad.svg",
  FmdGood: "/md-icons/fmd_good.svg",
  Folder: "/md-icons/folder.svg",
  FolderCopy: "/md-icons/folder_copy.svg",
  FolderDelete: "/md-icons/folder_delete.svg",
  FolderOff: "/md-icons/folder_off.svg",
  FolderOpen: "/md-icons/folder_open.svg",
  FolderShared: "/md-icons/folder_shared.svg",
  FolderSpecial: "/md-icons/folder_special.svg",
  FolderZip: "/md-icons/folder_zip.svg",
  FollowTheSigns: "/md-icons/follow_the_signs.svg",
  FontDownload: "/md-icons/font_download.svg",
  FontDownloadOff: "/md-icons/font_download_off.svg",
  FoodBank: "/md-icons/food_bank.svg",
  Forest: "/md-icons/forest.svg",
  ForkLeft: "/md-icons/fork_left.svg",
  ForkRight: "/md-icons/fork_right.svg",
  FormatAlignCenter: "/md-icons/format_align_center.svg",
  FormatAlignJustify: "/md-icons/format_align_justify.svg",
  FormatAlignLeft: "/md-icons/format_align_left.svg",
  FormatAlignRight: "/md-icons/format_align_right.svg",
  FormatBold: "/md-icons/format_bold.svg",
  FormatClear: "/md-icons/format_clear.svg",
  FormatColorFill: "/md-icons/format_color_fill.svg",
  FormatColorReset: "/md-icons/format_color_reset.svg",
  FormatColorText: "/md-icons/format_color_text.svg",
  FormatIndentDecrease: "/md-icons/format_indent_decrease.svg",
  FormatIndentIncrease: "/md-icons/format_indent_increase.svg",
  FormatItalic: "/md-icons/format_italic.svg",
  FormatLineSpacing: "/md-icons/format_line_spacing.svg",
  FormatListBulleted: "/md-icons/format_list_bulleted.svg",
  FormatListNumbered: "/md-icons/format_list_numbered.svg",
  FormatListNumberedRtl: "/md-icons/format_list_numbered_rtl.svg",
  FormatOverline: "/md-icons/format_overline.svg",
  FormatPaint: "/md-icons/format_paint.svg",
  FormatQuote: "/md-icons/format_quote.svg",
  FormatShapes: "/md-icons/format_shapes.svg",
  FormatSize: "/md-icons/format_size.svg",
  FormatStrikethrough: "/md-icons/format_strikethrough.svg",
  FormatTextdirectionLToR: "/md-icons/format_textdirection_l_to_r.svg",
  FormatTextdirectionRToL: "/md-icons/format_textdirection_r_to_l.svg",
  FormatUnderlined: "/md-icons/format_underlined.svg",
  Fort: "/md-icons/fort.svg",
  Forum: "/md-icons/forum.svg",
  Forward: "/md-icons/forward.svg",
  Forward10: "/md-icons/forward_10.svg",
  Forward30: "/md-icons/forward_30.svg",
  Forward5: "/md-icons/forward_5.svg",
  ForwardToInbox: "/md-icons/forward_to_inbox.svg",
  Foundation: "/md-icons/foundation.svg",
  FreeBreakfast: "/md-icons/free_breakfast.svg",
  FreeCancellation: "/md-icons/free_cancellation.svg",
  FrontHand: "/md-icons/front_hand.svg",
  Fullscreen: "/md-icons/fullscreen.svg",
  FullscreenExit: "/md-icons/fullscreen_exit.svg",
  Functions: "/md-icons/functions.svg",
  GMobiledata: "/md-icons/g_mobiledata.svg",
  GTranslate: "/md-icons/g_translate.svg",
  Gamepad: "/md-icons/gamepad.svg",
  Games: "/md-icons/games.svg",
  Garage: "/md-icons/garage.svg",
  GasMeter: "/md-icons/gas_meter.svg",
  Gavel: "/md-icons/gavel.svg",
  GeneratingTokens: "/md-icons/generating_tokens.svg",
  Gesture: "/md-icons/gesture.svg",
  GetApp: "/md-icons/get_app.svg",
  Gif: "/md-icons/gif.svg",
  GifBox: "/md-icons/gif_box.svg",
  Girl: "/md-icons/girl.svg",
  Gite: "/md-icons/gite.svg",
  GolfCourse: "/md-icons/golf_course.svg",
  GppBad: "/md-icons/gpp_bad.svg",
  GppGood: "/md-icons/gpp_good.svg",
  GppMaybe: "/md-icons/gpp_maybe.svg",
  GpsFixed: "/md-icons/gps_fixed.svg",
  GpsNotFixed: "/md-icons/gps_not_fixed.svg",
  GpsOff: "/md-icons/gps_off.svg",
  Grade: "/md-icons/grade.svg",
  Gradient: "/md-icons/gradient.svg",
  Grading: "/md-icons/grading.svg",
  Grain: "/md-icons/grain.svg",
  GraphicEq: "/md-icons/graphic_eq.svg",
  Grass: "/md-icons/grass.svg",
  Grid3x3: "/md-icons/grid_3x3.svg",
  Grid4x4: "/md-icons/grid_4x4.svg",
  GridGoldenratio: "/md-icons/grid_goldenratio.svg",
  GridOff: "/md-icons/grid_off.svg",
  GridOn: "/md-icons/grid_on.svg",
  GridView: "/md-icons/grid_view.svg",
  Group: "/md-icons/group.svg",
  GroupAdd: "/md-icons/group_add.svg",
  GroupOff: "/md-icons/group_off.svg",
  GroupRemove: "/md-icons/group_remove.svg",
  GroupWork: "/md-icons/group_work.svg",
  Groups: "/md-icons/groups.svg",
  Groups2: "/md-icons/groups_2.svg",
  Groups3: "/md-icons/groups_3.svg",
  HMobiledata: "/md-icons/h_mobiledata.svg",
  HPlusMobiledata: "/md-icons/h_plus_mobiledata.svg",
  Hail: "/md-icons/hail.svg",
  Handshake: "/md-icons/handshake.svg",
  Handyman: "/md-icons/handyman.svg",
  Hardware: "/md-icons/hardware.svg",
  Hd: "/md-icons/hd.svg",
  HdrAuto: "/md-icons/hdr_auto.svg",
  HdrAutoSelect: "/md-icons/hdr_auto_select.svg",
  HdrEnhancedSelect: "/md-icons/hdr_enhanced_select.svg",
  HdrOff: "/md-icons/hdr_off.svg",
  HdrOffSelect: "/md-icons/hdr_off_select.svg",
  HdrOn: "/md-icons/hdr_on.svg",
  HdrOnSelect: "/md-icons/hdr_on_select.svg",
  HdrPlus: "/md-icons/hdr_plus.svg",
  HdrStrong: "/md-icons/hdr_strong.svg",
  HdrWeak: "/md-icons/hdr_weak.svg",
  Headphones: "/md-icons/headphones.svg",
  HeadphonesBattery: "/md-icons/headphones_battery.svg",
  Headset: "/md-icons/headset.svg",
  HeadsetMic: "/md-icons/headset_mic.svg",
  HeadsetOff: "/md-icons/headset_off.svg",
  Healing: "/md-icons/healing.svg",
  HealthAndSafety: "/md-icons/health_and_safety.svg",
  Hearing: "/md-icons/hearing.svg",
  HearingDisabled: "/md-icons/hearing_disabled.svg",
  HeartBroken: "/md-icons/heart_broken.svg",
  HeatPump: "/md-icons/heat_pump.svg",
  Height: "/md-icons/height.svg",
  Help: "/md-icons/help.svg",
  HelpCenter: "/md-icons/help_center.svg",
  HelpOutline: "/md-icons/help_outline.svg",
  Hevc: "/md-icons/hevc.svg",
  Hexagon: "/md-icons/hexagon.svg",
  HideImage: "/md-icons/hide_image.svg",
  HideSource: "/md-icons/hide_source.svg",
  HighQuality: "/md-icons/high_quality.svg",
  Highlight: "/md-icons/highlight.svg",
  HighlightAlt: "/md-icons/highlight_alt.svg",
  HighlightOff: "/md-icons/highlight_off.svg",
  Hiking: "/md-icons/hiking.svg",
  History: "/md-icons/history.svg",
  HistoryEdu: "/md-icons/history_edu.svg",
  HistoryToggleOff: "/md-icons/history_toggle_off.svg",
  Hive: "/md-icons/hive.svg",
  Hls: "/md-icons/hls.svg",
  HlsOff: "/md-icons/hls_off.svg",
  HolidayVillage: "/md-icons/holiday_village.svg",
  Home: "/md-icons/home.svg",
  HomeMax: "/md-icons/home_max.svg",
  HomeMini: "/md-icons/home_mini.svg",
  HomeRepairService: "/md-icons/home_repair_service.svg",
  HomeWork: "/md-icons/home_work.svg",
  HorizontalDistribute: "/md-icons/horizontal_distribute.svg",
  HorizontalRule: "/md-icons/horizontal_rule.svg",
  HorizontalSplit: "/md-icons/horizontal_split.svg",
  HotTub: "/md-icons/hot_tub.svg",
  Hotel: "/md-icons/hotel.svg",
  HotelClass: "/md-icons/hotel_class.svg",
  HourglassBottom: "/md-icons/hourglass_bottom.svg",
  HourglassDisabled: "/md-icons/hourglass_disabled.svg",
  HourglassEmpty: "/md-icons/hourglass_empty.svg",
  HourglassFull: "/md-icons/hourglass_full.svg",
  HourglassTop: "/md-icons/hourglass_top.svg",
  House: "/md-icons/house.svg",
  HouseSiding: "/md-icons/house_siding.svg",
  Houseboat: "/md-icons/houseboat.svg",
  HowToReg: "/md-icons/how_to_reg.svg",
  HowToVote: "/md-icons/how_to_vote.svg",
  Html: "/md-icons/html.svg",
  Http: "/md-icons/http.svg",
  Https: "/md-icons/https.svg",
  Hub: "/md-icons/hub.svg",
  Hvac: "/md-icons/hvac.svg",
  IceSkating: "/md-icons/ice_skating.svg",
  Icecream: "/md-icons/icecream.svg",
  Image: "/md-icons/image.svg",
  ImageAspectRatio: "/md-icons/image_aspect_ratio.svg",
  ImageNotSupported: "/md-icons/image_not_supported.svg",
  ImageSearch: "/md-icons/image_search.svg",
  ImagesearchRoller: "/md-icons/imagesearch_roller.svg",
  ImportContacts: "/md-icons/import_contacts.svg",
  ImportExport: "/md-icons/import_export.svg",
  ImportantDevices: "/md-icons/important_devices.svg",
  Inbox: "/md-icons/inbox.svg",
  IncompleteCircle: "/md-icons/incomplete_circle.svg",
  IndeterminateCheckBox: "/md-icons/indeterminate_check_box.svg",
  Info: "/md-icons/info.svg",
  Input: "/md-icons/input.svg",
  InsertChart: "/md-icons/insert_chart.svg",
  InsertChartOutlined: "/md-icons/insert_chart_outlined.svg",
  InsertComment: "/md-icons/insert_comment.svg",
  InsertDriveFile: "/md-icons/insert_drive_file.svg",
  InsertEmoticon: "/md-icons/insert_emoticon.svg",
  InsertInvitation: "/md-icons/insert_invitation.svg",
  InsertLink: "/md-icons/insert_link.svg",
  InsertPageBreak: "/md-icons/insert_page_break.svg",
  InsertPhoto: "/md-icons/insert_photo.svg",
  Insights: "/md-icons/insights.svg",
  InstallDesktop: "/md-icons/install_desktop.svg",
  InstallMobile: "/md-icons/install_mobile.svg",
  IntegrationInstructions: "/md-icons/integration_instructions.svg",
  Interests: "/md-icons/interests.svg",
  InterpreterMode: "/md-icons/interpreter_mode.svg",
  Inventory: "/md-icons/inventory.svg",
  Inventory2: "/md-icons/inventory_2.svg",
  InvertColors: "/md-icons/invert_colors.svg",
  InvertColorsOff: "/md-icons/invert_colors_off.svg",
  IosShare: "/md-icons/ios_share.svg",
  Iron: "/md-icons/iron.svg",
  Iso: "/md-icons/iso.svg",
  Javascript: "/md-icons/javascript.svg",
  JoinFull: "/md-icons/join_full.svg",
  JoinInner: "/md-icons/join_inner.svg",
  JoinLeft: "/md-icons/join_left.svg",
  JoinRight: "/md-icons/join_right.svg",
  Kayaking: "/md-icons/kayaking.svg",
  KebabDining: "/md-icons/kebab_dining.svg",
  Key: "/md-icons/key.svg",
  KeyOff: "/md-icons/key_off.svg",
  Keyboard: "/md-icons/keyboard.svg",
  KeyboardAlt: "/md-icons/keyboard_alt.svg",
  KeyboardArrowDown: "/md-icons/keyboard_arrow_down.svg",
  KeyboardArrowLeft: "/md-icons/keyboard_arrow_left.svg",
  KeyboardArrowRight: "/md-icons/keyboard_arrow_right.svg",
  KeyboardArrowUp: "/md-icons/keyboard_arrow_up.svg",
  KeyboardBackspace: "/md-icons/keyboard_backspace.svg",
  KeyboardCapslock: "/md-icons/keyboard_capslock.svg",
  KeyboardCommandKey: "/md-icons/keyboard_command_key.svg",
  KeyboardControlKey: "/md-icons/keyboard_control_key.svg",
  KeyboardDoubleArrowDown: "/md-icons/keyboard_double_arrow_down.svg",
  KeyboardDoubleArrowLeft: "/md-icons/keyboard_double_arrow_left.svg",
  KeyboardDoubleArrowRight: "/md-icons/keyboard_double_arrow_right.svg",
  KeyboardDoubleArrowUp: "/md-icons/keyboard_double_arrow_up.svg",
  KeyboardHide: "/md-icons/keyboard_hide.svg",
  KeyboardOptionKey: "/md-icons/keyboard_option_key.svg",
  KeyboardReturn: "/md-icons/keyboard_return.svg",
  KeyboardTab: "/md-icons/keyboard_tab.svg",
  KeyboardVoice: "/md-icons/keyboard_voice.svg",
  KingBed: "/md-icons/king_bed.svg",
  Kitchen: "/md-icons/kitchen.svg",
  Kitesurfing: "/md-icons/kitesurfing.svg",
  Label: "/md-icons/label.svg",
  LabelImportant: "/md-icons/label_important.svg",
  LabelOff: "/md-icons/label_off.svg",
  Lan: "/md-icons/lan.svg",
  Landscape: "/md-icons/landscape.svg",
  Landslide: "/md-icons/landslide.svg",
  Language: "/md-icons/language.svg",
  Laptop: "/md-icons/laptop.svg",
  LaptopChromebook: "/md-icons/laptop_chromebook.svg",
  LaptopMac: "/md-icons/laptop_mac.svg",
  LaptopWindows: "/md-icons/laptop_windows.svg",
  LastPage: "/md-icons/last_page.svg",
  Launch: "/md-icons/launch.svg",
  Layers: "/md-icons/layers.svg",
  LayersClear: "/md-icons/layers_clear.svg",
  Leaderboard: "/md-icons/leaderboard.svg",
  LeakAdd: "/md-icons/leak_add.svg",
  LeakRemove: "/md-icons/leak_remove.svg",
  LegendToggle: "/md-icons/legend_toggle.svg",
  Lens: "/md-icons/lens.svg",
  LensBlur: "/md-icons/lens_blur.svg",
  LibraryAdd: "/md-icons/library_add.svg",
  LibraryAddCheck: "/md-icons/library_add_check.svg",
  LibraryBooks: "/md-icons/library_books.svg",
  LibraryMusic: "/md-icons/library_music.svg",
  Light: "/md-icons/light.svg",
  LightMode: "/md-icons/light_mode.svg",
  Lightbulb: "/md-icons/lightbulb.svg",
  LightbulbCircle: "/md-icons/lightbulb_circle.svg",
  LineAxis: "/md-icons/line_axis.svg",
  LineStyle: "/md-icons/line_style.svg",
  LineWeight: "/md-icons/line_weight.svg",
  LinearScale: "/md-icons/linear_scale.svg",
  Link: "/md-icons/link.svg",
  LinkOff: "/md-icons/link_off.svg",
  LinkedCamera: "/md-icons/linked_camera.svg",
  Liquor: "/md-icons/liquor.svg",
  List: "/md-icons/list.svg",
  ListAlt: "/md-icons/list_alt.svg",
  LiveHelp: "/md-icons/live_help.svg",
  LiveTv: "/md-icons/live_tv.svg",
  Living: "/md-icons/living.svg",
  LocalActivity: "/md-icons/local_activity.svg",
  LocalAirport: "/md-icons/local_airport.svg",
  LocalAtm: "/md-icons/local_atm.svg",
  LocalBar: "/md-icons/local_bar.svg",
  LocalCafe: "/md-icons/local_cafe.svg",
  LocalCarWash: "/md-icons/local_car_wash.svg",
  LocalConvenienceStore: "/md-icons/local_convenience_store.svg",
  LocalDining: "/md-icons/local_dining.svg",
  LocalDrink: "/md-icons/local_drink.svg",
  LocalFireDepartment: "/md-icons/local_fire_department.svg",
  LocalFlorist: "/md-icons/local_florist.svg",
  LocalGasStation: "/md-icons/local_gas_station.svg",
  LocalGroceryStore: "/md-icons/local_grocery_store.svg",
  LocalHospital: "/md-icons/local_hospital.svg",
  LocalHotel: "/md-icons/local_hotel.svg",
  LocalLaundryService: "/md-icons/local_laundry_service.svg",
  LocalLibrary: "/md-icons/local_library.svg",
  LocalMall: "/md-icons/local_mall.svg",
  LocalMovies: "/md-icons/local_movies.svg",
  LocalOffer: "/md-icons/local_offer.svg",
  LocalParking: "/md-icons/local_parking.svg",
  LocalPharmacy: "/md-icons/local_pharmacy.svg",
  LocalPhone: "/md-icons/local_phone.svg",
  LocalPizza: "/md-icons/local_pizza.svg",
  LocalPlay: "/md-icons/local_play.svg",
  LocalPolice: "/md-icons/local_police.svg",
  LocalPostOffice: "/md-icons/local_post_office.svg",
  LocalPrintshop: "/md-icons/local_printshop.svg",
  LocalSee: "/md-icons/local_see.svg",
  LocalShipping: "/md-icons/local_shipping.svg",
  LocalTaxi: "/md-icons/local_taxi.svg",
  LocationCity: "/md-icons/location_city.svg",
  LocationDisabled: "/md-icons/location_disabled.svg",
  LocationOff: "/md-icons/location_off.svg",
  LocationOn: "/md-icons/location_on.svg",
  LocationSearching: "/md-icons/location_searching.svg",
  Lock: "/md-icons/lock.svg",
  LockClock: "/md-icons/lock_clock.svg",
  LockOpen: "/md-icons/lock_open.svg",
  LockPerson: "/md-icons/lock_person.svg",
  LockReset: "/md-icons/lock_reset.svg",
  Login: "/md-icons/login.svg",
  LogoDev: "/md-icons/logo_dev.svg",
  Logout: "/md-icons/logout.svg",
  Looks: "/md-icons/looks.svg",
  Looks3: "/md-icons/looks_3.svg",
  Looks4: "/md-icons/looks_4.svg",
  Looks5: "/md-icons/looks_5.svg",
  Looks6: "/md-icons/looks_6.svg",
  LooksOne: "/md-icons/looks_one.svg",
  LooksTwo: "/md-icons/looks_two.svg",
  Loop: "/md-icons/loop.svg",
  Loupe: "/md-icons/loupe.svg",
  LowPriority: "/md-icons/low_priority.svg",
  Loyalty: "/md-icons/loyalty.svg",
  LteMobiledata: "/md-icons/lte_mobiledata.svg",
  LtePlusMobiledata: "/md-icons/lte_plus_mobiledata.svg",
  Luggage: "/md-icons/luggage.svg",
  LunchDining: "/md-icons/lunch_dining.svg",
  Lyrics: "/md-icons/lyrics.svg",
  MacroOff: "/md-icons/macro_off.svg",
  Mail: "/md-icons/mail.svg",
  MailLock: "/md-icons/mail_lock.svg",
  MailOutline: "/md-icons/mail_outline.svg",
  Male: "/md-icons/male.svg",
  Man: "/md-icons/man.svg",
  Man2: "/md-icons/man_2.svg",
  Man3: "/md-icons/man_3.svg",
  Man4: "/md-icons/man_4.svg",
  ManageAccounts: "/md-icons/manage_accounts.svg",
  ManageHistory: "/md-icons/manage_history.svg",
  ManageSearch: "/md-icons/manage_search.svg",
  Map: "/md-icons/map.svg",
  MapsHomeWork: "/md-icons/maps_home_work.svg",
  MapsUgc: "/md-icons/maps_ugc.svg",
  Margin: "/md-icons/margin.svg",
  MarkAsUnread: "/md-icons/mark_as_unread.svg",
  MarkChatRead: "/md-icons/mark_chat_read.svg",
  MarkChatUnread: "/md-icons/mark_chat_unread.svg",
  MarkEmailRead: "/md-icons/mark_email_read.svg",
  MarkEmailUnread: "/md-icons/mark_email_unread.svg",
  MarkUnreadChatAlt: "/md-icons/mark_unread_chat_alt.svg",
  Markunread: "/md-icons/markunread.svg",
  MarkunreadMailbox: "/md-icons/markunread_mailbox.svg",
  Masks: "/md-icons/masks.svg",
  Maximize: "/md-icons/maximize.svg",
  MediaBluetoothOff: "/md-icons/media_bluetooth_off.svg",
  MediaBluetoothOn: "/md-icons/media_bluetooth_on.svg",
  Mediation: "/md-icons/mediation.svg",
  MedicalInformation: "/md-icons/medical_information.svg",
  MedicalServices: "/md-icons/medical_services.svg",
  Medication: "/md-icons/medication.svg",
  MedicationLiquid: "/md-icons/medication_liquid.svg",
  MeetingRoom: "/md-icons/meeting_room.svg",
  Memory: "/md-icons/memory.svg",
  Menu: "/md-icons/menu.svg",
  MenuBook: "/md-icons/menu_book.svg",
  MenuOpen: "/md-icons/menu_open.svg",
  Merge: "/md-icons/merge.svg",
  MergeType: "/md-icons/merge_type.svg",
  Message: "/md-icons/message.svg",
  Mic: "/md-icons/mic.svg",
  MicExternalOff: "/md-icons/mic_external_off.svg",
  MicExternalOn: "/md-icons/mic_external_on.svg",
  MicNone: "/md-icons/mic_none.svg",
  MicOff: "/md-icons/mic_off.svg",
  Microwave: "/md-icons/microwave.svg",
  MilitaryTech: "/md-icons/military_tech.svg",
  Minimize: "/md-icons/minimize.svg",
  MinorCrash: "/md-icons/minor_crash.svg",
  MiscellaneousServices: "/md-icons/miscellaneous_services.svg",
  MissedVideoCall: "/md-icons/missed_video_call.svg",
  Mms: "/md-icons/mms.svg",
  MobileFriendly: "/md-icons/mobile_friendly.svg",
  MobileOff: "/md-icons/mobile_off.svg",
  MobileScreenShare: "/md-icons/mobile_screen_share.svg",
  MobiledataOff: "/md-icons/mobiledata_off.svg",
  Mode: "/md-icons/mode.svg",
  ModeComment: "/md-icons/mode_comment.svg",
  ModeEdit: "/md-icons/mode_edit.svg",
  ModeEditOutline: "/md-icons/mode_edit_outline.svg",
  ModeFanOff: "/md-icons/mode_fan_off.svg",
  ModeNight: "/md-icons/mode_night.svg",
  ModeOfTravel: "/md-icons/mode_of_travel.svg",
  ModeStandby: "/md-icons/mode_standby.svg",
  ModelTraining: "/md-icons/model_training.svg",
  MonetizationOn: "/md-icons/monetization_on.svg",
  Money: "/md-icons/money.svg",
  MoneyOff: "/md-icons/money_off.svg",
  MoneyOffCsred: "/md-icons/money_off_csred.svg",
  Monitor: "/md-icons/monitor.svg",
  MonitorHeart: "/md-icons/monitor_heart.svg",
  MonitorWeight: "/md-icons/monitor_weight.svg",
  MonochromePhotos: "/md-icons/monochrome_photos.svg",
  Mood: "/md-icons/mood.svg",
  MoodBad: "/md-icons/mood_bad.svg",
  Moped: "/md-icons/moped.svg",
  More: "/md-icons/more.svg",
  MoreHoriz: "/md-icons/more_horiz.svg",
  MoreTime: "/md-icons/more_time.svg",
  MoreVert: "/md-icons/more_vert.svg",
  Mosque: "/md-icons/mosque.svg",
  MotionPhotosAuto: "/md-icons/motion_photos_auto.svg",
  MotionPhotosOff: "/md-icons/motion_photos_off.svg",
  MotionPhotosOn: "/md-icons/motion_photos_on.svg",
  MotionPhotosPause: "/md-icons/motion_photos_pause.svg",
  MotionPhotosPaused: "/md-icons/motion_photos_paused.svg",
  Mouse: "/md-icons/mouse.svg",
  MoveDown: "/md-icons/move_down.svg",
  MoveToInbox: "/md-icons/move_to_inbox.svg",
  MoveUp: "/md-icons/move_up.svg",
  Movie: "/md-icons/movie.svg",
  MovieCreation: "/md-icons/movie_creation.svg",
  MovieFilter: "/md-icons/movie_filter.svg",
  Moving: "/md-icons/moving.svg",
  Mp: "/md-icons/mp.svg",
  MultilineChart: "/md-icons/multiline_chart.svg",
  MultipleStop: "/md-icons/multiple_stop.svg",
  Museum: "/md-icons/museum.svg",
  MusicNote: "/md-icons/music_note.svg",
  MusicOff: "/md-icons/music_off.svg",
  MusicVideo: "/md-icons/music_video.svg",
  MyLocation: "/md-icons/my_location.svg",
  Nat: "/md-icons/nat.svg",
  Nature: "/md-icons/nature.svg",
  NaturePeople: "/md-icons/nature_people.svg",
  NavigateBefore: "/md-icons/navigate_before.svg",
  NavigateNext: "/md-icons/navigate_next.svg",
  Navigation: "/md-icons/navigation.svg",
  NearMe: "/md-icons/near_me.svg",
  NearMeDisabled: "/md-icons/near_me_disabled.svg",
  NearbyError: "/md-icons/nearby_error.svg",
  NearbyOff: "/md-icons/nearby_off.svg",
  NestCamWiredStand: "/md-icons/nest_cam_wired_stand.svg",
  NetworkCell: "/md-icons/network_cell.svg",
  NetworkCheck: "/md-icons/network_check.svg",
  NetworkLocked: "/md-icons/network_locked.svg",
  NetworkPing: "/md-icons/network_ping.svg",
  NetworkWifi: "/md-icons/network_wifi.svg",
  NetworkWifi1Bar: "/md-icons/network_wifi_1_bar.svg",
  NetworkWifi2Bar: "/md-icons/network_wifi_2_bar.svg",
  NetworkWifi3Bar: "/md-icons/network_wifi_3_bar.svg",
  NewLabel: "/md-icons/new_label.svg",
  NewReleases: "/md-icons/new_releases.svg",
  Newspaper: "/md-icons/newspaper.svg",
  NextPlan: "/md-icons/next_plan.svg",
  NextWeek: "/md-icons/next_week.svg",
  Nfc: "/md-icons/nfc.svg",
  NightShelter: "/md-icons/night_shelter.svg",
  Nightlife: "/md-icons/nightlife.svg",
  Nightlight: "/md-icons/nightlight.svg",
  NightlightRound: "/md-icons/nightlight_round.svg",
  NightsStay: "/md-icons/nights_stay.svg",
  NoAccounts: "/md-icons/no_accounts.svg",
  NoAdultContent: "/md-icons/no_adult_content.svg",
  NoBackpack: "/md-icons/no_backpack.svg",
  NoCell: "/md-icons/no_cell.svg",
  NoCrash: "/md-icons/no_crash.svg",
  NoDrinks: "/md-icons/no_drinks.svg",
  NoEncryption: "/md-icons/no_encryption.svg",
  NoEncryptionGmailerrorred: "/md-icons/no_encryption_gmailerrorred.svg",
  NoFlash: "/md-icons/no_flash.svg",
  NoFood: "/md-icons/no_food.svg",
  NoLuggage: "/md-icons/no_luggage.svg",
  NoMeals: "/md-icons/no_meals.svg",
  NoMeetingRoom: "/md-icons/no_meeting_room.svg",
  NoPhotography: "/md-icons/no_photography.svg",
  NoSim: "/md-icons/no_sim.svg",
  NoStroller: "/md-icons/no_stroller.svg",
  NoTransfer: "/md-icons/no_transfer.svg",
  NoiseAware: "/md-icons/noise_aware.svg",
  NoiseControlOff: "/md-icons/noise_control_off.svg",
  NordicWalking: "/md-icons/nordic_walking.svg",
  North: "/md-icons/north.svg",
  NorthEast: "/md-icons/north_east.svg",
  NorthWest: "/md-icons/north_west.svg",
  NotAccessible: "/md-icons/not_accessible.svg",
  NotInterested: "/md-icons/not_interested.svg",
  NotListedLocation: "/md-icons/not_listed_location.svg",
  NotStarted: "/md-icons/not_started.svg",
  Note: "/md-icons/note.svg",
  NoteAdd: "/md-icons/note_add.svg",
  NoteAlt: "/md-icons/note_alt.svg",
  Notes: "/md-icons/notes.svg",
  NotificationAdd: "/md-icons/notification_add.svg",
  NotificationImportant: "/md-icons/notification_important.svg",
  Notifications: "/md-icons/notifications.svg",
  NotificationsActive: "/md-icons/notifications_active.svg",
  NotificationsNone: "/md-icons/notifications_none.svg",
  NotificationsOff: "/md-icons/notifications_off.svg",
  NotificationsPaused: "/md-icons/notifications_paused.svg",
  Numbers: "/md-icons/numbers.svg",
  OfflineBolt: "/md-icons/offline_bolt.svg",
  OfflinePin: "/md-icons/offline_pin.svg",
  OfflineShare: "/md-icons/offline_share.svg",
  OilBarrel: "/md-icons/oil_barrel.svg",
  OnDeviceTraining: "/md-icons/on_device_training.svg",
  OndemandVideo: "/md-icons/ondemand_video.svg",
  OnlinePrediction: "/md-icons/online_prediction.svg",
  Opacity: "/md-icons/opacity.svg",
  OpenInBrowser: "/md-icons/open_in_browser.svg",
  OpenInFull: "/md-icons/open_in_full.svg",
  OpenInNew: "/md-icons/open_in_new.svg",
  OpenInNewOff: "/md-icons/open_in_new_off.svg",
  OpenWith: "/md-icons/open_with.svg",
  OtherHouses: "/md-icons/other_houses.svg",
  Outbound: "/md-icons/outbound.svg",
  Outbox: "/md-icons/outbox.svg",
  OutdoorGrill: "/md-icons/outdoor_grill.svg",
  Outlet: "/md-icons/outlet.svg",
  OutlinedFlag: "/md-icons/outlined_flag.svg",
  Output: "/md-icons/output.svg",
  Padding: "/md-icons/padding.svg",
  Pages: "/md-icons/pages.svg",
  Pageview: "/md-icons/pageview.svg",
  Paid: "/md-icons/paid.svg",
  Palette: "/md-icons/palette.svg",
  PanTool: "/md-icons/pan_tool.svg",
  PanToolAlt: "/md-icons/pan_tool_alt.svg",
  Panorama: "/md-icons/panorama.svg",
  PanoramaFishEye: "/md-icons/panorama_fish_eye.svg",
  PanoramaHorizontal: "/md-icons/panorama_horizontal.svg",
  PanoramaHorizontalSelect: "/md-icons/panorama_horizontal_select.svg",
  PanoramaPhotosphere: "/md-icons/panorama_photosphere.svg",
  PanoramaPhotosphereSelect: "/md-icons/panorama_photosphere_select.svg",
  PanoramaVertical: "/md-icons/panorama_vertical.svg",
  PanoramaVerticalSelect: "/md-icons/panorama_vertical_select.svg",
  PanoramaWideAngle: "/md-icons/panorama_wide_angle.svg",
  PanoramaWideAngleSelect: "/md-icons/panorama_wide_angle_select.svg",
  Paragliding: "/md-icons/paragliding.svg",
  Park: "/md-icons/park.svg",
  PartyMode: "/md-icons/party_mode.svg",
  Password: "/md-icons/password.svg",
  Pattern: "/md-icons/pattern.svg",
  Pause: "/md-icons/pause.svg",
  PauseCircle: "/md-icons/pause_circle.svg",
  PauseCircleFilled: "/md-icons/pause_circle_filled.svg",
  PauseCircleOutline: "/md-icons/pause_circle_outline.svg",
  PausePresentation: "/md-icons/pause_presentation.svg",
  Payment: "/md-icons/payment.svg",
  Payments: "/md-icons/payments.svg",
  PedalBike: "/md-icons/pedal_bike.svg",
  Pending: "/md-icons/pending.svg",
  PendingActions: "/md-icons/pending_actions.svg",
  Pentagon: "/md-icons/pentagon.svg",
  People: "/md-icons/people.svg",
  PeopleAlt: "/md-icons/people_alt.svg",
  PeopleOutline: "/md-icons/people_outline.svg",
  Percent: "/md-icons/percent.svg",
  PermCameraMic: "/md-icons/perm_camera_mic.svg",
  PermContactCalendar: "/md-icons/perm_contact_calendar.svg",
  PermDataSetting: "/md-icons/perm_data_setting.svg",
  PermDeviceInformation: "/md-icons/perm_device_information.svg",
  PermIdentity: "/md-icons/perm_identity.svg",
  PermMedia: "/md-icons/perm_media.svg",
  PermPhoneMsg: "/md-icons/perm_phone_msg.svg",
  PermScanWifi: "/md-icons/perm_scan_wifi.svg",
  Person: "/md-icons/person.svg",
  Person2: "/md-icons/person_2.svg",
  Person3: "/md-icons/person_3.svg",
  Person4: "/md-icons/person_4.svg",
  PersonAdd: "/md-icons/person_add.svg",
  PersonAddAlt: "/md-icons/person_add_alt.svg",
  PersonAddAlt1: "/md-icons/person_add_alt_1.svg",
  PersonAddDisabled: "/md-icons/person_add_disabled.svg",
  PersonOff: "/md-icons/person_off.svg",
  PersonOutline: "/md-icons/person_outline.svg",
  PersonPin: "/md-icons/person_pin.svg",
  PersonPinCircle: "/md-icons/person_pin_circle.svg",
  PersonRemove: "/md-icons/person_remove.svg",
  PersonRemoveAlt1: "/md-icons/person_remove_alt_1.svg",
  PersonSearch: "/md-icons/person_search.svg",
  PersonalInjury: "/md-icons/personal_injury.svg",
  PersonalVideo: "/md-icons/personal_video.svg",
  PestControl: "/md-icons/pest_control.svg",
  PestControlRodent: "/md-icons/pest_control_rodent.svg",
  Pets: "/md-icons/pets.svg",
  Phishing: "/md-icons/phishing.svg",
  Phone: "/md-icons/phone.svg",
  PhoneAndroid: "/md-icons/phone_android.svg",
  PhoneBluetoothSpeaker: "/md-icons/phone_bluetooth_speaker.svg",
  PhoneCallback: "/md-icons/phone_callback.svg",
  PhoneDisabled: "/md-icons/phone_disabled.svg",
  PhoneEnabled: "/md-icons/phone_enabled.svg",
  PhoneForwarded: "/md-icons/phone_forwarded.svg",
  PhoneIphone: "/md-icons/phone_iphone.svg",
  PhoneLocked: "/md-icons/phone_locked.svg",
  PhoneMissed: "/md-icons/phone_missed.svg",
  PhonePaused: "/md-icons/phone_paused.svg",
  Phonelink: "/md-icons/phonelink.svg",
  PhonelinkErase: "/md-icons/phonelink_erase.svg",
  PhonelinkLock: "/md-icons/phonelink_lock.svg",
  PhonelinkOff: "/md-icons/phonelink_off.svg",
  PhonelinkRing: "/md-icons/phonelink_ring.svg",
  PhonelinkSetup: "/md-icons/phonelink_setup.svg",
  Photo: "/md-icons/photo.svg",
  PhotoAlbum: "/md-icons/photo_album.svg",
  PhotoCamera: "/md-icons/photo_camera.svg",
  PhotoCameraBack: "/md-icons/photo_camera_back.svg",
  PhotoCameraFront: "/md-icons/photo_camera_front.svg",
  PhotoFilter: "/md-icons/photo_filter.svg",
  PhotoLibrary: "/md-icons/photo_library.svg",
  PhotoSizeSelectActual: "/md-icons/photo_size_select_actual.svg",
  PhotoSizeSelectLarge: "/md-icons/photo_size_select_large.svg",
  PhotoSizeSelectSmall: "/md-icons/photo_size_select_small.svg",
  Php: "/md-icons/php.svg",
  Piano: "/md-icons/piano.svg",
  PianoOff: "/md-icons/piano_off.svg",
  PictureAsPdf: "/md-icons/picture_as_pdf.svg",
  PictureInPicture: "/md-icons/picture_in_picture.svg",
  PictureInPictureAlt: "/md-icons/picture_in_picture_alt.svg",
  PieChart: "/md-icons/pie_chart.svg",
  PieChartOutline: "/md-icons/pie_chart_outline.svg",
  Pin: "/md-icons/pin.svg",
  PinDrop: "/md-icons/pin_drop.svg",
  PinEnd: "/md-icons/pin_end.svg",
  PinInvoke: "/md-icons/pin_invoke.svg",
  Pinch: "/md-icons/pinch.svg",
  PivotTableChart: "/md-icons/pivot_table_chart.svg",
  Pix: "/md-icons/pix.svg",
  Place: "/md-icons/place.svg",
  Plagiarism: "/md-icons/plagiarism.svg",
  PlayArrow: "/md-icons/play_arrow.svg",
  PlayCircle: "/md-icons/play_circle.svg",
  PlayCircleFilled: "/md-icons/play_circle_filled.svg",
  PlayCircleOutline: "/md-icons/play_circle_outline.svg",
  PlayDisabled: "/md-icons/play_disabled.svg",
  PlayForWork: "/md-icons/play_for_work.svg",
  PlayLesson: "/md-icons/play_lesson.svg",
  PlaylistAdd: "/md-icons/playlist_add.svg",
  PlaylistAddCheck: "/md-icons/playlist_add_check.svg",
  PlaylistAddCheckCircle: "/md-icons/playlist_add_check_circle.svg",
  PlaylistAddCircle: "/md-icons/playlist_add_circle.svg",
  PlaylistPlay: "/md-icons/playlist_play.svg",
  PlaylistRemove: "/md-icons/playlist_remove.svg",
  Plumbing: "/md-icons/plumbing.svg",
  PlusOne: "/md-icons/plus_one.svg",
  Podcasts: "/md-icons/podcasts.svg",
  PointOfSale: "/md-icons/point_of_sale.svg",
  Policy: "/md-icons/policy.svg",
  Poll: "/md-icons/poll.svg",
  Polyline: "/md-icons/polyline.svg",
  Polymer: "/md-icons/polymer.svg",
  Pool: "/md-icons/pool.svg",
  PortableWifiOff: "/md-icons/portable_wifi_off.svg",
  Portrait: "/md-icons/portrait.svg",
  PostAdd: "/md-icons/post_add.svg",
  Power: "/md-icons/power.svg",
  PowerInput: "/md-icons/power_input.svg",
  PowerOff: "/md-icons/power_off.svg",
  PowerSettingsNew: "/md-icons/power_settings_new.svg",
  PrecisionManufacturing: "/md-icons/precision_manufacturing.svg",
  PregnantWoman: "/md-icons/pregnant_woman.svg",
  PresentToAll: "/md-icons/present_to_all.svg",
  Preview: "/md-icons/preview.svg",
  PriceChange: "/md-icons/price_change.svg",
  PriceCheck: "/md-icons/price_check.svg",
  Print: "/md-icons/print.svg",
  PrintDisabled: "/md-icons/print_disabled.svg",
  PriorityHigh: "/md-icons/priority_high.svg",
  PrivacyTip: "/md-icons/privacy_tip.svg",
  PrivateConnectivity: "/md-icons/private_connectivity.svg",
  ProductionQuantityLimits: "/md-icons/production_quantity_limits.svg",
  Propane: "/md-icons/propane.svg",
  PropaneTank: "/md-icons/propane_tank.svg",
  Psychology: "/md-icons/psychology.svg",
  PsychologyAlt: "/md-icons/psychology_alt.svg",
  Public: "/md-icons/public.svg",
  PublicOff: "/md-icons/public_off.svg",
  Publish: "/md-icons/publish.svg",
  PublishedWithChanges: "/md-icons/published_with_changes.svg",
  PunchClock: "/md-icons/punch_clock.svg",
  PushPin: "/md-icons/push_pin.svg",
  QrCode: "/md-icons/qr_code.svg",
  QrCode2: "/md-icons/qr_code_2.svg",
  QrCodeScanner: "/md-icons/qr_code_scanner.svg",
  QueryBuilder: "/md-icons/query_builder.svg",
  QueryStats: "/md-icons/query_stats.svg",
  QuestionAnswer: "/md-icons/question_answer.svg",
  QuestionMark: "/md-icons/question_mark.svg",
  Queue: "/md-icons/queue.svg",
  QueueMusic: "/md-icons/queue_music.svg",
  QueuePlayNext: "/md-icons/queue_play_next.svg",
  Quickreply: "/md-icons/quickreply.svg",
  Quiz: "/md-icons/quiz.svg",
  RMobiledata: "/md-icons/r_mobiledata.svg",
  Radar: "/md-icons/radar.svg",
  Radio: "/md-icons/radio.svg",
  RadioButtonChecked: "/md-icons/radio_button_checked.svg",
  RadioButtonUnchecked: "/md-icons/radio_button_unchecked.svg",
  RailwayAlert: "/md-icons/railway_alert.svg",
  RamenDining: "/md-icons/ramen_dining.svg",
  RampLeft: "/md-icons/ramp_left.svg",
  RampRight: "/md-icons/ramp_right.svg",
  RateReview: "/md-icons/rate_review.svg",
  RawOff: "/md-icons/raw_off.svg",
  RawOn: "/md-icons/raw_on.svg",
  ReadMore: "/md-icons/read_more.svg",
  RealEstateAgent: "/md-icons/real_estate_agent.svg",
  Receipt: "/md-icons/receipt.svg",
  ReceiptLong: "/md-icons/receipt_long.svg",
  RecentActors: "/md-icons/recent_actors.svg",
  Recommend: "/md-icons/recommend.svg",
  RecordVoiceOver: "/md-icons/record_voice_over.svg",
  Rectangle: "/md-icons/rectangle.svg",
  Recycling: "/md-icons/recycling.svg",
  Redeem: "/md-icons/redeem.svg",
  Redo: "/md-icons/redo.svg",
  ReduceCapacity: "/md-icons/reduce_capacity.svg",
  Refresh: "/md-icons/refresh.svg",
  RememberMe: "/md-icons/remember_me.svg",
  Remove: "/md-icons/remove.svg",
  RemoveCircle: "/md-icons/remove_circle.svg",
  RemoveCircleOutline: "/md-icons/remove_circle_outline.svg",
  RemoveDone: "/md-icons/remove_done.svg",
  RemoveFromQueue: "/md-icons/remove_from_queue.svg",
  RemoveModerator: "/md-icons/remove_moderator.svg",
  RemoveRedEye: "/md-icons/remove_red_eye.svg",
  RemoveRoad: "/md-icons/remove_road.svg",
  RemoveShoppingCart: "/md-icons/remove_shopping_cart.svg",
  Reorder: "/md-icons/reorder.svg",
  Repartition: "/md-icons/repartition.svg",
  Repeat: "/md-icons/repeat.svg",
  RepeatOn: "/md-icons/repeat_on.svg",
  RepeatOne: "/md-icons/repeat_one.svg",
  RepeatOneOn: "/md-icons/repeat_one_on.svg",
  Replay: "/md-icons/replay.svg",
  Replay10: "/md-icons/replay_10.svg",
  Replay30: "/md-icons/replay_30.svg",
  Replay5: "/md-icons/replay_5.svg",
  ReplayCircleFilled: "/md-icons/replay_circle_filled.svg",
  Reply: "/md-icons/reply.svg",
  ReplyAll: "/md-icons/reply_all.svg",
  Report: "/md-icons/report.svg",
  ReportGmailerrorred: "/md-icons/report_gmailerrorred.svg",
  ReportOff: "/md-icons/report_off.svg",
  ReportProblem: "/md-icons/report_problem.svg",
  RequestPage: "/md-icons/request_page.svg",
  RequestQuote: "/md-icons/request_quote.svg",
  ResetTv: "/md-icons/reset_tv.svg",
  RestartAlt: "/md-icons/restart_alt.svg",
  Restaurant: "/md-icons/restaurant.svg",
  RestaurantMenu: "/md-icons/restaurant_menu.svg",
  Restore: "/md-icons/restore.svg",
  RestoreFromTrash: "/md-icons/restore_from_trash.svg",
  RestorePage: "/md-icons/restore_page.svg",
  Reviews: "/md-icons/reviews.svg",
  RiceBowl: "/md-icons/rice_bowl.svg",
  RingVolume: "/md-icons/ring_volume.svg",
  Rocket: "/md-icons/rocket.svg",
  RocketLaunch: "/md-icons/rocket_launch.svg",
  RollerShades: "/md-icons/roller_shades.svg",
  RollerShadesClosed: "/md-icons/roller_shades_closed.svg",
  RollerSkating: "/md-icons/roller_skating.svg",
  Roofing: "/md-icons/roofing.svg",
  Room: "/md-icons/room.svg",
  RoomPreferences: "/md-icons/room_preferences.svg",
  RoomService: "/md-icons/room_service.svg",
  Rotate90DegreesCcw: "/md-icons/rotate_90_degrees_ccw.svg",
  Rotate90DegreesCw: "/md-icons/rotate_90_degrees_cw.svg",
  RotateLeft: "/md-icons/rotate_left.svg",
  RotateRight: "/md-icons/rotate_right.svg",
  RoundaboutLeft: "/md-icons/roundabout_left.svg",
  RoundaboutRight: "/md-icons/roundabout_right.svg",
  RoundedCorner: "/md-icons/rounded_corner.svg",
  Route: "/md-icons/route.svg",
  Router: "/md-icons/router.svg",
  Rowing: "/md-icons/rowing.svg",
  RssFeed: "/md-icons/rss_feed.svg",
  Rsvp: "/md-icons/rsvp.svg",
  Rtt: "/md-icons/rtt.svg",
  Rule: "/md-icons/rule.svg",
  RuleFolder: "/md-icons/rule_folder.svg",
  RunCircle: "/md-icons/run_circle.svg",
  RunningWithErrors: "/md-icons/running_with_errors.svg",
  RvHookup: "/md-icons/rv_hookup.svg",
  SafetyCheck: "/md-icons/safety_check.svg",
  SafetyDivider: "/md-icons/safety_divider.svg",
  Sailing: "/md-icons/sailing.svg",
  Sanitizer: "/md-icons/sanitizer.svg",
  Satellite: "/md-icons/satellite.svg",
  SatelliteAlt: "/md-icons/satellite_alt.svg",
  Save: "/md-icons/save.svg",
  SaveAlt: "/md-icons/save_alt.svg",
  SaveAs: "/md-icons/save_as.svg",
  SavedSearch: "/md-icons/saved_search.svg",
  Savings: "/md-icons/savings.svg",
  Scale: "/md-icons/scale.svg",
  Scanner: "/md-icons/scanner.svg",
  ScatterPlot: "/md-icons/scatter_plot.svg",
  Schedule: "/md-icons/schedule.svg",
  ScheduleSend: "/md-icons/schedule_send.svg",
  Schema: "/md-icons/schema.svg",
  School: "/md-icons/school.svg",
  Science: "/md-icons/science.svg",
  Score: "/md-icons/score.svg",
  Scoreboard: "/md-icons/scoreboard.svg",
  ScreenLockLandscape: "/md-icons/screen_lock_landscape.svg",
  ScreenLockPortrait: "/md-icons/screen_lock_portrait.svg",
  ScreenLockRotation: "/md-icons/screen_lock_rotation.svg",
  ScreenRotation: "/md-icons/screen_rotation.svg",
  ScreenRotationAlt: "/md-icons/screen_rotation_alt.svg",
  ScreenSearchDesktop: "/md-icons/screen_search_desktop.svg",
  ScreenShare: "/md-icons/screen_share.svg",
  Screenshot: "/md-icons/screenshot.svg",
  ScreenshotMonitor: "/md-icons/screenshot_monitor.svg",
  ScubaDiving: "/md-icons/scuba_diving.svg",
  Sd: "/md-icons/sd.svg",
  SdCard: "/md-icons/sd_card.svg",
  SdCardAlert: "/md-icons/sd_card_alert.svg",
  SdStorage: "/md-icons/sd_storage.svg",
  Search: "/md-icons/search.svg",
  SearchOff: "/md-icons/search_off.svg",
  Security: "/md-icons/security.svg",
  SecurityUpdate: "/md-icons/security_update.svg",
  SecurityUpdateGood: "/md-icons/security_update_good.svg",
  SecurityUpdateWarning: "/md-icons/security_update_warning.svg",
  Segment: "/md-icons/segment.svg",
  SelectAll: "/md-icons/select_all.svg",
  SelfImprovement: "/md-icons/self_improvement.svg",
  Sell: "/md-icons/sell.svg",
  Send: "/md-icons/send.svg",
  SendAndArchive: "/md-icons/send_and_archive.svg",
  SendTimeExtension: "/md-icons/send_time_extension.svg",
  SendToMobile: "/md-icons/send_to_mobile.svg",
  SensorDoor: "/md-icons/sensor_door.svg",
  SensorOccupied: "/md-icons/sensor_occupied.svg",
  SensorWindow: "/md-icons/sensor_window.svg",
  Sensors: "/md-icons/sensors.svg",
  SensorsOff: "/md-icons/sensors_off.svg",
  SentimentDissatisfied: "/md-icons/sentiment_dissatisfied.svg",
  SentimentNeutral: "/md-icons/sentiment_neutral.svg",
  SentimentSatisfied: "/md-icons/sentiment_satisfied.svg",
  SentimentSatisfiedAlt: "/md-icons/sentiment_satisfied_alt.svg",
  SentimentVeryDissatisfied: "/md-icons/sentiment_very_dissatisfied.svg",
  SentimentVerySatisfied: "/md-icons/sentiment_very_satisfied.svg",
  SetMeal: "/md-icons/set_meal.svg",
  Settings: "/md-icons/settings.svg",
  SettingsAccessibility: "/md-icons/settings_accessibility.svg",
  SettingsApplications: "/md-icons/settings_applications.svg",
  SettingsBackupRestore: "/md-icons/settings_backup_restore.svg",
  SettingsBluetooth: "/md-icons/settings_bluetooth.svg",
  SettingsBrightness: "/md-icons/settings_brightness.svg",
  SettingsCell: "/md-icons/settings_cell.svg",
  SettingsEthernet: "/md-icons/settings_ethernet.svg",
  SettingsInputAntenna: "/md-icons/settings_input_antenna.svg",
  SettingsInputComponent: "/md-icons/settings_input_component.svg",
  SettingsInputComposite: "/md-icons/settings_input_composite.svg",
  SettingsInputHdmi: "/md-icons/settings_input_hdmi.svg",
  SettingsInputSvideo: "/md-icons/settings_input_svideo.svg",
  SettingsOverscan: "/md-icons/settings_overscan.svg",
  SettingsPhone: "/md-icons/settings_phone.svg",
  SettingsPower: "/md-icons/settings_power.svg",
  SettingsRemote: "/md-icons/settings_remote.svg",
  SettingsSuggest: "/md-icons/settings_suggest.svg",
  SettingsSystemDaydream: "/md-icons/settings_system_daydream.svg",
  SettingsVoice: "/md-icons/settings_voice.svg",
  SevereCold: "/md-icons/severe_cold.svg",
  ShapeLine: "/md-icons/shape_line.svg",
  Share: "/md-icons/share.svg",
  ShareLocation: "/md-icons/share_location.svg",
  Shield: "/md-icons/shield.svg",
  ShieldMoon: "/md-icons/shield_moon.svg",
  Shop: "/md-icons/shop.svg",
  Shop2: "/md-icons/shop_2.svg",
  ShopTwo: "/md-icons/shop_two.svg",
  ShoppingBag: "/md-icons/shopping_bag.svg",
  ShoppingBasket: "/md-icons/shopping_basket.svg",
  ShoppingCart: "/md-icons/shopping_cart.svg",
  ShoppingCartCheckout: "/md-icons/shopping_cart_checkout.svg",
  ShortText: "/md-icons/short_text.svg",
  Shortcut: "/md-icons/shortcut.svg",
  ShowChart: "/md-icons/show_chart.svg",
  Shower: "/md-icons/shower.svg",
  Shuffle: "/md-icons/shuffle.svg",
  ShuffleOn: "/md-icons/shuffle_on.svg",
  ShutterSpeed: "/md-icons/shutter_speed.svg",
  Sick: "/md-icons/sick.svg",
  SignLanguage: "/md-icons/sign_language.svg",
  SignalCellular0Bar: "/md-icons/signal_cellular_0_bar.svg",
  SignalCellular4Bar: "/md-icons/signal_cellular_4_bar.svg",
  SignalCellularAlt: "/md-icons/signal_cellular_alt.svg",
  SignalCellularAlt1Bar: "/md-icons/signal_cellular_alt_1_bar.svg",
  SignalCellularAlt2Bar: "/md-icons/signal_cellular_alt_2_bar.svg",
  SignalCellularConnectedNoInternet0Bar: "/md-icons/signal_cellular_connected_no_internet_0_bar.svg",
  SignalCellularConnectedNoInternet4Bar: "/md-icons/signal_cellular_connected_no_internet_4_bar.svg",
  SignalCellularNoSim: "/md-icons/signal_cellular_no_sim.svg",
  SignalCellularNodata: "/md-icons/signal_cellular_nodata.svg",
  SignalCellularNull: "/md-icons/signal_cellular_null.svg",
  SignalCellularOff: "/md-icons/signal_cellular_off.svg",
  SignalWifi0Bar: "/md-icons/signal_wifi_0_bar.svg",
  SignalWifi4Bar: "/md-icons/signal_wifi_4_bar.svg",
  SignalWifi4BarLock: "/md-icons/signal_wifi_4_bar_lock.svg",
  SignalWifiBad: "/md-icons/signal_wifi_bad.svg",
  SignalWifiConnectedNoInternet4: "/md-icons/signal_wifi_connected_no_internet_4.svg",
  SignalWifiOff: "/md-icons/signal_wifi_off.svg",
  SignalWifiStatusbar4Bar: "/md-icons/signal_wifi_statusbar_4_bar.svg",
  SignalWifiStatusbarConnectedNoInternet4: "/md-icons/signal_wifi_statusbar_connected_no_internet_4.svg",
  SignalWifiStatusbarNull: "/md-icons/signal_wifi_statusbar_null.svg",
  Signpost: "/md-icons/signpost.svg",
  SimCard: "/md-icons/sim_card.svg",
  SimCardAlert: "/md-icons/sim_card_alert.svg",
  SimCardDownload: "/md-icons/sim_card_download.svg",
  SingleBed: "/md-icons/single_bed.svg",
  Sip: "/md-icons/sip.svg",
  Skateboarding: "/md-icons/skateboarding.svg",
  SkipNext: "/md-icons/skip_next.svg",
  SkipPrevious: "/md-icons/skip_previous.svg",
  Sledding: "/md-icons/sledding.svg",
  Slideshow: "/md-icons/slideshow.svg",
  SlowMotionVideo: "/md-icons/slow_motion_video.svg",
  SmartButton: "/md-icons/smart_button.svg",
  SmartDisplay: "/md-icons/smart_display.svg",
  SmartScreen: "/md-icons/smart_screen.svg",
  SmartToy: "/md-icons/smart_toy.svg",
  Smartphone: "/md-icons/smartphone.svg",
  SmokeFree: "/md-icons/smoke_free.svg",
  SmokingRooms: "/md-icons/smoking_rooms.svg",
  Sms: "/md-icons/sms.svg",
  SmsFailed: "/md-icons/sms_failed.svg",
  SnippetFolder: "/md-icons/snippet_folder.svg",
  Snooze: "/md-icons/snooze.svg",
  Snowboarding: "/md-icons/snowboarding.svg",
  Snowmobile: "/md-icons/snowmobile.svg",
  Snowshoeing: "/md-icons/snowshoeing.svg",
  Soap: "/md-icons/soap.svg",
  SocialDistance: "/md-icons/social_distance.svg",
  SolarPower: "/md-icons/solar_power.svg",
  Sort: "/md-icons/sort.svg",
  SortByAlpha: "/md-icons/sort_by_alpha.svg",
  Sos: "/md-icons/sos.svg",
  SoupKitchen: "/md-icons/soup_kitchen.svg",
  Source: "/md-icons/source.svg",
  South: "/md-icons/south.svg",
  SouthAmerica: "/md-icons/south_america.svg",
  SouthEast: "/md-icons/south_east.svg",
  SouthWest: "/md-icons/south_west.svg",
  Spa: "/md-icons/spa.svg",
  SpaceBar: "/md-icons/space_bar.svg",
  SpaceDashboard: "/md-icons/space_dashboard.svg",
  SpatialAudio: "/md-icons/spatial_audio.svg",
  SpatialAudioOff: "/md-icons/spatial_audio_off.svg",
  SpatialTracking: "/md-icons/spatial_tracking.svg",
  Speaker: "/md-icons/speaker.svg",
  SpeakerGroup: "/md-icons/speaker_group.svg",
  SpeakerNotes: "/md-icons/speaker_notes.svg",
  SpeakerNotesOff: "/md-icons/speaker_notes_off.svg",
  SpeakerPhone: "/md-icons/speaker_phone.svg",
  Speed: "/md-icons/speed.svg",
  Spellcheck: "/md-icons/spellcheck.svg",
  Splitscreen: "/md-icons/splitscreen.svg",
  Spoke: "/md-icons/spoke.svg",
  Sports: "/md-icons/sports.svg",
  SportsBar: "/md-icons/sports_bar.svg",
  SportsBaseball: "/md-icons/sports_baseball.svg",
  SportsBasketball: "/md-icons/sports_basketball.svg",
  SportsCricket: "/md-icons/sports_cricket.svg",
  SportsEsports: "/md-icons/sports_esports.svg",
  SportsFootball: "/md-icons/sports_football.svg",
  SportsGolf: "/md-icons/sports_golf.svg",
  SportsGymnastics: "/md-icons/sports_gymnastics.svg",
  SportsHandball: "/md-icons/sports_handball.svg",
  SportsHockey: "/md-icons/sports_hockey.svg",
  SportsKabaddi: "/md-icons/sports_kabaddi.svg",
  SportsMartialArts: "/md-icons/sports_martial_arts.svg",
  SportsMma: "/md-icons/sports_mma.svg",
  SportsMotorsports: "/md-icons/sports_motorsports.svg",
  SportsRugby: "/md-icons/sports_rugby.svg",
  SportsScore: "/md-icons/sports_score.svg",
  SportsSoccer: "/md-icons/sports_soccer.svg",
  SportsTennis: "/md-icons/sports_tennis.svg",
  SportsVolleyball: "/md-icons/sports_volleyball.svg",
  Square: "/md-icons/square.svg",
  SquareFoot: "/md-icons/square_foot.svg",
  SsidChart: "/md-icons/ssid_chart.svg",
  StackedBarChart: "/md-icons/stacked_bar_chart.svg",
  StackedLineChart: "/md-icons/stacked_line_chart.svg",
  Stadium: "/md-icons/stadium.svg",
  Stairs: "/md-icons/stairs.svg",
  Star: "/md-icons/star.svg",
  StarBorder: "/md-icons/star_border.svg",
  StarBorderPurple500: "/md-icons/star_border_purple500.svg",
  StarHalf: "/md-icons/star_half.svg",
  StarOutline: "/md-icons/star_outline.svg",
  StarPurple500: "/md-icons/star_purple500.svg",
  StarRate: "/md-icons/star_rate.svg",
  Stars: "/md-icons/stars.svg",
  Start: "/md-icons/start.svg",
  StayCurrentLandscape: "/md-icons/stay_current_landscape.svg",
  StayCurrentPortrait: "/md-icons/stay_current_portrait.svg",
  StayPrimaryLandscape: "/md-icons/stay_primary_landscape.svg",
  StayPrimaryPortrait: "/md-icons/stay_primary_portrait.svg",
  StickyNote2: "/md-icons/sticky_note_2.svg",
  Stop: "/md-icons/stop.svg",
  StopCircle: "/md-icons/stop_circle.svg",
  StopScreenShare: "/md-icons/stop_screen_share.svg",
  Storage: "/md-icons/storage.svg",
  Store: "/md-icons/store.svg",
  StoreMallDirectory: "/md-icons/store_mall_directory.svg",
  Storefront: "/md-icons/storefront.svg",
  Storm: "/md-icons/storm.svg",
  Straight: "/md-icons/straight.svg",
  Straighten: "/md-icons/straighten.svg",
  Stream: "/md-icons/stream.svg",
  Streetview: "/md-icons/streetview.svg",
  StrikethroughS: "/md-icons/strikethrough_s.svg",
  Stroller: "/md-icons/stroller.svg",
  Style: "/md-icons/style.svg",
  SubdirectoryArrowLeft: "/md-icons/subdirectory_arrow_left.svg",
  SubdirectoryArrowRight: "/md-icons/subdirectory_arrow_right.svg",
  Subject: "/md-icons/subject.svg",
  Subscript: "/md-icons/subscript.svg",
  Subscriptions: "/md-icons/subscriptions.svg",
  Subtitles: "/md-icons/subtitles.svg",
  SubtitlesOff: "/md-icons/subtitles_off.svg",
  Subway: "/md-icons/subway.svg",
  Summarize: "/md-icons/summarize.svg",
  Superscript: "/md-icons/superscript.svg",
  SupervisedUserCircle: "/md-icons/supervised_user_circle.svg",
  SupervisorAccount: "/md-icons/supervisor_account.svg",
  Support: "/md-icons/support.svg",
  SupportAgent: "/md-icons/support_agent.svg",
  Surfing: "/md-icons/surfing.svg",
  SurroundSound: "/md-icons/surround_sound.svg",
  SwapCalls: "/md-icons/swap_calls.svg",
  SwapHoriz: "/md-icons/swap_horiz.svg",
  SwapHorizontalCircle: "/md-icons/swap_horizontal_circle.svg",
  SwapVert: "/md-icons/swap_vert.svg",
  SwapVerticalCircle: "/md-icons/swap_vertical_circle.svg",
  Swipe: "/md-icons/swipe.svg",
  SwipeDown: "/md-icons/swipe_down.svg",
  SwipeDownAlt: "/md-icons/swipe_down_alt.svg",
  SwipeLeft: "/md-icons/swipe_left.svg",
  SwipeLeftAlt: "/md-icons/swipe_left_alt.svg",
  SwipeRight: "/md-icons/swipe_right.svg",
  SwipeRightAlt: "/md-icons/swipe_right_alt.svg",
  SwipeUp: "/md-icons/swipe_up.svg",
  SwipeUpAlt: "/md-icons/swipe_up_alt.svg",
  SwipeVertical: "/md-icons/swipe_vertical.svg",
  SwitchAccessShortcut: "/md-icons/switch_access_shortcut.svg",
  SwitchAccessShortcutAdd: "/md-icons/switch_access_shortcut_add.svg",
  SwitchAccount: "/md-icons/switch_account.svg",
  SwitchCamera: "/md-icons/switch_camera.svg",
  SwitchLeft: "/md-icons/switch_left.svg",
  SwitchRight: "/md-icons/switch_right.svg",
  SwitchVideo: "/md-icons/switch_video.svg",
  Synagogue: "/md-icons/synagogue.svg",
  Sync: "/md-icons/sync.svg",
  SyncAlt: "/md-icons/sync_alt.svg",
  SyncDisabled: "/md-icons/sync_disabled.svg",
  SyncLock: "/md-icons/sync_lock.svg",
  SyncProblem: "/md-icons/sync_problem.svg",
  SystemSecurityUpdate: "/md-icons/system_security_update.svg",
  SystemSecurityUpdateGood: "/md-icons/system_security_update_good.svg",
  SystemSecurityUpdateWarning: "/md-icons/system_security_update_warning.svg",
  SystemUpdate: "/md-icons/system_update.svg",
  SystemUpdateAlt: "/md-icons/system_update_alt.svg",
  Tab: "/md-icons/tab.svg",
  TabUnselected: "/md-icons/tab_unselected.svg",
  TableBar: "/md-icons/table_bar.svg",
  TableChart: "/md-icons/table_chart.svg",
  TableRestaurant: "/md-icons/table_restaurant.svg",
  TableRows: "/md-icons/table_rows.svg",
  TableView: "/md-icons/table_view.svg",
  Tablet: "/md-icons/tablet.svg",
  TabletAndroid: "/md-icons/tablet_android.svg",
  TabletMac: "/md-icons/tablet_mac.svg",
  Tag: "/md-icons/tag.svg",
  TagFaces: "/md-icons/tag_faces.svg",
  TakeoutDining: "/md-icons/takeout_dining.svg",
  TapAndPlay: "/md-icons/tap_and_play.svg",
  Tapas: "/md-icons/tapas.svg",
  Task: "/md-icons/task.svg",
  TaskAlt: "/md-icons/task_alt.svg",
  TaxiAlert: "/md-icons/taxi_alert.svg",
  TempleBuddhist: "/md-icons/temple_buddhist.svg",
  TempleHindu: "/md-icons/temple_hindu.svg",
  Terminal: "/md-icons/terminal.svg",
  Terrain: "/md-icons/terrain.svg",
  TextDecrease: "/md-icons/text_decrease.svg",
  TextFields: "/md-icons/text_fields.svg",
  TextFormat: "/md-icons/text_format.svg",
  TextIncrease: "/md-icons/text_increase.svg",
  TextRotateUp: "/md-icons/text_rotate_up.svg",
  TextRotateVertical: "/md-icons/text_rotate_vertical.svg",
  TextRotationAngledown: "/md-icons/text_rotation_angledown.svg",
  TextRotationAngleup: "/md-icons/text_rotation_angleup.svg",
  TextRotationDown: "/md-icons/text_rotation_down.svg",
  TextRotationNone: "/md-icons/text_rotation_none.svg",
  TextSnippet: "/md-icons/text_snippet.svg",
  Textsms: "/md-icons/textsms.svg",
  Texture: "/md-icons/texture.svg",
  TheaterComedy: "/md-icons/theater_comedy.svg",
  Theaters: "/md-icons/theaters.svg",
  Thermostat: "/md-icons/thermostat.svg",
  ThermostatAuto: "/md-icons/thermostat_auto.svg",
  ThumbDown: "/md-icons/thumb_down.svg",
  ThumbDownAlt: "/md-icons/thumb_down_alt.svg",
  ThumbDownOffAlt: "/md-icons/thumb_down_off_alt.svg",
  ThumbUp: "/md-icons/thumb_up.svg",
  ThumbUpAlt: "/md-icons/thumb_up_alt.svg",
  ThumbUpOffAlt: "/md-icons/thumb_up_off_alt.svg",
  ThumbsUpDown: "/md-icons/thumbs_up_down.svg",
  Thunderstorm: "/md-icons/thunderstorm.svg",
  TimeToLeave: "/md-icons/time_to_leave.svg",
  Timelapse: "/md-icons/timelapse.svg",
  Timeline: "/md-icons/timeline.svg",
  Timer: "/md-icons/timer.svg",
  Timer10: "/md-icons/timer_10.svg",
  Timer10Select: "/md-icons/timer_10_select.svg",
  Timer3: "/md-icons/timer_3.svg",
  Timer3Select: "/md-icons/timer_3_select.svg",
  TimerOff: "/md-icons/timer_off.svg",
  TipsAndUpdates: "/md-icons/tips_and_updates.svg",
  TireRepair: "/md-icons/tire_repair.svg",
  Title: "/md-icons/title.svg",
  Toc: "/md-icons/toc.svg",
  Today: "/md-icons/today.svg",
  ToggleOff: "/md-icons/toggle_off.svg",
  ToggleOn: "/md-icons/toggle_on.svg",
  Token: "/md-icons/token.svg",
  Toll: "/md-icons/toll.svg",
  Tonality: "/md-icons/tonality.svg",
  Topic: "/md-icons/topic.svg",
  Tornado: "/md-icons/tornado.svg",
  TouchApp: "/md-icons/touch_app.svg",
  Tour: "/md-icons/tour.svg",
  Toys: "/md-icons/toys.svg",
  TrackChanges: "/md-icons/track_changes.svg",
  Traffic: "/md-icons/traffic.svg",
  Train: "/md-icons/train.svg",
  Tram: "/md-icons/tram.svg",
  Transcribe: "/md-icons/transcribe.svg",
  TransferWithinAStation: "/md-icons/transfer_within_a_station.svg",
  Transform: "/md-icons/transform.svg",
  Transgender: "/md-icons/transgender.svg",
  TransitEnterexit: "/md-icons/transit_enterexit.svg",
  Translate: "/md-icons/translate.svg",
  TravelExplore: "/md-icons/travel_explore.svg",
  TrendingDown: "/md-icons/trending_down.svg",
  TrendingFlat: "/md-icons/trending_flat.svg",
  TrendingUp: "/md-icons/trending_up.svg",
  TripOrigin: "/md-icons/trip_origin.svg",
  Troubleshoot: "/md-icons/troubleshoot.svg",
  Try: "/md-icons/try.svg",
  Tsunami: "/md-icons/tsunami.svg",
  Tty: "/md-icons/tty.svg",
  Tune: "/md-icons/tune.svg",
  Tungsten: "/md-icons/tungsten.svg",
  TurnLeft: "/md-icons/turn_left.svg",
  TurnRight: "/md-icons/turn_right.svg",
  TurnSharpLeft: "/md-icons/turn_sharp_left.svg",
  TurnSharpRight: "/md-icons/turn_sharp_right.svg",
  TurnSlightLeft: "/md-icons/turn_slight_left.svg",
  TurnSlightRight: "/md-icons/turn_slight_right.svg",
  TurnedIn: "/md-icons/turned_in.svg",
  TurnedInNot: "/md-icons/turned_in_not.svg",
  Tv: "/md-icons/tv.svg",
  TvOff: "/md-icons/tv_off.svg",
  TwoWheeler: "/md-icons/two_wheeler.svg",
  TypeSpecimen: "/md-icons/type_specimen.svg",
  UTurnLeft: "/md-icons/u_turn_left.svg",
  UTurnRight: "/md-icons/u_turn_right.svg",
  Umbrella: "/md-icons/umbrella.svg",
  Unarchive: "/md-icons/unarchive.svg",
  Undo: "/md-icons/undo.svg",
  UnfoldLess: "/md-icons/unfold_less.svg",
  UnfoldLessDouble: "/md-icons/unfold_less_double.svg",
  UnfoldMore: "/md-icons/unfold_more.svg",
  UnfoldMoreDouble: "/md-icons/unfold_more_double.svg",
  Unpublished: "/md-icons/unpublished.svg",
  Unsubscribe: "/md-icons/unsubscribe.svg",
  Upcoming: "/md-icons/upcoming.svg",
  Update: "/md-icons/update.svg",
  UpdateDisabled: "/md-icons/update_disabled.svg",
  Upgrade: "/md-icons/upgrade.svg",
  Upload: "/md-icons/upload.svg",
  UploadFile: "/md-icons/upload_file.svg",
  Usb: "/md-icons/usb.svg",
  UsbOff: "/md-icons/usb_off.svg",
  Vaccines: "/md-icons/vaccines.svg",
  VapeFree: "/md-icons/vape_free.svg",
  VapingRooms: "/md-icons/vaping_rooms.svg",
  Verified: "/md-icons/verified.svg",
  VerifiedUser: "/md-icons/verified_user.svg",
  VerticalAlignBottom: "/md-icons/vertical_align_bottom.svg",
  VerticalAlignCenter: "/md-icons/vertical_align_center.svg",
  VerticalAlignTop: "/md-icons/vertical_align_top.svg",
  VerticalDistribute: "/md-icons/vertical_distribute.svg",
  VerticalShades: "/md-icons/vertical_shades.svg",
  VerticalShadesClosed: "/md-icons/vertical_shades_closed.svg",
  VerticalSplit: "/md-icons/vertical_split.svg",
  Vibration: "/md-icons/vibration.svg",
  VideoCall: "/md-icons/video_call.svg",
  VideoCameraBack: "/md-icons/video_camera_back.svg",
  VideoCameraFront: "/md-icons/video_camera_front.svg",
  VideoChat: "/md-icons/video_chat.svg",
  VideoFile: "/md-icons/video_file.svg",
  VideoLabel: "/md-icons/video_label.svg",
  VideoLibrary: "/md-icons/video_library.svg",
  VideoSettings: "/md-icons/video_settings.svg",
  VideoStable: "/md-icons/video_stable.svg",
  Videocam: "/md-icons/videocam.svg",
  VideocamOff: "/md-icons/videocam_off.svg",
  VideogameAsset: "/md-icons/videogame_asset.svg",
  VideogameAssetOff: "/md-icons/videogame_asset_off.svg",
  ViewAgenda: "/md-icons/view_agenda.svg",
  ViewArray: "/md-icons/view_array.svg",
  ViewCarousel: "/md-icons/view_carousel.svg",
  ViewColumn: "/md-icons/view_column.svg",
  ViewComfy: "/md-icons/view_comfy.svg",
  ViewComfyAlt: "/md-icons/view_comfy_alt.svg",
  ViewCompact: "/md-icons/view_compact.svg",
  ViewCompactAlt: "/md-icons/view_compact_alt.svg",
  ViewCozy: "/md-icons/view_cozy.svg",
  ViewDay: "/md-icons/view_day.svg",
  ViewHeadline: "/md-icons/view_headline.svg",
  ViewInAr: "/md-icons/view_in_ar.svg",
  ViewKanban: "/md-icons/view_kanban.svg",
  ViewList: "/md-icons/view_list.svg",
  ViewModule: "/md-icons/view_module.svg",
  ViewQuilt: "/md-icons/view_quilt.svg",
  ViewSidebar: "/md-icons/view_sidebar.svg",
  ViewStream: "/md-icons/view_stream.svg",
  ViewTimeline: "/md-icons/view_timeline.svg",
  ViewWeek: "/md-icons/view_week.svg",
  Vignette: "/md-icons/vignette.svg",
  Villa: "/md-icons/villa.svg",
  Visibility: "/md-icons/visibility.svg",
  VisibilityOff: "/md-icons/visibility_off.svg",
  VoiceChat: "/md-icons/voice_chat.svg",
  VoiceOverOff: "/md-icons/voice_over_off.svg",
  Voicemail: "/md-icons/voicemail.svg",
  Volcano: "/md-icons/volcano.svg",
  VolumeDown: "/md-icons/volume_down.svg",
  VolumeMute: "/md-icons/volume_mute.svg",
  VolumeOff: "/md-icons/volume_off.svg",
  VolumeUp: "/md-icons/volume_up.svg",
  VolunteerActivism: "/md-icons/volunteer_activism.svg",
  VpnKey: "/md-icons/vpn_key.svg",
  VpnKeyOff: "/md-icons/vpn_key_off.svg",
  VpnLock: "/md-icons/vpn_lock.svg",
  Vrpano: "/md-icons/vrpano.svg",
  Wallet: "/md-icons/wallet.svg",
  Wallpaper: "/md-icons/wallpaper.svg",
  Warehouse: "/md-icons/warehouse.svg",
  Warning: "/md-icons/warning.svg",
  WarningAmber: "/md-icons/warning_amber.svg",
  Wash: "/md-icons/wash.svg",
  Watch: "/md-icons/watch.svg",
  WatchLater: "/md-icons/watch_later.svg",
  WatchOff: "/md-icons/watch_off.svg",
  Water: "/md-icons/water.svg",
  WaterDamage: "/md-icons/water_damage.svg",
  WaterDrop: "/md-icons/water_drop.svg",
  WaterfallChart: "/md-icons/waterfall_chart.svg",
  Waves: "/md-icons/waves.svg",
  WavingHand: "/md-icons/waving_hand.svg",
  WbAuto: "/md-icons/wb_auto.svg",
  WbCloudy: "/md-icons/wb_cloudy.svg",
  WbIncandescent: "/md-icons/wb_incandescent.svg",
  WbIridescent: "/md-icons/wb_iridescent.svg",
  WbShade: "/md-icons/wb_shade.svg",
  WbSunny: "/md-icons/wb_sunny.svg",
  WbTwilight: "/md-icons/wb_twilight.svg",
  Wc: "/md-icons/wc.svg",
  Web: "/md-icons/web.svg",
  WebAsset: "/md-icons/web_asset.svg",
  WebAssetOff: "/md-icons/web_asset_off.svg",
  WebStories: "/md-icons/web_stories.svg",
  Webhook: "/md-icons/webhook.svg",
  Weekend: "/md-icons/weekend.svg",
  West: "/md-icons/west.svg",
  Whatshot: "/md-icons/whatshot.svg",
  WheelchairPickup: "/md-icons/wheelchair_pickup.svg",
  WhereToVote: "/md-icons/where_to_vote.svg",
  Widgets: "/md-icons/widgets.svg",
  WidthFull: "/md-icons/width_full.svg",
  WidthNormal: "/md-icons/width_normal.svg",
  WidthWide: "/md-icons/width_wide.svg",
  Wifi: "/md-icons/wifi.svg",
  Wifi1Bar: "/md-icons/wifi_1_bar.svg",
  Wifi2Bar: "/md-icons/wifi_2_bar.svg",
  WifiCalling: "/md-icons/wifi_calling.svg",
  WifiCalling3: "/md-icons/wifi_calling_3.svg",
  WifiChannel: "/md-icons/wifi_channel.svg",
  WifiFind: "/md-icons/wifi_find.svg",
  WifiLock: "/md-icons/wifi_lock.svg",
  WifiOff: "/md-icons/wifi_off.svg",
  WifiPassword: "/md-icons/wifi_password.svg",
  WifiProtectedSetup: "/md-icons/wifi_protected_setup.svg",
  WifiTethering: "/md-icons/wifi_tethering.svg",
  WifiTetheringError: "/md-icons/wifi_tethering_error.svg",
  WifiTetheringOff: "/md-icons/wifi_tethering_off.svg",
  WindPower: "/md-icons/wind_power.svg",
  Window: "/md-icons/window.svg",
  WineBar: "/md-icons/wine_bar.svg",
  Woman: "/md-icons/woman.svg",
  Woman2: "/md-icons/woman_2.svg",
  Work: "/md-icons/work.svg",
  WorkHistory: "/md-icons/work_history.svg",
  WorkOff: "/md-icons/work_off.svg",
  WorkOutline: "/md-icons/work_outline.svg",
  WorkspacePremium: "/md-icons/workspace_premium.svg",
  Workspaces: "/md-icons/workspaces.svg",
  WrapText: "/md-icons/wrap_text.svg",
  WrongLocation: "/md-icons/wrong_location.svg",
  Wysiwyg: "/md-icons/wysiwyg.svg",
  Yard: "/md-icons/yard.svg",
  YoutubeSearchedFor: "/md-icons/youtube_searched_for.svg",
  ZoomIn: "/md-icons/zoom_in.svg",
  ZoomInMap: "/md-icons/zoom_in_map.svg",
  ZoomOut: "/md-icons/zoom_out.svg",
  ZoomOutMap: "/md-icons/zoom_out_map.svg",
};
