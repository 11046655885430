import { useSearchParams } from "react-router-dom";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import { defaultSortOptions } from "@/components/DataList/ListHeader/SortSelector";

interface Params {
  sortOptions?: Array<SortOptions>;
  defaultSortBy?: string;
  defaultSortOption?: number;
}

/**
 * Returns the sort and filter params from the query string
 * and the corresponding index for the initial search option
 *
 * @param sortOptions
 * @param defaultSortBy
 * @param defaultSortOption
 */
export const useSortAndFilterState = ({
  sortOptions = defaultSortOptions,
  defaultSortBy = "updated_at",
  defaultSortOption = 0,
}: Params) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortBy = String(searchParams.get("sortBy") || defaultSortBy);
  const sortDirection = String(searchParams.get("sortDirection") || "asc");
  const sortAscending = sortDirection === "asc";
  const filterBy = String(searchParams.get("filterBy") || "no");

  let initialSortOption = defaultSortOption;

  if (sortBy && sortOptions) {
    initialSortOption = sortOptions && sortOptions.findIndex((o) => o.direction === sortDirection && o.prop === sortBy);
  }

  const setSearchParameters = (sortProp?: string, sortDirection?: string, filter?: string) =>
    setSearchParams((params) => {
      if (sortProp) params.set("sortBy", sortProp);
      if (sortDirection) params.set("sortDirection", sortDirection);
      if (filter) params.set("filterBy", filter);
      return params;
    });

  return { sortBy, sortAscending, filterBy, initialSortOption, setSearchParameters };
};
