import type { Field } from "@rjsf/utils";
import LocalizedStringField from "./fields/LocalizedStringField";
import LocationPickerField from "./fields/LocationPickerField";
import ChipListField from "./fields/ChipListField";
import type { FieldNamesList } from "./fieldList";
import { DMQueryField } from "@/framework/KioForm/fields/DMQueryField";
import DocumentRelationField from "@/framework/KioForm/fields/DocumentRelationField";
import LocalizedField from "@/framework/KioForm/fields/LocalizedField";
import MediaSelectorField from "@/framework/KioForm/fields/MediaSelectorField/MediaSelectorField";
import ImageMapField from "@/framework/KioForm/fields/ImageMapField";
import StateRelationPickerField from "@/framework/KioForm/fields/StateRelationPickerField";
import QRCodeField from "@/framework/KioForm/fields/QRCodeField";
import DMSelectorField from "@/framework/KioForm/fields/DMSelectorField";
import { MemoriaAPIField } from "@/framework/KioForm/fields/MemoriaAPIField";
import { KulturnavAutocompleteField } from "@/framework/KioForm/fields/KulturnavAutocompleteField";

export const Fields: { [key in FieldNamesList]: Field } = {
  LocalizedStringField,
  LocationPickerField,
  ChipList: ChipListField,
  DMQueryField,
  StateRelationPickerField,
  DocumentRelation: DocumentRelationField,
  LocalizedField,
  MediaSelectorField,
  ImageMapField,
  QRCodeField,
  DMSelectorField,
  MemoriaAPIField,
  KulturnavAutocompleteField,
};
