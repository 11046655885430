import { useEffect, useRef } from "react";
import videojs from "video.js";
import "node_modules/video.js/dist/video-js.css";

export const VideoJS = ({ options, onReady, subtitles }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    // Update subtitle track when subtitles changes
    if (playerRef.current) {
      // Remove existing text tracks
      //const tracks = playerRef.current.remoteTextTrackEls();
      const tracks = playerRef.current.remoteTextTracks();
      console.log({ tracks });
      for (let i = 0; i < tracks.length; i++) {
        playerRef.current.removeRemoteTextTrack(tracks[i]);
      }

      // Add the new subtitle track
      if (subtitles) {
        playerRef.current.addRemoteTextTrack(
          {
            kind: "subtitles",
            src: subtitles,
            label: "subtitles",
            default: true,
            showing: true,
          },
          false
        );
      }
    }
  }, [subtitles]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
