export enum DMSStatus {
  INIT = "init",
  CONVERT = "convert",
  CONVERTING = "converting",
  DONE = "done",
  FAILED = "failed",
}
export enum DMSMediaType {
  AUDIO = "Audio",
  VIDEO = "Video",
  IMAGE = "Image",
}

export interface DMSUploadStatus {
  identifier: string;
  title: string;
  status: DMSStatus;
  mediaType: DMSMediaType;
  published: boolean;
}
