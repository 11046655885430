/*
 * Utils for manipulating numbers
 */
/**
 * Make sure a number is higher, lower or within the provided min/max values
 * @param value The value to check
 * @param min The lowest allowed value (inclusive)
 * @param max The highest allowed value (inclusive)
 */
export function limit(value: number | null, min: number | null = null, max: number | null = null): number | null {
  if (!value && value !== 0) {
    return null;
  }
  if (min !== null && value <= min) {
    return min;
  }
  if (max !== null && value >= max) {
    return max;
  }
  return value;
}

/**
 * Check to see if a value is a valid number, either as a number or a string representation.
 * @param value
 */
export function validNumber(value: any): boolean {
  if (typeof value === "string" || typeof value === "number") {
    return !isNaN(Number(value));
  }
  return false;
}

export function isNumeric_no_whitespaces(str: string) {
  // https://stackoverflow.com/a/175787/12348879
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
