import type { ChangeEvent, FC } from "react";
import { useEffect, useReducer } from "react";
import { Button, CircularProgress, styled, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { TranslationKey } from "@/declarations/models/TranslationKey";
import type Locale from "@/declarations/models/Locale";
import Api from "@/services/Api";
import { useLoadingState } from "@/hooks/useLoadingState";

interface TranslationsState {
  [translationKey: string]: {
    [localeId: number]: string;
  };
}

interface TranslationsActions {
  translationKey: string;
  localeId: number;
  value?: string | null;
}

export interface TranslationEditorProps {
  translationKey: TranslationKey;
  localesToEdit: Array<Locale>;
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  gap: "16px",
});

const InputRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  alignItems: "stretch",
  gap: theme.spacing(2),
}));

const TranslationInput = styled(TextField)({
  flex: "1 0 auto",
  minWidth: "300px",
});

const translationsReducer = (state: TranslationsState, action: TranslationsActions) => {
  if (!action || !action.translationKey || !action.localeId) {
    return state;
  }
  let translationsForKey = state[action.translationKey];
  if (!translationsForKey) {
    translationsForKey = {};
  }
  translationsForKey[action.localeId] = action.value || "";
  return {
    ...state,
    [action.translationKey]: translationsForKey,
  };
};

export const TranslationEditor: FC<TranslationEditorProps> = ({ translationKey, localesToEdit }) => {
  const { t } = useTranslation("common");
  const [translations, setTranslations] = useReducer(translationsReducer, {});

  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const handleTranslationChanged = (locale: Locale) => async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || "";
    setTranslations({
      translationKey: translationKey.translation_key,
      localeId: locale.id,
      value,
    } as TranslationsActions);
  };

  const handleSaveClicked = async () => {
    startLoading();
    await Promise.all(
      localesToEdit.map(async (locale) => {
        const value = translations?.[translationKey.translation_key]?.[locale.id!] || "";
        // TODO fix
        // this breaks if trying to update deleted entries,
        // see add_or_update_translation in translation_service
        // and get_by_language_code_and_translation_key in translation_dao
        return Api.updateTranslation(translationKey.translation_key, locale.language_code, value).fetchDirect(null);
      })
    );
    stopLoading();
  };

  useEffect(() => {
    // Fetch all existing translations
    startLoading();
    const getTranslationsForKey = Api.getAllTranslations({ translation_key: translationKey.translation_key });
    getTranslationsForKey
      .fetchDirect(null)
      .then((page) => page?.items || [])
      .then((items) => {
        items.forEach((translation) =>
          setTranslations({
            translationKey: translationKey.translation_key,
            localeId: translation.locale_id,
            value: translation.translation,
          })
        );
      })
      .finally(stopLoading);
    return getTranslationsForKey.abort;
  }, [translationKey, startLoading, stopLoading]);

  return (
    <Container>
      <InputRow>
        {localesToEdit.map((locale) => (
          <TranslationInput
            key={`${translationKey.translation_key}:${locale.language_code}`}
            value={translations?.[translationKey.translation_key]?.[locale.id!] || ""}
            onChange={handleTranslationChanged(locale)}
            label={locale.name}
            disabled={isLoading}
            variant="filled"
            multiline
            InputProps={{
              endAdornment: isLoading ? (
                <CircularProgress variant="indeterminate" color="secondary" size="16px" />
              ) : null,
            }}
          />
        ))}
      </InputRow>
      <Button variant="outlined" color="secondary" onClick={handleSaveClicked} sx={{ marginLeft: "auto" }}>
        {t("generic.save")}
      </Button>
    </Container>
  );
};

export default TranslationEditor;
