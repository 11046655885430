import type { FC } from "react";
import { useState } from "react";
import { FormHelperText } from "@mui/material";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import ArrayItems from "@/framework/KioForm/fields/documentRelationFieldVariants/ArrayItems";
import type { CustomArrayFieldTemplateProps } from "@/framework/KioForm/templates/array/ArrayFieldTemplate";
import { getValueFromUiSchema } from "@/utils/schema";

// TODO: weird typing with Function signature, works?!
type Function = (v: any) => void;
const getOnChange = (onChange: any): Function | undefined => {
  if (onChange instanceof Function) return onChange;
};

const DefaultArrayFieldTemplate: FC<
  CustomArrayFieldTemplateProps & { collapsibleParent?: boolean; title?: string }
> = ({ title, collapsibleParent = true, ...props }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const titleAddon = Array.isArray(props?.formData) ? `(${props?.formData.length})` : undefined;
  const onChange = getOnChange(props.uiSchema?.items?.["ui:options"]?.onChange);
  const helperText = getValueFromUiSchema("help", props.uiSchema) || "";

  if (collapsibleParent) {
    return (
      <CollapseBar expanded={expanded} setExpanded={setExpanded} title={title} titleAddon={titleAddon}>
        {helperText && (
          <FormHelperText
            sx={{
              marginBottom: 2,
            }}
          >
            {helperText}
          </FormHelperText>
        )}
        <ArrayItems title={title} onChange={onChange} {...props} />
      </CollapseBar>
    );
  } else {
    return <ArrayItems title={title} onChange={onChange} {...props} />;
  }
};

export default DefaultArrayFieldTemplate;
