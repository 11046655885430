import type { FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import type { DMSUploadStatus } from "@/declarations/DMSUploadStatus";
import { DMSStatus } from "@/declarations/DMSUploadStatus";
import Api from "@/services/Api";
import Loader from "@/framework/Loader";
import { useDepSafeValue } from "@/hooks/useDepSafeCallback";

export interface DMSContentLoaderProps {
  identifier?: string;
  onReady?: () => void;
  onTimeout?: () => void;
  children?: any;
}

const POLLING_INTERVAL: number = 3000;
const TIMEOUT_MS: number = POLLING_INTERVAL * 10;

export const EnsureDMSContentReady: FC<DMSContentLoaderProps> = ({ children, identifier, onReady }) => {
  const onReadyRef = useDepSafeValue(onReady);
  const { t } = useTranslation("common");

  const pollingTimerId = useRef<NodeJS.Timeout | null>(null);
  const [currentStatus, setCurrentStatus] = useAsyncSafeState<DMSUploadStatus | null>(null);
  const [hasTimedOut, setHasTimedOut] = useState<boolean>(false);

  const stopPollingIfNeeded = useCallback(() => {
    if (pollingTimerId.current) {
      clearInterval(pollingTimerId.current);
      pollingTimerId.current = null;
    }
  }, []);

  const startPolling = useCallback(() => {
    stopPollingIfNeeded();

    if (!identifier) {
      onReadyRef.current?.();
      return;
    }

    setCurrentStatus(null);
    const poll = async () => {
      const status = await Api.getMediaUploadStatus(identifier).fetchDirect(null);
      setCurrentStatus(status);
      if (status?.status === DMSStatus.DONE || status?.status === DMSStatus.FAILED) {
        stopPollingIfNeeded();
        onReadyRef.current?.();
      }
    };
    pollingTimerId.current = setInterval(poll, POLLING_INTERVAL);
    poll().then();
  }, [identifier, stopPollingIfNeeded, setCurrentStatus, onReadyRef]);

  useEffect(() => {
    startPolling();

    const timoutTimerId = setTimeout(() => {
      if (pollingTimerId.current) {
        stopPollingIfNeeded();
        setHasTimedOut(true);
      }
      clearTimeout(timoutTimerId);
    }, TIMEOUT_MS);

    return () => {
      stopPollingIfNeeded();
      clearTimeout(timoutTimerId);
    };
  }, [startPolling, stopPollingIfNeeded]);

  if (!currentStatus) {
    return <Loader loadingText="components.DMSPolling.initialLoad" />;
  }
  if (currentStatus.status === DMSStatus.FAILED) {
    return <Typography color="error">{t("components.DMSPolling.uploadOrConversionFailed")}</Typography>;
  }
  if (hasTimedOut) {
    return <Typography>{t("components.DMSPolling.timedOut")}</Typography>;
  }
  if (currentStatus.status === DMSStatus.CONVERTING) {
    return <Loader loadingText="components.DMSPolling.contentIsConverting" />;
  }
  if (currentStatus.status === DMSStatus.CONVERT) {
    return <Loader loadingText="components.DMSPolling.queuedForConverting" />;
  }
  if (currentStatus.status === DMSStatus.INIT) {
    return <Loader loadingText="components.DMSPolling.contentIsUploading" />;
  }

  return <>{children}</>;
};

export default EnsureDMSContentReady;
