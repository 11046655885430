/*
 * Utilities for Async operations
 */
export function cancellablePromise<T>(promise: Promise<T>): [promise: Promise<T>, cancel: () => void] {
  let isCancelled = false;
  return [
    new Promise<T>((resolve, reject) => {
      promise
        .then((...args) => {
          if (!isCancelled) {
            resolve(...args);
          }
        })
        .catch((reason) => {
          if (!isCancelled) {
            reject(reason);
          }
        });
    }),
    () => (isCancelled = true),
  ];
}

/**
 * Typeguard for making sure object is a Promise
 * @param a
 */
export function isPromise(a: any): a is Promise<unknown> {
  return !!a && typeof a === "object" && "then" in a && typeof a.then === "function";
}
