import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortDirection } from "@/declarations/SortDirection";
import DataListSelect from "@/components/DataList/common/DataListSelect";

export interface SortOptions {
  label: string;
  prop: string;
  direction: SortDirection;
}

export interface SortSelectorProps {
  onItemsChanged?: (sortProp?: string, sortDirection?: SortDirection, filter?: string) => void;
  sortOptions?: Array<SortOptions>;
  initialSortOption?: number;
}

export const defaultSortOptions: Array<SortOptions> = [
  { prop: "name", direction: "asc", label: "components.list.sort.byTitleAsc" },
  { prop: "name", direction: "desc", label: "components.list.sort.byTitleDesc" },
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
];

export const SortSelector: FC<SortSelectorProps> = ({
  onItemsChanged,
  sortOptions = defaultSortOptions,
  initialSortOption = 0,
}) => {
  const { t } = useTranslation("common");
  const [currentSortOptionIndex, setCurrentSortOptionIndex] = useState<number>(initialSortOption);

  const handleSortChanged = (index: number | string) => {
    index = Number(index);
    if (onItemsChanged) {
      const sortProp = sortOptions[index].prop;
      const sortDirection = sortOptions[index].direction;
      onItemsChanged(sortProp, sortDirection);
    }

    setCurrentSortOptionIndex(index && index > 0 ? index : 0);
  };

  return (
    <DataListSelect
      items={sortOptions.map(({ label }, value) => ({ value, label: t(label) }))}
      value={currentSortOptionIndex}
      onChange={handleSortChanged}
    />
  );
};

export default SortSelector;
