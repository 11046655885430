/**
 * All layouts available in ObjectFieldTemplate
 */

export enum ObjectFieldTemplateLayout {
  SECTION = "Section",
  MODULE = "Module",
  TABS = "Tabs",
  CATEGORY = "Category",
}

/**
 * All layouts available in ArrayFieldTemplate
 */
export enum ArrayFieldLayoutTemplate {
  BLOCKS = "Blocks",
  FLAT = "Flat",
}

export type Template = ObjectFieldTemplateLayout | ArrayFieldLayoutTemplate;

type Tool = "Layout";
type Action = "Delete" | "Visibility";

export interface FormTab {
  label: string;
  icon?: string;
  fields?: string[];
  "ui:label"?: string;
}

interface ArrayButton {
  label?: string;
  type?: string;
  icon?: string;
}

interface BaseArrayOptions {
  buttons?: ArrayButton[];
  draggable?: boolean;
}

interface BaseObjectOptions {
  actions?: Action[];
  fieldAsTitle?: string;
  tools?: Tool[];
}

export interface TemplateOptions extends BaseObjectOptions, BaseArrayOptions {
  showDragHandle?: boolean;
  counter?: boolean;
  formTabs?: FormTab[];
  default?: {
    expanded?: boolean;
    field?: string;
  };
}

export interface TemplateLayout {
  template?: Template;
  fields?: string[];
  templateOptions?: TemplateOptions;
}
