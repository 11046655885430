import type { UiSchema } from "@rjsf/utils";
import i18n from "i18next";

export const uiSchema: UiSchema = {
  "ui:title": "schemaTypes.locale",
  language_code: {
    "ui:title": "generic.language_code",
    "ui:help": i18n.t("common:helpText.language_code"),
  },
  id: {
    "ui:title": "generic.id",
  },
  name: {
    "ui:title": "generic.name",
  },
};
