import { Delete, DeleteForever, Edit, MoreVert, Restore } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, styled, Tooltip } from "@mui/material";
import type { MouseEvent, ReactElement } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { DataListRowInternal } from "@/components/DataList/DataListRow";
export interface CustomButton {
  tooltip: string;
  onClick?: (item: DataListRowInternal<any>) => void;
  onClickAll?: (item: DataListRowInternal<any>[]) => void;
  icon: ReactElement;
  displayCondition?: (item: any) => boolean;
  confirmationText?: string;
}

export interface DataListOptionsProps<T> {
  item: DataListRowInternal<T>;
  denseOptions?: boolean;
  isDeleted: boolean;
  handleEditClick?: (item: DataListRowInternal<T>) => void;
  handleDeleteClick?: (item: DataListRowInternal<T>) => void;
  handleDeleteForeverClick?: (item: DataListRowInternal<T>) => void;
  handleUndelete?: (item: DataListRowInternal<T>) => void;
  customButtons?: Array<CustomButton>;
}

const StyledMenu = styled(Menu)`
  ul {
    padding: 0;
    li {
      gap: 8px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const StyledIcon = styled(IconButton)`
  background-color: rgba(255, 255, 255, 0.75);
  width: 36px;
  height: 36px;
  :hover {
    background-color: white;
  }
`;

const ActionButtonContainer = styled("span")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-end",
  marginLeft: "auto",
});

export const DataListOptions = <T extends object>({
  item,
  denseOptions = false,
  isDeleted,
  handleEditClick,
  handleDeleteClick,
  handleDeleteForeverClick,
  handleUndelete,
  customButtons,
}: DataListOptionsProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showOptionsMenu = Boolean(anchorEl);
  const { t } = useTranslation("common");

  return !denseOptions ? (
    <ActionButtonContainer sx={{ minWidth: `calc(${(customButtons?.length || 0) + 2} * 36px)` }}>
      {!!customButtons && (
        <>
          {customButtons.map(
            (button, index) =>
              (!button.displayCondition || button.displayCondition(item)) && (
                <Tooltip title={button.tooltip} key={`custom-button-${index}`}>
                  <StyledIcon
                    aria-controls={showOptionsMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={showOptionsMenu ? "true" : undefined}
                    onClick={() => !!button.onClick && button.onClick(item)}
                  >
                    {button.icon}
                  </StyledIcon>
                </Tooltip>
              )
          )}
        </>
      )}
      {!isDeleted && (
        <>
          {handleEditClick && (
            <Tooltip title={t("arrayButtons.editElement") || "Edit"}>
              <StyledIcon
                onClick={() => {
                  setAnchorEl(null);
                  handleEditClick(item);
                }}
              >
                <Edit />
              </StyledIcon>
            </Tooltip>
          )}
          {handleDeleteClick && (
            <Tooltip title={t("arrayButtons.removeElement") || "Remove"}>
              <StyledIcon onClick={() => handleDeleteClick(item)}>
                <Delete />
              </StyledIcon>
            </Tooltip>
          )}
        </>
      )}
      {isDeleted && handleDeleteForeverClick && (
        <Tooltip title={t("arrayButtons.removeElementForever") || "Delete forever"}>
          <StyledIcon onClick={() => handleDeleteForeverClick(item)}>
            <DeleteForever />
          </StyledIcon>
        </Tooltip>
      )}
      {isDeleted && handleUndelete && (
        <Tooltip title={t("arrayButtons.restoreElement") || "Restore"}>
          <StyledIcon onClick={() => handleUndelete(item)}>
            <Restore />
          </StyledIcon>
        </Tooltip>
      )}
    </ActionButtonContainer>
  ) : (
    <>
      <ActionButtonContainer>
        <Tooltip title={t("arrayButtons.showOptions") || "Options"}>
          <StyledIcon
            aria-controls={showOptionsMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={showOptionsMenu ? "true" : undefined}
            disabled={
              (isDeleted && !handleDeleteForeverClick && !handleUndelete) ||
              (!isDeleted && !handleEditClick && !handleDeleteClick)
            }
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVert />
          </StyledIcon>
        </Tooltip>
      </ActionButtonContainer>

      <StyledMenu open={showOptionsMenu} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {!isDeleted && handleEditClick && (
          <MenuItem
            key={0}
            onClick={() => {
              setAnchorEl(null);
              handleEditClick(item);
            }}
          >
            <Edit />
            {t("generic.edit")}
          </MenuItem>
        )}
        {!isDeleted && handleDeleteClick && (
          <MenuItem
            key={1}
            onClick={() => {
              setAnchorEl(null);
              handleDeleteClick(item);
            }}
          >
            <Delete />
            {t("generic.delete")}
          </MenuItem>
        )}
        {isDeleted && handleDeleteForeverClick && (
          <MenuItem
            key={2}
            onClick={() => {
              setAnchorEl(null);
              handleDeleteForeverClick(item);
            }}
          >
            <DeleteForever />
            {t("generic.deleteForever")}
          </MenuItem>
        )}
        {isDeleted && handleUndelete && (
          <MenuItem
            key={3}
            onClick={() => {
              setAnchorEl(null);
              handleUndelete(item);
            }}
          >
            <Restore />
            {t("generic.restore")}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default DataListOptions;
