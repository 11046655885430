import type { RJSFSchema } from "@rjsf/utils";

const deepCopy = (item: any) => JSON.parse(JSON.stringify(item));
/**
 * Used to build menu_items and menu_items.properties.items.submenu_items
 * No need to define menu_items twice.
 */
const menu_items_sub = {
  menu_items: {
    type: "array",
    items: {
      type: "object",
      properties: {
        mui_icon: {
          type: ["string", "null"],
        },
        title: {
          type: "object",
          patternProperties: {
            "^[a-zA-Z]{2}$": {
              type: "string",
            },
          },
        },
        action: {
          type: "object",
          properties: {
            doc_type: {
              type: "string",
              enum: ["Schema", "Media", "QR", "DocumentBin", "MediaBin"],
              default: "Schema",
            },
          },
          allOf: [
            {
              if: {
                properties: {
                  doc_type: {
                    const: "Schema",
                  },
                },
              },
              then: {
                properties: {
                  chip_content: {
                    title: "Chip Content",
                    type: "string",
                  },
                  schema_id: {
                    type: "string",
                  },
                  single_document: {
                    type: "boolean",
                    default: false,
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  doc_type: {
                    const: "Media",
                  },
                },
              },
              then: {
                title: "Media",
                properties: {
                  media_type: {
                    $ref: "#/definitions/media_type",
                  },
                },
              },
            },
          ],
        },
      },
    },
  },
};
// created using deep copy
const menu_with_submenu = deepCopy(menu_items_sub);
// set menu_items inside sub_menu
menu_with_submenu.menu_items.items.properties.sub_menu_items = deepCopy(menu_items_sub.menu_items);

export const schema: RJSFSchema = {
  type: "object",
  properties: {
    id: {
      type: "number",
      readOnly: true,
    },
    name: {
      type: "string",
      minLength: 1,
    },
    slug: {
      type: "string",
      minLength: 1,
    },
    description: {
      type: ["string", "null"],
    },
    image_url: {
      type: ["string", "null"],
    },
    url: {
      type: ["string", "null"],
    },
    qr_code_base_url: {
      type: ["string", "null"],
    },
    owner_schema_id: {
      type: ["integer", "null"],
    },
    menu_heading: {
      type: "string",
    },
    menu: {
      type: ["array", "null"],
      items: {
        properties: {
          title: {
            type: "object",
            patternProperties: {
              "^[a-zA-Z]{2}$": {
                type: "string",
              },
            },
          },
          menu_items: {
            $ref: "#/definitions/menu_with_submenu",
          },
        },
      },
    },
  },
  definitions: {
    media_type: {
      type: "string",
      anyOf: [
        {
          type: "string",
          enum: ["image"],
          title: "Bilder",
        },
        {
          type: "string",
          enum: ["video"],
          title: "Video",
        },
        {
          type: "string",
          enum: ["audio"],
          title: "Audio",
        },
        {
          type: "string",
          enum: ["dm"],
          title: "Digitalt Museum",
        },
        {
          type: "string",
          enum: ["document"],
          title: "Dokumenter",
        },
        {
          type: "string",
          enum: ["youtube"],
          title: "Youtube",
        },
        {
          type: "string",
          enum: ["sketchfab"],
          title: "Sketchfab",
        },
        {
          type: "string",
          enum: ["model3d"],
          title: "3D Modell",
        },
      ],
    },
    menu_with_submenu: deepCopy(menu_with_submenu.menu_items),
  },
  required: ["name", "slug"],
};
