import type { RJSFSchema } from "@rjsf/utils";

export const schema: RJSFSchema = {
  type: "object",
  title: "Instance",
  properties: {
    id: {
      type: "integer",
      readOnly: true,
    },
    path: {
      type: ["string", "null"],
      readOnly: true,
    },
    name: {
      type: ["string", "null"],
    },
    slug: {
      type: ["string", "null"],
    },
    application_id: {
      type: "integer",
    },
    license_id: {
      type: "integer",
    },
    owner_id: {
      type: "integer",
    },
    available_locales: {
      type: "array",
      minItems: 1,
      items: {
        type: "object",
        properties: {
          id: {
            type: "integer",
          },
        },
      },
    },
    shared_with: {
      type: "array",
      items: {
        type: "object",
        properties: {
          to_application_instance_id: {
            type: "integer",
          },
          type: {
            type: "string",
            anyOf: [
              {
                type: "string",
                enum: ["document"],
                title: "Dokumenter",
              },
              {
                type: "string",
                enum: ["media"],
                title: "Media",
              },
            ],
          },
        },
        required: ["to_application_instance_id", "type"],
      },
      uniqueItems: true,
    },
  },
  required: ["application_id", "license_id", "owner_id", "available_locales"],
};
