import { styled } from "@mui/material";
import type { UiSchema, RJSFSchema } from "@rjsf/utils";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "@/assets/styles/Styles";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import type { CreateOrEditProps } from "@/components/CreateOrEdit";
import type { DataListProps } from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import type { Schema } from "@/declarations/models/Schema";
import type { RequestContext } from "@/declarations/RequestContext";
import { schema } from "@/declarations/schemas/schema/schema";
import { uiSchema } from "@/declarations/schemas/schema/uiSchema";
import Loader from "@/framework/Loader";
import { useDebounce } from "@/hooks/useDebounce";
import { useLoadingState } from "@/hooks/useLoadingState";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";
import Api from "@/services/Api";
import { parseJson } from "@/utils/json";
import { extractFieldStrings } from "@/utils/obj";
import { parseSchema, translatableFieldNames } from "@/utils/schema";
import CreateOrEditRoutes from "@/views/admin/CreateOrEditRoutes";
import ReindexDocumentsButton from "@/views/admin/SchemaView/ReindexDocumentsButton";
import SchemaPreview from "@/views/admin/SchemaView/SchemaPreview";

const Overlay = styled("div")({
  position: "absolute",
  width: "100vw",
  height: "100vh",
  zIndex: 9999,
  inset: 0,
  backgroundColor: "rgba(255, 255, 255, .8)",
});

const mapperFn = (schemaItem: Schema): DataListRow => ({
  key: String(schemaItem.id),
  title: schemaItem.name,
  subTitle: schemaItem.description,
  infoText: `ID: ${schemaItem.id}`,
  updatedAt: schemaItem.updated_at,
  updatedBy: schemaItem.updated_by,
});

const customSortOptions: Array<SortOptions> = [
  { prop: "name", direction: "asc", label: "components.list.sort.byTitleAsc" },
  { prop: "name", direction: "desc", label: "components.list.sort.byTitleDesc" },
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
  { prop: "description", direction: "asc", label: "components.list.sort.byDescriptionAsc" },
  { prop: "description", direction: "desc", label: "components.list.sort.byDescriptionDesc" },
];

const getSchema: CreateOrEditProps<Schema>["getFormData"] = (id: number): RequestContext<Schema> =>
  Api.getOneSchema(id);

async function createMissingTranslationKeys(schema: Schema) {
  const translationKeys = extractFieldStrings(parseSchema(schema.ui_schema), translatableFieldNames);
  const translationKeysFiltered = translationKeys.filter((tKey) => tKey.startsWith("db."));
  const translationKeysWithoutDbPrefix = translationKeysFiltered.map((tKey) => tKey.replace(/^db\./, ""));
  await Api.createTranslationKeysIfMissing(translationKeysWithoutDbPrefix).fetch();
}

const updateSchema: CreateOrEditProps<Schema>["onSubmit"] = async (schema: Schema): Promise<RequestContext<Schema>> => {
  // set correct order on searchable fields
  const searchableFields = schema.searchable_fields?.map((field, index) => ({
    ...field,
    hit_order: index,
  }));
  const ctx = Api.updateSchema({ ...schema, searchable_fields: searchableFields });
  await createMissingTranslationKeys(schema);
  return ctx;
};

const createSchema: CreateOrEditProps<Schema>["onSubmit"] = async (schema: Schema): Promise<RequestContext<Schema>> => {
  const searchableFields = schema.searchable_fields?.map((field, index) => ({
    ...field,
    hit_order: index,
  }));
  const ctx = Api.createSchema({ ...schema, searchable_fields: searchableFields });
  await createMissingTranslationKeys(schema);
  return ctx;
};

const deleteSchema = async (schema: Schema) => {
  const error = (await Api.deleteSchema(schema?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Delete schema failed");
  }
};

const undeleteSchema = async (item: Schema) => {
  await Api.undeleteSchema(item.id!).fetch();
};

export const SchemaView: FC = () => {
  const { t } = useTranslation("common");

  const [currentJsonSchema, setCurrentJsonSchema] = useState<RJSFSchema>({});
  const [currentUiSchema, setCurrentUiSchema] = useState<UiSchema>({});
  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    sortOptions: customSortOptions,
    defaultSortBy: "name",
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const getSchemas: DataListProps<Schema>["getItems"] = (page, page_size) =>
    Api.getAllSchemas({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
    }).fetchDirect({ page, page_size, count: 0, items: [], total_count: 0 });

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  const _createSchema = async (schema: Schema) => {
    startLoading();
    const ctx = await createSchema(schema);
    stopLoading();
    return ctx;
  };

  const _updateSchema = async (schema: Schema) => {
    startLoading();
    const ctx = await updateSchema(schema);
    stopLoading();
    return ctx;
  };

  const handleFormDataChanged = (formData?: Schema): void => {
    setCurrentJsonSchema(parseJson(formData?.json_schema));
    setCurrentUiSchema(parseJson(formData?.ui_schema));
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.SCHEMA}`} />
      {isLoading && (
        <Overlay>
          <Loader loadingText={"views.admin.schemas.reindexNotice"} />
        </Overlay>
      )}
      <CreateOrEditRoutes
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit={false}
        createFormData={_createSchema}
        updateFormData={_updateSchema}
        getFormData={getSchema}
        onFormDataChanged={handleFormDataChanged}
        previewContent={<SchemaPreview schema={currentJsonSchema} uiSchema={currentUiSchema} />}
        previewWidth={Styles.Dimensions.SCHEMA_PREVIEW_WIDTH}
        afterContent={<ReindexDocumentsButton />}
        listProps={{
          listTitle: t("schemaTypes.schema_plural"),
          mapperFn,
          getItems: getSchemas,
          onDeleteItem: deleteSchema,
          onDeleteItemForever: deleteSchema,
          onUndeleteItem: undeleteSchema,
          hideImage: true,
          defaultViewType: ViewType.LIST,
          disableViewTypeSelection: true,
          handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          initialSortOption,
          sortOptions: customSortOptions,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default SchemaView;
