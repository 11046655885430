import type { FC } from "react";
import Home from "@/assets/img/custom-icons/home.svg";
import Articles from "@/assets/img/custom-icons/articles.svg";
import Presentations from "@/assets/img/custom-icons/presentations.svg";
import Museum from "@/assets/img/custom-icons/museum.svg";
import Map from "@/assets/img/custom-icons/map.svg";
import Rotation3D from "@/assets/img/custom-icons/3d-rotation.svg";
import PhotoLibrary from "@/assets/img/custom-icons/photo-library.svg";
import MusicLibrary from "@/assets/img/custom-icons/music-library.svg";
import VideoLibrary from "@/assets/img/custom-icons/video-library.svg";
import Restore from "@/assets/img/custom-icons/restore.svg";
import Deleted from "@/assets/img/custom-icons/deleted.svg";
import DM from "@/assets/img/custom-icons/icon_dm.svg";
import Minne from "@/assets/img/custom-icons/icon_minne.svg";
import Google from "@/assets/img/custom-icons/google.svg";
import YouTube from "@/assets/img/custom-icons/youtube.svg";
import Sketchfab from "@/assets/img/custom-icons/sketchfab.svg";
import { IconPaths } from "@/assets/icons/material-icons";

export type CustomIconNames = keyof typeof customIconPaths;

export interface CustomIconProps {
  name?: CustomIconNames;
  className?: string;
}

export const customIconPaths = {
  Home,
  Articles,
  Presentations,
  Museum,
  Map,
  "3DRotation": Rotation3D,
  MusicLibrary,
  PhotoLibrary,
  VideoLibrary,
  Restore,
  Deleted,
  DM,
  Minne,
  Google,
  YouTube,
  Sketchfab,
  QuestionMark: IconPaths.QuestionMark,
};

const CustomIcons: FC<CustomIconProps> = ({ name }) => {
  const imgSrc = customIconPaths[name ?? "QuestionMark"];
  return <svg data-loading="lazy" data-src={imgSrc} width="24" height="24"></svg>;
};

export default CustomIcons;
