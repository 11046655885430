import type { FC } from "react";
import { useEffect, useState } from "react";
import type { ObjectFieldTemplateProps } from "@rjsf/utils";
import { styled, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { FormTab, TemplateOptions } from "@/declarations/TemplateLayout";
import Styles from "@/assets/styles/Styles";
import { clsx } from "@/utils/styles";
import { MuiIcon } from "@/components/MuiIcon";

export interface CategoryObjectTemplateProps extends ObjectFieldTemplateProps {}

const TemplateContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 0, 0),
  width: "100%",
}));

const TabsContainer = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0, 2),
  "& .indicator": {
    display: "none",
  },
  "& .MuiTabs-root": {
    minHeight: "44px",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(0, 1, 0, 0),
  border: `1px solid ${Styles.Colors.EDITOR_TABS_BORDER_COLOR}`,
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  "&.active": {
    backgroundColor: theme.palette.background.paper,
    borderBottom: "none",
  },
  "&:hover, &:focus": {
    backgroundColor: theme.palette.background.paper,
  },
  minHeight: "47px",
  minWidth: "160px",
}));

const TabLabel = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  textTransform: "none",
}));

const TabContentContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const TabsBorder = styled("div")({
  position: "absolute",
  height: 0,
  bottom: 0,
  left: 0,
  right: 0,
  borderBottom: `1px solid ${Styles.Colors.EDITOR_TABS_BORDER_COLOR}`,
});

const CategoryObjectTemplate: FC<CategoryObjectTemplateProps> = ({ uiSchema, properties }) => {
  const { t } = useTranslation("common");
  const options: TemplateOptions | undefined = uiSchema?.["ui:layout"]?.templateOptions;
  const [tabs, setTabs] = useState<Array<FormTab>>(options?.formTabs ?? []);
  const [activeTabIdx, setActiveTabIdx] = useState<number>(0);

  useEffect(() => {
    if (options?.formTabs?.length) {
      setTabs(options.formTabs);
      setActiveTabIdx((curIdx) => (curIdx >= options.formTabs!.length ? 0 : curIdx));
    }
  }, [options?.formTabs]);

  if (!tabs.length) {
    return <Typography color="error">{t("kioForm.templates.CategoryObjectTemplate.missingTabs")}</Typography>;
  }

  return (
    <TemplateContainer>
      <TabsContainer>
        <TabsBorder />
        <Tabs
          value={activeTabIdx}
          onChange={(_, value) => setActiveTabIdx(value)}
          variant="scrollable"
          aria-label="Form tabs"
          TabIndicatorProps={{ className: "indicator" }}
        >
          {tabs.map((tab, i) => (
            <CustomTab
              key={`tab-${tab["ui:label"] || tab.label}`}
              id={`tab-${tab["ui:label"] || tab.label}`}
              className={clsx({ active: i === activeTabIdx })}
              aria-controls={`tab-${i}`}
              value={i}
              label={
                <TabLabel>
                  <MuiIcon className="material-icons-outlined">{tab.icon}</MuiIcon>
                  {tab["ui:label"] || tab.label}
                </TabLabel>
              }
            />
          ))}
        </Tabs>
      </TabsContainer>
      {tabs.map((tab, i) => (
        <TabContentContainer
          key={`tab-content-${tab["ui:label"] || tab.label}`}
          id={`tab-content-${tab["ui:label"] || tab.label}`}
          role="tabpanel"
          aria-labelledby={`tab-${tab["ui:label"] || tab.label}`}
          hidden={i !== activeTabIdx}
        >
          {properties.filter((prop) => tab.fields?.includes(prop.name)).map((ele) => ele.content)}
        </TabContentContainer>
      ))}
    </TemplateContainer>
  );
};

export default CategoryObjectTemplate;
