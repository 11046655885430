import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateOrEditRoutes from "../CreateOrEditRoutes";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import { schema } from "@/declarations/schemas/application/schema";
import { uiSchema } from "@/declarations/schemas/application/uiSchema";
import type { DataListProps } from "@/components/DataList/DataList";
import Api from "@/services/Api";
import type { CreateOrEditProps } from "@/components/CreateOrEdit";
import type { RequestContext } from "@/declarations/RequestContext";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type Application from "@/declarations/models/Application";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import Styles from "@/assets/styles/Styles";
import DynamicMenu from "@/framework/Menu/DynamicMenu";
import type Menu from "@/declarations/models/Menu";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { useDebounce } from "@/hooks/useDebounce";
import { DownloadCsvOfUsersByApplicationButton } from "@/views/admin/ApplicationView/DownloadCsvOfUsersByApplicationButton";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

const mapperFn = (appItem: Application): DataListRow => ({
  key: String(appItem.id),
  title: appItem.name,
  subTitle: appItem.description,
  chipContent: appItem.slug,
  infoText: `ID: ${appItem.id}`,
  imageURL: appItem.image_url,
  updatedAt: appItem.updated_at,
  updatedBy: appItem.updated_by,
});

const getApplication: CreateOrEditProps<Application>["getFormData"] = (id: number): RequestContext<Application> =>
  Api.getOneApplication(id);

const updateApplication: CreateOrEditProps<Application>["onSubmit"] = (app: Application): RequestContext<Application> =>
  Api.updateApplication(app);

const createApplication: CreateOrEditProps<Application>["onSubmit"] = (app: Application): RequestContext<Application> =>
  Api.createApplication(app);

const deleteApplication = async (application: Application) => {
  const error = (await Api.deleteApplication(application?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to delete application");
  }
};

const undeleteApplication = async (app: Application) => {
  await Api.undeleteApplication(app.id!).fetch();
};

export const ApplicationView: FC = () => {
  const { t } = useTranslation("common");
  const [newMenusToPreview, setNewMenusToPreview] = useState<Array<Menu>>([]);

  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    defaultSortBy: "name",
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  const getApplications: DataListProps<Application>["getItems"] = (page, page_size) =>
    Api.getAllApplications({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortBy === "updated_at" ? !sortAscending : sortAscending,
      search: searchTerms,
    }).fetchDirect({
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    });

  const handleFormDataChanged = async (application: Application) => {
    if (application?.menu && application.menu.length > 0) {
      setNewMenusToPreview(application.menu.filter((menu: any) => !!menu) as Array<Menu>);
    }
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.APPLICATION}`} />
      <CreateOrEditRoutes
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit
        createFormData={createApplication}
        updateFormData={updateApplication}
        getFormData={getApplication}
        previewWidth={Styles.Dimensions.MENU_PREVIEW_WIDTH}
        previewContent={<DynamicMenu menu={newMenusToPreview} />}
        onFormDataChanged={handleFormDataChanged}
        afterContent={<DownloadCsvOfUsersByApplicationButton />}
        listProps={{
          listTitle: t("schemaTypes.application_plural"),
          mapperFn,
          getItems: getApplications,
          onDeleteItem: deleteApplication,
          onDeleteItemForever: deleteApplication,
          onUndeleteItem: undeleteApplication,
          handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          initialSortOption,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default ApplicationView;
