import { styled, Typography } from "@mui/material";
import type { FC } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Styles from "@/assets/styles/Styles";
import EnsureDMSContentReady from "@/components/EnsureDMSContentReady";
import type { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";
import type { DMSPlaybackFormatUrls } from "@/declarations/DMSPlaybackUrls";
import Loader from "@/framework/Loader";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import VideoJS from "@/components/VideoJS/VideoJS";

interface DMSMediaPlayerProps extends AudioPreviewProps {
  thumbnailUrl?: string;
  audio?: boolean;
  captionSrc?: string;
  selectedLocale?: string;
}

const Video = styled("video")`
  max-width: 100%;
  object-fit: contain;
  &.audio {
    max-height: fit-content;
    padding: 0 16px;
  }
  &.video {
    height: fit-content;
    ::cue {
    } // style captions
  }
`;

const MediaContainer = styled("div")`
  max-width: 100%;
  height: ${(props) =>
    props.audio
      ? Styles.Dimensions.MEDIA_PREVIEW_AUDIO_PLAYER_HEIGHT
      : Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_HEIGHT};
  width: ${Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_WIDTH};
`;

export const DMSMediaPlayer: FC<DMSMediaPlayerProps> = ({ identifier, title, thumbnailUrl, audio, captionSrc }) => {
  const { t } = useTranslation("common");
  const [playbackUrl, setPlaybackUrl] = useAsyncSafeState<DMSPlaybackFormatUrls | null>(null);
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const playerRef = useRef(null);

  const handleContentReady = async () => {
    if (identifier) {
      startLoading();
      const getPlaybackUrl = Api.getPlaybackUrls(identifier);
      const urls = await getPlaybackUrl.fetchDirect(null);
      setPlaybackUrl(urls?.urls ?? null);
      stopLoading();
    }
  };

  const getContent = () => {
    if (isLoading) {
      return <Loader loadingText="components.MediaPreview.MDSMediaPreview.loadingPlayback" />;
    }
    if (!playbackUrl || !Object.values(playbackUrl).filter((v) => !!v).length) {
      return <Typography color="error">{t("components.MediaPreview.MDSMediaPreview.mediaNotFound")}</Typography>;
    }

    const handlePlayerReady = (player) => {
      playerRef.current = player;

      // Add subtitles.
      if (captionSrc) {
        player.addRemoteTextTrack(
          {
            kind: "subtitles",
            src: captionSrc,
            label: "subtitles",
            default: true,
          },
          false
        );
      }

      // You can handle player events here, for example:
      player.on("waiting", () => {
        console.log("player is waiting");
      });

      player.on("dispose", () => {
        console.log("player will dispose");
      });
    };
    const mediaTypes = Object.entries(playbackUrl).map(([fileType, url]) => ({
      src: url,
      type: fileType === "hls" ? "application/x-mpegURL" : `${audio ? "audio" : "video"}/${fileType}`,
    }));

    const mediaTypesFiltered = mediaTypes.filter((elem) => !!elem?.src);

    const videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      fill: false,
      sources: mediaTypesFiltered,
      audioPosterMode: !!audio,
      poster: thumbnailUrl,
      audioOnlyMode: !!audio,
      controlBar: {
        children: [
          "playToggle",
          "skipBackward",
          "skipForward",
          "volumePanel",
          "currentTimeDisplay",
          "timeDivider",
          "durationDisplay",
          "progressControl",
          "liveDisplay",
          "seekToLive",
          "remainingTimeDisplay",
          "customControlSpacer",
          "playbackRateMenuButton",
          "chaptersButton",
          "descriptionsButton",
          //'subsCapsButton', // deactivated
          "audioTrackButton",
          "pictureInPictureToggle",
          "fullscreenToggle",
        ],
      },
    };

    console.log(videoJsOptions);

    return (
      <MediaContainer audio={audio}>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} subtitles={captionSrc}></VideoJS>
      </MediaContainer>
    );
  };

  return (
    <EnsureDMSContentReady identifier={identifier} onReady={handleContentReady}>
      {getContent()}
    </EnsureDMSContentReady>
  );
};

export default DMSMediaPlayer;
