/*
 * Utils for manipulating styles
 * */

/**
 * Combine/concat (possibly conditionally) multiple classNames
 * @param classes
 */
export function clsx(...classes: Array<string | { [className: string]: boolean }>): string {
  return classes
    .reduce((classNames, value) => {
      if (typeof value == "string") {
        classNames.push(value);
      } else {
        classNames.push(...Object.keys(value).filter((className) => value[className]));
      }
      return classNames;
    }, [] as Array<string>)
    .join(" ");
}
