import type { FC } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { Box } from "@mui/material";
import CreateTranslationView from "@/views/admin/translation/CreateTranslationView";
import ManageTranslationsView from "@/views/admin/translation/ManageTranslationsView";

export const TranslationView: FC = () => (
  //const { pathname } = useLocation();

  <Box height={"100%"}>
    <Switch>
      <Route path={"/create"} element={<CreateTranslationView />} />
      <Route path={"/"} element={<ManageTranslationsView />} />
    </Switch>
  </Box>
);

export default TranslationView;
