import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import type { FC, MouseEvent } from "react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

export interface SplitButtonProps {
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  label?: string;
  options?: string[];
  disabledMenuValues?: string[];
  disabledButtonValues?: string[];
  primaryClickHandler?: () => void;
  dropdownClickHandler?: (selectedOption: string) => void;
  defaultMenuItem?: number;
}

const ButtonLabel = styled.div`
  text-transform: none;
`;

const SplitButton: FC<SplitButtonProps> = ({
  leftDisabled = false,
  rightDisabled = false,
  disabledMenuValues = [],
  primaryClickHandler,
  dropdownClickHandler,
  options = [],
  label = "buttonLabel",
}) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    primaryClickHandler?.();
  };

  const handleMenuItemClick = (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, index: number) => {
    dropdownClickHandler?.(options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
        <Button disabled={leftDisabled} onClick={handleClick}>
          <ButtonLabel>{t(label)}</ButtonLabel>
        </Button>
        {!rightDisabled && (
          <Button
            size="small"
            color="primary"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            style={{ textTransform: "none" }}
          >
            <ArrowDropDown />
          </Button>
        )}
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={disabledMenuValues.includes(option)}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {t(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
