import type { FC, MouseEvent } from "react";
import { IconButton } from "@mui/material";

import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  DragHandle,
  RestoreFromTrash,
  Edit,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import baseStyled from "@emotion/styled";
import Styles from "@/assets/styles/Styles";
import type { CollapseBarProps } from "@/framework/KioForm/common/CollapseBar";
import Image from "@/components/Image";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import IsFromSharedInstanceIndicator from "@/components/IsFromSharedInstanceIndicator";

const Container = baseStyled("div")(() => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  marginBottom: "16px",
  "&.draggable": {
    cursor: "grab",
  },
}));

const StartRow = baseStyled("div")(() => ({
  flex: "auto",
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
}));

const NoChildren = baseStyled("div")(() => ({
  flex: "auto",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100%",
  gap: `calc(16px - ${Styles.Dimensions.EDITOR_SECTION_ACTIVE_BORDER_WIDTH})`,
  paddingLeft: "16px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
}));

const EndRow = baseStyled("div")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-end",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
});

const StyledImage = baseStyled(Image)({
  overflow: "hidden",
  "& img": {
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
});

export const SettingsContentContainer = baseStyled("div")({
  display: "flex",
  gap: Styles.Dimensions.SETTINGS_BAR_GAP,
});

// TODO: Make draggable
// Implement settings
const ItemBar: FC<CollapseBarProps> = ({
  children,
  enableSettings = false,
  enableDelete = false,
  enableToggleVisibility = false,
  enableMovable = false,
  showDragHandle = false,
  isDeleted = false,
  isVisible = true,
  toggleVisibilityButtonDisabled = false,
  deleteButtonDisabled = false,
  moveUpButtonDisabled = false,
  moveDownButtonDisabled = false,
  onDelete,
  onToggleVisibility,
  onMoveUp,
  onMoveDown,
  onSettingsClick,
  settingsContent,
  thumbnailImageSrc,
  title,
  selectedLocale,
  isFromSharedInstance,
  sharedInstanceId,
}) => {
  const visibilityIcon = isVisible ? <Visibility /> : <VisibilityOff />;
  const deletedIcon = isDeleted ? <RestoreFromTrash /> : <Delete />;

  const stopEvent = (handler?: () => void) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handler?.();
  };

  return (
    <Container>
      <StartRow>
        {children ? (
          children
        ) : (
          <NoChildren>
            {thumbnailImageSrc && <StyledImage src={thumbnailImageSrc} alt={title} width="32px" height="24px" />}
            {title && <KioTitle title={title} />}
          </NoChildren>
        )}
        {isFromSharedInstance && <IsFromSharedInstanceIndicator applicationInstanceId={sharedInstanceId} />}
      </StartRow>

      <EndRow>
        {enableSettings && (
          <>
            <SettingsContentContainer>{settingsContent}</SettingsContentContainer>
            <IconButton onClick={stopEvent(onSettingsClick)}>
              <Edit />
            </IconButton>
          </>
        )}
        {enableToggleVisibility && (
          <IconButton disabled={toggleVisibilityButtonDisabled} onClick={stopEvent(onToggleVisibility)}>
            {visibilityIcon}
          </IconButton>
        )}
        {enableDelete && (
          <IconButton disabled={deleteButtonDisabled} onClick={stopEvent(onDelete)}>
            {deletedIcon}
          </IconButton>
        )}
        {enableMovable && (
          <IconButton disabled={moveUpButtonDisabled} onClick={stopEvent(onMoveUp)}>
            <ArrowUpward />
          </IconButton>
        )}
        {enableMovable && (
          <IconButton disabled={moveDownButtonDisabled} onClick={stopEvent(onMoveDown)}>
            <ArrowDownward />
          </IconButton>
        )}
        {showDragHandle && (
          <IconButton disabled>
            <DragHandle />
          </IconButton>
        )}
      </EndRow>
    </Container>
  );
};

export default ItemBar;
