import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../framework/Header";
import Layout from "../framework/Layout";

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const ToDashboardButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(4),
}));

const PageNotFoundView: FC = () => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  return (
    <Layout header={<Header />}>
      <ContentContainer>
        <Typography variant="h4" component="h1">
          {t("views.PageNotFound.notFoundText")}
        </Typography>
        <ToDashboardButton
          type="button"
          variant="contained"
          color="primary"
          role="navigation"
          onClick={() => history("/dashboard")}
        >
          {t("generic.toDashboard")}
        </ToDashboardButton>
      </ContentContainer>
    </Layout>
  );
};

export default PageNotFoundView;
