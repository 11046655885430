import { styled } from "@mui/material";
import { StyledDialog } from "@/components/MediaSelector/FocalPointModal";

export const FullScreenDialog = styled(StyledDialog)`
  .MuiDialog-paperFullWidth {
    width: 100% !important;
    max-width: calc(100vw - 64px) !important;
    height: 100%;
    max-height: calc(100vh - 64px);
    overflow: hidden;
    .MuiAppBar-positionStatic {
      width: 100%;
    }
    .MuiDrawer-paperAnchorLeft {
      position: absolute;
    }
  }
`;
