import type { UiSchema, WidgetProps, RJSFSchema } from "@rjsf/utils";
import type { ChangeEvent, FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getValueFromUiSchema } from "@/utils/schema";
import { limit } from "@/utils/numbers";
import { useDebounce } from "@/hooks/useDebounce";
import KioTextField from "@/framework/KioForm/common/KioTextField";

function getInputType(schema: RJSFSchema, uiSchema: UiSchema): string {
  const valueToCheck: string = getValueFromUiSchema("inputType", uiSchema) || String(schema.type);
  return ["number", "integer"].includes(valueToCheck) ? "number" : "text";
}

function getTitle(schema: RJSFSchema, uiSchema: UiSchema): string {
  return schema.title ?? getValueFromUiSchema("title", uiSchema) ?? getValueFromUiSchema("label", uiSchema) ?? "";
}

function getDescription(schema: RJSFSchema, uiSchema: UiSchema): string | undefined {
  return schema.description ?? getValueFromUiSchema("description", uiSchema);
}

function getDefaultValue(type: string, schema: RJSFSchema): string | number {
  const value = schema.default;
  if (type === "number") {
    if (Number.isNaN(Number(value))) return "";
    return limit(Number(value), schema.minimum ?? null, schema.maximum ?? null)!;
  }
  return "";
}

function validate(
  value: string | number | undefined,
  required: boolean,
  min: number | undefined,
  max: number | undefined,
  pattern: RegExp | undefined
): string | null {
  if (!value && value !== 0) {
    return required ? "kioForm.common.kioTextField.validationError.required" : null;
  }

  if (typeof min === "number" && !Number.isNaN(Number(min))) {
    if (typeof value === "string") {
      if (value.length < Number(min)) {
        return "kioForm.common.kioTextField.validationError.minLength";
      }
    } else if (Number(value) < Number(min)) {
      return "kioForm.common.kioTextField.validationError.min";
    }
  }

  if (typeof max === "number" && !Number.isNaN(Number(max))) {
    if (typeof value === "string") {
      if (value.length > Number(max)) {
        return "kioForm.common.kioTextField.validationError.maxLength";
      }
    } else if (Number(value) > Number(max)) {
      return "kioForm.common.kioTextField.validationError.max";
    }
  }

  if (!!pattern && typeof value === "string" && !pattern.test(value)) {
    return "kioForm.common.kioTextField.validationError.patternError";
  }

  return null;
}

const KioTextWidget: FC<WidgetProps> = ({
  schema,
  uiSchema,
  id,
  value,
  onChange,
  formContext,
  rawErrors,
  disabled,
  required,
  readonly,
  multiline = false,
  type,
}) => {
  const { t } = useTranslation("common");
  const { variant, debounceTime } = formContext;
  const inputType: string = type ?? getInputType(schema, uiSchema ?? {});
  const title = getTitle(schema, uiSchema ?? {});
  const description = getDescription(schema, uiSchema ?? {});
  const placeholder = getValueFromUiSchema("placeholder", uiSchema);
  const defaultValue = getDefaultValue(inputType, schema);

  const [touched, setTouched] = useState<boolean>(false);
  const [internalFieldValue, setInternalFieldValue] = useState<string | number>(value ?? defaultValue);
  const hasError = !!rawErrors?.length;

  const debouncedOnChange = useDebounce<{
    data: string | number;
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }>(debounceTime, (value) => {
    if (!value) return;
    onChange(value.data ?? defaultValue);
  });

  const min = schema.minimum;
  const { minLength, maxLength } = schema;
  const max = schema.maximum;
  const pattern = schema.pattern ? new RegExp(schema.pattern) : undefined;
  const error = validate(value, !!required, min ?? minLength, max ?? maxLength, pattern);

  const setNewValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value || "";
    const data: string | number = type === "number" ? Number(inputValue) : inputValue;
    setInternalFieldValue(data);
    debouncedOnChange({ data, event });
  };

  const getHelperText = (): string | undefined => {
    if ((touched || hasError) && !!error) {
      return t(error, { min: min ?? minLength, max: max ?? maxLength, pattern: pattern?.source });
    }
    return getValueFromUiSchema("help", uiSchema);
  };

  useEffect(() => {
    // Value is changed externally
    setInternalFieldValue((internalValue) => {
      if (value !== internalValue) {
        return value || "";
      }
      return internalValue;
    });
  }, [value]);

  return (
    <KioTextField
      type={inputType}
      id={id}
      value={internalFieldValue}
      onChange={setNewValue}
      description={description}
      error={(hasError || touched) && !!error}
      placeholder={placeholder}
      label={title}
      onFocus={() => setTouched(true)}
      helperText={getHelperText()}
      aria-describedby={`${title}-description`}
      multiline={multiline}
      variant={variant}
      autoComplete="on"
      color="primary"
      required={required}
      disabled={disabled || readonly}
      aria-required={required}
      aria-disabled={disabled}
      aria-readonly={readonly}
      fullWidth
      isLocalized={!!uiSchema?.["ui:options"]?.isLocalized}
    />
  );
};

export default KioTextWidget;
