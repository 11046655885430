import type { FC, KeyboardEvent, MouseEvent } from "react";
import { useState } from "react";
import type { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { Button, Chip, FormHelperText, styled, TextField, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getValueFromUiSchema } from "@/utils/schema";
import KioTitle from "@/framework/KioForm/common/KioTitle";

export interface ChipListFieldProps extends Omit<FieldProps, "formData"> {
  formData: Array<string>;
}

export interface ChipListFieldOptions {
  deletable?: boolean;
  addable?: boolean;
  variant?: "filled" | "outlined";
  size?: "medium" | "small";
  color?: "default" | "primary" | "secondary";
  allowDuplicates?: boolean;
}

const Container = styled("div")`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ChipListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(1),
}));

export const ChipList = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
}));

const InputContainer = styled("div")`
  width: 100%;
  display: flex;
`;

const SubmitButton = styled(Button)`
  min-width: fit-content;
  height: 48px;
  margin: 0 0 auto 16px;
  border-radius: 4px;
`;

export const ChipListField: FC<ChipListFieldProps> = ({
  name,
  schema,
  uiSchema,
  idSchema,
  formData,
  onChange,
  disabled,
  readonly,
  required,
}) => {
  const { t } = useTranslation("common");
  const title =
    schema.title || getValueFromUiSchema("title", uiSchema) || getValueFromUiSchema("label", uiSchema) || name;
  const description = getValueFromUiSchema("description", uiSchema);
  const options: ChipListFieldOptions | null = uiSchema?.["ui:options"] || null;

  const variant: "filled" | "outlined" = options?.variant || "filled";
  const color = options?.color || "primary";
  const size = options?.size === "medium" ? "medium" : "small";
  const addable = options?.addable ?? true;
  const deletable = options?.deletable ?? true;
  const allowDuplicates = options?.allowDuplicates ?? false;

  const [addChipValue, setAddChipValue] = useState<string>("");

  const currentValueIsDuplicateError: boolean = !allowDuplicates && !!(formData || []).find((d) => d === addChipValue);

  const handleAddChip = (event: MouseEvent | KeyboardEvent) => {
    if ("key" in event && event.key === "Enter") {
      // KULADM-207: Prevent form submission when trying to add new chips
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentValueIsDuplicateError || (event?.type === "keydown" && (event as KeyboardEvent).key !== "Enter")) {
      return;
    }
    if (addable && !!addChipValue) {
      onChange([...(Array.isArray(formData) ? formData : []), addChipValue]);
      setAddChipValue("");
    }
  };

  const handleDeleteChip = (index: number): void => {
    if (formData?.length > index) {
      const cpy = [...formData];
      cpy.splice(index, 1);
      onChange(cpy);
    }
  };

  if (
    schema?.type !== "array" ||
    typeof schema?.items !== "object" ||
    Array.isArray(schema.items) ||
    schema.items.type !== "string"
  ) {
    return (
      <Typography role="alert" color="error">
        {t("components.ChipListField.invalidConfiguration")}
      </Typography>
    );
  }

  const helperText = getValueFromUiSchema("help", uiSchema);
  const placeholderText = getValueFromUiSchema("placeholder", uiSchema);

  return (
    <Container>
      <KioTitle title={title} description={description} level={2} />
      <ChipListContainer>
        <ChipList>
          {((formData as Array<any>) || []).map((chipLabel, i) => (
            <Chip
              key={`${i}:${chipLabel}`}
              disabled={disabled || readonly}
              label={chipLabel}
              variant={variant}
              color={color}
              size={size}
              onDelete={!deletable ? undefined : () => handleDeleteChip(i)}
            />
          ))}
        </ChipList>
        {!disabled && !readonly && addable && (
          <>
            <InputContainer>
              <TextField
                id={idSchema[name]}
                name={t("components.ChipListField.AddChip")}
                variant={"filled"}
                size={"small"}
                value={addChipValue}
                error={currentValueIsDuplicateError}
                helperText={currentValueIsDuplicateError && t("components.ChipListField.NoDuplicates")}
                onChange={(e) => setAddChipValue(e.target.value)}
                required={required && !formData?.length}
                onKeyDown={handleAddChip}
                fullWidth
                placeholder={placeholderText ?? t("components.ChipListField.textFieldPlaceholder")}
              />
              <SubmitButton
                color="primary"
                variant={"outlined"}
                name={t("components.ChipListField.AddChipButtonLabel")}
                onClick={handleAddChip}
              >
                <Add />
                {t("components.ChipListField.AddChipButtonLabel")}
              </SubmitButton>
            </InputContainer>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        )}
      </ChipListContainer>
    </Container>
  );
};
export default ChipListField;
