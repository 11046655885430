import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NoAccounts, PersonAdd } from "@mui/icons-material";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import Api from "@/services/Api";
import type KioUser from "@/declarations/models/KioUser";
import type PaginationResult from "@/declarations/PaginationResult";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow, DataListRowInternal } from "@/components/DataList/DataListRow";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import type { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import type { CreateNewButton, SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { useDebounce } from "@/hooks/useDebounce";
import type { SelectOption } from "@/framework/KioForm/common/KioSelect";
import type { CustomButton } from "@/components/DataList/common/DataListOptions";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const mapperFn = (userItem: KioUser): DataListRow => {
  const numberOfLicenses = userItem.number_of_licenses || 0;

  return {
    key: String(userItem.id),
    title: userItem.name,
    subTitle: userItem.username,
    infoText: `ID: ${userItem.id}  ${
      numberOfLicenses ? `  🔑: ${numberOfLicenses}` : ""
    }\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0${
      userItem.is_banned ? "Banned" : userItem.activated ? "Activated" : "Not activated"
    }  `,
    chipContent: userItem.is_admin ? "Superuser" : "User",
    imageURL: userItem.image_url,
    updatedAt: userItem.updated_at,
    updatedBy: userItem.updated_by,
  };
};

const customSortOptions: Array<SortOptions> = [
  { prop: "name", direction: "asc", label: "components.list.sort.byTitleAsc" },
  { prop: "name", direction: "desc", label: "components.list.sort.byTitleDesc" },
  { prop: "auth_username", direction: "asc", label: "components.list.sort.byEmailAsc" },
  { prop: "auth_username", direction: "desc", label: "components.list.sort.byEmailDesc" },
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateDesc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "created_at", direction: "asc", label: "components.list.sort.byCreatedDateDesc" },
  { prop: "created_at", direction: "desc", label: "components.list.sort.byCreatedDateAsc" },
];

const filterOptions: SelectOption[] = [
  { label: "components.list.filter.noFilter", value: "no" },
  { label: "components.list.filter.isSuperuser", value: "is_superuser" },
  { label: "components.list.filter.notSuperuser", value: "not_superuser" },
  { label: "components.list.filter.isActivated", value: "is_activated" },
  { label: "components.list.filter.notActivated", value: "not_activated" },
  { label: "components.list.filter.isBanned", value: "is_banned" },
  { label: "components.list.filter.notBanned", value: "not_banned" },
];

const banUser = async (user: KioUser) => {
  const error = (await Api.banUser(user?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to ban user");
  }
};

const unbanUser = async (user: KioUser) => {
  const error = (await Api.unbanUser(user?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to unban user");
  }
};

export const UserView: FC = () => {
  const history = useNavigate();
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  const title = t("schemaTypes.user_plural");
  if (title) document.title = title;

  const { setSearchParameters, sortBy, sortAscending, filterBy, initialSortOption } = useSortAndFilterState({
    defaultSortBy: "name",
    sortOptions: customSortOptions,
  });

  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const handleUnbanUser = async (item: DataListRowInternal<KioUser>) => {
    if (window.confirm(t("views.userView.confirmUnBanUser"))) {
      await unbanUser(item.sourceItem);
      setLastFetchedTimestamp(Date.now());
    }
  };

  const handleBanUser = async (item: DataListRowInternal<KioUser>) => {
    if (window.confirm(t("views.userView.confirmBanUser"))) {
      await banUser(item.sourceItem);
      setLastFetchedTimestamp(Date.now());
    }
  };

  const displayConditionBan = (item: DataListRowInternal<KioUser>) => !item.sourceItem.is_banned;

  const displayConditionUnBan = (item: DataListRowInternal<KioUser>) => item.sourceItem.is_banned;

  const customButtons: Array<CustomButton> = [
    {
      tooltip: "Ban user",
      onClick: handleBanUser,
      icon: <NoAccounts />,
      displayCondition: displayConditionBan,
    },
    {
      tooltip: "Unban user",
      onClick: handleUnbanUser,
      icon: <PersonAdd />,
      displayCondition: displayConditionUnBan,
    },
  ];

  const handleOnItemsChanged = (sortProp?: string, sortDirection?: string, filter?: string) => {
    setSearchParameters(sortProp, sortDirection, filter);
    setLastFetchedTimestamp(Date.now());
  };

  const getItems: DataListProps<KioUser>["getItems"] = (page, page_size) => {
    const defaultValue: PaginationResult<KioUser> = {
      page,
      page_size,
      count: 0,
      total_count: 0,
      items: [],
    };

    return Api.getAllUsers({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
      is_superuser: filterBy === "is_superuser" ? true : filterBy === "not_superuser" ? false : undefined,
      is_activated: filterBy === "is_activated" ? true : filterBy === "not_activated" ? false : undefined,
      is_banned: filterBy === "is_banned" ? true : filterBy === "not_banned" ? false : undefined,
    }).fetchDirect(defaultValue);
  };

  const onItemClick = (user: KioUser) => {
    history(`${pathname}/${user.id}`);
  };

  const resetPageDeps = useMemo(() => [searchTerms], [searchTerms]);

  return (
    <Container>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.USER}`} />
      <DataList
        listTitle={title}
        getItems={getItems}
        mapperFn={mapperFn}
        onItemClick={onItemClick}
        customListItemButtons={customButtons}
        handleOnItemsChanged={handleOnItemsChanged}
        externalDataChanged={lastFetchedTimestamp}
        initialSortOption={initialSortOption}
        sortOptions={customSortOptions}
        filterOptions={filterOptions.map(({ label, value }) => ({ label: t(label), value }))}
        filter={filterBy}
        createNewButton={
          {
            onCreateNew: () => history(`${pathname}/create`),
            buttonLabel: `schemaTypes.create_user`,
          } as CreateNewButton
        }
        resetPageDeps={resetPageDeps}
        searchProp={
          {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp
        }
      />
    </Container>
  );
};

export default UserView;
