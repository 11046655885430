export const uiSchema = {
  "ui:title": "schemaTypes.owner",
  name: {
    "ui:field": "LocalizedField",
    items: {
      "ui:title": "generic.name",
    },
  },
  id: {
    "ui:title": "generic.id",
  },
  description: {
    "ui:field": "LocalizedField",
    items: {
      "ui:title": "generic.description",
      "ui:widget": "TextareaWidget",
    },
  },
  availability_url: {
    "ui:title": "generic.availability_url",
  },
  logo: {
    "ui:title": "generic.logo",
    "ui:colspan": 6,
    "ui:description": "generic.logo",
    "ui:field": "MediaSelectorField",
    "ui:options": {
      mediaTypes: ["image"],
      showFocalPoint: true,
      aspectRatio: "2:1",
    },
  },
  main_image: {
    "ui:colspan": 6,
    "ui:title": "generic.main_image",
    "ui:description": "generic.image",
    "ui:field": "MediaSelectorField",
    "ui:options": {
      mediaTypes: ["image"],
      showFocalPoint: true,
      aspectRatio: "2:1",
    },
  },
  location: {
    "ui:title": "generic.location",
    "ui:field": "LocationPickerField",
  },
};
