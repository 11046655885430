import type { FC } from "react";
import VimeoLogo from "@/assets/img/logos/Vimeo.jpg";
import EmbeddedContent from "@/components/EmbeddedContent";
import type { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";

export const VimeoPreview: FC<AudioPreviewProps> = ({ identifier, title }) => (
  <EmbeddedContent
    title={title || "Vimeo player"}
    src={`https://player.vimeo.com/video/${identifier}`}
    allow="fullscreen; picture-in-picture"
    allowFullScreen
    invalid={!identifier}
    fallbackImageSrc={VimeoLogo}
  />
);

export default VimeoPreview;
