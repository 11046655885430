import type { FC } from "react";
import { useMemo } from "react";
import { styled } from "@mui/material";
import Styles from "../../../../assets/styles/Styles";

export interface JsonContentPreviewProps {
  data: any;
  schemaId?: number;
}

const Container = styled("code")(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: Styles.Colors.PREVIEW_BACKGROUND_COLOR,
  color: theme.palette.getContrastText(Styles.Colors.PREVIEW_BACKGROUND_COLOR),
  width: "100%",
  height: "100%",
  overflow: "auto",
}));

export const JsonContentPreview: FC<JsonContentPreviewProps> = ({ data, schemaId }) => {
  const formattedData = useMemo<string>(() => {
    try {
      const dataAsObject = typeof data === "string" ? JSON.parse(data || "{}") : data;
      return JSON.stringify(dataAsObject, undefined, 2);
    } catch (e) {
      console.error("Unable to parse json-data: ", e);
      return "ERROR";
    }
  }, [data]);

  return (
    <Container>
      {schemaId && <p>schemaId: {schemaId}</p>}
      <pre>{formattedData}</pre>
    </Container>
  );
};

export default JsonContentPreview;
