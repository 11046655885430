import type { ChangeEvent, FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { getTemplate, type ObjectFieldTemplateProps } from "@rjsf/utils";
import styled from "@emotion/styled";
import { AppBar, Box, Paper, Tab, Tabs } from "@mui/material";
import { getSchemaTitle } from "@/utils/schema";

const Container = styled(Paper)`
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  .tab-content {
    display: flex;
    flex-wrap: wrap;
  }
`;

const parseTitle = (attribute: string, schema: any) => {
  try {
    return schema?.properties?.[attribute].title ?? attribute;
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.warn(err.message);
    }
    return attribute;
  }
};

interface TabPanelProps {
  children?: ReactNode;
  index?: number;
  value?: number;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...rest }) => (
  <div
    className="tab-content"
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...rest}
  >
    {value === index && <Box mt={2}>{children}</Box>}
  </div>
);

const TabsObjectTemplate: FC<Required<ObjectFieldTemplateProps>> = ({
  uiSchema,
  schema,
  properties,
  required,
  formContext,
  idSchema,
  registry,
}) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const options = uiSchema?.["ui:layout"]?.templateOptions;
  const TitleField = getTemplate("TitleFieldTemplate", registry, options);
  const DescriptionField = getTemplate("DescriptionFieldTemplate", registry, options);

  const handleChange = (event: ChangeEvent<{}>, idx: any) => {
    setCurrentIdx(idx);
  };

  useEffect(() => {
    if (options?.default?.field) {
      const idx = properties.findIndex((prop) => prop.name === options?.default?.field) ?? 0;
      setCurrentIdx(idx);
    }
  }, [options, properties]);

  return (
    <Container variant={"elevation"} elevation={4}>
      <TitleField
        schema={schema}
        registry={registry}
        id={`tabs-object-field-template-title-${idSchema.$id}`}
        title={getSchemaTitle(formContext, schema, uiSchema)}
        required={required}
      />
      {(uiSchema?.["ui:description"] || schema["description"]) && (
        <DescriptionField
          schema={schema}
          registry={registry}
          id={`tabs-object-field-template-description-${idSchema.$id}`}
          description={uiSchema["ui:description"] || schema["description"] || ""}
        />
      )}
      <AppBar position="static" color="default">
        <Tabs
          onChange={handleChange}
          value={currentIdx}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="options"
        >
          {properties.map((prop) => (
            <Tab key={prop?.name} label={parseTitle(prop?.name || "", schema)} {...a11yProps(currentIdx)} />
          ))}
        </Tabs>
      </AppBar>
      {properties.map((prop, index) => (
        <TabPanel key={`${index}`} value={currentIdx} index={index}>
          {prop.content}
        </TabPanel>
      ))}
    </Container>
  );
};

export default TabsObjectTemplate;
