import { createContext, useContext } from "react";

export interface BlockClipboard {
  value: any;
}

export interface BlockClipboardContextValue {
  currentClipboard: BlockClipboard | null;
  copy: (value: any) => void;
  paste: (pasteIntoArrayId$: string) => void;
}

export const BlockClipboardContext = createContext<BlockClipboardContextValue | null>(null);

export function useBlockClipboardContext(): BlockClipboardContextValue | null {
  return useContext(BlockClipboardContext);
}
