import { styled, Typography } from "@mui/material";
import Styles from "@/assets/styles/Styles";

export const SelectMediaLabel = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  backgroundColor: Styles.Colors.PREVIEW_BACKGROUND_COLOR,
  color: theme.palette.getContrastText(Styles.Colors.PREVIEW_BACKGROUND_COLOR),
  border: `1px solid ${theme.palette.getContrastText(Styles.Colors.PREVIEW_BACKGROUND_COLOR)}`,
  borderRadius: "2px",
  padding: theme.spacing(1.5, 3),
  textTransform: "initial",
  fontWeight: 700,
  "*:hover > &": {
    opacity: 0.5,
  },
}));
