import { Typography } from "@mui/material";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type Media from "@/declarations/models/Media";

export interface DocumentPreviewProps {
  media?: Media;
}

export const DocumentPreview: FC<DocumentPreviewProps> = ({ media }) => {
  const { t } = useTranslation("common");
  if (!media?.src) return <Typography color="error">{t("components.MediaPreview.UnableToPreview")}</Typography>;

  return <iframe src={media.src} width={"100%"} height={"100%"} />;
};

export default DocumentPreview;
