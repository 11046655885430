import type { FC } from "react";
import type { WidgetProps } from "@rjsf/utils";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MuiIconSelect from "@/components/MuiIconSelect";
import { getValueFromUiSchema } from "@/utils/schema";

export const MuiIconWidget: FC<WidgetProps> = ({ schema, uiSchema, value, onChange, label, placeholder }) => {
  const { t } = useTranslation("common");

  if (schema.type !== "string" && (!Array.isArray(schema.type) || !schema.type.includes("string"))) {
    return (
      <Typography color="error">
        {t("kioForm.widgets.MuiIconSelectWidget.invalidType", { fieldType: "string" })}
      </Typography>
    );
  }

  const title = getValueFromUiSchema("title", uiSchema);
  const helperText = getValueFromUiSchema("help", uiSchema);
  return (
    <MuiIconSelect
      label={title ? t(title) : label}
      value={value ? String(value) : ""}
      placeholder={placeholder}
      helpText={helperText}
      onChange={onChange}
    />
  );
};

export default MuiIconWidget;
