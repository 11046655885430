import { StrictMode } from "react";
import "./fonts.css";
import "./index.css";
import { CssBaseline, ThemeProvider, unstable_createMuiStrictModeTheme } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./Store";
import Settings from "./Settings";
import i18n from "./i18n/config";

const theme = unstable_createMuiStrictModeTheme(Settings.THEME_OPTIONS);

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StoreProvider>
    </I18nextProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: setup reporting of WebVitals
reportWebVitals();
