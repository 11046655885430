import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import type Media from "@/declarations/models/Media";
import "@google/model-viewer/dist/model-viewer";

export const Model3dPreview: FC<{
  id?: string;
  media?: Media;
}> = ({ id, media }) => {
  const { t } = useTranslation("common");
  if (!media?.src) return <Typography color="error">{t("components.MediaPreview.UnableToPreview")}</Typography>;

  return (
    <model-viewer
      id={id ?? `model-viewer-${media.identifier}`}
      alt={media.alt_text}
      src={media.src}
      camera-controls
      style={{ height: "100%", width: "100%", position: "absolute" }}
    ></model-viewer>
  );
};
