import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadcrumbNode from "../../components/BreadcrumbNode";
import type License from "../../declarations/models/License";
import Api from "../../services/Api";
import CreateOrEditRoutes from "./CreateOrEditRoutes";
import type { CreateOrEditProps } from "@/components/CreateOrEdit";
import type { DataListProps } from "@/components/DataList/DataList";
import type { DataListRow } from "@/components/DataList/DataListRow";
import type { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import type { RequestContext } from "@/declarations/RequestContext";
import { licenseSchema } from "@/declarations/schemas/license/licenseSchema";
import { licenseUiSchema } from "@/declarations/schemas/license/licenseUiSchema";
import { useDebounce } from "@/hooks/useDebounce";
import LicenseUserList from "@/views/admin/LicenseUserList";
import { useSortAndFilterState } from "@/hooks/useSortAndFilterState";

const isExpired = (date: Date | undefined): boolean => {
  if (!date) {
    return false;
  }
  try {
    return new Date(date).getTime() <= Date.now();
  } catch (err) {
    if (err instanceof RangeError) {
      return true; // illegal date
    } else {
      throw err;
    }
  }
};

const mapperFn = (licenseItem: License): DataListRow => ({
  key: String(licenseItem.id),
  title: isExpired(licenseItem.expires_at) ? `${licenseItem.name} (expired)` : licenseItem.name,
  subTitle: licenseItem.owner_name,
  infoText: `ID: ${licenseItem.id}${
    licenseItem.num_users
      ? ` 👤${licenseItem.num_users}${licenseItem.max_users ? `/${licenseItem.max_users}` : ""}`
      : ""
  }`,
  updatedAt: licenseItem.updated_at,
  updatedBy: licenseItem.updated_by,
});

const getLicenseFormData: CreateOrEditProps<License>["getFormData"] = (id: number): RequestContext<License> =>
  Api.getOneLicense(id);

const updateLicenseFormData: CreateOrEditProps<License>["onSubmit"] = (license: License): RequestContext<License> =>
  Api.updateLicense(license);

const createLicenseFormData: CreateOrEditProps<License>["onSubmit"] = (license: License): RequestContext<License> =>
  Api.createLicenses(license);

const deleteLicense = async (license: License) => {
  const error = (await Api.setLicenseToExpired(license?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to delete license");
  }
};

export const LicenseView: FC = () => {
  const { t } = useTranslation("common");
  const [showExpiredLicenses, setShowExpiredLicenses] = useState(false);

  const { setSearchParameters, sortBy, sortAscending, initialSortOption } = useSortAndFilterState({
    defaultSortBy: "name",
  });
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    setSearchParameters(sortProp, sortDirection);
    setLastFetchedTimestamp(Date.now());
  };

  const getLicenses: DataListProps<License>["getItems"] = (page, page_size) => {
    const defaultResponse = {
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    };
    return Api.getAllLicenses({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      include_expired: showExpiredLicenses,
      search: searchTerms,
    }).fetchDirect(defaultResponse);
  };

  const onToggleExpiredLicenses = (checked: boolean) => {
    setShowExpiredLicenses(checked);
    setLastFetchedTimestamp(Date.now());
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.LICENSE}`} />
      <CreateOrEditRoutes
        schema={licenseSchema}
        uiSchema={licenseUiSchema}
        goBackOnSubmit
        createFormData={createLicenseFormData}
        updateFormData={updateLicenseFormData}
        getFormData={getLicenseFormData}
        afterContent={<LicenseUserList />}
        listProps={{
          getItems: getLicenses,
          listTitle: t("schemaTypes.license_plural"),
          mapperFn,
          hideImage: true,
          onDeleteItem: deleteLicense,
          onToggle: onToggleExpiredLicenses,
          toggleLabel: "views.admin.licenses.toggleText",
          defaultViewType: ViewType.LIST,
          disableViewTypeSelection: true,
          handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          initialSortOption,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default LicenseView;
