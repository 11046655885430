import type { FC, RefObject } from "react";
import { useEffect, useRef, useState } from "react";
import { Fab, Menu, Tooltip, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DesktopWindows, ScreenRotation } from "@mui/icons-material";
import Styles from "@/assets/styles/Styles";
import type Locale from "@/declarations/models/Locale";
import Api from "@/services/Api";
import type PaginationResult from "@/declarations/PaginationResult";
import { StyledMenuItem } from "@/framework/KioForm/common/KioSelect";
import Loader from "@/framework/Loader";
import type { SchemaObject } from "@/views/cms/EditorView/EditDocumentLoader";

export enum DeviceType {
  DESKTOP = "desktop",
  TABLET = "tablet",
  MOBILE = "mobile",
}

const DEFAULT_AVAILABLE_DEVICE_TYPES = [DeviceType.MOBILE, DeviceType.TABLET, DeviceType.DESKTOP];

export enum DeviceRotation {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
}

export interface DeviceDimensions {
  width: number;
  height: number;
}

export interface ViewTypePreviewProps {
  schemas: SchemaObject;
  selectedLocale: string;
  formData: any;
  availableLocales: Locale[];
}

export interface Preview {
  availableDevices?: DeviceType[];
  disableRotation?: boolean;
  defaultDimensions: DeviceDimensions;
  defaultDevice: DeviceType;
  defaultOrientation: DeviceRotation;
  previewApp: string;
  url: string;
}

const PreviewWrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Preview = styled("iframe")`
  border: ${Styles.Dimensions.PREVIEW_BORDER_WIDTH} solid ${Styles.Colors.PREVIEW_BORDER_COLOR};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
`;

const Toolbar = styled("menu")`
  position: fixed;
  left: 50vw;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
`;

export const PreviewButton = styled(Fab)(
  ({ theme }) => `
  background: ${Styles.Colors.WHITE};
  margin: ${theme.spacing(0, 0, 2, 2)};
  :hover {
    filter: brightness(75%);
  }
`
);

export const deviceTypeLookup: { [key in DeviceType]: DeviceDimensions } = {
  [DeviceType.DESKTOP]: {
    width: Styles.Dimensions.PREVIEW_DESKTOP_WIDTH,
    height: Styles.Dimensions.PREVIEW_DESKTOP_HEIGHT,
  },
  [DeviceType.TABLET]: {
    width: Styles.Dimensions.PREVIEW_TABLET_WIDTH,
    height: Styles.Dimensions.PREVIEW_TABLET_HEIGHT,
  },
  [DeviceType.MOBILE]: {
    width: Styles.Dimensions.PREVIEW_MOBILE_WIDTH,
    height: Styles.Dimensions.PREVIEW_MOBILE_HEIGHT,
  },
};

interface PreviewMessage {
  formData: any;
  locale: string;
  availableLocales: Locale["language_code"][];
  schemas: SchemaObject;
}

const validUrl = (url: any): boolean => {
  let _url;
  try {
    _url = new URL(url);
  } catch (_) {
    return false;
  }
  return _url.protocol === "http:" || _url.protocol === "https:";
};

function postToPreviewApplication(previewRef: RefObject<HTMLIFrameElement>, url: string | null, data: PreviewMessage) {
  if (!url || !previewRef.current) {
    return;
  }
  if (!validUrl(url)) {
    console.info(`Url for preview is not valid. Url given: ${String(url)}`);
    return;
  }
  try {
    const targetUrl = new URL(url);
    // FIXME dev hack because protocol becomes http on localhost
    targetUrl.protocol = "https:"; // window.location.protocol;
    console.groupCollapsed(`Posting event to preview`);
    console.log("TO ORIGIN:\t", targetUrl.origin);
    console.log("DATA:\t\t", data);
    console.groupEnd();
    previewRef.current.contentWindow?.postMessage(JSON.stringify(data), targetUrl.origin);
  } catch (e) {
    console.error("Unable to post data to preview.", e);
  }
}

export const ViewTypePreview: FC<ViewTypePreviewProps> = ({
  formData,
  schemas,
  selectedLocale,
  availableLocales: availableLocalesIds,
}) => {
  const { t } = useTranslation("common");
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const iFrameWrapperRef = useRef<HTMLIFrameElement>(null);
  const [deviceMenuAnchorEl, setDeviceMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [previewTypeMenuAnchorEl, setPreviewTypeMenuAnchorEl] = useState<HTMLElement | null>(null);

  const [previewLoaded, setPreviewLoaded] = useState<boolean>(false);
  const [availableLocales, setAvailableLocales] = useState<Locale["language_code"][]>([]);

  const [previews, setPreviews] = useState<Array<Preview>>(schemas.uiSchema?.["ui:previews"]);
  const [prevUrl, setPrevUrl] = useState<string>(schemas.preview_url || "");
  const [isOrientationFlipped, setIsOrientationFlipped] = useState<boolean>(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceType>(DeviceType.DESKTOP);
  const [availableDeviceTypes, setAvailableDeviceTypes] = useState<DeviceType[]>([...DEFAULT_AVAILABLE_DEVICE_TYPES]);
  const [disableRotation, setDisableRotation] = useState<boolean>(false);
  const [timeoutAfterPreviewLoadComplete, setTimeoutAfterPreviewLoadComplete] = useState(false);
  const timerId = useRef<any>(undefined);
  const timeoutAfterPreviewLoad = 1000;

  const device = deviceTypeLookup[selectedDeviceType];
  const [scale, setScale] = useState<number>(1);

  const loading = !prevUrl || !formData || !schemas || !previewLoaded || !timeoutAfterPreviewLoadComplete;

  useEffect(() => {
    const uiPreviews = schemas.uiSchema?.["ui:previews"];
    if (uiPreviews) setPreviews(uiPreviews);
    else if (schemas.preview_url) setPrevUrl(schemas.preview_url);
  }, [schemas]);

  useEffect(() => {
    if (!!previews && previews.length > 0) {
      !!previews[0].availableDevices && setAvailableDeviceTypes(previews[0].availableDevices);
      !!previews[0].disableRotation && setDisableRotation(previews[0].disableRotation);
      setIsOrientationFlipped(previews[0].defaultOrientation !== DeviceRotation.LANDSCAPE);
      setSelectedDeviceType(previews[0].defaultDevice);
      setPrevUrl(previews[0].url);
    }
  }, [previews]);

  const handleChangeDeviceType = (dt: DeviceType): void => {
    setSelectedDeviceType(dt);
    setDeviceMenuAnchorEl(null);
  };

  const handleChangePreviewType = (pt: Preview) => {
    setPreviewLoaded(false);
    setAvailableDeviceTypes(pt.availableDevices ?? [...DEFAULT_AVAILABLE_DEVICE_TYPES]);
    setDisableRotation(pt.disableRotation ?? false);
    setSelectedDeviceType(pt.defaultDevice);
    setIsOrientationFlipped(pt.defaultOrientation !== DeviceRotation.LANDSCAPE);
    setPrevUrl(pt.url);
    setPreviewTypeMenuAnchorEl(null);
  };

  useEffect(() => {
    const cleanupFn = () => {
      clearTimeout(timerId.current);
      timerId.current = undefined;
    };
    if (!previewLoaded) {
      return () => {
        cleanupFn();
      };
    }
    if (previewLoaded) {
      setTimeoutAfterPreviewLoadComplete(false);
    }
    // Delay to make sure that external site used in iframe is loaded
    timerId.current = setTimeout(() => setTimeoutAfterPreviewLoadComplete(true), timeoutAfterPreviewLoad);
    return () => {
      cleanupFn();
    };
  }, [previewLoaded]);

  useEffect(() => {
    const defaultValue: PaginationResult<Locale> = { page: 0, page_size: 100, count: 0, total_count: 0, items: [] };
    const getLocales = Api.getAllLocales();
    const _availableLocales: Locale["language_code"][] = [];
    getLocales.fetchDirect(defaultValue).then((localesResponse) => {
      localesResponse.items.forEach((_locale) => {
        if (availableLocalesIds.some((_localeIdObj) => _localeIdObj?.id === _locale?.id)) {
          _availableLocales.push(_locale.language_code);
        }
      });
      setAvailableLocales(_availableLocales);
    });

    return () => {
      getLocales.abort();
    };
  }, [availableLocalesIds]);

  useEffect(() => {
    if (
      timeoutAfterPreviewLoadComplete &&
      previewLoaded &&
      formData &&
      Object.keys(formData).length > 0 &&
      availableLocales?.length > 0
    ) {
      postToPreviewApplication(iFrameRef, prevUrl, {
        formData,
        locale: selectedLocale,
        availableLocales,
        schemas,
      });
    }
  }, [formData, previewLoaded, selectedLocale, schemas, availableLocales, prevUrl, timeoutAfterPreviewLoadComplete]);

  useEffect(() => {
    calculateScale();
    const resizeListener = () => {
      calculateScale();
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [device, isOrientationFlipped]);

  const calculateScale = () => {
    let newScale: number = 1;
    if (isOrientationFlipped) {
      const height: number | undefined = iFrameWrapperRef.current?.clientHeight;
      if (height && device.height) newScale = (height - 100) / device.width;
    } else {
      const width: number | undefined = iFrameWrapperRef.current?.clientWidth;
      if (width && device.width) newScale = (width - 100) / device.width;
    }
    if (newScale !== 1 && newScale !== scale) setScale(newScale);
  };

  return (
    <>
      <PreviewWrapper ref={iFrameWrapperRef}>
        {loading && <Loader loadingText={"views.cms.loadingContent"} darkBackground />}
        <Preview
          sx={{ visibility: loading ? "hidden" : "visible" }}
          ref={iFrameRef}
          loading="eager"
          referrerPolicy="origin-when-cross-origin"
          width={isOrientationFlipped ? device.height : device.width}
          height={isOrientationFlipped ? device.width : device.height}
          style={{ transform: `scale(${scale}) translate(-50%, -50%)` }}
          name="Application preview"
          src={prevUrl}
          onLoad={() => setPreviewLoaded(true)}
        />
      </PreviewWrapper>
      <Toolbar>
        <Tooltip title={t("views.cms.preview.selectDeviceType")}>
          <PreviewButton
            aria-controls="devices"
            aria-haspopup="true"
            onClick={(e) => setDeviceMenuAnchorEl(e.target as HTMLButtonElement)}
          >
            <DesktopWindows />
          </PreviewButton>
        </Tooltip>
        {!disableRotation && (
          <Tooltip title={t("views.cms.preview.rotateScreen")}>
            <PreviewButton onClick={() => setIsOrientationFlipped((p) => !p)}>
              <ScreenRotation />
            </PreviewButton>
          </Tooltip>
        )}
        <Menu
          open={Boolean(deviceMenuAnchorEl)}
          onClose={() => setDeviceMenuAnchorEl(null)}
          id="devices"
          anchorEl={deviceMenuAnchorEl}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          autoFocus={false}
        >
          {Object.keys(deviceTypeLookup).map((dt) => {
            if (availableDeviceTypes.includes(dt as DeviceType)) {
              return (
                <StyledMenuItem
                  key={dt}
                  selected={dt === selectedDeviceType}
                  onClick={() => handleChangeDeviceType(dt as DeviceType)}
                  dense
                >
                  {t(`views.cms.preview.deviceType.${String(dt).toLowerCase()}`)}
                </StyledMenuItem>
              );
            }
          })}
        </Menu>
        {!!previews && previews.length > 1 && (
          <>
            <Tooltip title={t("views.cms.preview.selectPreviewApp")}>
              <PreviewButton onClick={(e) => setPreviewTypeMenuAnchorEl(e.target as HTMLButtonElement)}>
                <h1>[K]</h1>
              </PreviewButton>
            </Tooltip>
            <Menu
              open={Boolean(previewTypeMenuAnchorEl)}
              onClose={() => setPreviewTypeMenuAnchorEl(null)}
              id="previewType"
              anchorEl={previewTypeMenuAnchorEl}
              anchorOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              autoFocus={false}
            >
              {previews.map((pt) => (
                <StyledMenuItem
                  key={pt.previewApp}
                  selected={pt.url === prevUrl}
                  onClick={() => handleChangePreviewType(pt)}
                  dense
                >
                  {pt.previewApp}
                </StyledMenuItem>
              ))}
            </Menu>
          </>
        )}
      </Toolbar>
    </>
  );
};

export default ViewTypePreview;
