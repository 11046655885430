import type { FC } from "react";
import YouTubeLogo from "@/assets/img/logos/YouTube.png";
import EmbeddedContent from "@/components/EmbeddedContent";
import type { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";

export const YoutubePreview: FC<AudioPreviewProps> = ({ identifier, title }) => (
  <EmbeddedContent
    src={`https://youtube.com/embed/${identifier}`}
    title={title || "YouTube player"}
    allow="fullscreen; picture-in-picture"
    allowFullScreen
    invalid={!identifier}
    fallbackImageSrc={YouTubeLogo}
  />
);

export default YoutubePreview;
