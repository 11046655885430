import { useContext } from "react";
import type { MediaSelectorAPI } from "./MediaSelector";
import { MediaSelectorApi } from "./context";

/**
 * This hook provides access to the Media Selector API.
 */
export function useMediaSelector(): MediaSelectorAPI {
  return useContext(MediaSelectorApi);
}
