/**
 * Attempt to parse a value as JSON. returns the defaultValue if it fails.
 * @param value
 * @param defaultValue
 */
import { checkIsDirty } from "@/utils/obj";

/**
 * Whether or not the value is valid json
 * @param value
 */
export function isJson(value: any): boolean {
  const defaultValue = {};
  return parseJson(value, defaultValue) !== defaultValue;
}

export function parseJson(value: any, defaultValue: object = {}): object {
  if (!!value && typeof value === "object" && !Array.isArray(value)) {
    // The value is already an object
    return value;
  }
  if (!value || typeof value !== "string") {
    // The value is not json-serializable
    return defaultValue;
  }
  try {
    return JSON.parse(value);
  } catch (_) {
    return defaultValue;
  }
}

/**
 * Compare two JSON-structures and check if they are equal
 * @param json1
 * @param json2
 */
export function jsonEquals(json1: any, json2: any): boolean {
  return !checkIsDirty(parseJson(json1), parseJson(json2));
}
