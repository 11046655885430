import { styled } from "@mui/material";
import Styles from "@/assets/styles/Styles";

export const ActionButtonContainer = styled("span")`
  background-color: ${Styles.Colors.WHITE};
  z-index: 2;
  border-radius: 50%;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.2);
  & button {
    width: 28px;
    height: 28px;
    :hover {
      filter: brightness(50%);
    }
    & svg {
      width: 18px;
      height: 18px;
    }
  }
`;
