/*
 * Utils manipulating URLs
 * */
import Settings from "../Settings";
import type { QueryParams } from "@/declarations/QueryParams";

/**
 * Parse the current/provided query-params to a dictonary
 * @param defaultValues dictionary of default parameters
 * @param searchParams The params to parse. Uses the search-params from the current URL if not provided
 */
export function getQueryParams<R extends QueryParams>(defaultValues?: Partial<R>, searchParams?: string): R {
  const params: QueryParams = { ...defaultValues };
  const search: string = searchParams ?? window.location.search;
  if (search) {
    decodeURI(search)
      .replace("?", "")
      .split("&")
      .forEach((param) => {
        const [key, value] = param.split("=");
        if (key) {
          const strVal = String(value).toLowerCase();
          let valueWithCorrectType: string | number | boolean | undefined | null;
          if (value === undefined || value === "" || strVal === "undefined") {
            valueWithCorrectType = undefined;
          } else if (value === null || strVal === "null") {
            valueWithCorrectType = null;
          } else if (!Number.isNaN(Number(value))) {
            valueWithCorrectType = Number(value);
          } else if (strVal === "true" || strVal === "false") {
            valueWithCorrectType = strVal === "true";
          } else {
            valueWithCorrectType = value;
          }
          params[key] = valueWithCorrectType;
        }
      });
  }
  return params as R;
}

/**
 *
 * @param path
 * @param params
 */
export function parseURL(path: string | Array<string | number> = [], params: QueryParams = {}): string {
  if (typeof path === "string") {
    path = [path];
  }
  path = path.join("/");
  const query: string = Object.keys(params)
    .map((param) => {
      /*
       *  Converts arrays into query string:
       *  Ie. schema_ids = [1,2] -> schema_ids=1&schema_ids=2
       */
      if (params[param] && Array.isArray(params[param])) {
        const arrayParam: number[] | string[] = params?.[param] as []; // Burde ikke typescript skjønne at hvis noe er array så er det ikke string eller undefined
        const partialQuery: string = arrayParam.map((arrayParamValue) => `${param}=${arrayParamValue}`).join("&");
        return partialQuery;
      }
      return `${param}=${params[param]}`;
    })

    .join("&");
  return encodeURI(`${Settings.API_BASE_HOST}/${path}${query ? `?${query}` : ""}`);
}

// NOTE This seems to be the same as parseURL
/**
 *
 * @param o
 */
export function toQueryString(o: QueryParams): string {
  return `?${Object.keys(o)
    .filter((k) => o[k] !== undefined && o[k] !== null)
    .map((k) => `${k}=${encodeURIComponent(String(o[k]))}`)
    .join("&")}`;
}

/**
 * Replace a queryParam in the specified URL or add it if missing
 * @param url
 * @param paramName
 * @param value
 */
export function replaceQueryParam(url?: string, paramName?: string, value?: string): string {
  if (!url || !paramName || !value) {
    return url ?? "";
  }
  if (!url.includes("?")) {
    return `${url}?${paramName}=${value}`;
  }
  const [host] = url.split("?");
  const params = getQueryParams({}, url);
  params[paramName] = value;
  return `${host}?${toQueryString(params)}`;
}

/**
 * Adds a trailing slash if it's missing in the url
 * @param url
 */
export function addTrailingSlashIfMissing(url: string | undefined, replaceIfUndefined: string = "") {
  if (!url) {
    return replaceIfUndefined;
  }
  if (url.endsWith("/")) {
    return url;
  } else return `${url}/`;
}
