import { Button } from "@mui/material";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Api from "@/services/Api";

export const ReindexDocumentsButton: FC = () => {
  const { t } = useTranslation("common");
  const { id: schema_id } = useParams<{ id: string }>();

  const handleClick = () => {
    if (window.confirm(t("views.admin.schemas.confirmReindex"))) {
      const ctx = Api.reindexDocumentSchema(Number(schema_id));
      ctx.fetchDirect({}).finally(ctx.abort);
    }
  };

  return (
    <Button onClick={handleClick} variant={"contained"}>
      {t("views.admin.schemas.reindexDocuments")}
    </Button>
  );
};

export default ReindexDocumentsButton;
