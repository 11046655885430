import type { RJSFSchema } from "@rjsf/utils";

export const schema: RJSFSchema = {
  type: "object",
  properties: {
    name: {
      type: "object",
      patternProperties: {
        "^[a-zA-Z]{2}": {
          type: "string",
          minLength: 1,
        },
      },
    },
    id: {
      type: "integer",
      readOnly: true,
    },
    description: {
      type: "object",
      patternProperties: {
        "^[a-zA-Z]{2}": {
          type: "string",
        },
      },
    },
    availability_url: {
      type: ["string", "null"],
      format: "url",
    },
    slug: {
      type: "string",
      minLength: 1,
    },
    identifier: {
      type: ["string", "null"],
    },
    google_analytics_code: {
      type: ["string", "null"],
    },
    owner_id: {
      type: ["number", "null"],
    },
    logo: {
      $ref: "#/definitions/global/Media",
    },
    main_image: {
      $ref: "#/definitions/global/Media",
    },
    image_url: {
      type: ["string", "null"],
      format: "url",
    },
    location: {
      type: ["object", "null"],
    },
  },
  required: ["name", "slug"],
};
