export const FieldNames = [
  "LocalizedStringField",
  "LocationPickerField",
  "ChipList",
  "DMQueryField",
  "StateRelationPickerField",
  "DocumentRelation",
  "LocalizedField",
  "MediaSelectorField",
  "ImageMapField",
  "QRCodeField",
  "DMSelectorField",
  "MemoriaAPIField",
  "KulturnavAutocompleteField",
] as const;

export type FieldNamesList = (typeof FieldNames)[number];

export const WhiteListedFields: string[] = [
  "array",
  "boolean",
  "description",
  "one-of",
  "any-of",
  "null",
  "number",
  "object",
  "schema",
  "string",
  "title",
  "unsupported",
];
