import type { RJSFSchema, UiSchema } from "@rjsf/utils";
import { baseJsonSchema, baseUiSchema } from "@/views/cms/EditorView/mediaSchemas/baseMediaSchema";

const tempJson = JSON.parse(JSON.stringify(baseJsonSchema)) as RJSFSchema;
delete tempJson?.properties?.credits;
export const DMJsonSchema: RJSFSchema = tempJson;

const tempUi = JSON.parse(JSON.stringify(baseUiSchema)) as UiSchema;
delete tempUi.credits;
export const DMUiSchema: UiSchema = tempUi;
