import type { FC, ReactNode } from "react";
import styled from "@emotion/styled";

interface MainLayoutProps {
  header?: ReactNode;
  footer?: ReactNode;
}

const Container = styled.main`
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: minmax(auto, 100%);
  grid-template-areas:
    "header"
    "content"
    "footer";
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  grid-area: header;
`;
const ContentContainer = styled.main`
  grid-area: content;
  overflow: auto;
`;
const FooterContainer = styled.div`
  grid-area: footer;
`;

export const Layout: FC<MainLayoutProps | any> = ({ children, header, footer }) => (
  <Container>
    {header && <HeaderContainer>{header}</HeaderContainer>}
    <ContentContainer>{children}</ContentContainer>
    {footer && <FooterContainer>{footer}</FooterContainer>}
  </Container>
);

export default Layout;
