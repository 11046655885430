import type { FC } from "react";
import { useMemo } from "react";
import type { ObjectFieldTemplateProps } from "@rjsf/utils";
import { styled } from "@mui/material";
import { getValueFromUiSchema } from "@/utils/schema";
import GridTemplateContainer from "@/framework/KioForm/common/GridTemplateContainer";
import type { TemplateLayout } from "@/declarations/TemplateLayout";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  width: "100%",
  backgroundColor: theme.palette.background.paper,

  "&.no-padding": {
    padding: 0,
    "&> div": {
      gap: 0,
    },
  },
}));

const checkChildrenForPaddingNeed = (properties: ObjectFieldTemplateProps["properties"]) => {
  let check: boolean = true;
  properties.find((child, key) => {
    const childUiSchema = child.content.props.uiSchema;
    const childLayout: TemplateLayout | undefined = getValueFromUiSchema("layout", childUiSchema);
    if (typeof childLayout === "object") {
      if (childLayout?.template === "Section") {
        check = false;
      }
    }
  });
  return check;
};

const DefaultObjectFieldTemplate: FC<ObjectFieldTemplateProps> = ({ properties }) => {
  const isPaddingNeeded = useMemo(() => checkChildrenForPaddingNeed(properties), [properties]);

  return (
    <Container className={isPaddingNeeded ? "" : "no-padding"}>
      <GridTemplateContainer>{properties.map((ele: any) => ele.content)}</GridTemplateContainer>
    </Container>
  );
};

export default DefaultObjectFieldTemplate;
