import i18next from "i18next";
import type { FC } from "react";
import { useEffect } from "react";
import type { SideMenuDef, SideMenuItemDef, SideMenuProps } from "@/components/SideMenu/SideMenu";
import SideMenu from "@/components/SideMenu/SideMenu";
import type Action from "@/declarations/models/Action";
import type Menu from "@/declarations/models/Menu";
import type MenuItem from "@/declarations/models/MenuItem";
import Loader from "@/framework/Loader";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";

export interface DynamicMenuProps extends Pick<SideMenuProps, "showDocumentSearch"> {
  applicationId?: number;
  menu?: Array<Menu>;
}

export enum DocType {
  SCHEMA = "Schema",
  MEDIA = "Media",
  QR = "QR",
  DOC_BIN = "DocumentBin",
  MEDIA_BIN = "MediaBin",
}

const getPathFromAction = (action: Action) => {
  switch (action.doc_type) {
    case DocType.SCHEMA:
      return `?schema=${action.schema_id}${action?.chip_content ? `&chipContent=${action?.chip_content}` : ""}`;
    case DocType.QR:
      return "?schema=qr";
    case DocType.MEDIA:
      return `?schema=0&mediaType=${action.media_type}`;
    case DocType.DOC_BIN:
      return "?schema=documentbin";
    case DocType.MEDIA_BIN:
      return "?schema=mediabin";
    default:
      return "";
  }
};

export const DynamicMenu: FC<DynamicMenuProps> = ({ menu, applicationId, showDocumentSearch }) => {
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [applicationMenus, setApplicationMenus] = useAsyncSafeState<Array<SideMenuDef>>([]);
  const selectedLocale = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);

  function mapMenusToSideMenu(menus: Array<Menu>): Array<SideMenuDef> {
    const mapToItem = (item: MenuItem, items: Array<MenuItem>): SideMenuItemDef =>
      ({
        label: getLocalizedString(item.title),
        path: getPathFromAction(item.action),
        icon: item.mui_icon,
        subItems: item.sub_menu_items?.map((subItem) => mapToItem(subItem, items)) || [],
      } as SideMenuItemDef);
    return menus?.map(
      (menu) =>
        ({
          label: getLocalizedString(menu.title),
          items: menu.menu_items?.map((item) => mapToItem(item, menu.menu_items || [])),
        } as SideMenuDef)
    );
  }

  useEffect(() => {
    if (menu) {
      startLoading();
      setApplicationMenus(mapMenusToSideMenu(menu));
      stopLoading();
    } else {
      if (applicationId) {
        startLoading();
        const getApp = Api.getOneApplication(applicationId);
        getApp
          .fetchDirect(null)
          .then((app) => {
            if (app?.menu && app?.menu.length > 0) {
              const { menu } = app;
              setApplicationMenus(mapMenusToSideMenu(menu));
            }
          })
          .finally(stopLoading);
      }
    }
  }, [menu, applicationId, i18next.language]);

  if (isLoading) {
    return <Loader />;
  }

  return <SideMenu menus={applicationMenus} showDocumentSearch={showDocumentSearch} />;
};

export default DynamicMenu;
