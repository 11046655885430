import { useEffect, useRef } from "react";

/**
 * Returns previous value of a given value
 * @param value
 * @param initialValue
 */
export function usePrevious<T>(value: T, initialValue?: T): T | undefined {
  const ref = useRef<T | undefined>(initialValue);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
