import { List, ListSubheader, styled } from "@mui/material";
import type { FC, ReactNode } from "react";
import SideMenuItem from "@/components/SideMenu/SideMenuItem";
import DocumentSearch from "@/framework/DocumentSearch";

export interface SideMenuItemDef {
  label: string;
  path: string;
  icon?: string | ReactNode;
  subItems?: Array<SideMenuItemDef>;
}

export interface SideMenuDef {
  label: string;
  icon?: string | ReactNode;
  items?: Array<SideMenuItemDef>;
}

export interface SideMenuProps {
  menus: Array<SideMenuDef>;
  showDocumentSearch?: boolean;
}

const MenuItemList = styled(List)(
  ({ theme }) => `
    width: 100%;
    .Mui-selected {
      padding-left: 12px;
      border-left: ${theme.palette.primary.main} solid 4px;
    }
    .MuiListSubheader-root {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.5rem;
      background-color: transparent;
    },
  `
);

export const SideMenu: FC<SideMenuProps> = ({ menus, showDocumentSearch = false }) => (
  <>
    {showDocumentSearch && <DocumentSearch />}
    {menus &&
      menus.map((menu, i) => (
        <MenuItemList key={menu.label ?? i} role="list">
          <ListSubheader>{menu.label}</ListSubheader>
          {menu?.items?.map((item, j) => (
            <SideMenuItem key={`${menu.label}-item-${item.label}-idx-${j}`} item={item} />
          ))}
        </MenuItemList>
      ))}
  </>
);

export default SideMenu;
