import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { Autocomplete, Chip, CircularProgress, debounce, styled, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import { distinctBy } from "@/utils/arrays";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { ChipList, ChipListContainer } from "@/framework/KioForm/fields/ChipListField";

const Container = styled("div")`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

// TODO: 22/03/2024 separate beta-environment?
const KULTURNAV_API_URL = "https://kulturnav.org/api/v1.5/autocomplete";

async function fetchKulturnavAutocomplete(
  entityType: string,
  propertyType: string,
  lang: string,
  query: string,
  dataset?: string
): Promise<KulturnavAutocompleteResponse> {
  const url = new URL(KULTURNAV_API_URL);
  url.searchParams.append("entityType", entityType);
  url.searchParams.append("propertyType", propertyType);
  url.searchParams.append("lang", lang);
  url.searchParams.append("query", query);
  if (dataset) {
    url.searchParams.append("dataset", dataset);
  }
  const response = await fetch(url.toString());
  return response.json();
}

const fullKulturnavItemCache = new Map<string, Promise<KulturnavFullItem>>();
async function fetchKulturnavItem(uuid: string): Promise<KulturnavFullItem> {
  if (fullKulturnavItemCache.has(uuid)) {
    return fullKulturnavItemCache.get(uuid)!;
  }
  const response = await fetch(`https://kulturnav.org/api/v1.5/${uuid}`);
  const item = response.json();
  fullKulturnavItemCache.set(uuid, item);
  return item;
}

type KulturnavAutocompleteItem = {
  uuid: string;
  caption: string | { [lang: string]: string; "*": string };
  datasetUuid: string;
  datasetCaption: string;
};

type KulturnavFullItem = {
  uuid: string;
  caption: {
    [lang: string]: string;
    "*": string;
  };
  properties: {
    [key: string]: unknown;
  };
};

type KulturnavAutocompleteResponse = {
  fullMatch: KulturnavAutocompleteItem[];
  startMatch: KulturnavAutocompleteItem[];
};
const kulturnavAcceptedLanguages = ["no", "nn", "sv", "en", "fi", "et"];

interface KulturnavAutocompleteProps {
  value: string[];
  onChange: (value: string[]) => void;
  locale: string;
  entityType?: string;
  propertyType?: string;
  label?: string;
  dataset?: string;
}

export const KulturnavAutocomplete: FC<KulturnavAutocompleteProps> = ({
  value,
  onChange,
  locale,
  label,
  dataset,
  entityType = "Concept",
  propertyType = "nativeCompoundName",
}) => {
  const { t, i18n } = useTranslation("common");
  const getLocalizedString = resolvePotentiallyLocalizedString(i18n.language);
  const [options, setOptions] = useState<KulturnavAutocompleteItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedKulturnavItems, setSelectedKulturnavItems] = useState<KulturnavAutocompleteItem[]>([]);

  const kulturnavLanguage = useMemo(() => (kulturnavAcceptedLanguages.includes(locale) ? locale : "no"), [locale]);

  const fetchOptions = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly KulturnavAutocompleteItem[]) => void) => {
        setLoading(true);
        fetchKulturnavAutocomplete(entityType, propertyType, kulturnavLanguage, request.input, dataset).then(
          (response) => {
            setLoading(false);
            callback([...(response.startMatch ?? []), ...(response.fullMatch ?? [])]);
          }
        );
      }, 400),
    [kulturnavLanguage]
  );

  useEffect(() => {
    if (value.length) {
      Promise.all(value.map((uuid) => fetchKulturnavItem(uuid))).then((items) => {
        setSelectedKulturnavItems(
          items.map((item) => ({
            uuid: item.uuid,
            caption: item.caption,
            datasetUuid: "",
            datasetCaption: "",
          }))
        );
      });
    } else {
      setSelectedKulturnavItems([]);
    }
  }, [value]);

  useEffect(() => {
    let active = true;

    if (inputValue === "" || inputValue?.length < 3) {
      setOptions([]);
      return undefined;
    }

    fetchOptions({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: KulturnavAutocompleteItem[] = [];

        if (selectedKulturnavItems) {
          newOptions = [...selectedKulturnavItems];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(distinctBy(newOptions, "uuid"));
      }
    });

    return () => {
      active = false;
    };
  }, [selectedKulturnavItems, inputValue, fetchOptions]);

  const getOptionLabel = (option: KulturnavAutocompleteItem) => {
    const { caption } = option;
    return typeof caption === "string" ? caption : getLocalizedString(caption) ?? "";
  };

  const handleDeleteChip = (option: KulturnavAutocompleteItem) => {
    setSelectedKulturnavItems((items) => items.filter((i) => i.uuid !== option.uuid));
    onChange(selectedKulturnavItems.filter((i) => i.uuid !== option.uuid).map((i) => i.uuid));
  };

  const autoCompleteOnChange = (evt, value, reason) => {
    if (reason === "selectOption") {
      setInputValue("");
      const newKulturnavItems = [...selectedKulturnavItems, value];
      onChange(newKulturnavItems.map((i) => i.uuid));
    }
  };

  const autoCompleteOnInputChange = (evt, value, reason) => {
    if (!evt && reason === "reset") {
      setInputValue("");
      return;
    }
    setInputValue(value);
  };

  return (
    <Container>
      <KioTitle title={label} level={2} />

      <ChipListContainer>
        <ChipList>
          {(selectedKulturnavItems || []).map((chipLabel, i) => (
            <Chip
              key={`${i}:${chipLabel.uuid}`}
              label={getOptionLabel(chipLabel)}
              variant="filled"
              color="primary"
              size="small"
              onDelete={() => handleDeleteChip(chipLabel)}
            />
          ))}
        </ChipList>

        <Autocomplete
          disableClearable={inputValue === ""}
          size={"small"}
          autoComplete
          filterOptions={(opts) => opts.filter((opt) => !selectedKulturnavItems.some((item) => item.uuid === opt.uuid))}
          getOptionLabel={getOptionLabel}
          inputValue={inputValue}
          onChange={autoCompleteOnChange}
          onInputChange={autoCompleteOnInputChange}
          noOptionsText={t("search.noHits")}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              // label={label}
              variant="filled"
              InputProps={{
                ...params.InputProps,
                placeholder: t("search.inputLabel"),
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderTags={() => null}
          renderOption={(props, option) => (
            <li {...props} key={option.uuid}>
              {getOptionLabel(option)}
            </li>
          )}
        />
      </ChipListContainer>
    </Container>
  );
};
