import type { FC } from "react";
import { useState } from "react";
import { Checkbox, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormButtons from "@/components/FormButtons";
import type { DMSearchResult } from "@/declarations/models/DMSearchResult";
import Styles from "@/assets/styles/Styles";
import { useLoadingState } from "@/hooks/useLoadingState";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import Api from "@/services/Api";
import Loader from "@/framework/Loader";

export interface SelectedDMItemsTabContentProps {
  selectedItems: Array<DMSearchResult>;
  onCancel: () => void;
  onSubmit: () => void;
  ownerId?: number;
  instanceId?: number;
}

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: Styles.Colors.DATA_LIST_IMAGE_BACKGROUND_COLOR,
}));

const Image = styled("img")(({ theme }) => ({
  width: Styles.Dimensions.DATA_LIST_GRID_ITEM_SIZE,
  height: Styles.Dimensions.DATA_LIST_GRID_ITEM_SIZE,
  objectFit: "contain",
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(1),
  backgroundColor: "#FFFFFF",
  borderRadius: "50%",
  boxShadow: theme.shadows[6],
}));

export const SelectedDMItemsTabContent: FC<SelectedDMItemsTabContentProps> = ({
  selectedItems,
  onCancel,
  onSubmit,
  ownerId,
  instanceId,
}) => {
  const { t } = useTranslation("common");
  const [removedItems, setRemovedItems] = useState<Array<DMSearchResult>>([]);

  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const isRemoved = (item: DMSearchResult): boolean => removedItems.includes(item);
  const removeItem = (item: DMSearchResult) => setRemovedItems((prev) => [...prev, item]);
  const restoreItem = (item: DMSearchResult) => setRemovedItems((prev) => prev.filter((i) => i !== item));

  const toggleRemoveItem = (item: DMSearchResult, checked: boolean) => {
    if (checked) {
      restoreItem(item);
    } else {
      removeItem(item);
    }
  };

  const handleSaveSelected = () => {
    startLoading();
    Promise.all(
      selectedItems
        .filter((item) => !removedItems.includes(item))
        .map(
          (item) =>
            ({
              media_type: MediaType.DM,
              src: item.thumbnail_src,
              name: item.artifact_ingress_title,
              thumbnail_src: item.thumbnail_src,
              filename: item.artifact_defaultMediaIdentifier,
              identifier: item.identifier_id,
              owner_id: ownerId as unknown,
              application_instance_id: instanceId,
            } as Media)
        )
        .map(async (media) => Api.createMedia(media).fetchDirect(null))
    )
      .then(onSubmit)
      .finally(stopLoading);
  };

  return (
    <>
      <Typography variant="h4" component="p">
        {t("components.MediaSelector.AddDMContent.youHaveSelected")}
      </Typography>
      <Grid container spacing={1}>
        {selectedItems.map((item) => {
          const itemIsRemoved = isRemoved(item);
          return (
            <Grid item key={item.artifact_uuid}>
              <ImageContainer>
                <CheckBoxContainer>
                  <Checkbox
                    disabled={isLoading}
                    checked={!itemIsRemoved}
                    onChange={(_, checked) => toggleRemoveItem(item, checked)}
                  />
                </CheckBoxContainer>
                <Image src={item.thumbnail_src} alt={item.artifact_ingress_title} />
              </ImageContainer>
            </Grid>
          );
        })}
      </Grid>
      {isLoading && <Loader />}
      <FormButtons
        pt={2}
        disableCancelButton={isLoading}
        onCancel={onCancel}
        onSubmit={handleSaveSelected}
        disableSubmitButton={isLoading || !selectedItems.length || (!ownerId && !instanceId)}
        submitButtonLabel="components.MediaSelector.AddDMContent.addSelected"
      />
    </>
  );
};

export default SelectedDMItemsTabContent;
