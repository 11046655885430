import type { FC, ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import type DocumentRelation from "@/declarations/models/DocumentRelation";

export enum EditorActionType {
  DOCUMENT_RELATIONS = "DOCUMENT_RELATIONS",
  DOCUMENT_RELATIONS_ADD = "DOCUMENT_RELATIONS_ADD",
  DOCUMENT_REFERENCED_BY = "DOCUMENT_REFERENCED_BY",
  DOCUMENT_IDS_ADD = "DOCUMENT_IDS_ADD",
  DOCUMENT_RELATION_UPDATE = "DOCUMENT_RELATION_UPDATE",
}

export interface EditorAction {
  type: EditorActionType;
  payload: any;
}

export interface EditorState {
  documentRelations: DocumentRelation[] | null;
  documentReferencedBy: DocumentRelation[] | null;
  documentIds: number[] | null;
}

const initialState: EditorState = {
  documentRelations: null,
  documentReferencedBy: null,
  documentIds: null,
};

export type EditorStateValue = [state: EditorState, dispatch: (action: EditorAction) => void];

export const EditorStore = createContext<EditorStateValue>([initialState, () => {}]);

class Reducers {
  public static setDocumentRelations = (state: EditorState, documentRelations: DocumentRelation[]): EditorState => ({
    ...state,
    documentRelations,
  });
  public static addDocumentRelations = (state: EditorState, documentRelations: DocumentRelation[]): EditorState => {
    const newRelations = documentRelations.filter(
      (doc) => !state.documentRelations?.some((_doc) => _doc.document_id === doc.document_id)
    );
    if (newRelations.length) {
      return { ...state, documentRelations: [...(state.documentRelations ?? []), ...newRelations] };
    }
    return state;
  };
  public static setDocumentReferencedBy = (
    state: EditorState,
    documentReferencedBy: DocumentRelation[]
  ): EditorState => ({ ...state, documentReferencedBy });
  public static addDocumentIds = (state: EditorState, documentIds: number[]): EditorState => {
    const newIds = documentIds.filter((id) => !state.documentIds?.includes(id));
    if (newIds.length) {
      return { ...state, documentIds: [...(state.documentIds ?? []), ...newIds] };
    }
    return state;
  };
  public static updateDocumentRelation = (state: EditorState, relation: DocumentRelation): EditorState => {
    const stateRels = state.documentRelations?.slice();
    const index = stateRels?.findIndex((rel) => rel.document_id === relation.document_id) ?? -1;
    if (stateRels && index >= 0) {
      return { ...state, documentRelations: [...stateRels.slice(0, index), relation, ...stateRels.slice(index + 1)] };
    }
    return state;
  };
}

export const useEditorStore = () => {
  const [state, dispatch] = useContext(EditorStore);

  const setDocumentRelations = useCallback(
    (relations: DocumentRelation[]) => {
      dispatch({
        type: EditorActionType.DOCUMENT_RELATIONS,
        payload: relations,
      });
    },
    [dispatch]
  );

  const addDocumentRelations = useCallback(
    (relations: DocumentRelation[]) => {
      dispatch({
        type: EditorActionType.DOCUMENT_RELATIONS_ADD,
        payload: relations,
      });
    },
    [dispatch]
  );

  const setDocumentReferencedBy = useCallback(
    (relations: DocumentRelation[]) => {
      dispatch({
        type: EditorActionType.DOCUMENT_REFERENCED_BY,
        payload: relations,
      });
    },
    [dispatch]
  );

  const addDocumentIds = useCallback(
    (ids: number[]) => {
      dispatch({
        type: EditorActionType.DOCUMENT_IDS_ADD,
        payload: ids,
      });
    },
    [dispatch]
  );

  const updateDocumentRelation = useCallback(
    (relation: DocumentRelation) => {
      dispatch({
        type: EditorActionType.DOCUMENT_RELATION_UPDATE,
        payload: relation,
      });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      state,
      setDocumentReferencedBy,
      setDocumentRelations,
      addDocumentRelations,
      addDocumentIds,
      updateDocumentRelation,
    }),
    [state, setDocumentRelations]
  );
};

export const EditorStoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const state = useReducer((_state: EditorState, action: EditorAction) => {
    switch (action.type) {
      case EditorActionType.DOCUMENT_RELATIONS:
        return Reducers.setDocumentRelations(_state, action.payload);
      case EditorActionType.DOCUMENT_RELATIONS_ADD:
        return Reducers.addDocumentRelations(_state, action.payload);
      case EditorActionType.DOCUMENT_REFERENCED_BY:
        return Reducers.setDocumentReferencedBy(_state, action.payload);
      case EditorActionType.DOCUMENT_IDS_ADD:
        return Reducers.addDocumentIds(_state, action.payload);
      case EditorActionType.DOCUMENT_RELATION_UPDATE:
        return Reducers.updateDocumentRelation(_state, action.payload);
      default:
        return _state;
    }
  }, initialState);
  return <EditorStore.Provider value={state}>{children}</EditorStore.Provider>;
};
