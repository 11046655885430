import type { PaginationProps } from "@mui/material";
import { Box, styled, Typography, Pagination } from "@mui/material";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SelectOption } from "@/framework/KioForm/common/KioSelect";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";

export interface KioPaginatorProps {
  /**
   * An array of available page sizes. E.g. [5, 10, 25, 50, 100]
   */
  pageSizes?: Array<number>;
  /**
   * The pagesize to use by default.
   * Should be one of the selectable items in "pageSizes".
   */
  defaultPageSize?: number;
  /**
   * The total number of items that potentially could be loaded.
   * Used to calculate the number of pages to display.
   * If not provided, the user can turn the page indefinitely
   */
  totalNumberOfItems: number;
  /**
   * Called when the page changes.
   * @param page The page to load (starting at 0)
   * @param pageSize The number of items to load
   */
  onPageChange: (page: number, pageSize: number) => void;
  /**
   * Whether to display the previous/next button
   */
  showPreviousNextButtons?: boolean;
  /**
   * Whether to display the first and last -page buttons
   */
  showFirstLastButtons?: boolean;
  /**
   * Hides (and effectively disables) the selector for page-size
   */
  hidePageSizeSelector?: boolean;
  resetPageDeps: Array<any>;
}

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  gap: "16px",
});

export const KioPaginator: FC<KioPaginatorProps> = ({
  pageSizes = [5, 10, 25, 50, 100],
  defaultPageSize = 10,
  totalNumberOfItems,
  onPageChange,
  showPreviousNextButtons = true,
  showFirstLastButtons = true,
  hidePageSizeSelector = false,
  resetPageDeps,
}) => {
  const { t } = useTranslation("common");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(defaultPageSize);

  const totalNumberOfPages: number = Math.ceil(totalNumberOfItems / currentPageSize);

  useEffect(() => {
    setCurrentPage(0);
  }, [...resetPageDeps]);

  useEffect(() => {
    onPageChange(currentPage, currentPageSize);
  }, [currentPage, currentPageSize, onPageChange]);

  const handlePageChange: PaginationProps["onChange"] = (event, page) => {
    setCurrentPage(page - 1);
  };

  const handlePageSizeChanged = (value: number) => {
    const _pageSize = value || defaultPageSize;
    setCurrentPage(0);
    setCurrentPageSize(_pageSize!);
  };

  return (
    <Container>
      {!hidePageSizeSelector && (
        <>
          <Typography variant="subtitle1">{t("components.list.paginator.rowsPerPage")}</Typography>
          <Box>
            <KioSelect
              value={currentPageSize}
              options={pageSizes?.map((size) => ({ value: size, label: size.toString() } as SelectOption))}
              onChange={handlePageSizeChanged}
              disabled={hidePageSizeSelector}
              isHeaderVariant
            />
          </Box>
        </>
      )}
      <Pagination
        color="secondary"
        variant="outlined"
        page={currentPage + 1}
        count={totalNumberOfPages || 0}
        onChange={handlePageChange}
        showFirstButton={showFirstLastButtons}
        showLastButton={totalNumberOfPages !== null && showFirstLastButtons}
        hideNextButton={!showPreviousNextButtons}
        hidePrevButton={!showPreviousNextButtons}
      />
    </Container>
  );
};
export default KioPaginator;
