/*
 * Utils for all things schema (json and ui)
 * */
import type { RJSFSchema, UiSchema } from "@rjsf/utils";
import { getValue } from "@/utils/obj";
import type { KioFormContext } from "@/framework/KioForm/KioForm";

export function parseSchema(schema?: string | RJSFSchema | UiSchema | null): RJSFSchema | UiSchema {
  if (!schema) return {};
  if (typeof schema !== "string") return schema;
  try {
    return JSON.parse(schema);
  } catch (e) {
    return {};
  }
}

/**
 * Fields in UI-Schema that should be translated
 */
export const translatableFieldNames = ["ui:title", "ui:help", "ui:description", "ui:placeholder", "ui:label"];

/**
 * Creates a mapping function, which will map one object (a schema definition) to another,
 * but where certain (string) fields have been processed by supplied function (translate function)
 *
 * @param t the processing function to run on the field values
 * @param fields list of field names to process
 */
export function createSchemaTranslator(t: (s: string) => string, tFallback: (s: string) => string, fields: string[]) {
  function translate<T>(schema: T | T[]): T | T[] {
    if (!schema) {
      return schema;
    }
    if (Array.isArray(schema)) {
      return schema.map(translate) as T[];
    } else if (typeof schema === "object") {
      return Object.entries(schema).reduce(objectReducer, {} as T);
    } else {
      return schema;
    }
  }

  function objectReducer<T>(acc: T, [field, value]: [string, any]) {
    if (fields.includes(field) && typeof value === "string") {
      const translator = value.startsWith("db.") ? t : tFallback;

      if (value.startsWith("db")) {
        value = value.substring(3);
      }

      return {
        ...acc,
        [field]: translator(value),
      };
    }
    if (typeof value === "object") {
      return {
        ...acc,
        [field]: translate(value),
      };
    }
    return {
      ...acc,
      [field]: value,
    };
  }

  return function result<T>(schema: T): T {
    return translate(schema) as T;
  };
}

/**
 * Fetch a value with the given prop from the UiSchema, if it is present
 * @param prop
 * @param uiSchema
 */
export function getValueFromUiSchema<T = string>(prop: string, uiSchema?: UiSchema): T | undefined {
  const options = uiSchema?.["ui:options"] || {};
  return uiSchema?.[`ui:${prop}`] ?? options?.[prop];
}

/**
 * Get the title from the current schema/uiSchema/formData
 * @param schema
 * @param uiSchema
 * @param formContext
 * @param formData
 */
export function getSchemaTitle(
  formContext: KioFormContext,
  schema?: RJSFSchema,
  uiSchema?: UiSchema,
  formData?: any
): string {
  const { selectedLocale } = formContext || {};

  if (!schema && !uiSchema) {
    return "";
  }

  const dynamicTitleProp = getValueFromUiSchema("titleProp", uiSchema);

  if (!!dynamicTitleProp) {
    if (dynamicTitleProp === ".") {
      if (schema?.type !== "object" && schema?.type !== "array") {
        return String(formData || "");
      }
      if (!!formData && Array.isArray(formData) && formData.every((item) => typeof item === "string")) {
        return formData.join(", ");
      }
    }

    if (!!formData) {
      const dynamicValue = getValue(formData, dynamicTitleProp, "");
      if (!!dynamicValue) {
        if (Array.isArray(dynamicValue)) {
          if (dynamicValue.every((item) => typeof item === "string")) {
            return dynamicValue.join(", ");
          }
        } else if (typeof dynamicValue === "object") {
          if (dynamicValue.hasOwnProperty(selectedLocale)) {
            return String(dynamicValue[selectedLocale] ?? "");
          }
        } else {
          return String(dynamicValue);
        }
      }
    } else if (schema?.type === "object") {
      const referencedFieldTitle =
        getValueFromUiSchema(dynamicTitleProp, uiSchema?.[dynamicTitleProp]) ||
        getValue(schema, `properties.${dynamicTitleProp}.title`);
      if (referencedFieldTitle) {
        return referencedFieldTitle;
      }
    }
  }

  return getValueFromUiSchema("title", uiSchema) ?? schema?.title ?? "";
}
