import { Tooltip } from "@mui/material";
import { Link as LinkIcon } from "@mui/icons-material";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import baseStyled from "@emotion/styled";
import Api from "@/services/Api";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import Settings from "@/Settings";

const IconIndicator = baseStyled("div")(({ isLoading }: { isLoading: boolean }) => ({
  color: "gray",
  cursor: isLoading ? "progress !important" : "inherit",
  borderRadius: "50%",
  backgroundColor: "#00000000",
  height: "30px",
  width: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: "#00000010",
  },
}));

interface IsFromSharedInstanceIndicatorProps {
  applicationInstanceId?: number;
  selectedLocale?: string;
}

const IsFromSharedInstanceIndicator: FC<IsFromSharedInstanceIndicatorProps> = ({
  applicationInstanceId,
  selectedLocale,
}) => {
  const { t } = useTranslation("common");
  const [ownerName, setOwnerName] = useState<string | { [key: string]: { any } }>("...");
  const [hasBeenHovered, setHasBeenHovered] = useState<boolean>(false);
  const getPotentiallyLocalizedString = resolvePotentiallyLocalizedString(selectedLocale || Settings.DEFAULT_LOCALE);
  useEffect(() => {
    if (!applicationInstanceId || !hasBeenHovered) return;
    Api.getOneInstance(applicationInstanceId)
      .fetchDirect({})
      .then((instance) => {
        setOwnerName(instance.owner_name);
      });
  }, [applicationInstanceId, hasBeenHovered]);

  return (
    <Tooltip
      title={`${t("IsFromSharedInstanceIndicator.ownerPrefix")}: ${
        getPotentiallyLocalizedString(ownerName) || ownerName
      }`}
    >
      <IconIndicator
        isLoading={ownerName === "..."}
        onMouseOver={() => {
          setHasBeenHovered(true);
        }}
        size="small"
      >
        <LinkIcon fontSize="small" />
      </IconIndicator>
    </Tooltip>
  );
};

export default IsFromSharedInstanceIndicator;
