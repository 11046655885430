import { Add, FolderOpenOutlined, PermMediaOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { Box, Button, ButtonBase, Checkbox, IconButton, styled, Tooltip } from "@mui/material";
import type { FieldProps } from "@rjsf/utils";
import type { TFunction } from "i18next";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledImage } from "@/components/DataList/ViewTypes/GridView";
import MediaPreview from "@/components/MediaPreview/MediaPreview";
import { useMediaSelector } from "@/components/MediaSelector/useMediaSelector";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import ItemBar from "@/framework/KioForm/common/ItemBar";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import Loader from "@/framework/Loader";
import { useDebounce } from "@/hooks/useDebounce";
import { useLoadingState } from "@/hooks/useLoadingState";
import { useLookup } from "@/hooks/useLookup";
import Api from "@/services/Api";
import { iseAspectRatioValid } from "@/framework/KioForm/fields/MediaSelectorField/utils";
import { PreviewContainer } from "@/framework/KioForm/fields/MediaSelectorField/PreviewContainer";
import { ActionButtonRow } from "@/framework/KioForm/fields/MediaSelectorField/ActionButtonRow";
import { ActionButtonContainer } from "@/framework/KioForm/fields/MediaSelectorField/ActionButtonContainer";
import { SelectMediaLabel } from "@/framework/KioForm/fields/MediaSelectorField/SelectMediaLabel";

const Container = styled("div")`
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
`;

const GridContainer = styled("div")`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
`;

const ImageContainer = styled("div")`
  display: flex;
  flex-flow: column;
  width: fit-content;
  height: fit-content;
`;

const SwitchContainer = styled("div")`
  display: flex;
  justify-content: end;
  z-index: 1;
  margin-top: -34px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: translate(0px, 34px) scale(1);
  > .MuiCheckbox-root {
    padding: 5px;
  }
`;

export interface DMImage {
  imageId?: number | string;
  licenses?: Array<any>;
  mediaId: string;
  photographer?: string;
  url: string;
}

export interface DMField {
  field: string;
  imageIds?: Array<string>;
}

export interface DMData {
  mediaId?: number;
  includeFields?: Array<DMField>;
}

export interface DMSelectorFieldProps extends Omit<FieldProps, "formData"> {
  formData?: DMData;
}

const fieldTypeOptions = (t: TFunction) => [
  {
    label: t?.("generic.images") || "images",
    value: "images",
  },
  {
    label: t?.("generic.title") || "title",
    value: "title",
  },
  {
    label: t?.("generic.motif") || "motif",
    value: "motif",
  },
  {
    label: t?.("generic.description") || "description",
    value: "description",
  },
  {
    label: t?.("generic.history") || "history",
    value: "history",
  },
  {
    label: t?.("generic.owner") || "owner",
    value: "owner",
  },
  {
    label: t?.("mediaType.video") || "video",
    value: "video",
  },
  {
    label: t?.("mediaType.audio") || "audio",
    value: "audio",
  },
];

export const DMSelectorField: FC<DMSelectorFieldProps> = ({
  formData,
  onChange,
  id,
  disabled,
  readonly,
  required,
  uiSchema,
}) => {
  const { t } = useTranslation("common");

  const { isOpen, openMediaDrawer, closeMediaDrawer } = useMediaSelector();
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const handleUnselectMediaTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const handleMediaSelectedTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const itemsExpandedState = useLookup<boolean>();

  const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
  const [images, setImages] = useState<Array<DMImage>>([]);
  const [includeFields, setIncludeFields] = useState<Array<DMField>>(formData?.includeFields || []);

  const aspectRatio = uiSchema?.["ui:options"]?.["aspectRatio"];
  const aspectRatioStyle =
    !!aspectRatio && iseAspectRatioValid(aspectRatio)
      ? { aspectRatio: aspectRatio.replace(":", "/"), minHeight: "auto" }
      : {};

  useEffect(() => {
    if (selectedMedia) {
      const abortController = new AbortController();
      fetch(
        `https://api.dimu.org/api/artifact?unique_id=${selectedMedia?.identifier}&mapping=simple_json&api.key=demo`,
        { signal: abortController.signal }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.images) {
            const tempImages = data.images.map((img: DMImage) => ({
              ...img,
              url: img.url.replace("http:", "https:"),
            }));
            setImages(tempImages);
          }
        });
      return () => {
        abortController.abort();
      };
    } else {
      setImages([]);
    }
  }, [selectedMedia]);

  const handleUnselectMedia = () => {
    setIncludeFields([]);
    // Hack to make onChange actually fire:
    handleUnselectMediaTimeoutIdRef.current = setTimeout(() => {
      onChange({
        ...formData,
        includeFields: [],
        mediaId: null,
      });
      handleUnselectMediaTimeoutIdRef.current = null;
    });
  };

  const handleMediaSelected = (media: Media) => {
    setIncludeFields([]);
    // Hack to make onChange actually fire:
    handleMediaSelectedTimeoutIdRef.current = setTimeout(() => {
      onChange({
        ...formData,
        includeFields: [],
        mediaId: media.id,
      });
      handleMediaSelectedTimeoutIdRef.current = null;
    });
    closeMediaDrawer();
  };

  useEffect(
    () => () => {
      handleUnselectMediaTimeoutIdRef.current !== null && clearTimeout(handleUnselectMediaTimeoutIdRef.current);
      handleMediaSelectedTimeoutIdRef.current !== null && clearTimeout(handleMediaSelectedTimeoutIdRef.current);
    },
    []
  );

  const toggleMediaDrawer = () => {
    isOpen ? closeMediaDrawer() : openMediaDrawer([MediaType.DM], handleMediaSelected);
  };

  const debouncedFetchMedia = useDebounce<string>(200, () => {
    const mediaId: number | undefined = formData?.mediaId;
    if (!mediaId && selectedMedia) setSelectedMedia(null);
    else if (mediaId && selectedMedia?.id !== mediaId) {
      startLoading();
      const loadMedia = Api.getOneMedia(mediaId);
      loadMedia
        .fetchDirect(null)
        .then((media) => setSelectedMedia(media))
        .then(loadMedia.abort)
        .finally(stopLoading);
    }
  });

  useEffect(() => {
    debouncedFetchMedia();
  }, [formData?.mediaId, debouncedFetchMedia]);

  const getContentElement = () => {
    if (isLoading) {
      return (
        <PreviewContainer>
          <Loader loadingText={t("kioForm.widgets.MediaSelectorField.loadingMedia")} />
        </PreviewContainer>
      );
    }
    if (selectedMedia) {
      return (
        <PreviewContainer style={aspectRatioStyle}>
          <ActionButtonRow>
            <ActionButtonContainer>
              <Tooltip title={t("kioForm.fields.DMSelectorField.selectDMMedia") || "Select"}>
                <IconButton color="primary" size="small" onClick={toggleMediaDrawer}>
                  <FolderOpenOutlined />
                </IconButton>
              </Tooltip>
            </ActionButtonContainer>
            <ActionButtonContainer>
              <Tooltip title={t("kioForm.widgets.MediaSelectorField.removeMedia") || "Remove"}>
                <IconButton color="primary" size="small" onClick={handleUnselectMedia}>
                  <RemoveCircleOutlineOutlined />
                </IconButton>
              </Tooltip>
            </ActionButtonContainer>
          </ActionButtonRow>

          <MediaPreview media={selectedMedia} />
        </PreviewContainer>
      );
    }
    if (!selectedMedia && !disabled && !readonly) {
      return (
        <ButtonBase onClick={toggleMediaDrawer} disabled={disabled || readonly}>
          <PreviewContainer style={aspectRatioStyle}>
            <SelectMediaLabel variant="button">
              <PermMediaOutlined />
              {t("kioForm.fields.DMSelectorField.selectDMMedia")}
            </SelectMediaLabel>
          </PreviewContainer>
        </ButtonBase>
      );
    }
  };

  const swapFields = (a: number, b: number) => {
    const tempFields = [...includeFields];
    const tempA = tempFields[a];
    tempFields[a] = tempFields[b];
    tempFields[b] = tempA;
    setIncludeFields(tempFields);
    onChange({
      ...formData,
      includeFields: tempFields,
    });
  };

  const deleteField = (i: number) => {
    const tempFields = [...includeFields];
    tempFields.splice(i, 1);
    setIncludeFields(tempFields);
    onChange({
      ...formData,
      includeFields: tempFields,
    });
  };

  const addField = () => {
    const tempFields = [...includeFields];
    tempFields.push({ field: "" });
    setIncludeFields(tempFields);
    onChange({
      ...formData,
      includeFields: tempFields,
    });
  };

  const setField = (value: string, index: number) => {
    const tempFields = [...includeFields];
    tempFields[index].field = value;
    value === "images" && (tempFields[index].imageIds = []);
    setIncludeFields(tempFields);
    onChange({
      ...formData,
      includeFields: tempFields,
    });
  };

  const handleOnSelectionChanged = (fieldIndex: number, imageIndex: number) => {
    const tempFields = [...includeFields];
    const tempIds = tempFields[fieldIndex].imageIds || [];
    const index = tempIds.indexOf(images[imageIndex].mediaId);

    if (index < 0) tempIds.push(images[imageIndex].mediaId);
    else tempIds.splice(index, 1);

    tempFields[fieldIndex].imageIds = tempIds;
    setIncludeFields(tempFields);
    onChange({
      ...formData,
      includeFields: tempFields,
    });
  };

  return (
    <Container>
      <input
        id={id}
        type="hidden"
        value={formData?.mediaId || ""}
        disabled={disabled}
        required={required}
        readOnly={readonly}
      />
      {getContentElement()}

      <div>
        {includeFields.length > 0 &&
          includeFields.map((element, i) => (
            <div key={`field-${i}-${element.field}`}>
              <ItemBar
                onDelete={() => deleteField(i)}
                onMoveUp={() => swapFields(i, i - 1)}
                onMoveDown={() => swapFields(i, i + 1)}
                enableDelete={true}
                enableMovable={true}
                moveUpButtonDisabled={i <= 0}
                moveDownButtonDisabled={i >= includeFields.length - 1}
              >
                <KioSelect
                  label={t("kioForm.fields.DMSelectorField.selectField")}
                  value={element.field}
                  onChange={(value) => setField(value, i)}
                  options={fieldTypeOptions(t)}
                />
              </ItemBar>
              {element.field === "images" && (
                <Box m={"-16px 0 16px 0"}>
                  <CollapseBar
                    title={t("kioForm.fields.DMSelectorField.selectImages")}
                    expanded={!!itemsExpandedState.getItem(i)}
                    setExpanded={(e) => itemsExpandedState.setItem(i, e)}
                  >
                    <GridContainer>
                      {images.map((item, j) => (
                        <ImageContainer key={`dm-image-${j}`}>
                          <SwitchContainer>
                            <Checkbox
                              color="primary"
                              checked={includeFields[i].imageIds?.includes(item.mediaId)}
                              value={includeFields[i].imageIds?.[j]}
                              onChange={() => handleOnSelectionChanged(i, j)}
                            />
                          </SwitchContainer>
                          <StyledImage src={item?.url || ""} height={"150px"} width={"150px"} />
                        </ImageContainer>
                      ))}
                    </GridContainer>
                  </CollapseBar>
                </Box>
              )}
            </div>
          ))}
        <Button variant="outlined" color="primary" onClick={addField} startIcon={<Add />} fullWidth>
          {t("kioForm.fields.DMSelectorField.addField")}
        </Button>
      </div>
    </Container>
  );
};

export default DMSelectorField;
