import type { FC } from "react";
import type { CreateOrEditProps } from "@/components/CreateOrEdit";
import CreateOrEdit from "@/components/CreateOrEdit";
import { schema } from "@/declarations/schemas/user/schema";
import { uiSchema } from "@/declarations/schemas/user/uiSchema";
import type { RequestContext } from "@/declarations/RequestContext";
import Api from "@/services/Api";
import type KioUser from "@/declarations/models/KioUser";

const createUserFormData: CreateOrEditProps<KioUser>["onSubmit"] = (user: KioUser): RequestContext<KioUser> =>
  Api.createUser(user);

export const CreateUserView: FC = () => (
  <CreateOrEdit schema={schema} uiSchema={uiSchema} onSubmit={createUserFormData} />
);

export default CreateUserView;
