import { styled } from "@mui/material";
import type { FC } from "react";
import { useEffect, useState } from "react";
import FileUpload from "@/components/FileUpload";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import type { MimeTypes } from "@/declarations/models/MimeTypes";
import Api from "@/services/Api";

export interface AddDMSContentProps {
  allowedMediaTypes: Array<MediaType>;
  media?: Media | null;
  onCancel?: () => void;
  onMediaSaved?: () => void;
  ownerId?: number;
  instanceId?: number;
  isModal?: boolean;
}

const Container = styled("div")`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

export const AddDMSContent: FC<AddDMSContentProps> = ({
  allowedMediaTypes,
  media,
  onMediaSaved = () => {},
  onCancel = () => {},
  ownerId,
  instanceId,
  isModal = false,
}) => {
  ownerId = media?.owner_id || ownerId;
  instanceId = media?.application_instance_id || instanceId;

  const [allowedMimeTypes, setAllowedMediaTypes] = useState<Array<string>>([]);
  useEffect(() => {
    const fetchMimeTypes = async () => {
      const mimeTypes: MimeTypes | null = await Api.getSupportedMediaTypes().fetchDirect(null);
      const allowed: Array<string> = [];

      if (allowedMediaTypes?.includes(MediaType.IMAGE) && mimeTypes) allowed.push(...mimeTypes.image);
      if (allowedMediaTypes?.includes(MediaType.VIDEO) && mimeTypes) allowed.push(...mimeTypes.video);
      if (allowedMediaTypes?.includes(MediaType.AUDIO) && mimeTypes) allowed.push(...mimeTypes.audio);
      if (allowedMediaTypes?.includes(MediaType.DOCUMENT) && mimeTypes) allowed.push(...mimeTypes.document);
      if (allowedMediaTypes?.includes(MediaType.MODEL_3D) && mimeTypes) allowed.push(...mimeTypes.model3d);

      setAllowedMediaTypes(allowed);
    };

    fetchMimeTypes().catch(console.error);
  }, [allowedMediaTypes]);

  return (
    <Container>
      <FileUpload
        allowedMimeTypes={allowedMimeTypes}
        name="dms-upload"
        onCancel={onCancel}
        onSaved={onMediaSaved}
        required
        ownerId={ownerId}
        instanceId={instanceId}
        isModal={isModal}
      />
    </Container>
  );
};

export default AddDMSContent;
