import type { FC } from "react";
import { useMemo } from "react";
import type { WidgetProps } from "@rjsf/utils";
import type { JSONSchema7TypeName } from "json-schema";
import type { KioSelectFieldProps, SelectOption } from "@/framework/KioForm/common/KioSelect";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import { getValueFromUiSchema } from "@/utils/schema";

const getOptions = (options: WidgetProps["options"]): SelectOption[] => {
  if (options?.choices && Array.isArray(options.choices)) {
    return options.choices.map((opt) => ({
      label: opt?.["ui:label"] ?? opt.label ?? "",
      value: opt?.value,
    }));
  }
  if (options?.enumOptions && Array.isArray(options.enumOptions)) {
    return options.enumOptions;
  }
  return [];
};

const isValidOption = (option: any, widgetType?: JSONSchema7TypeName | JSONSchema7TypeName[]): boolean => {
  if (!option?.["label"] && !option?.value && typeof widgetType !== "string") return false;
  const labelValid = typeof option["label"] === "string";
  const valueValid = typeof option.value === widgetType;
  return labelValid && valueValid;
};

export const SelectWidget: FC<WidgetProps> = ({
  schema,
  uiSchema,
  onChange,
  value,
  placeholder,
  options,
  readonly,
  disabled,
  required,
  label,
}) => {
  const _options: SelectOption[] = useMemo(() => {
    const tmp: SelectOption[] = [];
    const chosenOptions = getOptions(options);
    for (const option of chosenOptions) {
      if (!isValidOption(option, schema.type)) continue;
      tmp.push({
        label: option.label ?? "",
        value: option.value ?? "",
      });
    }
    return tmp;
  }, [options, schema]);

  const onChangeHandler: KioSelectFieldProps["onChange"] = (newValue) => {
    onChange?.(newValue);
  };

  const isHeaderVariant = Boolean(uiSchema?.["ui:options"]?.isHeaderVariant);
  const title =
    schema.title || getValueFromUiSchema("title", uiSchema) || getValueFromUiSchema("label", uiSchema) || label;

  return (
    <KioSelect
      disabled={disabled || readonly}
      required={required}
      placeholder={placeholder}
      value={value ?? schema?.default}
      label={title}
      onChange={onChangeHandler}
      isHeaderVariant={isHeaderVariant}
      options={_options}
      helperText={getValueFromUiSchema("help", uiSchema)}
      displayEmpty
    />
  );
};
